import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { Storage, API } from "aws-amplify";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography"; 
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LoaderButton from "../LoaderButton";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomizedTooltips from "../../lib/tooltIp";
import CardContent from "@mui/material/CardContent";
import StripeLogo from "../../static/images/Stripe_Logo.svg";
import PlaidLogo from "../../static/images/Plaid_logo.svg";
import usePersistedState from "../../usePersistedState";
import { Spinner } from "../../common/components/spinner";
import { areas } from "../../common/constants/areas";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import SvgIcon from "@mui/material/SvgIcon";
import { Box } from "@mui/material";
import { StipeKeyManage, PlaidKeyManage } from "./index";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from 'notistack';
import { Skeleton } from '@mui/material';
import { ReactComponent as VisaSvg } from "../../static/images/visa-light-large.svg";
import { ReactComponent as MasterCardSvg } from "../../static/images/mastercard-light-large.svg";
import { ReactComponent as AmericanExpressSvg } from "../../static/images/americanexpress-light-large.svg";
import { ReactComponent as MaestroSvg } from "../../static/images/maestro-light-large.svg";
import { ReactComponent as DiscoverSvg } from "../../static/images/discover-light-large.svg";
const PREFIX = "settingspayment";

const classes = {
  card1: `${PREFIX}-card1`,
  card2: `${PREFIX}-card2`,
  details: `${PREFIX}-details`,
  cover: `${PREFIX}-cover`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.card1}`]: {
    display: "flex",
    marginRight: "auto",
    marginBottom: "30px",
    maxHeight: "215px",
    maxWidth: "215px",
  },

  [`& .${classes.card2}`]: {
    display: "flex",
    marginRight: "auto",
    marginBottom: "30px",
    maxHeight: "315px",
    maxWidth: "315px",
  },

  [`& .${classes.details}`]: {
    display: "flex",
  },

  [`& .${classes.cover}`]: {},
}));

export function SettingsPayment(props) {
  console.log("ADD NEW PROPERTY PROPS ", props);
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const inputSize = props.fromRent ? 6 : 6;
  const [isEditing, setIsEditing] = useState(false);
  const [stripeOpen, setStripeOpen] = useState(false);
  const [plaidOpen, setPlaidOpen] = useState(false);
  const [plaidValues, setPlaidValues] = useState({
    PlaidApiMode: "",
    PlaidSecretKey: "",
    PlaidClientKey: "",
    DisplayPlaidKey: "",
    IncludePlaidFee: false
  });
  const [stripeValues, setStripeValues] = useState({
    StripePublicKey: "",
    StripeSecretKey: "",
    DisplayStripeKey: "",
    IncludeStripeFee:false
  });
  const [property, setProperty] = usePersistedState("property", {
    MasterID: "",
    StripePublicKey: "",
    StripeSecretKey: "",
    PlaidApiMode: "",
    PlaidSecretKey: "",
    PlaidClientKey: "",
    OmitTax: true,
    Tax: 0,
    CurrencyName: "",
    CurrencyCode: "",
    IncludeStripeFee: false,
    IncludePlaidFee: false,
    DisplayStripeKey: "",
    DisplayPlaidKey: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProperty, setSelectedProperty] = usePersistedState(
    "selectedProperty",
    {}
  );
  useEffect(() => {
    console.log("selectedProperty-----------", selectedProperty);
    if (
      (selectedProperty.DisplayPlaidKey &&
        selectedProperty.DisplayPlaidKey !== "") ||
      (selectedProperty.DisplayStripeKey &&
        selectedProperty.DisplayStripeKey !== "")
    ) {
      setIsEditing(true);
      setStripeValues((prvProperty) => {
        return {
          ...prvProperty,
          DisplayStripeKey: selectedProperty.DisplayStripeKey,
          IncludeStripeFee: selectedProperty.IncludeStripeFee? selectedProperty.IncludeStripeFee:false
        };
      });
      setPlaidValues((prvProperty) => {
        return {
          ...prvProperty,
          DisplayPlaidKey: selectedProperty.DisplayPlaidKey,
          PlaidApiMode: selectedProperty.PlaidApiMode,
          IncludePlaidFee: selectedProperty.IncludePlaidFee? selectedProperty.IncludePlaidFee:false
        };
      });
    }
    setProperty(selectedProperty);
  }, []);

  const StripeDisplayCard = (prop) => {
    console.log("stripe keu add window", prop);
    let editMode =
      prop.type === "plaid" &&
        plaidValues.DisplayPlaidKey &&
        plaidValues.DisplayPlaidKey != ""
        ? true
        : prop.type === "stripe" &&
          stripeValues.DisplayStripeKey &&
          stripeValues.DisplayStripeKey != ""
          ? true
          : false;
    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Card sx={{
          display: "flex",
          width: "100%",
          border: "1px solid #DADADA",
          borderRadius: "5px",
        }} >
          <CardContent >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={9}>
                {prop.type === "plaid" ? (
                  <img src={PlaidLogo} alt="" height="30" />
                ) : (
                  <img src={StripeLogo} alt="" height="30" />
                )}
              </Grid>

              <Grid item xs={3} sx={{ textAlign: "right" }}>
                {prop.type === "plaid" ? (
                  <Typography variant="button">ACH</Typography>
                ) : (
                  <Typography variant="button">CREDIT CARD</Typography>
                )}
              </Grid>
              <Grid item xs={12} sx={{ ml: 2, borderBottom: '1px solid #cccc' }}>
              </Grid>
              <Grid item xs={12}>
                <Typography>PAYMENT METHODS</Typography>
              </Grid>
              {prop.type !== "plaid" ? (
                <Grid item xs={12}>
                  {!props.isLoading && (
                    <div>
                      <SvgIcon
                        viewBox="0 0 240 240"
                        className={
                          "makepayment-gatewaysvg addcreditcard-marginleft3d5p"
                        }
                      >
                        <VisaSvg width="240" height="240" />
                      </SvgIcon>
                      <SvgIcon
                        viewBox="0 0 240 240"
                        className={
                          "makepayment-gatewaysvg addcreditcard-marginleft3d5p"
                        }
                      >
                        <MasterCardSvg width="240" height="240" />
                      </SvgIcon>
                      <SvgIcon
                        viewBox="0 0 240 240"
                        className={
                          "makepayment-gatewaysvg addcreditcard-marginleft3d5p"
                        }
                      >
                        <AmericanExpressSvg width="240" height="240" />
                      </SvgIcon>
                      <SvgIcon
                        viewBox="0 0 240 240"
                        className={
                          "makepayment-gatewaysvg addcreditcard-marginleft3d5p"
                        }
                      >
                        <MaestroSvg width="240" height="240" />
                      </SvgIcon>
                      <SvgIcon
                        viewBox="0 0 240 240"
                        className={
                          "makepayment-gatewaysvg addcreditcard-marginleft3d5p"
                        }
                      >
                        <DiscoverSvg width="240" height="240" />
                      </SvgIcon>
                    </div>
                  )}
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography>
                    Supports over 11,500 institutions across North America and
                    Europe, learn more
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Box>
                  {prop.type !== "plaid" ? (
                    <Card
                      sx={{ backgroundColor: "background.card" }}
                      variant="outlined"
                    >
                      <Grid container sx={{ p: 2, pt:2.5,pb:2.5, margin: 0 }} >
                        <Grid item xs={12}>
                          <Typography
                            className="home-headerfour"
                            variant="subtitle1"
                            paragraph={true}
                          >
                            Credit card rate
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" >
                            As low as 2.9% + $0.30 per transaction
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Button sx={{ pl: 0 }} color="info" onClick={() => {
                            window.open('https://stripe.com/', '_blank');
                          }} >LEARN MORE</Button>
                        </Grid>
                      </Grid>
                    </Card>
                  ) : (
                    <Card
                      sx={{ backgroundColor: "background.card" }}
                      variant="outlined"
                    >
                      <Grid container spacing={1} sx={{ p: 2, margin: 0 }}  >
                        <Grid item xs={12}>
                          <Typography
                            className="home-headerfour "
                            variant="subtitle1"
                          >
                            ACH rate (Pay as you go)
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" >
                            $0.10 per transaction + Stripe fee of 0.8%
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" >
                            One-time fee of $1.50 for each account setup
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Button sx={{ pl: 0 }} color="info" onClick={() => {
                            window.open('https://plaid.com/', '_blank');
                          }} >LEARN MORE</Button>
                        </Grid>
                      </Grid>
                    </Card>
                  )}
                </Box>
              </Grid>
              {/* </Grid> */}
              <Grid item xs={12} sx={{ ml: 2, borderBottom: '1px solid #cccc' }}>
              </Grid>
              {!editMode ? (
                <>
                  <Grid item xs={12}>
                    {prop.type !== "plaid" ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography sx={{ color: '#fff' }} >
                            We use Stripe with Plaid Auth to send and receive
                            payments. Please enable the integration between both
                            services before adding your Plaid API keys
                          </Typography>
                        </Grid>
                        <Grid item >
                          <Button
                            variant="outlined"
                            color="info"
                            className="blueOutlineButton"
                            onClick={() => {
                              window.open('https://dashboard.plaid.com/team/integrations', '_blank');
                            }}
                          >
                            SETUP ACCOUNT
                          </Button>{" "}
                        </Grid>
                        <Grid item >
                          <Button
                            sx={{ boxShadow: "none"}}
                            variant="contained"
                            color="info" 
                            onClick={() => {
                              setStripeOpen(true);
                            }}
                          >
                            ADD KEYS
                          </Button>
                        </Grid>
                       
                      </Grid>
                    ) : (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography>
                            We use Stripe with Plaid Auth to send and receive
                            payments. Please enable the integration between both
                            services before adding your Plaid API keys
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button variant="outlined" color="info" onClick={() => {
                            window.open('https://dashboard.plaid.com/team/integrations', '_blank');
                          }}>
                            SETUP ACCOUNT
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            sx={{ boxShadow: "none"}}
                            variant="contained"
                            color="info"
                            onClick={() => {
                              setPlaidOpen(true);
                            }}
                          >
                            ADD KEYS
                          </Button>
                        </Grid> 
                      </Grid>
                    )}
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  {prop.type !== "plaid" ? (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography sx={{ color: '#fff' }}>
                            We use Stripe with Plaid Auth to send and receive
                            payments. Please enable the integration between both
                            services before adding your Plaid API keys
                          </Typography>
                        </Grid>
                      </Grid>
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        {!isLoading && <Alert
                          variant="filled"
                          severity="success"
                          action={
                            <>
                              <div className="align-center">
                                <Button
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setStripeOpen(true);
                                  }}
                                >
                                  EDIT KEYS
                                </Button>
                                <IconButton color="inherit" size="small">
                                  <CloseIcon   onClick={async () => {
                                    await deletePaymentProvider(prop);
                                  }} />
                                </IconButton>
                              </div>
                            </>
                          }
                        >
                          <Typography>Active</Typography>
                          <Typography>API KEY: {prop.displayKey}</Typography>

                          {prop.type !== "plaid" && <Typography>
                            {`${prop.includeFee?'Yes':'No'}, pass payment processing fee on to the tenants`}
                          </Typography>}
                        </Alert>}
                        {isLoading && <Skeleton variant="rectangular" height={100} />}
                      </Stack></>
                  ) : (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography>
                            We use Stripe with Plaid Auth to send and receive
                            payments. Please enable the integration between both
                            services before adding your Plaid API keys
                          </Typography>
                        </Grid>
                      </Grid>
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        {!isLoading &&<Alert
                          variant="filled"
                          severity="success"
                          action={
                            <>
                              <div className="align-center">
                                <Button
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                    setPlaidOpen(true);
                                  }}
                                >
                                  EDIT KEYS
                                </Button>
                                <IconButton color="inherit" size="small">
                                  <CloseIcon onClick={async () => {
                                    await deletePaymentProvider(prop);
                                  }} />
                                </IconButton>
                              </div>
                            </>
                          }
                        >
                          <Typography>Active</Typography>
                          <Typography>API KEY: {prop.displayKey}, MODE : {prop.PlaidApiMode}</Typography>
                          
                          {prop.type === "plaid" && <Typography>
                            {`${prop.includeFee?'Yes':'No'}, pass payment processing fee on to the tenants`}
                          </Typography>}
                        </Alert>}
                        {isLoading && <Skeleton variant="rectangular" height={100} />}
                      </Stack>
                    </>
                  )}
                </Grid>
              )}
              {/* </Grid> */}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  async function deletePaymentProvider(e) {
    /*  e.preventDefault(); */
    console.log('### plaid api mode saveProperty',plaidValues )
    setIsLoading(true);
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: {
        ...e, 
        PropertyID:selectedProperty.PropertyID,
        property:selectedProperty, 
        userData: props.userSession,
        MasterType: "Property",
        UserName: props.userSession.UserName,
      },
      queryStringParameters: {
        module: "property",
        op: "deletePaymentProvider",
      },
    };
    API.post(apiName, path, myInit)
      .then(async (response) => {
        setIsLoading(false);
        if (response && response.success) { 
          setTimeout(async () => {
            /*  handleClearForm(); */
            let myProperties = await loadMyProperties(
              props.userSession.UserName
            );
            enqueueSnackbar(response.message, { variant: 'success' }); 
            if (myProperties && myProperties.length > 0) {
              let currentProperty =
                myProperties[
                myProperties.findIndex(
                  (x) => x.PropertyID == selectedProperty.PropertyID
                )
                ];
              localStorage.userProperties = JSON.stringify(myProperties);
              setSelectedProperty(currentProperty ? currentProperty : {});
            }
            history.push({
              pathname: `/${property.PropertyName.replace(
                /\s+/g,
                "-"
              )}/settings`,
              props: {
                fromAppbar: true,
              },
            });
          }, 500);
        } else {
          enqueueSnackbar(response.message, { variant: 'error' }); 
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.message, { variant: 'error' }); 
        setIsLoading(false);
      });
  }
  async function saveProperty(e) {
    /*  e.preventDefault(); */
    console.log('### plaid api mode saveProperty',plaidValues )
    setIsLoading(true);
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: {
        ...stripeValues,
        ...plaidValues,
        ...e,
        Tax: e.OmitTax ? 0 : e.Tax,
        userData: props.userSession,
        MasterType: "Property",
        UserName: props.userSession.UserName,
      },
      queryStringParameters: {
        module: "property",
        op: "savePaymentSetting",
      },
    };
    API.post(apiName, path, myInit)
      .then(async (response) => {
        setIsLoading(false);
        if (response && response.success) { 
          setTimeout(async () => {
            /*  handleClearForm(); */
            let myProperties = await loadMyProperties(
              props.userSession.UserName
            );
            enqueueSnackbar(response.message, { variant: 'success' }); 
            if (myProperties && myProperties.length > 0) {
              let currentProperty =
                myProperties[
                myProperties.findIndex(
                  (x) => x.PropertyID == selectedProperty.PropertyID
                )
                ];
              localStorage.userProperties = JSON.stringify(myProperties);
              setSelectedProperty(currentProperty ? currentProperty : {});
            }
            history.push({
              pathname: `/${property.PropertyName.replace(
                /\s+/g,
                "-"
              )}/settings`,
              props: {
                fromAppbar: true,
              },
            });
          }, 500);
        } else {
          enqueueSnackbar(response.message, { variant: 'error' }); 
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.message, { variant: 'error' }); 
        setIsLoading(false);
      });
  }

  async function eventChange(e, updatedKey) {
    console.log("eventChange ##", e, updatedKey);
    if (e === "stripe add") {
      var disKey = updatedKey.StripePublicKey;
      if (disKey) {
        disKey = disKey.slice(disKey.length - 15).replace(/.(?=.{4})/g, "*");
      }
      setStripeValues((prvproperty) => {
        return {
          ...prvproperty,
          StripePublicKey: updatedKey.StripePublicKey,
          StripeSecretKey: updatedKey.StripeSecretKey,
          DisplayStripeKey: disKey,
          IncludeStripeFee: updatedKey.IncludeStripeFee
        };
      });
      setStripeOpen(false);
    } else if (e === "stripe close") {
      setStripeOpen(false);
    } else if (e === "plaid add") {      
      var disKey = updatedKey.PlaidClientKey;
      if (disKey) {
        disKey = disKey.slice(disKey.length - 15).replace(/.(?=.{4})/g, "*");
      }
      setPlaidValues((prvProperty) => {
        return {
          ...prvProperty,
          PlaidClientKey: updatedKey.PlaidClientKey,
          PlaidSecretKey: updatedKey.PlaidSecretKey,
          PlaidApiMode: updatedKey.PlaidApiMode,
          DisplayPlaidKey: disKey,
          IncludePlaidFee: updatedKey.IncludePlaidFee
        };
      });
      setPlaidOpen(false);
    } else if (e === "plaid close") {
      setPlaidOpen(false);
    }
  }
  /**
   * this function will load properties for the user
   */
  function loadMyProperties() {
    setIsLoading(true);
    var masterType = "User";
    var apiModule = "property";
    var op = "listMyProperty";
    let body = {
      userData: props.userSession,
      masterType: masterType,
      MasterID: props.userSession.UserName,
      for: "CardDisplay",
    };
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op,
        },
      };
      API.post(apiName, path, myInit)
        .then((response) => {
          if (response && response.length > 0) {
            resolve(response);
          } else {
            resolve([]);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
  return (
    <Root className="home-root">
      <StipeKeyManage
        {...property}
        eventChange={eventChange}
        isEditing={isEditing}
        stripeOpen={stripeOpen}
      />
      <PlaidKeyManage
        property={property}
        eventChange={eventChange}
        isEditing={isEditing}
        plaidOpen={plaidOpen}
      />
      <Grid container spacing={0} direction="column">
        <Grid item>
          <Container component="main" className="home-main">
            <CssBaseline />
            <Spinner className="common-spinner" area={areas.admin} />
            <div className="home-paper">
              <div style={{ padding: "20px 0px 40px 0px" }}>
                <Grid container spacing={1} className={"add-padding-p8p"}>
                  <Grid item>
                    <Button
                      onClick={(e) => {
                        history.push(
                          `/${selectedProperty.PropertyName.replace(
                            /\s+/g,
                            "-"
                          )}/settings`
                        );
                      }}
                    >
                      <Typography
                        component="h6"
                        sx={{ fontWeight: 600 }}
                        color="text.primary"
                        variant="h6"
                      >
                        &#8249; {" RETURN"}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Typography component="h1" variant="h1">
                      Payments
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item sx={{pb:2}} xs={12}>
                  <Typography className="home-headerfour" variant="subtitle1">
                    Update property banking, payment method, currency and taxes
                  </Typography>
                </Grid>
              </Grid>
              <Formik
                initialValues={{
                  MasterID: property.PropertyID,
                  CurrencyName: property.CurrencyName,
                  OmitTax: property.OmitTax,
                  Tax: property.Tax,
                  DisplayStripeKey: property.DisplayStripeKey,
                  DisplayPlaidKey: property.DisplayPlaidKey
                }}
                validationSchema={Yup.object().shape({
                  CurrencyName: Yup.string()
                    .max(150)
                    .required("Field is required"),
                  OmitTax: Yup.boolean(),
                  Tax: Yup.number().when("OmitTax", {
                    is: false,
                    then: Yup.number()
                      .required("Field is required")
                      .positive("Tax rate should be greater than 0"),
                  }),
                })}
                onSubmit={async (e) => {
                  await saveProperty(e);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form
                   onSubmit={handleSubmit}
                  >
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item sx={{ display: 'inline-flex' }} xs={12} sm={12} md={3} lg={3}>
                            <InputLabel
                              sx={{ pl: "5px", color: "rgba(0, 0, 0, 0.87)" }}
                              className={classes.wrapIcon}
                            >
                              PROPERTY CURRENCY
                            </InputLabel>
                            <CustomizedTooltips
                              content={
                                "Currency is based on country/region selection. Contact support if you want to change your currency"
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                            <TextField
                              error={Boolean(
                                touched.CurrencyName && errors.CurrencyName
                              )}
                              fullWidth
                              helperText={
                                touched.CurrencyName && errors.CurrencyName
                              }
                              label="PROPERTY CURRENCY"
                              margin="normal"
                              name="CurrencyName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.CurrencyName}
                              disabled
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item xs={12} sm={12} md={3} lg={3}>
                            <FormControlLabel
                              label="OMIT TAX"
                              control={
                                <Checkbox
                                  color="default"
                                  checked={values.OmitTax}
                                  name="OmitTax"
                                  onChange={handleChange}
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={8} md={3} lg={3}>
                            <TextField
                              error={Boolean(touched.Tax && errors.Tax)}
                              fullWidth
                              helperText={touched.Tax && errors.Tax}
                              label="TAX %"
                              margin="normal"
                              name="Tax"
                              type="number"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.Tax}
                              disabled={values.OmitTax}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5">Payment Providers</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <StripeDisplayCard
                          type={"stripe"}
                          includeFee={stripeValues.IncludeStripeFee}
                          displayKey={stripeValues.DisplayStripeKey}
                          inputSize={inputSize}
                        />
                      </Grid>
                      {/** Uncomment for enabling PLAID feature */}
                     {/*  <Grid item xs={12} sm={12} md={6} lg={6}>
                        <StripeDisplayCard
                          type={"plaid"}
                          includeFee={plaidValues.IncludePlaidFee}
                          PlaidApiMode={plaidValues.PlaidApiMode}
                          displayKey={plaidValues.DisplayPlaidKey}
                          inputSize={inputSize}
                        />
                      </Grid>       */}
                      {/** Uncomment for enabling PLAID feature */}
                    </Grid>
                    <div style={{ padding: "20px 0px 40px 0px" }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <LoaderButton
                            block="true"
                            type="submit"
                            size="large"
                            isLoading={isLoading}
                            disabled={isSubmitting}
                          >
                            UPDATE
                          </LoaderButton>
                        </Grid>
                      </Grid>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </Container>
        </Grid>
      </Grid>
    </Root>
  );
}
