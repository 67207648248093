import React, { useRef, useState, useEffect } from "react";
import { styled } from '@mui/material/styles'; 
import { useHistory, useLocation } from "react-router-dom";
import { Storage, API } from 'aws-amplify';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import Typography from "@mui/material/Typography"; 
import FormControl from '@mui/material/FormControl'; 
import { useSnackbar } from 'notistack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoaderButton, { LoaderCLickButton, LoaderDeleteButton } from "../LoaderButton";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { AmplifyS3Image  } from '@aws-amplify/ui-react';
import Card from "@mui/material/Card"
import CardMedia from "@mui/material/CardMedia"
import CardActions from "@mui/material/CardActions"
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import usePersistedState from '../../usePersistedState';
import { Spinner } from '../../common/components/spinner';
import { areas } from '../../common/constants/areas';
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { useFormik } from 'formik';
import * as yup from 'yup';
import FocusError from "../../lib/FocusError";

const PREFIX = 'settingsgeneral';

const classes = {
  card1: `${PREFIX}-card1`,
  card2: `${PREFIX}-card2`,
  details: `${PREFIX}-details`,
  input: `${PREFIX}-input`,
  wrapIcon: `${PREFIX}-wrapIcon`,
  myImage: `${PREFIX}-myImage`,
  submit: `${PREFIX}-submit`,
  form: `${PREFIX}-form`,
  avatar: `${PREFIX}-avatar`,
  paper: `${PREFIX}-paper`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.card1}`]: {
    display: "flex",
    marginRight: "auto",
    marginBottom: "30px",
    maxHeight: "215px",
    maxWidth: "215px"
  },

  [`& .${classes.card2}`]: {
    display: "flex",
    marginRight: "auto",
    marginBottom: "30px",
    maxHeight: "315px",
    maxWidth: "315px"
  },

  [`& .${classes.details}`]: {
    display: "flex"
  },
  [`& .${classes.wrapIcon}`]: {
    verticalAlign: 'middle',
    display: 'inline-flex',
    wordWrap: "nowrap",
    paddingLeft: "9px"
  },
  [`& .${classes.input}`]: {
    display: 'none',
  },
  [`& .${classes.myImage}`]: {
    width: "100%"
  },
  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2)
  },
  [`& .${classes.form}`]: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  [`& .${classes.paper}`]: {
    display: "flex",
    justify: "center",
    flexDirection: "column",
    alignItems: "flex-start"
  }
}));


const DialogBox = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));
  
const DialogTitle = (props => {
  const { children,  onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography  sx={{m:0, p:2, pl:3, pr:3}}  {...other}>
      <Typography variant="h3" component="h3" sx={{ lineHeight:1}}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: "8px",
            top: "8px",
            color: "text.secondary",
          }} 
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const MapData = window['countryConfig'];
const validationSchema = yup.object({
     PropertyName: yup
    .string('Enter property name') 
    .required('Property name is required'),
    Email: yup
    .string('Enter property email')
    .email('Enter a valid email')
    .required('Email is required'),
    BusinessAddress: yup
    .string('Enter your Business address')
    .max(250, 'Address should be of maximum 250 characters length')
    .required('Business address is required'),
    Phone: yup
    .string('Enter your Phone number') 
    /* .Phone('Enter a valid phone number') */
    .required('Phone number is required'),
    WebSite: yup
    .string('Enter your website')
    .required('Website URL is required'),
});

export function SettingsGeneral(props) {
  console.log("ADD NEW PROPERTY PROPS ", props);
  const { enqueueSnackbar } = useSnackbar(); 
  let history = useHistory();
  const inputSize = props.fromRent ? 12 : 6;
  const [isEditing] = useState(true);
  const [property, setProperty] = usePersistedState("property", {
     PropertyName: ""
    , MasterID: ""
    , OwnerFirstName: ""
    , OwnerLastName: ""
    , Email: ""
    , PropertyUnit: ""
    , BusinessAddress: ""
    , City: ""
    , State: ""
    , Country: ""
    , PostalCode: ""
    , Phone: ""
    , WebSite: "" 
    , PropertyLogo: {}
    , PropertyImage: {} 
  })
  const [deltedKey] = useState([]);
  const [fromRent, setFromRent] = usePersistedState("fromRent", false);
  const [isLoading, setIsLoading] = useState(false);
  const [comboDisable, setcomboDisable] = useState(true);
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [isStateSelect, setIsStateSelect] = useState(false);
  const [selectedProperty, setSelectedProperty] = usePersistedState('selectedProperty', {});
  console.log("editing", isEditing);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  useEffect(function effectFunction() {
    if (!isStateSelect && property.Country !== "") {
      const result = MapData.filter((country) => country.name === property.Country);
      if (result.length > 0) {
        console.log("use effect country" + property.Country, result[0].states)
        setStateData(result[0].states);
        setcomboDisable(false);
        setIsStateSelect(true);
      }
    }
  }, []);
  useEffect(() => {
    console.log("selectedProperty-----------" , selectedProperty);
    setProperty(selectedProperty);
  },[]);
  
  const formik = useFormik({
    initialValues: {
        PropertyName: selectedProperty.PropertyName
      , MasterID: selectedProperty.MasterID
      , OwnerFirstName: selectedProperty.OwnerFirstName
      , OwnerLastName: selectedProperty.OwnerLastName
      , Email: selectedProperty.Email
      , PropertyUnit: selectedProperty.PropertyUnit
      , BusinessAddress: selectedProperty.BusinessAddress
      , City: selectedProperty.City
      , State: selectedProperty.State
      , Country: selectedProperty.Country
      , PostalCode: selectedProperty.PostalCode
      , Phone:selectedProperty.Phone
      , WebSite:selectedProperty.WebSite
      , PropertyLogo: selectedProperty.PropertyLogo
      , PropertyImage:selectedProperty.PropertyImage
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {    
      saveProperty(values);
    },
  });
  const ImageComponent = React.memo((props) => {     
    return (
      <Root style={{ paddingTop: "30px" }}>
        <Card className={props.Type === "PropertyLogo" ? classes.card1 : classes.card2}>
          <Root className={classes.details}>
            <CardActions>
              <Fab onClick={() => imageDelete(props)} style={{ color: "#000000", background: "none", boxShadow: "none", }} size="small" aria-label="delete picture" component="span">
                <CloseSharpIcon />
              </Fab>
            </CardActions>
          </Root>
          <CardMedia>
            <AmplifyS3Image  style={{"--height": "100%","--width": "100%"}} imgProps={{display: "block",  width:100, height:100 }} level="public" imgKey={props.FileKey} />
          </CardMedia>
        </Card>
      </Root>
    );
  });
 
  function imageDelete(e) {
    console.log("DeletedKeyset,e.FileKey", e);
    let items = deltedKey;
    if (items.length > 0) {
      for (let i = 0; i < items; i++) {
        if (items[i].FileKey !== e.FileKey) {
          items.push(e.FileKey);
        }
      }
    }
    else {
      items.push(e.FileKey);
    }
    Object.assign(deltedKey, items);
    (e.Type === "PropertyImage") ? setProperty({ ...property, "PropertyImage": {} }) : setProperty({ ...property, "PropertyLogo": {} });
    console.log("DeletedKeyset,go and  delete deltedKey", deltedKey);

  }

  function getFileExtension(filename) {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }
  const onImageChange =React.useCallback((e) => {
    const { files,name } = e.target;      
    const file = files[0]; 
    var img = document.createElement("img");
    img.onload = function () {
      if (name === "PropertyLogo" && this.width <= 500 && this.height <= 500 || (name === "PropertyImage" && this.width <= 1000 && this.height <= 500)) {
        let fileName = file.name;
        let fileExt = getFileExtension(fileName);
        let s3Key = (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)) + '.' + fileExt;
        const fileData = { FileKey: s3Key, FileName: fileName };
        Storage.put(s3Key, file, {
          contentType: file.type
        })
          .then(result => {
            setProperty({ ...property, [name]: fileData });
            formik.setFieldValue(name,fileData);
          })
          .catch(err => console.log(err));
      }
      else {
        (name === "PropertyImage") ? enqueueSnackbar("Image size should be smaller than 1000X500 pixels", { variant: 'error' }) : enqueueSnackbar("Image size should be lesser than 500X500", { variant: "error" });
      }
    };
    var reader = new FileReader();
    reader.onloadend = function (ended) {
      img.src = ended.target.result;
    };
    reader.readAsDataURL(files[0]);
  }, [property.PropertyImage, property.PropertyLogo]);
 
  const handleClose = (e) => {
    if (e == "delete") {
      setDeleteOpen(false);
    }
  };
  const handleClickOpen = (e) => {
    setDeleteOpen(true);
  };
  
  function handleClearForm() {
    document.getElementById("proeprty-form").reset();
  }
  async function deleteProperty() {
    setIsLoading(true);
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: { ...property, MasterType: 'Property', userData: props.userSession },
      queryStringParameters: {
        module: "property",
        op: "deleteProperty"
      }
    };
    API.post(apiName, path, myInit).then(response => {
      setIsLoading(false); 
      enqueueSnackbar(response.message, {variant: 'success'});
      handleClearForm();
      history.push({
        pathname: `/${property.PropertyName.replace(/\s+/g, '-')}/settings`,
        props: {
          fromAppbar: true,                   
        },
      });
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      enqueueSnackbar(err.message, {variant: 'error'});
    });

  }
  const deleteFileFromS3 = async (fileKeys) => {
    fileKeys.forEach(async element => {
      await Storage.remove(element)
        .then(result => { console.log(result); })
        .catch(err => console.log(err));
    });
  }
  async function getProperty(e) {  
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: { ...property, userData: props.userSession, MasterType: 'Property', UserName: props.userSession.UserName },
      queryStringParameters: {
        module: "property",
        op: "getMyPropertyById"
      }
    };
    API.post(apiName, path, myInit)
      .then(async response => {
        setIsLoading(false);
        console.log("get property ",response);
        if (response && response.success) {  
          await setSelectedProperty(response.data);
        }
        else {
          // openSnackbar({ message: 'Something gone wrong, Try refresh the page', variant: "error" });
          enqueueSnackbar('Something gone wrong, Try refresh the page', {variant: 'error'});
          setIsLoading(false);
        }
      })
      .then(()=>{
        history.push({
          pathname: `/${property.PropertyName.replace(/\s+/g, '-')}/settings`,
          props: {
            fromAppbar: true,                   
          },
        });
      })
      .catch(err => {
        console.log(err);
        // openSnackbar({ message: err.message, variant: "error" });
        enqueueSnackbar(err.message, {variant: 'error'});
        setIsLoading(false);
      });
  }
  async function saveProperty(e) {  
    setIsLoading(true);
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: { ...e,PropertyID: selectedProperty.PropertyID, userData: props.userSession, MasterType: 'Property', UserName: props.userSession.UserName },
      queryStringParameters: {
        module: "property",
        op: "saveGeneral"
      }
    };
    API.post(apiName, path, myInit)
      .then(async response => {        
        if (response && response.success) {
          await deleteFileFromS3(deltedKey);          
          enqueueSnackbar(response.message, {variant: 'success'});
          setTimeout(async () => {
            await getProperty();
            await handleClearForm();
          }, 500); 
        }
        else { 
          enqueueSnackbar(response.message, {variant: 'error'});
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log(err); 
        enqueueSnackbar(err.message, {variant: 'error'});
        setIsLoading(false);
      });
  }

  return (
    <Root className="home-root"> 
      <DialogBox
        open={deleteOpen}
        onClose={e => handleClose("delete")}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="delete-dialog-title"
          onClose={e => handleClose("delete")}
        >
          Deletion Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            We just want to make sure this is the correct decision. This is irreversible action and the data will be removed from our system immediately
          </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Grid
            container
            direction='row'
            spacing={2}
            alignItems='flex-start'
          >
            <Grid item>
              <LoaderCLickButton
               onClick={e => deleteProperty()}
                isLoading={isLoading}
              >
                YES
              </LoaderCLickButton>
            </Grid>
            <Grid item>
              <LoaderDeleteButton
                isLoading={isLoading}
                size='large'
                onClick={e => handleClose("delete")}
              >
                CANCEL
              </LoaderDeleteButton>
            </Grid>
          </Grid>
        </DialogActions>
      </DialogBox> 
      <Grid
        container
        spacing={0}
        direction="column"
        maxWidth={"sm"}
      >
        <Grid item>
          <Container component="main" className="home-main">
            <CssBaseline />
            <Spinner className="common-spinner"
              area={areas.admin}
            />
            <div className="home-paper">
              <div style={{ padding: "20px 0px 40px 0px" }}>

              <Grid container spacing={1} className={"add-padding-p8p"} >
                  <Grid item>
                    <Button onClick={e => {
                  history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/settings`);
                }}>
                       <Typography
                      component="h6"
                      sx={{ fontWeight: 600 }}
                      color="text.primary"
                      variant="h6"
                    >
                      &#8249; {" RETURN"}
                    </Typography>
                    </Button>
                  </Grid>
                </Grid>


                <Grid container
                  spacing={1}
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start">
                  <Grid item>
                    <Typography
                      component="h1" 
                      variant="h1"
                    >
                      {isEditing && property &&  property.PropertyName ? property.PropertyName : "Add A New Property"}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <Grid container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start">
                <Grid item xs={12}>
                  <Typography className="home-headerfour"
                    variant="subtitle1"
                  >
                    {isEditing ? "Update property information" : "Add a new property"}
                  </Typography>
                </Grid>
              </Grid>
              <form
                id="proeprty-form"
                className={classes.form}
                noValidate
                onSubmit={formik.handleSubmit/* saveProperty */}
              >
                <FocusError formik={formik}/>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="PropertyName"
                      label="PROPERTY NAME"
                      /* onChange={onChange} */ 
                      name="PropertyName"
                      autoComplete="name"
                      autoFocus
                      value={formik.values.PropertyName}
                      onChange={formik.handleChange}
                      error={formik.touched.PropertyName && Boolean(formik.errors.PropertyName)}
                      helperText={formik.touched.PropertyName && formik.errors.PropertyName}
                    
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="Email" 
                      label="EMAIL"
                      /* onChange={onChange} */
                      name="Email"
                      autoComplete="property-Email"
                      value={formik.values.Email}
                      onChange={formik.handleChange}
                      error={formik.touched.Email && Boolean(formik.errors.Email)}
                      helperText={formik.touched.Email && formik.errors.Email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined" 
                      fullWidth
                      id="OwnerFirstName"
                      label="PROPERTY OWNER FIRST NAME"
                      /* onChange={onChange} */
                      name="OwnerFirstName"
                      autoComplete="owner-FirstName"
                      value={formik.values.OwnerFirstName}
                      onChange={formik.handleChange}
                  /*     error={formik.touched.OwnerFirstName && Boolean(formik.errors.OwnerFirstName)}
                      helperText={formik.touched.OwnerFirstName && formik.errors.OwnerFirstName} */
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined" 
                      fullWidth
                      id="OwnerLastName" 
                      label="PROPERTY OWNER LAST NAME"
                      /* onChange={onChange} */
                      name="OwnerLastName"
                      autoComplete="owner-LastName"
                      value={formik.values.OwnerLastName}
                      onChange={formik.handleChange}
                      error={formik.touched.OwnerLastName && Boolean(formik.errors.OwnerLastName)}
                      helperText={formik.touched.OwnerLastName && formik.errors.OwnerLastName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined" 
                      fullWidth
                      name="PropertyUnit" 
                      label="# OF UNITS"
                      /* onChange={onChange} */
                      type="text"
                      id="PropertyUnit"
                      autoComplete="current-PropertyUnit"                      
                      value={formik.values.PropertyUnit}
                      onChange={formik.handleChange}
                      error={formik.touched.PropertyUnit && Boolean(formik.errors.PropertyUnit)}
                      helperText={formik.touched.PropertyUnit && formik.errors.PropertyUnit}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="BusinessAddress" 
                      label="BUSINESS ADDRESS"
                      /* onChange={onChange} */
                      id="BusinessAddress"
                      autoComplete="current-address"
                      value={formik.values.BusinessAddress}
                      onChange={formik.handleChange}
                      error={formik.touched.BusinessAddress && Boolean(formik.errors.BusinessAddress)}
                      helperText={formik.touched.BusinessAddress && formik.errors.BusinessAddress}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined" 
                      fullWidth
                      name="City" 
                      label="CITY"
                      /* onChange={onChange} */
                      id="City"
                      autoComplete="current-City"
                      value={formik.values.City}
                      onChange={formik.handleChange}
                      error={formik.touched.City && Boolean(formik.errors.City)}
                      helperText={formik.touched.City && formik.errors.City}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined" 
                      fullWidth
                      name="PostalCode" 
                      label="ZIP/POSTAL CODE"
                      /* onChange={onChange} */
                      id="PostalCode"
                      autoComplete="current-PostalCode"
                      value={formik.values.PostalCode}
                      onChange={formik.handleChange}
                      error={formik.touched.PostalCode && Boolean(formik.errors.PostalCode)}
                      helperText={formik.touched.PostalCode && formik.errors.PostalCode}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel ref={inputLabel} id="select-state-label">
                            COUNTRY/REGION
                         </InputLabel>
                          <Select
                            labelid="select-Country-label"
                            id="Country-select-outlined"
                            fullWidth
                            variant="outlined"
                            name="Country"
                            label="COUNTRY/REGION" 
                            labelWidth={labelWidth}
                            /* onChange={(e, alldata) => { onChange(e, alldata); }} */
                            value={formik.values.Country}
                            onChange={async(e, allData) => {
                                const { value } = e.target;
                                var selected = allData.props.alldata;
                                setStateData(selected.states)
                                formik.setFieldValue('Country',value);
                                formik.setFieldValue('CurrencyName',selected.currency.name + " (" + selected.currency.code + ")");
                                formik.setFieldValue('CurrencyCode',selected.currency.code);
                             }}
                            error={formik.touched.Country && Boolean(formik.errors.Country)}
                            helperText={formik.touched.Country && formik.errors.Country}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {MapData.map((e, keyIndex) => {
                              return (
                                <MenuItem key={keyIndex} alldata={e} value={e.name}>
                                  {e.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel ref={inputLabel} id="select-state-label">
                            STATE/PROVINCE
                        </InputLabel>
                          <Select
                            labelid="select-state-label"
                            id="state-select-outlined"
                            fullWidth
                            variant="outlined"
                            name="State"
                            label="STATE/PROVINCE"
                            /* value={property.State} */
                            labelWidth={labelWidth}
                            disabled={comboDisable}
                            value={formik.values.State}
                            onChange={formik.handleChange}
                            error={formik.touched.State && Boolean(formik.errors.State)}
                            helperText={formik.touched.State && formik.errors.State}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {stateData.map((e, keyIndex) => {
                              return (
                                <MenuItem key={keyIndex} value={e}>
                                  {e}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      autoComplete="p-Phone"
                      name="Phone"
                      variant="outlined"
                      required
                      fullWidth
                      id="Phone"
                      /* value={property.Phone} */
                      label="PHONE #"
                      /* onChange={onChange} */ 
                      value={formik.values.Phone}
                      onChange={formik.handleChange}
                      error={formik.touched.Phone && Boolean(formik.errors.Phone)}
                      helperText={formik.touched.Phone && formik.errors.Phone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      id="WebSite"
                      /* value={property.WebSite} */
                      label="WEBSITE"
                      /* onChange={onChange} */
                      name="WebSite"
                      autoComplete="auto-Website"
                      value={formik.values.WebSite} 
                      onChange={formik.handleChange}
                      error={formik.touched.WebSite && Boolean(formik.errors.WebSite)}
                      helperText={formik.touched.WebSite && formik.errors.WebSite}
                    />
                  </Grid>
                  {!props.fromRent ?
                    <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                      {property.PropertyLogo && property.PropertyLogo.hasOwnProperty("FileName") ?
                        <ImageComponent Type={"PropertyLogo"} FileKey={property.PropertyLogo.FileKey} FileName={property.PropertyLogo.FileName} />
                        :
                        <Grid container style={{ paddingTop: "30px" }} direction="row" spacing={3}>
                          <Grid item xs={1}>
                            <input name='PropertyLogo' 
                            onChange={(e) => onImageChange(e)} 
                            accept="image/*" className={classes.input} id="icon-button-file1" type="file" />
                            <label htmlFor="icon-button-file1">
                              <Fab size="small" color="secondary" aria-label="upload picture" component="span">
                                <AddIcon />
                              </Fab>
                            </label>
                          </Grid>
                          <Grid item xs={11}>
                            <Typography variant="h6"> UPLOAD PROPERTY LOGO</Typography>
                            <Typography variant="caption">500x500 - png, jpeg, gif</Typography>
                          </Grid>
                        </Grid>}
                    </Grid>
                    : null}
                  {!props.fromRent ?
                    <Grid item xs={12} sm={12} md={inputSize} lg={inputSize}>
                      {property.PropertyImage && property.PropertyImage.hasOwnProperty("FileName") ?
                        <ImageComponent Type={"PropertyImage"} FileKey={property.PropertyImage.FileKey} FileName={property.PropertyImage.FileName} />
                        :
                        <Grid container direction="row" spacing={3}>
                          <Grid item xs={1}>
                            <input name='PropertyImage' onChange={(e) => onImageChange(e)} accept="image/*" className={classes.input} id="icon-button-file2" type="file" />
                            <label htmlFor="icon-button-file2">
                              <Fab size="small" color="secondary" aria-label="upload picture" component="span">
                                <AddIcon />
                              </Fab>
                            </label>
                          </Grid>
                          <Grid item xs={11}>
                            <Typography variant="h6"> UPLOAD PROPERTY IMAGE</Typography>
                            <Typography variant="caption">1000x500 - png, jpeg, gif</Typography>
                          </Grid>
                        </Grid>}
                    </Grid>
                    : null}
                </Grid>
                <div style={{ padding: "20px 0px 40px 0px" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <LoaderButton
                        block="true"
                        type="submit"
                        size="large"
                        isLoading={isLoading}
                      /*   disabled={!validateForm()} */
                      >
                        {isEditing ? "UPDATE" : "SETUP A PROPERTY"}
                      </LoaderButton>
                    </Grid>
                    {isEditing && !fromRent && (
                      <Grid item>
                        <Button
                          block="true"
                          style={{ backgroundColor: 'transparent' }}
                          size="large"
                          onClick={() => { handleClickOpen("delete") }}>
                          CLOSE ACCOUNT
              </Button>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </form>

            </div>
          </Container>
        </Grid>
      </Grid>
    </Root>
  );
}
