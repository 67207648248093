import React, { useCallback, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { API } from 'aws-amplify';
import {
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  Divider,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useSnackbar } from 'notistack';
import { Skeleton } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { LoaderCLickButton, LoaderDeleteButton } from '../LoaderButton';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import usePersistedState from '../../usePersistedState';
import { Spinner } from '../../common/components/spinner';
import { areas } from '../../common/constants/areas';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import Moment from 'react-moment';
import Checkbox from '@mui/material/Checkbox';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
import Button from '@mui/material/Button';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from "react-router-dom";
import EditUser from "../user/editUser";
//** Data Grid Import Part **/
import {
  LicenseInfo,
  DataGridPro,
  GridOverlay,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport
} from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';

LicenseInfo.setLicenseKey(
  '79e390e23cc1709a04137710c7c5cdc1T1JERVI6Mjg0NDQsRVhQSVJZPTE2NjEwMDE3MTQwMDAsS0VZVkVSU0lPTj0x',
);
const MAX_ROW_LENGTH = 1500;
//** Data Grid Import Part **/

const PREFIX = 'settingsteams';
const classes = {
  root: `${PREFIX}-root`,
  textPrimary: `${PREFIX}-textPrimary`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.text.secondary,
  },

  [`& .${classes.textPrimary}`]: {
    color: theme.palette.text.primary,
  }
}));


const DialogBox = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));
const NoBorderTextField = styled(TextField)`
& .MuiOutlinedInput-root.Mui-disabled {
  &.Mui-focused fieldset {
    border: none;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}
`; 
const DialogTitle = (props => {
  const { children,  onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography  sx={{m:0, p:2, pl:3, pr:3}}  {...other}>
      <Typography variant="h3" component="h3" sx={{ lineHeight:1}}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: "8px",
            top: "8px",
            color: "text.secondary",
          }} 
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const MapData = (process.env.REACT_APP_STAGE === 'dev') ? awsdev : (process.env.REACT_APP_STAGE === 'dev2') ? awsdev2 : window['runConfig'];
function RenderUserStatus(rowData) {
  switch (rowData.CognitoUserStatus) {
    case 'CONFIRMED':
      return 'Active';
    case 'UNCONFIRMED':
      return 'Invited'
    case 'FORCE_CHANGE_PASSWORD':
      return 'Password Set Required';
    case 'DISABLED':
      return 'Inactive';
    default:
      break;
  }
}
let defaultUserValue = {
  'firstName': '-',
  'lastName': '-',
  'CognitoUserID': '',
  'Name': '',
  'mobNumber': '',
  'Email': '',
  'PropertyID': [],
  'Privilege': '',
  'checkedNotification': false
};
//** Data Grid Action related Code */
function CustomToolbar(props) { 
  return (
      <GridToolbarContainer>
          <Grid container
              spacing={3}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
          >
                 <Grid item minWidth="320px" >
                  {!props.loading && <TextField
                      variant="outlined" 
                      fullWidth                            
                      display="flex"
                      label="Search by email or name.."   
                      onBlur={props.onBlur}
                      onKeyDown={props.onKeyDown}                         
                      onChange={props.onChange}
                      value={props.value}
                      InputProps={{ 
                        endAdornment: (
                          <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.onBlur}
                          >
                            <SearchIcon fontSize="small" />
                          </IconButton>
                        ),
                      }}
                  />}
                  {props.loading && <Skeleton variant="rectangular" height={52} />}
              </Grid>
              <Grid item >
                  {props.loading && <div style={{ display: "flex" }}>
                      <Skeleton variant="rectangular" width={30} height={32} className={"skelton-margin-right-5"} />
                      <Skeleton variant="rectangular" width={100} height={32} />
                  </div>}
                  {!props.loading && <GridToolbarFilterButton />}
              </Grid>
              <Grid item>
                  {props.loading && <div style={{ display: 'flex' }}>
                      <Skeleton variant='rectangular' width={30} height={32} className={'skelton-margin-right-5'} />
                      <Skeleton variant='rectangular' width={100} height={32} />
                  </div>}
                  {!props.loading && <GridToolbarExport
                      csvOptions={{
                          fileName: props.customFileName,
                          delimiter: ',',
                          utf8WithBom: true,
                      }}
                  />}
              </Grid>
          </Grid>
      </GridToolbarContainer>
  );
}
function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <Root style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </Root>
    </GridOverlay>
  );
}
function RowMenuCell({ api, id, row, userSession, selectedProperty, gridRefresh, ...rest }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [userFormOpen,setUserFormOpen]= useState(false);
  const handleClose = (e) => {
    if (e === "delete") {
      setDeleteOpen(false);
    }
    if (e === "resetPassword") {
      setResetPasswordOpen(false);
    }
  };
  async function disableUser() {
    console.log("DISABLE USER ");
    setIsLoading(true);
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: { ...row, userData: userSession },
      queryStringParameters: {
        module: "user",
        op: "disableUser"
      }
    };
    console.log("PROPERTY OBJECT ", myInit);
    trackPromise(
      API.post(apiName, path, myInit).then(response => {
        console.log("UPDATE USER", response);
        setDeleteOpen(false);
        setIsLoading(false);
        enqueueSnackbar(response.message, { variant: 'success' });
        setIsLoading(false);
        setTimeout(() => {
          gridRefresh();
        }, 2000);
      }).catch(err => {
        console.log(err);
        setIsLoading(false);
        enqueueSnackbar(err.message, { variant: 'error' });
      }), areas.admin);
  }
  async function resetPassword() {
    setIsLoading(true);
    let apiName = 'rentapi';
    let path = '/modules';
    let myInit = {
      headers: { 'Content-Type': 'application/json' },
      body: { ...row, userData: userSession, DomainName: MapData.aws_app_domain_prefix, firstName: row.FirstName, lastName: row.LastName },
      queryStringParameters: {
        module: 'user',
        op: 'resetPassword'
      }
    };
    await API.post(apiName, path, myInit).then(response => {
      setResetPasswordOpen(false);
      enqueueSnackbar(response.message, { variant: 'success' });
      setIsLoading(false);
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      enqueueSnackbar(err.message, { variant: 'error' });
    });

  }
  
  const handleGridAction = (e, rowData) => {
    if (e === 'delete') {
      setDeleteOpen(true);
    }
    if (e === 'reset') {
      setResetPasswordOpen(true);
    }
  };
  return (
    <div className={classes.root}>
        {userFormOpen && <EditUser type={"team"} userSession={userSession} isEditing={true} user= {row} fromGrid={true} userFormOpen={userFormOpen} setUserFormOpen={setUserFormOpen} />}
      <DialogBox open={deleteOpen} onClose={e => handleClose("delete")} fullWidth={true}
        maxWidth={"sm"} aria-labelledby="form-dialog-title">
        <DialogTitle id="delete-dialog-title" onClose={e => handleClose("delete")}>
          Remove User From Tenant
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`${row.Email} will no longer be able to access this account.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction='row'
            spacing={2}
            alignItems='flex-start'
          >
            <Grid item>
              <LoaderCLickButton
                onClick={e => disableUser()}
                isLoading={isLoading}
              >
                REMOVE
              </LoaderCLickButton>
            </Grid>
            <Grid item>
              <LoaderDeleteButton
                isLoading={isLoading}
                size='large'
                onClick={() => { handleClose('delete'); }}
              >
                CANCEL
              </LoaderDeleteButton>
            </Grid>
          </Grid>
          <div style={{ flex: '1 0 0' }} />
        </DialogActions>
      </DialogBox>
      <DialogBox open={resetPasswordOpen} onClose={e => handleClose('resetPassword')} fullWidth
        aria-labelledby='form-dialog-title'>
        <DialogTitle id='delete-dialog-title' onClose={e => handleClose('resetPassword')}>
          Reset password
        </DialogTitle>
        <DialogContent>
          <DialogContentText color='textPrimary'>
            {row.Email} will have to change their password the next time they sign in or link a new device.
            <br /><br />
            They will also receive email in their inbox requesting to change the login. To proceed, please hit reset below.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction='row'
            spacing={2}
            alignItems='flex-start'
          >
            <Grid item>
              <LoaderCLickButton
                onClick={e => resetPassword()}
                isLoading={isLoading}
              >
                RESET PASSWORD
              </LoaderCLickButton>
            </Grid>
            <Grid item>
              <LoaderDeleteButton
                isLoading={isLoading}
                size='large'
                onClick={() => { handleClose('resetPassword'); }}
              >
                CANCEL
              </LoaderDeleteButton>
            </Grid>
          </Grid>
          <div style={{ flex: '1 0 0' }} />
        </DialogActions>
      </DialogBox>
      <Tooltip title="Edit">
      <IconButton
        color="inherit"
        className={classes.textPrimary}
        size="small"
        disabled={row.UserStatus === 'Deleted'}
        aria-label="edit"
        onClick={(event) => {
          event.stopPropagation(); 
          setUserFormOpen(true);
         /*  history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/settings/teams/edit`, props: { isEditing: true, rowEditData: row, fromGrid: true } }); */
        }}
      >
        <EditIcon fontSize="small" />
      </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
      <IconButton
        color="inherit"
        className={classes.textPrimary}
        size="small"
        disabled={row.UserStatus === 'Deleted'}
        aria-label="delete"
        onClick={(event) => {
          event.stopPropagation();
          handleGridAction('delete');
        }}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
      </Tooltip>
      <Tooltip title="Reset">
      <IconButton
        color="inherit"
        className={classes.textPrimary}
        size="small"
        aria-label="lock"
        disabled={row.UserStatus === 'Deleted'}
        onClick={(event) => {
          event.stopPropagation();
          handleGridAction('reset');
        }}
      >
        <LockIcon fontSize="small" />
      </IconButton>
      </Tooltip>
    </div>
  );
}

//** Data Grid Action related Code */

export function SettingsTeams(props) {
  console.log('ADD NEW PROPERTY PROPS ', props);
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const tableRef = React.createRef();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProperty] = usePersistedState('selectedProperty', {});
  const [user, setUser] = usePersistedState('user', defaultUserValue);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [emailHelper, setEmailHelper] = useState('');
  //** Data Grid Related Code */
  const apiRef = useGridApiRef();
  const [filterValue, setFilterValue] = useState({}); 
  const [searchText, setSearchText] = React.useState('');  
  const [queryOptions, setQueryOptions] = React.useState({});
  const [tabActive, setTabActive] = useState(0);
  var cusFileName = MapData.aws_app_domain_prefix + "-" + selectedProperty?.PropertyName?.replace(/ /g,"_") + "-" + moment().format('MMMM-DD-YYYY')
  const [data, setData] = useState({
    loading: true,
    rows: [],
    pageSize: 25,
    columns: [
      { field: "MasterID", hide: true, filterable: false },
      {
        headerName: "MEMBER", field: "Name", flex: 1, minWidth: 150,
        renderCell: (params) => {
          const rowData = params.api.getRow(params.id);
          return (rowData.Email === props.userSession.Email ?
            <Grid container direction='row' sx={{pt:1, pb:1}} alignContent={'center'} spacing={1}>
              <Grid item style={{ 'alignSelf': 'center' }}>
                {rowData.Name}
              </Grid>
              <Grid item>
                <NoBorderTextField width='max-content'
                  variant='outlined'
                  inputProps={{
                    min: 0,
                    style: { textAlign: 'center' }
                  }}
                  disabled={true}
                  className={'team-grid-tf'} value='YOU' />
              </Grid>
            </Grid> : <>{rowData.Name}</>)
        }
      },
      { headerName: "EMAIL", field: "Email", flex: 1, minWidth: 100 },
      {
        headerName: "ROLE", field: "Privilege", flex: 1, hide: false, filterable: true,
        renderCell: (params) => {
          const rowData = params.api.getRow(params.id);
          console.log('getRoleName', rowData.Privilege, rowData.TenantAdmin);
          return (getRoleName(rowData.Privilege, rowData.TenantAdmin))
        },
        type: 'singleSelect',
        valueOptions: ["All", "Admin", "Billing", "Manager"]
      },
      {
        headerName: "LAST ACTIVITY", field: "LastLogin", flex: 1, minWidth: 100, filterable: false,
        renderCell: (params) => {
          const rowData = params.api.getRow(params.id);
          return (<Moment format="YYYY-MM-DD">{rowData.LastLogin ? rowData.LastLogin : rowData.CreatedDate}</Moment>)
        }
      },
      /* {
        headerName: "STATUS", field: "CognitoUserStatus", flex: 1, minWidth: 200,
        type: 'singleSelect',
        renderCell: (params) => {
          const rowData = params.api.getRow(params.id);
          return (
          RenderUserStatus(rowData) === 'Inactive' ? 
          <TextField variant='outlined' inputProps={{ min: 0, style: { textAlign: 'center' } }} disabled={true} className={'tenant-grid-tf-dsbl' } value={RenderUserStatus(rowData)} />:
          <NoBorderTextField variant='outlined' inputProps={{ min: 0, style: { textAlign: 'center',WebkitTextFillColor: RenderUserStatus(rowData) === 'Inactive' ? '#cbcbcb' : '#6100ED' } }} disabled={true} className={'tenant-grid-tf'} value={RenderUserStatus(rowData)}/>)
        },
        valueOptions: ['All', 'Active', 'Invited', 'Password Set Required', 'Inactive']
      }, */
      {
        headerName: 'Actions',
        field: 'actions',
        sortable: false,
        width: 150,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        renderCell: params => { return RowMenuCell({ ...params, ...props, selectedProperty: selectedProperty, gridRefresh: gridRefresh }) }
      }
    ]
  });
  const handleChange = (event, newValue) => {
    setTabActive(newValue);
  };
  const handleSortModelChange = React.useCallback((sortModel) => { 
    console.log('sort model change');
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);
  const onFilterChanges = useCallback((filterModel) => {
    console.log('filterModel', filterModel)
    setFilterValue(filterModel);
    mounted.current = true;
  }, []);
  const requestSearch = async (searchValue) => {
    setSearchText(searchValue);
  };
  const handleKeyDown = async (event) => {
    console.log('event', event);
    const ENTER_KEY = 13;
    if (event.keyCode === ENTER_KEY) { 
      setIsLoading(true);
      updateData("loading", true);
      const newData = await GetInfinityTable(data.pageSize, 0);
      if (newData)
          updateData("rows", newData);
      updateData("loading", false);
      setIsLoading(false);
    }
  };
  const handleBlur = async (event) => {
    setIsLoading(true);
    updateData("loading", true);
    const newData = await GetInfinityTable(data.pageSize, 0);
    if (newData)
      updateData("rows", newData);
    updateData("loading", false);
    setIsLoading(false);
  }; 
 
  useEffect(() => {
    let active = true;
    (async () => {
      updateData("loading", true);
      const newData = await GetInfinityTable(data.pageSize, 0);
      if (mounted.current) {
        if (newData)
          updateData("rows", newData);
      }
      updateData("loading", false);
      if (!active) {
        return;
      } 
    })();
    return () => {
      active = false;
    };
  }, [filterValue,queryOptions,tabActive]);

  const mounted = React.useRef(true);
 
  const updateData = (k, v) => setData((prev) => ({ ...prev, [k]: v }));
  const loadServerRows = async (newRowLength) => {
    updateData("loading", true);
    const newData = await GetInfinityTable(data.pageSize, newRowLength);
    if (mounted.current) {
      if (newData && newData.length > 0)
        updateData("rows", data.rows.concat(newData));
      updateData("loading", false);
    }
  };
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  const handleOnRowsScrollEnd = (params) => {
    if (data.rows.length <= MAX_ROW_LENGTH && data.rows.length !== 0) {
      loadServerRows(data.rows.length);
    }
  };
  async function GetInfinityTable(pageSize, fromNo) {
    updateData("loading", true);
    updateData("pageSize", pageSize);
    var body = {
      masterType: "User",
      PropertyID: selectedProperty.PropertyID,
      UserID: props.userSession.UserName,
      pageSize: pageSize,
      fromNo: fromNo,
      userData: props.userSession,
      tabName: tabActive===2?"Inactive":tabActive===1?"Invited":"Active",
      searchValue: searchText,
      filterValue: filterValue,
      sortModel: queryOptions.sortModel,
      tabActive: tabActive
    };
    body.userData = props.userSession;
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: 'user',
          op: 'loadTeamGrid'
        }
      };
      API.post(apiName, path, myInit)
        .then(async response => {
          resolve(response.data);
        })
        .catch(err => {
          console.log(err);
          reject([]);
        });
    });
  }
  const gridRefresh = async () => {
    mounted.current = true;
    updateData("loading", true);
    const newData = await GetInfinityTable(data.pageSize, 0);
    console.log('mounted.current', mounted.current);
    if (newData && newData.length > 0)
      updateData("rows", data.rows.concat(newData));
    else
      updateData("rows", []);
    updateData("loading", false);
  }
  const handleClose = (e) => {
    if (e === 'User') {
      setAddUserOpen(false);
      setUser(defaultUserValue);
    }
  };
  function addNewUserChange(e) {
    let ob = {};
    if (e.target && e.target.name) {
      if (e.target.name === 'checkedNotification') {
        ob[e.target.name] = e.target.checked;
        setUser({ ...user, ...ob });
      }
      else {
        ob[e.target.name] = e.target.value
        setUser({ ...user, ...ob });
      }
    }
  } 
  function aadNewTeamMember() {
    /* const MapData =window['getConfig']; */
    setIsLoading(true);
    if (user.firstName &&
      user.lastName &&
      user.Email) {

      let apiName = 'rentapi';
      let path = '/modules';
      let userRequest = {
        firstName: user.firstName,
        lastName: user.lastName,
        PropertyID: selectedProperty.PropertyID,
        PropertyName: selectedProperty.PropertyName,
        Email: user.Email,
        domain: MapData.aws_app_domain_prefix,
        userData: props.userSession,
        Privilege: user.privilage,
        enableNotification: user.checkedNotification

      }
      let myInit = {
        headers: { 'Content-Type': 'application/json' },
        body: userRequest,
        queryStringParameters: {
          module: 'user',
          op: 'addTeamMember'
        }
      };
      API.post(apiName, path, myInit).then(response => {
        setIsLoading(false);

        if (!response.success) {
          setEmailHelper(response.message)
        }
        else {
          setAddUserOpen(false);
          setUser(defaultUserValue);
          if (tableRef.current)
            tableRef.current.onQueryChange();
          let message = response.isUserExist ? `${user.Email} already exist in the system. We have sent out an invitation` : `Invitation sent out ${user.Email}`;
          enqueueSnackbar(message, { variant: 'success' });
        }
        setUser(defaultUserValue);
      }).catch(err => {
        console.log(err);
        setIsLoading(false);
        setUser(defaultUserValue);
        enqueueSnackbar('Tenant adding failed', { variant: 'error' });
      });

    }
    else {
      //PLEASE FILL ALL DATA
      setIsLoading(false);
      enqueueSnackbar('Please enter all required fields', { variant: 'error' });
    }
  }
  function validateFields() {
    return (!(user && user.privilage && (user.privilage != '')) || isLoading)
  }
  function getRoleName(privilege, TenantAdmin) { return TenantAdmin && TenantAdmin === 'Yes' ? 'Admin (owner)' : privilege === 'ADMIN' ? 'Admin' : privilege === 'BILLING' ? 'Billing' : privilege === 'MANAGER' ? 'Manager' : ''; }
  return (
    <div className='home-root'>
      <DialogBox
        maxWidth={'md'}
        open={addUserOpen}
        onClose={e => handleClose('User')}
        aria-labelledby='addnew-user-dialog-title'
      >
        <DialogTitle id='addnew-user-dialog-title' onClose={e => { handleClose('User') }}>
          Add A Team Member
        </DialogTitle>
        <DialogContent>
          <DialogContentText color='textPrimary'>
            Enter the email addresses of the users you’d like to invite, and choose the role they should have.
          </DialogContentText>
          <Grid container
            className='addtenant-grid'
            spacing={2}
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                className='addtenant-email'
                id='input-addtenant-email'
                variant='outlined'
                name='Email'
                onChange={addNewUserChange}
                value={user.Email}
                placeholder='Email*'
                inputProps={{ style: { textTransform: "lowercase" } }}
                error={Boolean(emailHelper)}
                helperText={emailHelper}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl width='100%' component='fieldset'>
                <FormLabel component='legend'><Typography style={{ fontWeight: 'bold' }} variant='h6'>
                  Role  </Typography></FormLabel>
                <RadioGroup aria-label='role' name='privilage' value={user.privilage} onChange={addNewUserChange}>
                  <FormControlLabel value='ADMIN' control={<Radio style={{ color: '#6200EE' }} />} label={<Box marginTop={3}>
                    <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>
                      Administrator
                    </Typography>
                    <Typography variant='subtitle1' >
                      Full access to all the features including the property & business settings
                    </Typography></Box>} />
                  <FormControlLabel value='MANAGER' control={<Radio style={{ color: '#6200EE' }} />} label={<Box marginTop={3}>
                    <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>
                      Manager
                    </Typography>
                    <Typography variant='subtitle1' >
                      Full access to edit and manage Tenant Management, Manage Lease, View Transaction features
                    </Typography></Box>} />
                  <FormControlLabel value='BILLING' control={<Radio style={{ color: '#6200EE' }} />} label={<Box marginTop={3}>
                    <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>
                      Billing
                    </Typography>
                    <Typography variant='subtitle1' >
                      View access only of the Tenant Managements, View Transactions & Manage Lease features
                    </Typography>
                  </Box>} />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user.checkedNotification}
                    onChange={addNewUserChange}
                    name='checkedNotification'
                    style={{ color: '#374761' }}
                  />
                }
                label='Yes, team member will receive notifications when tenant make a payment'
              />
            </Grid>

          </Grid>
        </DialogContent>
        <Divider variant='middle' />
        <DialogActions>
          <Grid container
            spacing={2}
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'>
            <Grid item xs={12}
              className='add-teammember-margin-top-p7p'
            >
              <LoaderCLickButton
                isLoading={false}
                size='large'
                disabled={validateFields()}
                onClick={() => { aadNewTeamMember() }}
              >
                + ADD
              </LoaderCLickButton>
            </Grid>

          </Grid>
        </DialogActions>
      </DialogBox>
      <Grid
        container
        spacing={0}
        direction='column'
      >
        <Grid item>
          <Container component='main' className='home-main'>
            <CssBaseline />
            <Spinner className='common-spinner'
              area={areas.admin}
            />
            <div className='home-paper'>
              <div style={{ padding: "20px 0px 40px 0px" }}>
                <Grid container spacing={1} className={"add-padding-p8p"} >
                  <Grid item>
                    <Button onClick={e => {
                      history.push(`/${selectedProperty?.PropertyName?.replace(/\s+/g, '-')}/settings`);
                    }}>
                      <Typography
                        component="h6"
                        sx={{ fontWeight: 600 }}
                        color="text.primary"
                        variant="h6"
                      >
                        &#8249; {" RETURN"}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>

                <Grid container
                  spacing={1}
                  direction='row'
                  justifyContent='center'
                  alignItems='flex-start'>
                  <Grid xs={12} item>
                    <Box mb={3}>
                      <Typography
                        component="h1"
                        variant="h1"
                      >
                        Teams
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mb={3}>
                      <Grid container direction='row' spacing={1} alignItems='center'>
                        <Grid item>
                          {!isLoading &&
                            <Fab
                              size='small'
                              onClick={() => {
                                setUser(defaultUserValue);
                                setAddUserOpen(true);
                              }}
                              color='secondary'
                              aria-label='add'

                            >
                              <AddIcon className='home-linkIcon' />
                            </Fab>
                          }
                          {isLoading && <Skeleton variant="circular" width={40} height={40} />}
                        </Grid>
                        <Grid item>
                          {!isLoading &&
                            <Typography
                              className='home-headerThree add-teammember-tag'
                              component='h5'
                              variant='h5'
                              onClick={() => {
                                setUser(defaultUserValue);
                                setAddUserOpen(true);
                              }}
                            >
                              ADD A TEAM MEMBER
                            </Typography>
                          }
                          {isLoading && <Skeleton variant="rectangular" width={218} height={32} />}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mb={3}>
                      <Typography className='home-headerfour'
                        variant='subtitle1'
                      >
                        Add and update team members to the property and its features.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid xs={12} item>
                    <Tabs
                      value={tabActive}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                      style={{ padding: "20px 0px 00px 0px" }}>
                      <Tab label="Active" />
                      <Tab label="Invited" />
                      <Tab label="Inactive" />
                    </Tabs>
                  </Grid>
                  <Grid xs={12} item>
                    <div style={{ height: 1024, width: '100%' }}>
                      <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                          <DataGridPro
                            apiRef={apiRef}
                            density="compact"
                            rowHeight={91}
                            hideFooter
                            hideFooterPagination
                            loading={data.loading}
                            onRowsScrollEnd={handleOnRowsScrollEnd}
                            rows={data.rows}
                            getRowId={(r) => r.MasterID}
                            columns={data.columns}
                            filterMode="server"
                            onFilterModelChange={onFilterChanges}  
                            onSortModelChange={handleSortModelChange}                         
                            componentsProps={{
                              toolbar: {
                                  value: searchText,
                                  onChange: (event) => requestSearch(event.target.value),
                                  onBlur:(event) =>handleBlur(event),
                                  onKeyDown:(event) =>handleKeyDown(event),
                                  loading: data.loading,
                                  customFileName: cusFileName
                              },
                          }} 
                          components={{
                              LoadingOverlay: CustomLoadingOverlay,
                              Toolbar: CustomToolbar
                          }}
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}
