import React, { useState, useRef, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography"; 
import Container from "@mui/material/Container";
import { API } from "aws-amplify";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CardActions from "@mui/material/CardActions";
import LoaderButton, { LoaderCLickButton } from "../LoaderButton";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import clsx from "clsx";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; 
import { useSnackbar } from 'notistack';
import Divider from '@mui/material/Divider';
import { Hidden } from '@mui/material'; 
import { Skeleton } from '@mui/material'; 
import usePersistedState from "../../usePersistedState"; 
import { MakePaymentRentCards } from '../../common/components/placeholder';
import CardForm from './creditcardform';
import Button from '@mui/material/Button';
import BankForm from './bankaccountform';
import CustomizedTooltips from '../../lib/tooltIp';
const PREFIX = 'makepayment';

const classes = {
  "@global": `${PREFIX}-undefined`,
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  submit: `${PREFIX}-submit`,
  card: `${PREFIX}-card`,
  details: `${PREFIX}-details`,
  content: `${PREFIX}-content`,
  cover: `${PREFIX}-cover`,
  controls: `${PREFIX}-controls`,
  cards: `${PREFIX}-cards`,
  rentCardClick: `${PREFIX}-rentCardClick`,
  rentCard: `${PREFIX}-rentCard`,
  f: `${PREFIX}-f`,
  rentCardSelect: `${PREFIX}-rentCardSelect`,
  form: `${PREFIX}-form`,
  expand: `${PREFIX}-expand`,
  expandOpen: `${PREFIX}-expandOpen`,
  payImg: `${PREFIX}-payImg`,
  payCardCont: `${PREFIX}-payCardCont`,
  main: `${PREFIX}-main`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.undefined}`]: {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },

  [`& .${classes.root}`]: {
    flexGrow: 1
  },

  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(8),
    flexDirection: "column",
    alignItems: "center",
    /*   minHeight: "600px", */
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2)
  },

  [`& .${classes.card}`]: {
    display: "flex",
    /* padding: theme.spacing(2, 2,3, 2)  */
  },

  [`& .${classes.details}`]: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    /*  width:"-moz-available" */
  },

  [`& .${classes.content}`]: {
    flex: "1 0 auto"
  },

  [`& .${classes.cover}`]: {
    width: "170px",
    height: "170px"
  },

  [`& .${classes.controls}`]: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },

  [`& .${classes.cards}`]: {
    display: "flex",
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"
  },

  [`& .${classes.rentCardClick}`]: {
    display: "flex",
    width: "100%",
    background: "#3f51b52e",
    border: "1px solid #3f51b5",
    borderRadius: "5px",
    /*  opacity:"0.8", */
    boxShadow: "none",
  },

  [`& .${classes.rentCard}`]: {
    display: "flex",
    width: "100%",
    border: "1px solid #DADADA",
    borderRadius: "5px"
  },

  [`& .${classes.f}`]: {
    display: "flex",
    width: "100%",
    border: "1px solid #DADADA",
    borderRadius: "5px",
    boxShadow: "none"
  },

  [`& .${classes.rentCardSelect}`]: {
    display: "flex",
    width: "100%",
    border: "1px solid #6200EE",
    borderRadius: "5px"
  },

  [`& .${classes.form}`]: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },

  [`& .${classes.expand}`]: {
    transform: "rotate(0deg)",
    marginLeft: "36%",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },

  [`& .${classes.expandOpen}`]: {
    transform: "rotate(180deg)"
  },

  [`& .${classes.payImg}`]: {
    width: "100%",
    height: "100%"
  },

  [`& .${classes.payCardCont}`]: {
    padding: "8px"
  },

  [`& .${classes.main}`]: {
    maxWidth: "100% !important"
  }
}));

const MapData = window['countryConfig'];

export function MakePayment(props) {
  const { enqueueSnackbar } = useSnackbar(); 
  let history = useHistory();  
  const [isLoading, setIsLoading] = useState(false); 
  const [paymentSourceType,setPaymentSourceType]=useState('')
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [monthSelected, SetMonthSelected] = useState([]);
  const [carDetails, setCardDetails] = usePersistedState("carDetails", "");
  const [selctedData, setSelctedData] = usePersistedState('selctedData', []);
  const [expanded, setExpanded] = useState(false);
  const [cardSelected, setcardSelected] = useState({});
  const [transactionData,setTransactionData] = useState({AchSetupFee:0.0, TaxAmount: 0.0, Tax: 0.0, TransactionFee: 0.0, TotalAmount: 0.0, Misc: [], MiscAddition: 0.0 });
  const [isThankyou, setIsThankyou] = useState(false);  
  const [cardData, setCardData] = useState({
    MasterID: "",
    NameOnCard: "",
    BillingAddress: "",
    Country: "",
    PostalCode: "",
    CardNumber: "",
    CvvCid: "",
    ExpiryMonth: "",
    ExpiryYear: "",
    UserName: props.userSession.UserName,
    RenterName: props.userSession.FirstName + ' ' + props.userSession.FirstName 
  });
  const [bankData,setBankData]=useState({
    UserName: props.userSession.UserName,
    RenterName: props.userSession.FirstName + ' ' + props.userSession.FirstName,
    FirstName:"",
    LastName:"",
    AccountType:"",
    RoutingNumber:"",
    AccountNumber:""
  }); 
  const [rentReadyToPay, setRentReadyToPay] = useState([]);
  const [currentRentDetails, setCurrentRentDetails] = useState([]); // useState([]);  
  const [userCreditCards, setUserCreditCards] = usePersistedState('userCreditCards', []);
  const isRefresh = React.useRef(true); 
  const inputLabel = useRef(1);
  const [paymentMethod, setPaymentMethod] = useState("Saved Source");
  const [selectedProperty] = usePersistedState('selectedProperty', {});
  const [feeLabel,setFeeLabel]= useState('');
  const [feeTooltip,setFeeTooltip]= useState('');
  const [currentClick, setCurrentClick]=useState({});
  const [selectedIndex, setSelectedIndex]=useState(-1);
  useEffect(() => {
    let isSubscribed = true;
    const userFetch = async () => { 
      if (selectedProperty.hasOwnProperty("PropertyID") && isSubscribed) {
        isRefresh.current = false; 
        setIsLoading(true);
        getRentPeriod(selectedProperty.PropertyID, props.userSession)
            .then(async (data) => {
              await setCurrentRentDetails(data);
              if (data && data.hasOwnProperty("MiscFee")) {
                let miscAddition = 0.0, miscflag = false;
                data.MiscFee.forEach((each) => {
                  if (each.activeDatePlan === "oneTimePlan" && isInitialLoading) {
                    setIsInitialLoading(false);
                    miscflag = true;
                    if (each.appFeeType === "percentage") {
                      miscAddition += (Number(data.RentEachMonth) * Number(each.appFeeValue)) / 100;
                    } else {
                      miscAddition += Number(each.appFeeValue);
                    }
                  }
                }); 
                var trnx = transactionData.TotalAmount;
                var achSetupFee=data.AchSetupFee?data.AchSetupFee:0.0;
                trnx=miscAddition+trnx;
                let finalData={ TotalAmount: Number((trnx).toFixed(2)), AchSetupFee:achSetupFee, TaxAmount: Number((transactionData.TaxAmount).toFixed(2)), Tax: selectedProperty.hasOwnProperty("Tax") ? parseFloat(selectedProperty.Tax) : 0.0, Misc: data.MiscFee }
                Object.assign(transactionData, );
                if (miscflag) {
                  finalData.MiscAddition=Number((miscAddition).toFixed(2));
                }
                Object.assign(transactionData, { ...finalData});
              }
            }).then(async () => { 
              var cardData = await getCardsData(props.userSession); 
              let setcard=false,paymentType='';
              await setUserCreditCards(cardData); 
              if (cardData && cardData.length > 0) {
                cardData.forEach(async element => { 
                 if(element.Primary === "Yes")
                  {
                    await setSelctedData(element);
                    await setCardDetails(element.MasterID);
                    setcard=true;  
                   if (element.MasterType === "User Card")
                     paymentType = 'Stripe';
                   if (element.MasterType === "Bank Account")
                     paymentType = 'Plaid';
                  } 
                }); 
                if(!setcard)
                {
                  await setSelctedData(cardData[0]);
                  await setCardDetails(cardData[0].MasterID);
                  if (cardData[0].MasterType === "User Card")
                    paymentType = 'Stripe';
                  if (cardData[0].MasterType === "Bank Account")
                    paymentType = 'Plaid';
                }  
              }
              else {
               cardData = []; 
               paymentType= 'Stripe';
               await setPaymentMethod(prvCard => {return "Manual Card"});
               await setCardDetails(prvCard => {return "null"});
               await setSelctedData(prvCard => { return {"MasterID":"", "MasterType": "Manual Card"} });  
              } 
              await setPaymentSourceType(prvCard => {return paymentType}); 
              if ((paymentType == 'Stripe' && selectedProperty.hasOwnProperty("IncludeStripeFee") && selectedProperty.IncludeStripeFee)
                || (paymentType == 'Plaid' && selectedProperty.hasOwnProperty("IncludePlaidFee") && selectedProperty.IncludePlaidFee)) {
                var totalAmount = transactionData.TotalAmount;
                var taxPercentage = transactionData.Tax, taxAmount = 0.0, trasnactionFee = 0.0;
                taxAmount = totalAmount * (taxPercentage / 100);
                trasnactionFee = CalculateFee(totalAmount + taxAmount, paymentType);
                Object.assign(transactionData, { TransactionFee: Number(trasnactionFee.toFixed(2)) });
              } 
              else {
                Object.assign(transactionData, { TransactionFee: 0.0 });
              }             
            })
            .then(async ()=>{ 
              setIsLoading(false);   
            })
      }
      return () => isSubscribed = false
    }
    userFetch();
  }, [selectedProperty]);
  useEffect(() => {
   if(currentClick && Object.keys(currentClick).length>0 )
    (async () => { 
      var totalAmount = transactionData.TotalAmount;
      var totalMiscAmount = transactionData.MiscAddition;
      var miscAmount = 0.0;
      var taxPercentage = transactionData.Tax, taxAmount = 0.0;
      var transactionFee = transactionData.TransactionFee; 
      console.log('********** totalAmount before reduction',totalAmount,totalMiscAmount, taxPercentage, transactionFee);
      //CALCULATE MISC
      if (currentClick.MonthName !== "SecurityDeposit") {
        var Misc = transactionData.Misc;
        Misc.forEach((eachMisc) => {
          if (eachMisc.activeDatePlan === "reccuringPlan") {
            let validFrom = new Date(eachMisc.validFrom);
            let validTo = new Date(eachMisc.validTo);
            if (
              (Number(currentClick.Year + "" + getTwoDigit(currentClick.Month)) >= Number(validFrom.getFullYear() + "" + getMonth(validFrom))) &&
              (Number(currentClick.Year + "" + getTwoDigit(currentClick.Month)) <= Number(validTo.getFullYear() + "" + getMonth(validTo)))
            ) {
              miscAmount += (eachMisc.appFeeType !== "percentage" ? Number(eachMisc.appFeeValue) :
                (Number(currentClick.Rent) * Number(eachMisc.appFeeValue)) / 100);
            }
          }
        });
      }
      if (!cardSelected[selectedIndex]) { 
        const rentItems = monthSelected.filter(rItem => { 
          return rItem.RentMonth !== currentClick.RentMonth
        }); 
        SetMonthSelected(rentItems);
        console.log(totalAmount +'-'+ currentClick.Rent+' - '+ miscAmount, (totalAmount-miscAmount - currentClick.Rent ));
        totalAmount = (totalAmount-miscAmount - currentClick.Rent);
        
        totalMiscAmount -= miscAmount;
        taxAmount = totalAmount * (taxPercentage / 100); 
        if ((paymentSourceType == 'Stripe' && selectedProperty.hasOwnProperty("IncludeStripeFee") && selectedProperty.IncludeStripeFee)
          || (paymentSourceType == 'Plaid' && selectedProperty.hasOwnProperty("IncludePlaidFee") && selectedProperty.IncludePlaidFee))
          transactionFee = await CalculateFee(totalAmount + taxAmount, paymentSourceType);
        else {
          transactionFee=0.0
        }
        //Object.assign(transactionData, { TaxAmount: Number(taxAmount.toFixed(2)), TotalAmount: Number((totalAmount).toFixed(2)), MiscAddition: Number(totalMiscAmount.toFixed(2)), TransactionFee: Number(trasnactionFee.toFixed(2)) });
        setTransactionData(prvData=>{ return { ...prvData, TaxAmount: Number(taxAmount.toFixed(2)), TotalAmount: Number((totalAmount).toFixed(2)), MiscAddition: Number(totalMiscAmount.toFixed(2)), TransactionFee: Number(transactionFee.toFixed(2)) } });
        let temp = rentReadyToPay;
        temp.splice(rentReadyToPay.findIndex((f) => f.ID === currentClick.ID), 1);
        setRentReadyToPay(temp);
      }
      else {
        
        var tmp = [...monthSelected, currentClick];
        totalAmount = totalAmount + currentClick.Rent + miscAmount;        
        totalMiscAmount += miscAmount;
        console.log('********** select totalAmount after reduction',totalAmount,miscAmount,totalMiscAmount);
        taxAmount = totalAmount * (taxPercentage / 100);
        if ((paymentSourceType == 'Stripe' && selectedProperty.hasOwnProperty("IncludeStripeFee") && selectedProperty.IncludeStripeFee)
          || (paymentSourceType == 'Plaid' && selectedProperty.hasOwnProperty("IncludePlaidFee") && selectedProperty.IncludePlaidFee))
          transactionFee = CalculateFee(totalAmount + taxAmount, paymentSourceType);
        else {
          transactionFee = 0.0
        }
        console.log('********** select trasnactionFee',transactionFee);
        Object.assign(monthSelected, tmp);
        //setTransactionData(prvData=>{return { ...prvData, TaxAmount: Number(taxAmount.toFixed(2)), TotalAmount: Number((totalAmount).toFixed(2)), MiscAddition: Number(totalMiscAmount.toFixed(2)), TransactionFee: Number(trasnactionFee.toFixed(2)) }});
        Object.assign(transactionData, { TaxAmount: Number(taxAmount.toFixed(2)), TotalAmount: Number((totalAmount).toFixed(2)), MiscAddition: Number(totalMiscAmount.toFixed(2)), TransactionFee: Number(transactionFee.toFixed(2)) })
        setRentReadyToPay(rentReadyToPay.concat([currentClick]));
      }
    })()
  }, [currentClick]);
  
  const CreateHeaderWithTooltip = ({title, tooltip}) => {
    return (
      <Grid
          container
          direction="row"
          justifyContent="flex-start"
          spacing={2}
          alignItems="center"
      > <Grid item>
              {title}
          </Grid>
          <Grid item>
              <CustomizedTooltips placement={'right'} iconClass="tooltip-icon" content={tooltip} />
          </Grid>
      </Grid>
    );
  };
  const getCardsData = async (userSession) => { 
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: ["Bank Account", "User Card"], CurrentUserID: userSession.UserName, userData: userSession,PropertyId:selectedProperty.PropertyID   },
        queryStringParameters: {
          module: "renter",
          op: "getCardData"
        }
      };

      API.post(apiName, path, myInit).then(response => { 
        resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  }
  const getRentPeriod = async (propertyId, userSession) => { 
    /* setIsLoading(true); */
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: { masterType: "Rent", renter: userSession.UserName, PropertyID: propertyId, userData: userSession },
        queryStringParameters: {
          module: "renter",
          op: "getRentPeriod"
        }
      };
      API.post(apiName, path, myInit).then(response => { 
      /*   setIsLoading(false); */
        resolve(response);
      }).catch(err => {
        console.log(err); reject(err);
      });
    });
  }
  function getMonth(date) {
    let month = date.getMonth();
    return month < 10 ? '0' + month : '' + month;
  }
  function getTwoDigit(value) {
    return value < 10 ? '0' + value : '' + value;
  }
  /**
   * CALCULATE Stripe/Plaid transaction fee 
   * Stripe feee 2.9% + 0.3 per transaction
   * Plaid fee 0.8% per transaction
   * @param {*} amount 
   * @param {*} sourceType - Plaid/Stripe 
   */
  function CalculateFee(amount,sourceType) {
    let fee=0.0; 
    if (amount === 0.0) return 0.0;
    if(sourceType==='Stripe')
    {
      setFeeTooltip('Stripe fee of 2.9% + $0.30 cents per transaction')
      setFeeLabel('Credit Card Processing Fee')
      var tamount = parseFloat(amount);
      var total = ((tamount + 0.3) / (1 - (2.9 / 100)));
      fee = total - tamount;
    }
    if(sourceType==='Plaid')
    {
      setFeeTooltip('Stripe fee of 0.8% + Plaid fee of $0.10 cents per transaction')
      setFeeLabel('ACH Processing Fee');      
      var tamount = parseFloat(amount);
      console.log('amount',amount,tamount);
      var total = ((tamount+ 0.1) / (1 - (0.8/100)));
      console.log('amount total fee',total);
      fee = total - tamount;
      console.log('amount fee',fee);
    } 
    return parseFloat(fee.toFixed(2));
  }
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  /**
     * Handle Card selection, calculate transaction amount
     * @param {*} clicked 
     * @param {*} keyIndex 
     */
  async function handleSelection(clicked, keyIndex) {  
    setcardSelected(prvCardSelected=>{ return { ...prvCardSelected, [keyIndex]: !prvCardSelected[keyIndex] }});
    setSelectedIndex(prvKeyIndex=>{ return keyIndex}); 
    setCurrentClick(prvClicked=>{ return {...clicked}}); 
  }
  function formChange(type,e,child) { 
    if(type==='card'  && e.name){ 
          setCardData(prvCard => { return {...prvCard,[e.name]: e.value} });
      }
    else if(type==='bank' && e.name)
    {
       setBankData(prvCard => { return {...prvCard, [e.name]: e.value} });         
    }
  } 
  function onChange(e, child) { 
    let paymentType='';
    if (e.target.value === "null") { 
      setPaymentMethod("Manual Card");
      setCardDetails("null");
      setSelctedData(prvCard => { return {"MasterID":"", "MasterType": "Manual Card"} });     
      paymentType='Stripe';     
    }
    else if(e.target.value==="ach"){ 
      setPaymentMethod("Manual Bank");
      setCardDetails("ach");
      setSelctedData(prvCard => { return {"MasterID":"", "MasterType": "Manual Bank"} });    
      setPaymentSourceType('Plaid'); 
      paymentType='Plaid';
    }
    else if (e.target.value !== "null" && e.target.value !== "ach" && e.target.name === "CardDetails") { 
      setPaymentMethod("Saved Source")
      setSelctedData(prvCard => { return  child.props.name });  
      setCardDetails(prvCard => { return e.target.value });   
      if( child.props.name.MasterType==="User Card")
          paymentType='Stripe';
      if( child.props.name.MasterType==="Bank Account")
          paymentType='Plaid';
    }
    else {
      setCardDetails(prvCard => { return "" });      
      setCardData({ ...cardData, [e.target.name]: e.target.value });
    }
    setPaymentSourceType(paymentType);
    if ((paymentType=='Stripe' && selectedProperty.hasOwnProperty("IncludeStripeFee") && selectedProperty.IncludeStripeFee)
    || (paymentType=='Plaid' && selectedProperty.hasOwnProperty("IncludePlaidFee") && selectedProperty.IncludePlaidFee)) { 
      var totalAmount = transactionData.TotalAmount;
      var taxPercentage = transactionData.Tax, taxAmount = 0.0,trasnactionFee=0.0;
      taxAmount = totalAmount * (taxPercentage / 100);
      trasnactionFee = CalculateFee(totalAmount + taxAmount,paymentType);
      Object.assign(transactionData, {TransactionFee: Number(trasnactionFee.toFixed(2)) });
    }
    else
    {
      Object.assign(transactionData, {TransactionFee: 0.0 });
    }
  }
  function validatePaymetButton() {
    return (rentReadyToPay.length > 0 && selctedData.hasOwnProperty("MasterID"));
  }

  function makeaPayment() {
    if (isLoading) {
      return;
    } 
    setIsLoading(true);
    let bodyOb = {};
    bodyOb.TransactionData =transactionData;//{ ...transactionData,TrasnactionFee: 0.0};
    bodyOb.RentData = rentReadyToPay;
    bodyOb.PropertyID = selectedProperty.PropertyID;
    bodyOb.PropertyName = selectedProperty.PropertyName;
    bodyOb.BankData=bankData;
    bodyOb.CardData = paymentMethod==="Manual Card"? cardData : selctedData;
    bodyOb.CurrentUserID = props.userSession.UserName;
    bodyOb.CurrentUserName = props.userSession.FirstName + " " + props.userSession.LastName;
    bodyOb.CurrentUserEmail = props.userSession.Email;
    bodyOb.CurrentRentMaster = props.userSession.currentRentDetails;
    bodyOb.Unit = currentRentDetails.Unit;
    bodyOb.RentMasterID = currentRentDetails.RentMasterID;
    bodyOb.userData = props.userSession;
    bodyOb.PaymentMethod = paymentMethod;
    bodyOb.PaymentSourceType =paymentSourceType;
    let apiName = "rentapi";
    let path = "/modules";

    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: bodyOb,
      queryStringParameters: {
        module: "renter",
        op: "saveTransaction"
      }
    };
    API.post(apiName, path, myInit).then(response => {
      if (response.success) {
        /* After payment customer Id will get it from server,  */
        /* if(localStorage.getItem("renterProperty")!=="" && localStorage.getItem("renterProperty")!=="{}")
        {
          var property=JSON.parse(localStorage.getItem("renterProperty")); 
          localStorage.setItem("renterProperty",JSON.stringify(property));
        }  */
        setIsLoading(false);
        // openSnackbar({ message: response.message, variant: "success" });
        enqueueSnackbar(response.message, {variant: 'success'});
        setIsThankyou(true);
      }
      else {
        setIsLoading(false);
        // openSnackbar({ message: response.message, variant: "error" });
        enqueueSnackbar(response.message, {variant: 'error'});
      }
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      // openSnackbar({ message: err.message, variant: "error" });
      enqueueSnackbar(err.message, {variant: 'error'});
    });
  }
  return (
    <Root className={"home-root"}> 
      <Grid
        container
        spacing={0}
        direction="column"
      >
        <Grid item>
          <Container component="main" className="home-main">
            <CssBaseline /> 
              {isThankyou ?
              <div style={{
                display: "flex",
                marginTop: "1.3%",
                justifyContent:"center",
                flexDirection: "column",
                alignItems: "center",
                minHeight: "94vh"
              }}>
                <div>
                  <Grid container justifyContent="center" direction="row" spacing={1}>
                    <Grid item lg={2} md={2} implementation="css" smDown component={Hidden} />
                    <Grid item lg={8} md={8}>
                      <Grid container justifyContent="center" direction="column" alignItems="flex-start" spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            component="h1"
                            color={"textPrimary"}
                            variant="h1"
                          >
                            Thank You
                        </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography 
                            color={"textSecondary"}
                            component="h3"
                            variant="h3"
                          >
                            {"Your total payment of $"}{Number((transactionData.AchSetupFee+transactionData.TransactionFee + transactionData.TotalAmount + (transactionData.TotalAmount * (transactionData.Tax / 100))).toFixed(2))} {" is recieved for "} {selectedProperty.PropertyName}{"!"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className="renter-transpage-fee">
                          <Typography 
                            color={"textSecondary"}
                            component="h5"
                            variant="h5"
                          >
                            Here is the breakdown of your transaction:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className="renter-transpage-fee">
                          <Grid container direction="column" spacing={2}>
                            {rentReadyToPay.map((each, keyIndex) => {
                              return (
                                <Grid item key={keyIndex} xs={12}>
                                  <Typography className="renter-thankYouHFour"
                                    component="h6"
                                    variant="h6"
                                  >
                                    {"$"}{each.Rent}{" - "} {each.RentMonth}
                                  </Typography>
                                </Grid>
                              )
                            })}
                            {
                              transactionData.Misc.length > 0 && <Grid item xs={12}>
                                {transactionData.Misc.map((eachMisc, keyIndex) => { 
                                  return (
                                    <Typography key={keyIndex} className="renter-thankYouHFour"
                                      component="h6"
                                      variant="h6"
                                    >
                                      {"$"}{transactionData.MiscAddition}{" - "} {eachMisc.appfeeName} {eachMisc.appFeeType !== "percentage" ? '' : ' (' + eachMisc.appFeeValue + '%)'}
                                    </Typography>
                                  )
                                })
                                }  </Grid>
                            }
                            {
                              transactionData.Tax > 0.0 && <Grid item xs={12}>
                                <Typography className="renter-thankYouHFour"
                                  component="h6"
                                  variant="h6"
                                >
                                  {"$"}{Number((transactionData.TotalAmount * (transactionData.Tax / 100)).toFixed(2))}{" - "} {"Taxes ("}{transactionData.Tax}{"%)"}
                                </Typography>
                              </Grid>
                            }
                            {
                              !isLoading && transactionData.AchSetupFee > 0.0 /* && paymentSourceType === 'Plaid' */ && <Grid item xs={12}>
                                <Typography
                                  className="renter-thankYouHFour"
                                  component="h6"
                                  variant="h6"
                                >
                                  <CreateHeaderWithTooltip variant="h5" title={`$${transactionData.AchSetupFee} - ACH Account Setup Fee`} tooltip={`Plaid one-time fee of $${transactionData.AchSetupFee} for each account setup`}/>                                 
                                </Typography>
                              </Grid>
                            }
                            {
                              transactionData.TransactionFee > 0.0 && <Grid item xs={12}>
                                <Typography className="renter-thankYouHFour"
                                  component="h6"
                                  variant="h6"
                                >
                                  <CreateHeaderWithTooltip variant="h5" title={`$${transactionData.TransactionFee} - ${feeLabel}`} tooltip={feeTooltip}/> 
                                  {/* {"$"}{transactionData.TransactionFee}{" - "} {"Convenience Fee"} */} 
                                </Typography>
                              </Grid>
                            }
                          </Grid>

                        </Grid>
                        <Grid item style={{ width: "100%", paddingTop: "25px" }} xs={12}>
                          <Divider light />
                        </Grid>
                        <Grid item xs={12}>
                          <LoaderButton
                            block="true"
                            style={{ backgroundColor: 'transparent', padding: "0px" }}
                            size="large"
                            onClick={() => {
                              history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}`);
                            }}>
                            &#8249; {" RETURN TO DASHBOARD"}
                          </LoaderButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={2} md={2} implementation="css" smDown component={Hidden} />
                  </Grid>
                  </div>
                </div> :
                <div style={{
                  display: "flex",
                  marginTop: "20px",
                  justifyContent:"center",
                  flexDirection: "column",
                  alignItems: "center",
                  minHeight: "600px"
                }}> 
                  <div style={{ padding: "0px 0px 40px 0px" }}>

                    <Grid container spacing={1} className={"add-padding-p8p"}>
                      <Grid item>
                        <Button onClick={e => {
                          history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}`);
                        }}>
                          <Typography
                            component="h6"
                            sx={{ fontWeight: 600 }}
                            color="text.primary"
                            variant="h6"
                          >
                            &#8249; {" RETURN"}
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>


                    <Grid
                      container
                      spacing={3}
                      direction="column"
                      justifyContent="center"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        {!isLoading &&
                          <Typography
                            component="h1"
                            color={"textPrimary"}
                            variant="h1"
                          >
                            Make A Payment
                          </Typography>
                        }
                        {isLoading && <Skeleton variant="rectangular" width={1051} height={69} />}
                      </Grid>
                      <Grid item xs={12}>
                        {!isLoading &&
                          <Typography
                            color={"textSecondary"}
                          >
                            Outstanding payment details are given below. Make the payment with a credit card. We accept all major credit cards.
                            Your payment through credit card will be processed immediately and you will receive a confirmation mail.
                          </Typography>
                        }
                        {isLoading && <Skeleton variant="text" width={1664} height={32} />}
                      </Grid>
                    </Grid>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                      <Card style={{ border: "none", boxShadow: "none", backgroundColor: "#F8F8F8" }}>
                        {((currentRentDetails && currentRentDetails.RentPeriods && currentRentDetails.RentPeriods.length > 0) || isLoading) ?
                          <>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                {!isLoading &&
                                  <Typography
                                    component="h3"
                                    color={"textSecondary"} 
                                    variant="h3"
                                  >
                                    Outstanding Payment
                                  </Typography>
                                }
                                {isLoading && <Skeleton variant="rectangular" width={386} height={47} />}

                              </Grid>
                              <Grid item xs={12}
                                className={isLoading ? "skelton-padding-0008" : ""}
                              >
                                {!isLoading &&
                                  <Typography
                                    color={"textSecondary"} 
                                  >
                                    Choose which month you will like to make the payment towards.
                                  </Typography>
                                }
                                {isLoading && <Skeleton variant="text" width={1664} height={42} />}
                              </Grid>
                            (
                           <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  {!isLoading && currentRentDetails.RentPeriods.map((e, keyIndex) => {
                                    if (keyIndex <= 2) {
                                      return (
                                        <Grid key={keyIndex} item xs={4}>
                                          <Card className={cardSelected[keyIndex] ? classes.rentCardClick : classes.rentCard}>
                                            <CardActionArea onClick={() => { handleSelection(e, keyIndex) }}>

                                              <CardContent
                                                className={classes.payCardCont}
                                              >
                                                <div
                                                  style={{
                                                    float: "left",
                                                    padding: "6px",
                                                  }}
                                                >
                                                  {cardSelected[keyIndex] ? <div style={{ width: "100%" }}> <CheckCircleIcon style={{ right: "6px", top: "6px", position: "absolute" }} color={"primary"} /></div> : null}
                                                  <img width="61px" alt='' src={'../' + e.MonthName + '.svg'} style={{paddingTop: "13%"}}/>
                                                </div> 
                                                <div
                                                  style={{
                                                    padding: "3px 3px 6px 2%",
                                                    float: "left",
                                                  }}
                                                >
                                                  <Typography component="h6" variant="h6" color="text.secondary">
                                                  ${e.Rent}
                                                  </Typography>

                                                  <Typography
                                                    /* component="h5" variant="h5" */
                                                    sx={{ fontSize: '22px', fontWeight:"400"}}
                                                    color={"textPrimary"}
                                                  >
                                                    {e.RentMonth.split(" ")[0].toUpperCase()}<br /> {e.Year ? e.Year : <br />}
                                                  </Typography>
                                                </div>
                                              </CardContent>

                                            </CardActionArea>
                                          </Card>
                                        </Grid>
                                      );
                                    }
                                    else {
                                      return null;
                                    }

                                  })}

                                  {isLoading && [1, 2, 3].map((val) => {
                                    return <MakePaymentRentCards key={val} />
                                  })
                                  }

                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Collapse in={expanded} timeout="auto" unmountOnExit>
                                  <Grid container spacing={3}>
                                    {!isLoading && currentRentDetails.RentPeriods.map((e, keyIndex) => {
                                      if (keyIndex > 2) {
                                        return (

                                          <Grid key={keyIndex} item xs={4}>
                                            <Card className={cardSelected[keyIndex] ? classes.rentCardClick : classes.rentCard}>
                                              <CardActionArea onClick={() => { handleSelection(e, keyIndex) }} >
                                                <CardContent
                                                  className={classes.payCardCont}
                                                >
                                                  <div
                                                    style={{ 
                                                      float: "left",
                                                      padding: "6px"
                                                    }}
                                                  >
                                                    {cardSelected[keyIndex] ? <div style={{ width: "100%" }}> <CheckCircleIcon style={{ right: "6px", top: "6px", position: "absolute" }} color={"primary"} /></div> : null}
                                                    <img width="61px" alt='' src={'../' + e.MonthName + '.svg'} style={{paddingTop: "13%"}} />
                                                  </div> 
                                                  <div
                                                    style={{
                                                      padding: "3px 3px 6px 2%",
                                                      float: "left",
                                                    }}
                                                  >

                                                    <Typography component="h6" variant="h6" color="text.secondary">
                                                      ${e.Rent}
                                                    </Typography>

                                                    <Typography
                                                    /* component="h5" variant="h5" */
                                                    sx={{ fontSize: '22px', fontWeight:"400"}}
                                                    color={"textPrimary"}
                                                    >
                                                      {e.RentMonth.split(" ")[0].toUpperCase()}<br /> {e.Year ? e.Year : <br />}
                                                    </Typography>
                                                  </div>
                                                </CardContent>
                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    top: "22px",
                                                    right: "10px"
                                                  }}
                                                ></div>
                                              </CardActionArea>
                                            </Card>

                                          </Grid>
                                        );

                                      }
                                      else {
                                        return null;
                                      }

                                    })}
                                  </Grid>
                                </Collapse>
                              </Grid>
                              )
                              {!isLoading && <Grid item xs={12}>
                                <CardActions style={{ cursor: "pointer", paddingBottom: "0px", borderBottom: "1px solid #e7e7e7" }} disableSpacing onClick={handleExpandClick}>
                                  <IconButton
                                    style={{}}
                                    className={clsx(classes.expand, {
                                      [classes.expandOpen]: expanded
                                    })}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                    size="large">
                                    <ExpandMoreIcon />
                                  </IconButton>
                                  <Typography
                                    color={"textSecondary"}
                                    component="h6"
                                    variant="h6"
                                  >
                                    {expanded ? "SHOW LESS" : "SHOW ALL"}
                                  </Typography>
                                </CardActions>
                              </Grid>
                              } 
                            </Grid> 
                          </> : <> <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography
                                component="h3"
                                color={"textSecondary"} 
                                variant="h3"
                              >
                                Outstanding Payment
                          </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography 
                                color={"textSecondary"} 
                              >
                                No data found
                          </Typography>
                            </Grid>
                          </Grid> </>
                        }
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {!isLoading &&
                            <Typography
                              component="h3"
                              color={"textSecondary"}
                              /* className={"renter-headerFive"} */
                              variant="h3"
                            >
                              Payment Method
                            </Typography>
                          }
                          {isLoading && <Skeleton variant="rectangular" width={386} height={47} />}
                        </Grid>
                        <Grid item xs={12}
                          className={isLoading ? "skelton-padding-0008" : ""}
                        >
                          {!isLoading &&
                            <Typography
                              color={"textSecondary"}
                            >
                             Choose saved payment method from the list or enter a new card/account detail manually 
                              to initiate the payment.
                            </Typography>
                          }

                          {isLoading && <Skeleton variant="text" width={1664} height={42} />}
                        </Grid>
                        <Grid item xs={12}>
                          {!isLoading &&
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel ref={inputLabel} id="select-state-label">
                                SAVED CARDS
                        </InputLabel>
                              <Select
                                labelid="select-Card-label"
                                id="Card-select-outlined"
                                fullWidth
                                name="CardDetails"
                                variant="outlined"
                                label="SAVED CARDS"
                                value={carDetails}
                                labelWidth={110}
                                onChange={(ed, child) => {
                                  onChange(ed, child);
                                }}
                              >
                                <MenuItem value="null">
                                  <em>Enter Credit Card Details Manually</em>
                                </MenuItem>
                                {/** Uncomment for enabling PLAID feature */}
                                {/* comment for remove ach release*/} 
                                 {/*  <MenuItem value="ach">
                                  <em>Enter Bank Account Details Manually</em>
                                </MenuItem>  */} 
                                {userCreditCards.map((e, keyIndex) => { 
                                  return (
                                    <MenuItem
                                      key={keyIndex+2}
                                      name={e}
                                      value={e.MasterID}
                                    >
                                      {e.MasterType==="User Card" ? `${e.DisplayCardNo} ${"exp:"}${e.ExpiryMonth}/${e.ExpiryYear}${" "}${e.Primary === "Yes" ? "(Primary)" : ""}` : `${e.BankAccount}${" "}${e.Primary === "Yes" ? "(Primary)" : ""}`}
                                    </MenuItem>
                                  );
                                })} 
                              </Select>
                            </FormControl>
                          }
                          {isLoading && <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Skeleton variant="rectangular" width={738} height={52} />
                          </Grid>
                          }

                        </Grid>
                        {paymentMethod==="Manual Card" ?
                        <CardForm {...props} formChange={formChange}/>
                        : paymentMethod==="Manual Bank" ?
                        <BankForm {...props} formChange={formChange}/>   
                        :<Grid item xs={12}  >
                            {!isLoading &&
                              <LoaderCLickButton
                                style={{ fontSize: "20px" }}
                                isLoading={isLoading}
                                size="large"
                                onClick={() => {
                                  history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/manage`, props: { fromRenter: true } });
                                }}
                              >
                                UPDATE CARDS
                                </LoaderCLickButton>
                            }
                            {isLoading && <Grid item xs={12} sm={12} md={3} lg={3}>
                              <Skeleton variant="rectangular" width={211} height={52} />
                            </Grid>
                            }
                          </Grid>}
                        {props.isMobile ?
                          <Grid item xs={12}>
                            <LoaderCLickButton
                              style={{ fontSize: "30px" }}
                              isLoading={isLoading}
                              size="large"
                              disabled={!validatePaymetButton()}
                              onClick={() => { makeaPayment() }}
                            >
                              MAKE PAYMENT
                              </LoaderCLickButton>

                          </Grid>
                          : null}
                      </Grid>
                    </Grid>
                    {((currentRentDetails && currentRentDetails.RentPeriods && currentRentDetails.RentPeriods.length > 0)) && 
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                                <Grid container spacing={2}>
                                  {
                                    !isLoading && (transactionData.Misc.length > 0) && <Grid item xs={12}>
                                      {

                                        transactionData.Misc.map((eachMisc, keyIndex) => {
                                          return (<Typography key={keyIndex}
                                            color={"textSecondary"}
                                            component="h5"
                                            variant="h5"
                                          >
                                            {eachMisc.appfeeName}{eachMisc.appFeeType !== "percentage" ? ': $ ' + transactionData.MiscAddition : ' (' + eachMisc.appFeeValue + '%): $' + transactionData.MiscAddition}
                                          </Typography>)
                                        })
                                      }

                                    </Grid>
                                  }
                                  {
                                    !isLoading && transactionData.AchSetupFee > 0.0  /* && paymentSourceType==='Plaid' */ && <Grid item xs={12}>
                                      <Typography
                                        color={"textSecondary"}
                                        component="h5"
                                        variant="h5"
                                      >
                                      {<CreateHeaderWithTooltip variant="h5" title={`ACH Account Setup Fee: $${transactionData.AchSetupFee}`} tooltip={`Plaid one-time fee of $${transactionData.AchSetupFee} for each account setup`}/> }  {/* {"Convenience Fee: $"} transactionData.TransactionFee*/}
                                      </Typography>
                                    </Grid>
                                  }
                                     {
                                    !isLoading && transactionData.TransactionFee > 0.0 && <Grid item xs={12}>
                                      <Typography
                                        color={"textSecondary"}
                                        component="h5"
                                        variant="h5"
                                      >
                                      {<CreateHeaderWithTooltip variant="h5" title={`${feeLabel}: $${transactionData.TransactionFee}`} tooltip={feeTooltip}/> }  {/* {"Convenience Fee: $"} transactionData.TransactionFee*/}
                                      </Typography>
                                    </Grid>
                                  }
                                  {
                                    !isLoading && transactionData.Tax > 0.0 && <Grid item xs={12}>
                                      <Typography
                                        color={"textSecondary"}
                                        component="h5"
                                        variant="h5"
                                      >
                                        {'Taxes (' + transactionData.Tax + '%): $' + Number((transactionData.TotalAmount * (transactionData.Tax / 100)).toFixed(2))}
                                      </Typography>
                                    </Grid>
                                  }
                                  <Grid item xs={12}>
                                    {!isLoading &&
                                      <Typography
                                        component="h3"
                                        color={"textSecondary"}
                                        variant="h3"
                                      >
                                        Total Payment:  ${Number((transactionData.TotalAmount + transactionData.TransactionFee +transactionData.AchSetupFee+ (transactionData.TotalAmount * (transactionData.Tax / 100))).toFixed(2))}
                                      </Typography>
                                    }
                                    {isLoading && <Skeleton variant="rectangular" width={455} height={50} />}
                                  </Grid>
                                  {props.isMobile ? null : <Grid item xs={12}>
                                    {!isLoading &&
                                      <LoaderCLickButton
                                        style={{ fontSize: "30px" }}
                                        isLoading={isLoading}
                                        size="large" 
                                        disabled={!validatePaymetButton()}
                                        onClick={() => { makeaPayment() }}
                                      >
                                        MAKE PAYMENT
                                      </LoaderCLickButton>
                                    }
                                    {isLoading && <Grid item xs={12} sm={12} md={3} lg={3}>
                                      <Skeleton variant="rectangular" width={295} height={63} />
                                    </Grid>
                                    }
                                  </Grid>}
                                </Grid>
                      </Grid>
                      }
                    <Grid item xs={12} sm={12} md={5} lg={5}>

                    </Grid>
                  </Grid>
                  </div>} 
          </Container>
        </Grid>
      </Grid>
    </Root>
  );
}  