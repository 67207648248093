import React, { Component} from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid"; 
import { Auth } from 'aws-amplify';  
import LoaderButton from "./LoaderButton"; 
import { withSnackbar } from 'notistack';

const fontScale = window.devicePixelRatio; 
 class ChangeEmail extends Component {
    constructor(props) {
      super(props); 
      this.state = {
        code: "",
        email: "",
        currentEmail:this.props.userSession.Email,
        codeSent: false,
        isConfirming: false,
        isSendingCode: false
      };
    }
  
    validatEmailForm() {
      return this.state.email.length > 0;
    }
  
    validateConfirmForm() {
      return this.state.code.length > 0;
    }
  
    handleChange = event => {
      if(event.target.id==='email')
      {
        this.setState({
          [event.target.id]: event.target.value.toLowerCase()
        });
      }
      else
      {
        this.setState({
          [event.target.id]: event.target.value
        });
      }
     
    };
  
    handleUpdateClick = async event => {
      event.preventDefault();
  
      this.setState({ isSendingCode: true });
  
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, { email: this.state.email });  
        this.setState({ codeSent: true });
      } catch (e) { 
        this.props.enqueueSnackbar(e.message, {variant: 'error'}) 
        this.setState({ isSendingCode: false });
      }
    };
  
    handleConfirmClick = async event => {
      event.preventDefault();  
      this.setState({ isConfirming: true });  
      try {
        await Auth.verifyCurrentUserAttributeSubmit("email", this.state.code);  
        //this.props.history.push("/change-email");
        this.setState({ isConfirming: false }); 
        this.props.enqueueSnackbar("Your email has been changed.!!", {variant: 'success'});
        this.props.closeModel();
        this.handleLogout();
      } catch (e) {
        //alert(e.message);
        this.setState({ isConfirming: false });
         this.props.enqueueSnackbar(e.message, {variant: 'error'}) 
      }
    };  
    handleLogout=async  () => {
        await Auth.signOut();    
        this.props.userHasAuthenticated(false);    
        this.props.history.push("/");
      } 
    renderUpdateForm() {
      const { classes } = this.props;
      console.log("SCREEN SIZE ", fontScale);
      return (
        <form className="common-form" noValidate  onSubmit={this.handleUpdateClick}> 
        <Grid container spacing={3} >
        <Grid item xs={12}>
            <TextField
            autoComplete="fname"
            type="text"
            name="currentEmail"
            variant="outlined"
            disabled
            fullWidth
            id="currentEmail"
            label="Current Email"
            value={this.state.currentEmail}
            onChange={this.handleChange}
            autoFocus
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
            autoComplete="fname"
            type="text"
            name="email"
            variant="outlined"
            required
            fullWidth
            id="email"
            label="New Email"
            value={this.state.email}
            onChange={this.handleChange}
            autoFocus
            />
        </Grid>
        <Grid item xs={12}>
                <LoaderButton
                    block
                    type="submit"
                    size="large"
                    className="common-form-submit"
                    disabled={!this.validatEmailForm()}
                    isLoading={this.state.isSendingCode}        
                    >
                    UPDATE
                </LoaderButton>
            </Grid>
        </Grid>
    </form>
      );
    }
  
    renderConfirmationForm() {
      const { classes } = this.props;
      return (
        <form className="common-form" noValidate  onSubmit={this.handleConfirmClick}> 
        <Grid container  spacing={3}>
          <Grid item xs={12}>
          <TextField
              autoComplete="fname"
              name="code"
              variant="outlined"
              required               
              fullWidth
              id="code"
              label="Verification Code"
              value={this.state.code}
              onChange={this.handleChange}
              helperText={"Please check your email"+ this.state.email+" for the confirmation code."}
              autoFocus
            />            
          </Grid>
          <Grid item xs={12}>
                <LoaderButton
                block
                type="submit"
                size="large" 
                className="common-form-submit"
                isLoading={this.state.isConfirming}
                disabled={!this.validateConfirmForm()}     
                >
                SUBMIT
                </LoaderButton>
            </Grid>
        </Grid>          
      </form>
      );
    }
  
    render() {
      return (
        <div className="ChangeEmail">
          {!this.state.codeSent
            ? this.renderUpdateForm()
            : this.renderConfirmationForm()}
        </div>
      );
    }
  }

 
export default withSnackbar(ChangeEmail);