import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { API } from "aws-amplify";
import Moment from 'react-moment';
import CsvExport from '../CsvExport';
import { useHistory } from "react-router-dom";
import { Skeleton, TextField } from '@mui/material';
import usePersistedState from '../../usePersistedState';
import CustomizedTooltips from '../../lib/tooltIp';
import Button from '@mui/material/Button';

//** Data Grid Import Part **/
import {
    LicenseInfo,
    DataGridPro,
    GridOverlay,
    useGridApiRef,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarExport
} from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
const PREFIX = 'viewtransactions';

const classes = {
    root: `${PREFIX}-root`,
    textPrimary: `${PREFIX}-textPrimary`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        display: 'inline-flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        color: theme.palette.text.secondary,
    },

    [`& .${classes.textPrimary}`]: {
        color: theme.palette.text.primary,
    }
}));

LicenseInfo.setLicenseKey(
    '79e390e23cc1709a04137710c7c5cdc1T1JERVI6Mjg0NDQsRVhQSVJZPTE2NjEwMDE3MTQwMDAsS0VZVkVSU0lPTj0x',
);
const MAX_ROW_LENGTH = 1500;
//** Data Grid Import Part **/


//** Data Grid Action related Code */

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <Root style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </Root>
        </GridOverlay>
    );
}
let capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
}
//** Data Grid Action related Code */
export function ViewPaymentHistory(props) {
    let history = useHistory();
    const [exportAvailable, setExportAvailable] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [transactionTypeCombo] = usePersistedState("transactionTypeCombo", []);
    const [selectedProperty] = usePersistedState('selectedProperty', {});
    //** Data Grid Related Code */
    const apiRef = useGridApiRef();
    const [filterValue, setFilterValue] = React.useState({});
    const [data, setData] = useState({
        loading: true,
        rows: [],
        pageSize: 25,
        columns: [
            { field: "MasterID", hide: true, filterable: false },
            {
                headerName: "TRANSACTION DATE",
                field: "TransactionDate",
                renderCell: params => <Moment format="MMM DD YYYY">{params.value}</Moment>,
                flex: 1,
                minWidth: 150,
                type: 'date'
            },
            { headerName: "RENT MONTH", field: "TransactionRentMonth", flex: 1, minWidth: 150 },
            {
                headerName: "TRANSACTION TYPE", field: "TransactionType", flex: 1, minWidth: 150,
                renderCell: (params) => {
                    return params.value ? (
                        params.value == 'cash' ? "Cash"
                            : params.value == 'etransfer' ? "E-transfer"
                                : params.value == 'checkdeposit' ? "Check"
                                    :params.value == 'STRIPE'? 'Credit Card'
                                        :params.value == 'PLAID'? 'ACH'                                    
                                            : params.value == '' ? "" : capitalizeFirstLetter(params.value.toLowerCase())
                    ) : ""
                },
                type: 'singleSelect',
                valueOptions: transactionTypeCombo.map((e) => { return e.Text })
            },
            {
                headerName: "DEPOSIT", field: "TransactionDeposit", flex: 1, minWidth: 150, filterable: false,
                renderCell: params => { return params.value === 0 ? "-" : "$" + params.value }
            },
            {
                headerName: "MISC", field: "TransactionMiscValue", flex: 1, minWidth: 150, filterable: false,
                renderCell: params => { return params.value === 0 ? "-" : "$" + params.value }
            },
            {
                headerName: "RENT", field: "TransactionRent", flex: 1, minWidth: 150, filterable: false,
                renderCell: params => { return params.value === 0 ? "-" : "$" + params.value }
            },
            {
                field: "ConvenienceFee", flex: 1, minWidth: 170, filterable: false,
                renderCell: params => { return params.value === 0 ? "-" : "$" + params.value },
                renderHeader: () => (
                    <> <span style={{ fontWeight: '500' }}>
                        {'FEE (TENANT) '}
                    </span>
                        <CustomizedTooltips content={'Payment gateway processing fee paid by the tenant'} />
                    </>
                )
            },
            {
                headerName: "TOTAL", field: "TransactionTotal", flex: 1, minWidth: 150, filterable: false,
                renderCell: params => { return params.value === 0 ? "-" : "$" + params.value }
            }
        ]
    });
    useEffect(() => {
        let active = true;
        (async () => {
            console.log('filterValue useEffect', filterValue)
            updateData("loading", true);
            const newData = await GetInfinityTable(data.pageSize, 0);
            console.log('mounted.current', mounted.current);
            if (mounted.current) {
                if (newData && newData.data)
                    updateData("rows", newData.data);
            }
            if (!active) {
                return;
            }
            updateData("loading", false);
        })();
        return () => {
            active = false;
        };
    }, [filterValue]);
    const onFilterChanges = React.useCallback((filterModel) => {
        console.log('filterModel', filterModel)
        setFilterValue(filterModel);
        mounted.current = true;
    }, []);
    const mounted = React.useRef(true);
    function CustomToolbar(props) {
        console.log('props', props);
        return (
            <GridToolbarContainer>
                <Grid container
                    spacing={3}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid item>
                        {data.loading && <div style={{ display: 'flex' }}>
                            <Skeleton variant='rectangular' width={30} height={32} className={'skelton-margin-right-5'} />
                            <Skeleton variant='rectangular' width={100} height={32} />
                        </div>}
                        {!data.loading && <GridToolbarFilterButton />}
                    </Grid>
                    <Grid item>
                        {data.loading && <div style={{ display: 'flex' }}>
                            <Skeleton variant='rectangular' width={30} height={32} className={'skelton-margin-right-5'} />
                            <Skeleton variant='rectangular' width={100} height={32} />
                        </div>}
                        {!data.loading && <GridToolbarExport />}
                    </Grid>
                </Grid>
            </GridToolbarContainer>
        );
    }
    const updateData = (k, v) => setData((prev) => ({ ...prev, [k]: v }));
    const loadServerRows = async (newRowLength) => {
        updateData("loading", true);
        const newData = await GetInfinityTable(data.pageSize, newRowLength);
        if (mounted.current) {
            if (newData && newData.data)
                updateData("rows", data.rows.concat(newData.data));
            updateData("loading", false);
        }
    };
    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);
    const handleOnRowsScrollEnd = (params) => {
        if (data.rows.length <= MAX_ROW_LENGTH && data.rows.length !== 0) {
            loadServerRows(data.rows.length);
        }
    };
    async function GetInfinityTable(pageSize, fromNo) {
        setIsLoading(true);
        updateData("loading", true);
        updateData("pageSize", pageSize);
        var body = {
            masterType: "Transaction",
            property: selectedProperty,
            UserID: props.userSession.UserName,
            pageSize: pageSize,
            PropertyID: selectedProperty.PropertyID,
            fromNo: fromNo,
            userData: props.userSession,
            filterValue: filterValue
        };
        body.userData = props.userSession;
        return new Promise((resolve, reject) => {
            let apiName = "rentapi";
            let path = "/modules";
            let myInit = {
                headers: { "Content-Type": "application/json" },
                body: body,
                queryStringParameters: {
                    module: 'renter',
                    op: 'viewTransactions'
                }
            };
            API.post(apiName, path, myInit)
                .then(async response => {
                    if (response.data && response.data.length > 0) setExportAvailable(true);
                    // resolve({ data: responses.slice(fromNo, (fromNo + 25)) });
                    setIsLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    setIsLoading(false);
                    reject([]);
                });
        });
    }
    //** Data Grid Related Code END */   
    return (
        <div className={"home-root"}>
            <Grid
                container
                spacing={0}
                direction="column">
                <Grid item>
                    <Container component="main" className="home-main">
                        <CssBaseline />
                        <div className="home-paper">
                            <Grid container spacing={1} className={"add-padding-p8p"} >
                                <Grid item>
                                    <Button onClick={e => {
                                        history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}`);
                                    }}>
                                        <Typography
                                            component="h6"
                                            sx={{ fontWeight: 600 }}
                                            color="text.primary"
                                            variant="h6"
                                        >
                                            &#8249; {" RETURN"}
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container
                                spacing={2}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start">
                                <Grid item xs={6}>
                                    {!isLoading &&
                                        <Typography
                                            component="h1"
                                            variant="h1"
                                        >
                                            Billing History
                                        </Typography>
                                    }
                                    {isLoading && <Skeleton variant="rectangular" width={350} height={69} />}
                                </Grid> 
                            </Grid>


                            <Grid container
                                spacing={2}
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start">
                                <Grid item xs={12}>
                                    {!isLoading &&
                                        <Typography 
                                            color={"textSecondary"}
                                            variant="subtitle1"
                                        >
                                            A complete history of your account's billing activity.
                                        </Typography>
                                    }
                                    {isLoading && <Skeleton variant="text" width={810} height={32} />}
                                </Grid>
                            </Grid>
                            {/* DataGridPro Start */}
                            <div style={{ height: 1024, width: '100%' }}>
                                <div style={{ display: 'flex', height: '100%' }}>
                                    <div style={{ flexGrow: 1 }}>
                                        <DataGridPro
                                            apiRef={apiRef}
                                            density="compact"
                                            rowHeight={81}
                                            hideFooter
                                            hideFooterPagination
                                            loading={data.loading}
                                            onRowsScrollEnd={handleOnRowsScrollEnd}
                                            rows={data.rows}
                                            getRowId={(r) => r.MasterID}
                                            columns={data.columns}
                                            filterMode="server"
                                            onFilterModelChange={onFilterChanges}
                                            components={{
                                                LoadingOverlay: CustomLoadingOverlay,
                                                Toolbar: CustomToolbar
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* DataGridPro Start */}
                        </div>
                    </Container>
                </Grid>
            </Grid>
        </div>
    );
}
