import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { LoaderCLickButton } from "../LoaderButton";
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker' 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from "@mui/material/FormControl";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { useHistory, useLocation } from "react-router-dom";
import usePersistedState from '../../usePersistedState';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import { useSnackbar } from 'notistack';
import { isEmpty, isDate } from "../../lib/util";
import { styled } from '@mui/material/styles';

const DialogBox = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(2),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  }));
  
  const DialogTitle = (props => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography sx={{ m: 0, p: 2, pl: 3, pr: 3 }}  {...other}>
        <Typography variant="h3" component="h3" sx={{ lineHeight: 1 }}>{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: "8px",
              top: "8px",
              color: "text.secondary",
            }}
            onClick={onClose}
            size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

export default function AddMiscFee(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [dateValue, setDateValue] = React.useState([null, null]);
    const [miscData, setMiscData] = usePersistedState('miscData', {
        "appfeeName": "",
        "appFeeValue": 0,
        "appFeeType": "percentage",
        "activeDatePlan": "oneTimePlan",
        "validFrom": "",
        "validTo": "",
        "added": false
    }); 
    const [isLoading, setIsLoading] = useState(false);
    const [activateValidationBorder, setActivateValidationBorder] = React.useState(false);
    useEffect(() => {
        let isSubscribed = true;
        const userFetch = async () => {
            if (props.miscData && isSubscribed) {
                let misc = {};
                misc = props.miscData;
                if (!isEmpty(misc)) {
                    misc.added = true;
                }
                await setMiscData(!isEmpty(misc) ? misc : [{
                    "appfeeName": "",
                    "appFeeValue": 0,
                    "appFeeType": "percentage",
                    "activeDatePlan": "oneTimePlan",
                    "validFrom": "",
                    "validTo": "",
                    "added": false
                }]);
            }
            return () => isSubscribed = false;
        }
        userFetch();
    }, [props.miscData]); 

    return (
        <DialogBox
            fullWidth
            maxWidth={"sm"}
            open={props.addMiscFeeOpen}
            onClose={e => {

                props.setAddMiscFeeOpen(false);
            }}
            aria-labelledby="responsive-dialog-title"        >
            <DialogTitle id="customized-dialog-title" onClose={e => { props.setAddMiscFeeOpen(false) }}>
                Add Fee
            </DialogTitle>
            <DialogContent>
                    <Grid container
                        spacing={1}
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start">
                        <Grid item style={{ paddingTop: "1%" }}>
                            <Typography
                                component="h6"
                                variant="h6"
                                color="text.secondary"
                            >
                                {"Use this widget to add those varied expenses, that the tenants may have incurred during the lease term. The tenant will see the total fee on their next payment transaction in the checkout page."}
                            </Typography>
                        </Grid>

                        <Grid item
                            className="misc-card-parent-grid"
                        >
                            <Card className="misc-card">
                                <div className="misc-details">
                                    <CardContent className="misc-content">
                                        <Grid
                                            container
                                            spacing={1}
                                            direction="row">
                                            <Grid item xs={12} sm={12} md={12} lg={12} className="misc-card-fst-child">
                                                <Grid className="misc-card-snd-child">
                                                    <TextField
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        name="appfeeName"
                                                        placeholder="e.g Application Fee"
                                                        onChange={miscChange}
                                                        id="appfeeName"
                                                        autoComplete="current-password"
                                                        value={miscData.appfeeName}
                                                    />

                                                    <Typography
                                                        style={{
                                                            fontSize: "14px",
                                                            lineHeight: "29px",
                                                            fontWeight: "lighter",
                                                            paddingLeft: "6%",
                                                            color: "#7c7c7c"
                                                        }}
                                                    >
                                                        {"This will read in the breakdown"}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} className="misc-card-fst-child">
                                                TYPES
                                                <Grid item xs={12} className="misc-card-types">
                                                    <FormControl component="fieldset">

                                                        <RadioGroup
                                                            defaultValue="percentage"
                                                            value={miscData.appFeeType || "percentage"}
                                                            aria-label="percentage"
                                                            name="appFeeType"
                                                            onChange={e => {
                                                                console.log("CURRENTVAL : ", e.target.value)
                                                                miscChange(e);
                                                            }}
                                                        >
                                                            <FormControlLabel value="percentage" control={<Radio />} label={<Typography style={{ fontWeight: "bold" }} >Percentage</Typography>} />
                                                            <FormControlLabel value="fixedAmount" control={<Radio />} label={<Typography style={{ fontWeight: "bold" }} >Fixed Amount</Typography>} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} className="misc-card-fst-child">
                                                VALUE
                                                <Grid className="misc-card-value">
                                                    <TextField
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        name="appFeeValue"
                                                        /* placeholder="Application Fee" */
                                                        label=""
                                                        id="applicationFee"
                                                        autoComplete="current-password"
                                                        value={miscData.appFeeValue}
                                                        onChange={miscChange}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    className="misc-unit"
                                                                >
                                                                    {miscData.appFeeType === "percentage" ? " %" : ""}
                                                                </InputAdornment>
                                                            ),
                                                            startAdornment: (
                                                                <InputAdornment
                                                                    className="misc-unit">
                                                                    {miscData.appFeeType === "fixedAmount" ? "$ " : ""}
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        InputAdornmentProps={{ position: "end" }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} className="misc-card-activ-dates">
                                                ACTIVE DATES

                                                <Grid item xs={12} className="misc-add-padd-top-two">
                                                    <FormControl component="fieldset">

                                                        <RadioGroup
                                                            defaultValue="oneTimePlan"
                                                            value={miscData.activeDatePlan || "oneTimePlan"}
                                                            aria-label="activeDatePlan"
                                                            name="activeDatePlan"
                                                            onChange={e => {
                                                                console.log("CURRENTVAL : ", e.target.value)

                                                                miscChange(e);
                                                            }}
                                                        >
                                                            <FormControlLabel value="oneTimePlan" control={<Radio />} label={<Typography style={{ fontWeight: "bold" }} >One Time (on the next pay cycle)</Typography>} />
                                                            <FormControlLabel value="reccuringPlan" control={<Radio />} label={<Typography style={{ fontWeight: "bold" }} >Recurring</Typography>} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>

                                                {
                                                    miscData.activeDatePlan != "oneTimePlan" &&

                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        direction="row">
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DateRangePicker
                                                                    startText="Valid From"
                                                                    endText="Valid To"
                                                                    value={dateValue}
                                                                    onChange={date => {
                                                                        miscChange(date, 'miscDate')
                                                                    }}
                                                                    renderInput={(startProps, endProps) => (
                                                                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={4} >
                                                                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                                                                <div className="addlease-right-fields-p">
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        InputProps={{
                                                                                            startAdornment: (
                                                                                                <InputAdornment position="start">
                                                                                                    <EventOutlinedIcon />
                                                                                                </InputAdornment>
                                                                                            ),
                                                                                        }}
                                                                                        className={`${activateValidationBorder && !miscData.validFrom ? " common-mandatory-red" : ""}`}
                                                                                        {...startProps} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                                                                <div className="addlease-right-fields-p">
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        InputProps={{
                                                                                            startAdornment: (
                                                                                                <InputAdornment position="start">
                                                                                                    <EventOutlinedIcon />
                                                                                                </InputAdornment>
                                                                                            ),
                                                                                        }}
                                                                                        className={`${activateValidationBorder && !miscData.validTo ? " common-mandatory-red" : ""}`}
                                                                                        {...endProps} />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </div>
                            </Card>

                        </Grid>
                    </Grid>
            </DialogContent>
            <Divider variant="middle" />
            <DialogActions>
                <Grid container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start">
                    <Grid item xs={12}>
                        <div className={"offline-bt-flex"}>
                            <LoaderCLickButton
                                isLoading={isLoading}
                                size="large"
                                disabled={false}
                                onClick={() => {
                                    updateMiscFee()
                                }}
                            >
                                DONE
                            </LoaderCLickButton>
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
        </DialogBox>
    );
    function miscChange(e, dateType) {
        let ob = {};
        if (e.target && e.target.name) {
            ob[e.target.name] = e.target.value
        }
        else {
            if (dateType === 'miscDate') {
                setDateValue(e);
                if (e.length > 0 && e[0] && isDate(e[0])) ob['validFrom'] = e[0].toISOString();
                if (e.length > 1 && e[1] && isDate(e[1])) ob['validTo'] = e[1].toISOString();
            }
        }
        setMiscData({ ...miscData, ...ob });
    }

    function updateMiscFee() {
        console.log('updateMiscFee', miscData);
        let emptyFields = 0;
        Object.keys(miscData).forEach((each) => {
            if (each == "validFrom" || each == "validTo") {
                if (miscData.activeDatePlan == "reccuringPlan" && !miscData[each]) emptyFields++;
            }
            else if (each == "added") { }
            else {
                if (!miscData[each]) emptyFields++;
            }
        });
        if (emptyFields) {
            enqueueSnackbar("Enter all fields", { variant: 'error' })
        }
        else {
            miscData.added = true;
            props.setSelectedMisc(miscData);
            props.setAddMiscFeeOpen(false);
            enqueueSnackbar(
                "Misc fee added successfully",
                {
                    variant: "success"
                });
        }
    }
}


