import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
export default function PoweredBy(props) {
    return (
        <Box>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item >
                    <Typography className='powered-by-txt' align="center">
                        Powered by
                 </Typography>
                </Grid>
                <Grid item >
                    <a href="https://www.leaseleads.com/">
                    <img alt='portal logo' align="center" className='powered-by-logo' src={'../img/leaseleads-LOGO-horizontal.png'} />
                    </a>
                </Grid>
            </Grid>
            <br />
        </Box>
    );
}
