import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useHistory, useLocation } from "react-router-dom";
import clsx from 'clsx';
import { Storage, Auth, API } from 'aws-amplify';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import Typography from "@mui/material/Typography"; 
import FormControl from '@mui/material/FormControl'; 
import { LoaderDeleteButton } from "../LoaderButton";
import md5 from 'crypto-js';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import EditIcon from '@mui/icons-material/Edit';
import LoaderButton, { LoaderCLickButton } from "../LoaderButton";
import ChangeEmail from "../ChangeEmail";
import { useSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import { Skeleton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from "@mui/icons-material/Add";
import { AmplifyS3Image  } from '@aws-amplify/ui-react';
import Fab from "@mui/material/Fab";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { Box, Card } from "@mui/material"
import CardMedia from "@mui/material/CardMedia"
import CardActions from "@mui/material/CardActions"
import { trackPromise } from 'react-promise-tracker';
import { areas } from '../../common/constants/areas';
import usePersistedState from '../../usePersistedState';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
const PREFIX = 'manageuser';

const classes = {
  newpasswordmargin: `${PREFIX}-newpasswordmargin`,
  input: `${PREFIX}-input`,
  card1: `${PREFIX}-card1`,
  card2: `${PREFIX}-card2`,
  details: `${PREFIX}-details`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.newpasswordmargin}`]: {
    marginTop: theme.spacing(3)
  },

  [`& .${classes.input}`]: {
    display: 'none',
  },
  [`& .${classes.card1}`]: {
    display: "flex",
    marginRight: "auto", 
    maxHeight: "215px",
    maxWidth: "215px"
  },

  [`& .${classes.card2}`]: {
    display: "flex",
    marginRight: "auto",
    marginBottom: "30px",
    maxHeight: "315px",
    maxWidth: "315px"
  },

  [`& .${classes.details}`]: {
    display: "flex"
  }
}));

const DialogBox = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));

const DialogTitle = (props => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle sx={{ m: 0, p: 2, pl: 3, pr: 3 }}  {...other}>
      <Typography variant="h3" component="h3" sx={{ lineHeight: 1 }}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: "8px",
            top: "8px",
            color: "text.secondary",
          }}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const MapData = (process.env.REACT_APP_STAGE === "dev") ? awsdev : (process.env.REACT_APP_STAGE === "dev2") ? awsdev2 : window['runConfig'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function EditUser(props) {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  let history = useHistory();
  const [userFormOpen, setUserFormOpen] = useState(false);
  const [isEditing, setIsEditing] = usePersistedState("isEditing", false); // useState(false);
  const [fromGrid, setFromGrid] = usePersistedState("fromGrid", false); //useState(props.fromGrid); 
  const [isLoading, setIsLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passOpen, setpassOpen] = useState(false);
  const [user, setUser] = usePersistedState("user", { "PropertyID": [], "PassWord": "", "Privilege": "RENTERS" });// useState({ "PropertyID": [], "PassWord": "" });      
  const [selectedProperty] = usePersistedState('selectedProperty', {});
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [deltedKey] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  console.log('props edit user ***', props);
  useEffect(() => {
    const userFetch = async () => {
      if (props?.type === 'team' || props?.type === 'tenant') {
        if (!props.isEditing) {
          await setUser({ ...props.user, "PropertyID": [{ PropertyID: selectedProperty.PropertyID, PropertyName: selectedProperty.PropertyName }], "PassWord": "", "Privilege": "RENTERS" });
        }
        else {
          console.log('props.user *** ', props.user);
          await setUser(props.user);
        }
        await setIsEditing(props.isEditing);
        await setFromGrid(props.fromGrid);
      }
      else if (props?.type === 'profile') {
        await GetUserProfile();
        await setIsEditing(props.isEditing);
        await setFromGrid(props.fromGrid);
      }
      else {
        if (isEditing) {
          let userFromCache = JSON.parse(window.localStorage.getItem('user') || {});
          setUser({ ...userFromCache, ...{ "PropertyID": [{ PropertyID: selectedProperty.PropertyID, PropertyName: selectedProperty.PropertyName }] } })
        }
      }
    }
    userFetch();
  }, [props.user]);

  useEffect(() => {
    setUserFormOpen(props.userFormOpen)
  }, [props.userFormOpen]);


  async function GetUserProfile() {

    console.log("UserID ", props.userSession.UserName);
    setIsLoading(true);
    var columns = [];
    var masterType = "User";
    var apiModule = "user";
    var op = "getUser";
    var userData = { "userData": props.userSession }
    let body = { masterType: masterType, MasterID: props.userSession.UserName, for: "CardDisplay", ...userData };
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit)
        .then(response => {
          setIsLoading(false);
          if (response && response.length > 0) {
            window.localStorage.setItem('user', JSON.stringify({ ...user, ...response[0] }));
            setUser({ ...user, ...response[0] });
            console.log("prop lateMinutes changed 1 : ", response[0], user);
          }
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }
  const handleClickOpen = (e) => {
    if (e === "email") {
      setEmailOpen(true);
    }
    if (e === "password") {
      setpassOpen(true);
    }
    if (e === "delete") {
      setDeleteOpen(true);
    }
  };
  const handleClose = (e) => {
    if (e === "email") {
      setEmailOpen(false);
    }
    if (e === "password") {
      setpassOpen(false);
    }
    if (e === "delete") {
      setDeleteOpen(false);
    }
    if (e === "user") {
      props.setUserFormOpen(false);
    }
  };

  function imageDelete(e) {
    console.log("DeletedKeyset,e.FileKey", e);
    let items = deltedKey;
    if (items.length > 0) {
      for (let i = 0; i < items; i++) {
        if (items[i].FileKey !== e.FileKey) {
          items.push(e.FileKey);
        }
      }
    }
    else {
      items.push(e.FileKey);
    }
    Object.assign(deltedKey, items);
    setUser({ ...user, "ProfileImage": {} })
    console.log("DeletedKeyset,go and  delete deltedKey", deltedKey);

  }
  const ImageComponent = props => {
    return (
      <Root>
       <Card className={classes.card1}>
                <div className={classes.details}>
            <CardActions>
              <Fab onClick={() => imageDelete(props)} style={{ color: "#000000", background: "none", boxShadow: "none", }} size="small" aria-label="delete picture" component="span">
                <CloseSharpIcon />
              </Fab>
            </CardActions>
          </div>
          <CardMedia>
            <AmplifyS3Image style={{"--height": "100%","--width": "100%"}} imgProps={{display: "block",  width:100, height:100 }} level="public" imgKey={props.FileKey} />
          </CardMedia>
        </Card>
      </Root>
    );
  };
  function getFileExtension(filename) {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }
  function onChange(e, child) {
    if (e.target.name === "currentPassword") setCurrentPassword(e.target.value);
    if (e.target.name === "newPassword") setNewPassword(e.target.value);
    if (e.target.name === "firstname") setUser({ ...user, "FirstName": e.target.value })
    if (e.target.name === "lastName") setUser({ ...user, "LastName": e.target.value })
    if (e.target.name === "password") setUser({ ...user, "PassWord": e.target.value })
    if (e.target.name === "unit") setUser({ ...user, "Unit": e.target.value })
    if (e.target.name === "privilege") setUser({ ...user, "Privilege": e.target.value })
    if (e.target.name === "phonenumber") setUser({ ...user, "PhoneNumber": e.target.value })
    if (e.target.name === "email") {
      setUser({ ...user, "Email": e.target.value, "UserName": e.target.value });
    };
    if (e.target.name === "ProfileImage") {
      const target = e.target;
      const file = target.files[0];
      let targetName = target.name;
      var img = document.createElement("img");
      img.onload = function () {
        console.log(this.width + " " + this.height);
        if (targetName === "ProfileImage" && this.width <= 500 && this.height <= 500) {

          let fileName = file.name;
          let fileExt = getFileExtension(fileName);
          let s3Key = (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)) + '.' + fileExt;
          const fileData = { FileKey: s3Key, FileName: fileName };
          Storage.put(s3Key, file, {
            contentType: file.type
          })
            .then(result => {
              console.log(result);
              setUser({ ...user, "ProfileImage": fileData });
            })
            .catch(err => console.log(err));
        }
        else {
          // openSnackbar({ message: "Image size should be lesser than 500X500", variant: "error" });
          enqueueSnackbar("Image size should be lesser than 500X500", { variant: 'error' });
        }
      };
      let sfile = target.files[0]
      if (sfile) {
        var reader = new FileReader();
        reader.onloadend = function (ended) {
          img.src = ended.target.result;
        };
        reader.readAsDataURL(file);
        console.log("img", img);
      }
    }
  };
  function validateForm() {
    console.log("validate form : ", user.Privilege, user.PropertyID);
    return user !== null && user.FirstName && user.FirstName.length > 0 && user.LastName && user.LastName.length > 0 &&
      (user.Privilege == "ADMIN" || (user.PropertyID && user.PropertyID.length > 0)) &&
      /* (isEditing || (user.Privilege && user.Privilege.length > 0)) */
      (isEditing || (user.Email && user.Email.length > 0));
  };

  function validateChangePassword() {
    return currentPassword != "" && newPassword != "";
  }

  function validatePasswordEmailForm() {
    console.log("VALIDATE PASSWORD ", isEditing);
    return isEditing;
  }

  function handleClearForm() {
    setUser({ "PropertyID": [], "PassWord": "", "Privilege": "RENTERS" });
    document.getElementById("user-form").reset();
  }
  function handleClickShowNewPassword() {
    console.log("Handle show ", showNewPassword);
    setShowNewPassword(!showNewPassword);
    console.log("Handle show ", showNewPassword);
  };
  function handleMouseDownNewPassword(e) {
    e.preventDefault();
  };
  function handleClickShowCurrentPassword() {
    console.log("Handle show ", showCurrentPassword);
    setShowCurrentPassword(!showCurrentPassword);
    console.log("Handle show ", showCurrentPassword);
  };
  function handleMouseDownCurrentPassword(e) {
    e.preventDefault();
  };
  async function updatepropertyDB(res) {
    console.log("ADD USER TO DB");
    setIsLoading(true);
    let apiName = "rentapi";
    let path = '/store/updateUserProperty';
    var body = {};
    body.PropertyID = user.PropertyID;
    body.MasterID = res.userSub;
    body.email = user.Email;
    body.name = user.FirstName + ' ' + user.LastName;
    body["custom:firstname"] = user.FirstName;
    body["custom:lastname"] = user.LastName;
    body["custom:privilege"] = user.Privilege;
    body["custom:unit"] = user.Unit;
    body["custom:domain"] = MapData.aws_app_domain_prefix;
    if (user.PhoneNumber && user.PhoneNumber !== "") body.PhoneNumber = user.PhoneNumber;
    if (user.ProfileImage && user.ProfileImage.hasOwnProperty("FileKey") && user.ProfileImage.FileKey.length > 0) body.ProfileImage = user.ProfileImage;
    body.userData = props.userSession;
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body
    };
    console.log(myInit);
    API.post(apiName, path, myInit).then(response => {
      console.log("UPDATE USER PROPERTY", response);
      setIsLoading(false);
      // openSnackbar({ message: "Invitation sent out" + user.Email, variant: "success" });
      enqueueSnackbar("Invitation sent out" + user.Email, { variant: 'success' });
      handleClearForm();
      //history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant`);
      history.goBack();
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      // openSnackbar({ message: err, variant: "error" });
      enqueueSnackbar(err, { variant: 'error' });

    });
  }
  async function changePassword(event) {
    event.preventDefault();
    console.log("CHANGE PASSWORD USER ", newPassword + " : " + currentPassword + " : " + md5.MD5(newPassword).toString() + " : " + md5.MD5(currentPassword).toString());

    setIsLoading(true);
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, md5.MD5(currentPassword).toString(), md5.MD5(newPassword).toString());
      })
      .then(data => {
        console.log("CHANGE PASSWORD ", data);
        if (data == "SUCCESS") {
          // handleModal(false);
          setNewPassword("");
          setCurrentPassword("");
          handleClose("password");
          // openSnackbar({
          //   message: "Password was successfully changed",
          //   variant: "success"
          // });
          enqueueSnackbar("Password was successfully changed", { variant: 'success' });
        }
        setIsLoading(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
        // openSnackbar({ message: err.message, variant: "error" });
        enqueueSnackbar(err.message, { variant: 'success' });
      });
  }
  async function addNewUser(event) {
    console.log("ADD NEW USER ", event, user.Privilege);
    event.preventDefault();
    setIsLoading(true);

    console.log("ADD NEW USER DATA ", user.UserName);

    var username = user.Email;
    var password = md5.MD5(user.PassWord).toString();
    var attrVal = {
      email: user.Email,
      name: user.FirstName + ' ' + user.LastName,
      "custom:firstname": user.FirstName,
      "custom:lastname": user.LastName,
      "custom:privilege": user.Privilege,
      "custom:domain": MapData.aws_app_domain_prefix
    };
    if (user.Unit && user.Unit !== "" && user.Privilege !== "BILLING" && user.Privilege !== "ADMIN") {
      attrVal["custom:unit"] = user.Unit;
    }
    trackPromise(
      Auth.signUp({
        username,
        password,
        attributes: attrVal
      })
        .then(response => {
          //setIsLoading(false);
          console.log("response" + JSON.stringify(response));
          if (response.UserSub !== "") {
            console.log("Successful userSub" + response.UserSub);
            console.log("Successful CodeDeliveryDetails" + response.CodeDeliveryDetails);
            updatepropertyDB(response);
          }
        })
        .catch(err => {
          console.log(err);
          // openSnackbar({ message: err.message, variant: "error" });
          enqueueSnackbar(err.message, { variant: 'error' });
          setIsLoading(false);
        }), areas.admin);
  }
  async function updateUser(event) {
    event.preventDefault();
    setIsLoading(true);
    if (user.hasOwnProperty("PassWord") && user.PassWord.length > 0) {
      user.PassWord = md5.MD5(user.PassWord).toString();
    }
    user.getMaster = props.userSession;
    console.log("ProfileImage", user.ProfileImage);
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: user,
      queryStringParameters: {
        module: "user",
        op: "updateUser"
      }
    };
    trackPromise(
      API.post(apiName, path, myInit).then(response => {
        if (response.success) {
          console.log("UPDATE USER", response);
          setIsLoading(false);
          // openSnackbar({
          //   message:
          //     response.message,
          //   variant: "success"
          // });  
          enqueueSnackbar(response.message, { variant: 'success' });
          handleClearForm();
          if (location.pathname !== '/profile')
            history.goBack();
          //history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant`);
          else
            history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}`);
        }
        else {
          setIsLoading(false);
          // openSnackbar({ message:response.message, variant: "error" });
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      }).catch(err => {
        console.log(err);
        setIsLoading(false);
        // openSnackbar({ message: "Network problem, Please try after refresh!", variant: "error" });
        enqueueSnackbar("Network problem, Please try after refresh!", { variant: 'error' });

      }), areas.admin);
  };
  async function disableUser() {
    console.log("DISABLE USER ");
    setIsLoading(true);
    user.userData = props.userSession;
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: user,
      queryStringParameters: {
        module: "user",
        op: "disableUser"
      }
    };
    console.log("PROPERTY OBJECT ", myInit);
    trackPromise(
      API.post(apiName, path, myInit).then(response => {

        console.log("UPDATE USER", response);
        setDeleteOpen(false);
        setIsLoading(false);
        enqueueSnackbar(response.message, { variant: 'success' });
        if (fromGrid && user.Email !== props.userSession.Email) {
          setIsLoading(false);
          history.goBack();
          //history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant`);
        }
        else {
          Auth.signOut();
          props.userHasAuthenticated(false);
          props.history.push("/");
        }
      }).catch(err => {
        console.log(err);
        setIsLoading(false);
        // openSnackbar({ message: err.message, variant: "error" });
        enqueueSnackbar(err.message, { variant: 'error' });
      }), areas.admin);
  }

  return (
    <div className="home-root">
      <DialogBox open={passOpen} onClose={e => { handleClose("password") }} fullWidth={true}
        maxWidth={"sm"} aria-labelledby="customized-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={e => { handleClose("password") }}>
          Change Your Password
        </DialogTitle>
        <DialogContent >
          <Grid container direction="column">
            <Grid item>
              <FormControl fullWidth required className="addnew-user-currentpasswordmargin" variant="outlined">
                <InputLabel htmlFor="currentPassword">Verify Your Old Password </InputLabel>
                <OutlinedInput
                  id="currentPassword"
                  name="currentPassword"
                  type={showCurrentPassword ? 'text' : 'password'}
                  value={currentPassword}
                  onChange={onChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowCurrentPassword}
                        onMouseDown={handleMouseDownCurrentPassword}
                        size="large">
                        {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={240}
                />
              </FormControl>
              <Grid item>
              </Grid>
              <FormControl fullWidth required className={clsx(classes.newpasswordmargin)} variant="outlined">
                <InputLabel htmlFor="newPassword">Enter Your New Password</InputLabel>
                <OutlinedInput
                  id="newPassword"
                  name="newPassword"
                  type={showNewPassword ? 'text' : 'password'}
                  value={newPassword}
                  onChange={onChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownNewPassword}
                        size="large">
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={240}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions >
          <Grid
            container
            direction='row'
            spacing={2}
            alignItems='flex-start'
          >
            <Grid item>
              <LoaderCLickButton
                onClick={e => changePassword(e)}
                isLoading={isLoading}
                disabled={!validateChangePassword()}
              >
                UPDATE
              </LoaderCLickButton>
            </Grid>
            <Grid item>
              <LoaderDeleteButton
                isLoading={isLoading}
                size='large'
                onClick={e => { handleClose("password") }}
              >
                CANCEL
              </LoaderDeleteButton>
            </Grid>
          </Grid>
        </DialogActions>
      </DialogBox>
      <DialogBox open={emailOpen} onClose={e => handleClose("email")} fullWidth={true}
        maxWidth={"sm"} aria-labelledby="form-dialog-title">
        <DialogTitle id="email-dialog-title" onClose={e => handleClose("email")}>
          Change Email
        </DialogTitle>
        <DialogContent>
          <ChangeEmail closeModel={e => handleClose("email")} {...props} />
        </DialogContent>
      </DialogBox>
      <DialogBox open={deleteOpen} onClose={e => handleClose("delete")} fullWidth={true}
        maxWidth={"sm"} aria-labelledby="form-dialog-title">
        <DialogTitle id="delete-dialog-title" onClose={e => handleClose("delete")}>
          Delete Confirmation
        </DialogTitle>
        <DialogContent>
          {fromGrid ? <DialogContentText>
            {`${user.Email} will no longer be able to access this account.`}
          </DialogContentText> :
            <DialogContentText>
              You are about to delete your account.<br />
              If you confirm the deletion request, your data will be removed from our system immediately!
            </DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction='row'
            spacing={2}
            alignItems='flex-start'
          >
            <Grid item>
              <LoaderCLickButton
                onClick={e => disableUser()}
                isLoading={isLoading}
              >
                YES
              </LoaderCLickButton>
            </Grid>
            <Grid item>
              <LoaderDeleteButton
                isLoading={isLoading}
                size='large'
                onClick={e => { handleClose("delete") }}
              >
                CANCEL
              </LoaderDeleteButton>
            </Grid>
          </Grid>
        </DialogActions>
      </DialogBox>
      <DialogBox open={userFormOpen} onClose={e => { handleClose("user") }} fullWidth={true}
        maxWidth={"sm"} aria-labelledby="customized-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={e => { handleClose("user") }}>
          {isEditing ? user.Name : "Add Tenant"}</DialogTitle>
        <DialogContent >
          <form
            id="user-form"
            noValidate
            autoComplete="off"
            onSubmit={isEditing == true ? updateUser : addNewUser}
          >
            <Grid sx={{ pt: 2 }} container direction="column" spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {!isLoading &&
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="EMAIL"
                    disabled={validatePasswordEmailForm()}
                    onChange={onChange}
                    name="email"
                    autoComplete="off"
                    value={user?.Email ? user.Email : ""}

                    InputProps={isEditing && !fromGrid ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => { handleClickOpen("email"); }} size="large">
                            <EditIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    } : null}
                  />
                }
                {isLoading && <Skeleton variant="rectangular" height={52} />}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {!isLoading &&
                  <TextField
                    autoComplete="fname"
                    name="firstname"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstname"
                    label="FIRST NAME"
                    onChange={onChange}

                    value={user?.FirstName ? user.FirstName : ""}
                  />
                }
                {isLoading && <Skeleton variant="rectangular" height={52} />}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {!isLoading &&
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="LAST NAME"
                    onChange={onChange}
                    name="lastName"
                    autoComplete="lname"
                    value={user?.LastName ? user.LastName : ""}
                  />
                }
                {isLoading && <Skeleton variant="rectangular" height={52} />}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {!isLoading &&
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="phonenumber"
                    label="PHONE NUMBER"
                    onChange={onChange}
                    name="phonenumber"
                    autoComplete="lname"
                    value={user?.PhoneNumber ? user.PhoneNumber : ""}
                  />
                }
                {isLoading && <Skeleton variant="rectangular" height={52} />}
              </Grid>
              {user.Privilege !== "BILLING" && user.Privilege !== "ADMIN" &&
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  {!isLoading &&
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="unit"
                      label="UNIT #"
                      onChange={onChange}
                      type="text"
                      id="unit"
                      autoComplete="current-unit"
                      value={user && user.Unit ? user.Unit : ""}
                    />
                  }
                  {isLoading && <Skeleton variant="rectangular" height={52} />}
                </Grid>}
              {user.ProfileImage && user.ProfileImage.hasOwnProperty("FileName") ?
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  {!isLoading &&
                    <ImageComponent Type={"ProfileImage"} FileKey={user.ProfileImage.FileKey} FileName={user.ProfileImage.FileName} />
                  }
                  {isLoading && <Skeleton variant="rectangular" />}

                </Grid>
                :
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Grid container direction="row" spacing={2} className={"skelton-flex-parent skelton-float-left"}>
                    <Grid item>
                      <input name='ProfileImage' onChange={(e) => onChange(e)} accept="image/*" style={{ display: "none" }} id="icon-button-file1" type="file" />
                      <label htmlFor="icon-button-file1">
                        {!isLoading &&
                          <Fab size="small" color="secondary" aria-label="upload picture" component="span">
                            <AddIcon />
                          </Fab>
                        }
                        {isLoading && <Skeleton variant="circular" width={40} height={40} />}
                      </label>
                    </Grid>
                    <Grid item>
                      {!isLoading && <div>
                        <Typography variant="h6"> Upload Profile Photo</Typography>
                        <Typography variant="caption">500x500 - png, jpeg, gif</Typography></div>
                      }
                      {isLoading && <Skeleton variant="rectangular" className={"skelton-min-width-190"} height={52} />}
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
            <Box sx={{ p: 0, pt: 2, pb: 0 }}>
              <Grid
                container
                direction="row"
                spacing={2}
                alignItems="flex-start"
              >
                <Grid item>
                  {!isLoading &&
                    <LoaderButton
                      type="submit"
                      size="large"

                      isLoading={isLoading}
                      disabled={!validateForm()}
                    >
                      {isEditing && fromGrid ? "UPDATE" : isEditing && !fromGrid ? "UPDATE PROFILE" : "+ ADD"}
                    </LoaderButton>
                  }
                  {isLoading && <Skeleton variant="rectangular" className={"skelton-min-width-200 skelton-max-width-200"} height={52} />}
                </Grid>

                {isEditing && !fromGrid && props.userSession.Email === user.Email && props.userSession.Privilege !== "ADMIN" &&
                  <Grid item>
                    <LoaderCLickButton
                      isLoading={isLoading}
                      size="large"
                      disabled={!validateForm()}
                      onClick={() => {
                        setNewPassword("");
                        setCurrentPassword("");
                        handleClickOpen("password");
                      }}
                    >
                      CHANGE PASSWORD
                    </LoaderCLickButton>
                  </Grid>
                }
                {isEditing /* && fromGrid && (props.userSession.Privilege == "ADMIN") && (user.Email != "ADMIN") */ &&
                  <Grid item>
                    {!isLoading && !fromGrid &&
                      <LoaderDeleteButton
                        block="true"
                        size="large"
                        isLoading={isLoading}
                        onClick={() => { handleClickOpen("delete") }}>
                        DELETE YOUR ACCOUNT
                      </LoaderDeleteButton>
                    }
                    {isLoading && <Skeleton variant="rectangular" className={"skelton-min-width-259 skelton-max-width-259"} height={52} />}
                  </Grid>}
              </Grid>
            </Box>
          </form>
        </DialogContent>
      </DialogBox>
    </div>
  );
}