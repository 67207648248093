import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/swipeableDrawer.css';
import * as Sentry from '@sentry/browser';
import App from './App';
/* import Maintenance from './components/Maintenance'; */
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

Sentry.init({dsn: "https://c0f94582154a4e9cb3f5318c189eaba9@sentry.io/1874024", ignoreErrors: ['Non-Error promise rejection captured']});   /* Sentry initialize - Used for UI error tracking */
ReactDOM.render(
    <Router>
     {/*  <Maintenance/>        *//* Use this for site Maintenance purpose and comment down App*/} 
     <App /> 
    </Router>,
    document.getElementById('root')
  ); 
serviceWorker.register();
