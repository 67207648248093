import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import InputLabel from "@mui/material/InputLabel";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Skeleton } from '@mui/material';
import VisaSvg from '../../static/images/visa-light-large.svg';
import MasterCardSvg from '../../static/images/mastercard-light-large.svg';
import AmericanExpressSvg from '../../static/images/americanexpress-light-large.svg';
import MaestroSvg from '../../static/images/maestro-light-large.svg';
import DiscoverSvg from '../../static/images/discover-light-large.svg';

const carIconStyle = {
  color: "rgb(0, 0, 0)",
  marginRight: "12px"
}
export default function AddCreditCard(props) { 
  const year = (new Date()).getFullYear(); 
  const [creditCardData, setCreditCardData] = useState(props.creditCardData ? props.creditCardData : {});
  const [expYear] = useState(Array.from(new Array(20), (val, index) => (index + year).toString()));
  const [expMonth] = useState(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]);
  const MapData = window['countryConfig'];

  function onChange(e, inputProps) {
    console.log(e);
    if (e.target.name === "Country") {
      var selected = inputProps.props.alldata;
      setCreditCardData(prvCard => { return { ...prvCard, "Country": e.target.value, "CountryCode": selected.iso2 } });
    }
    else {
      setCreditCardData({ ...creditCardData, [e.target.name]: e.target.value });
    }
    props.onChildClick(e.target.name, e.target.value/* , child && child.props && child.props.id ? child.props.id : null */);
  }

  return (
    <div>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item className={"addcreditcard-topicons"} xs={12}>
          {!props.isLoading && <div>
            <img width={"44px"} style={carIconStyle} src={VisaSvg} />
            <img width={"44px"} style={carIconStyle} src={MasterCardSvg} />
            <img width={"44px"} style={carIconStyle} src={AmericanExpressSvg} />
            <img width={"44px"} style={carIconStyle} src={MaestroSvg} />
            <img width={"44px"} style={carIconStyle} src={DiscoverSvg} />
          </div>}
          {props.isLoading && <Skeleton variant="rectangular" height={50} />}
        </Grid>

        <Grid item xs={12} className={"addcreditcard-width100p"}>
          {!props.isLoading && <TextField
            variant="outlined"
            className={"addcreditcard-nameOnCard"}
            placeholder="NAME ON CARD"
            fullWidth
            name="NameOnCard"
            value={creditCardData.NameOnCard}
            onChange={onChange}
            type="text"
            id="NameOnCard"

          />
          }

          {props.isLoading && <Skeleton variant="rectangular" height={55} />}

        </Grid>

        <Grid item xs={12} className={"addcreditcard-width100p"}>

          {!props.isLoading && <TextField
            variant="outlined"
            className={"addcreditcard-billingAddress"}
            placeholder="BILLING ADDRESS"
            fullWidth
            name="BillingAddress"
            value={creditCardData.BillingAddress}
            onChange={onChange}
            type="text"
            id="BillingAddress"

          />
          }
          {props.isLoading && <Skeleton variant="rectangular" height={55} />}

        </Grid>

        <Grid item xs={12} className={"addcreditcard-width100p"}>
          {!props.isLoading && <Select
            //labelid="select-Country-label"
            id="Country-select-outlined"
            fullWidth
            className={"addcreditcard-select-placeholder"}
            variant="outlined"
            name="Country"
            displayEmpty
            value={creditCardData.Country}
            onChange={(e, alldata) => { onChange(e, alldata); }}
          >

            <MenuItem value="" disabled >
              BILLING COUNTRY
            </MenuItem>
            {MapData.map((e, keyIndex) => {
              return (
                <MenuItem key={keyIndex} alldata={e} value={e.name}>
                  {e.name}
                </MenuItem>
              );
            })}
          </Select>
          }
          {props.isLoading && <Skeleton variant="rectangular" height={55} />}
        </Grid>


        <Grid item xs={12} className={"addcreditcard-width100p"}>

          {!props.isLoading && <TextField
            variant="outlined"
            className={"addcreditcard-billingPostalCode"}
            placeholder="BILLING POSTAL CODE"
            fullWidth
            name="PostalCode"
            value={creditCardData.PostalCode}
            onChange={onChange}
            type="text"
            id="PostalCode"

          />
          }
          {props.isLoading && <Skeleton variant="rectangular" height={55} />}

        </Grid>

        <Grid item xs={12} className={"addcreditcard-width100p"}>

          {!props.isLoading && <TextField
            variant="outlined"
            className={"addcreditcard-cardNumber"}
            placeholder="CARD NUMBER"
            fullWidth
            name="CardNumber"
            value={creditCardData.CardNumber}
            onChange={onChange}
            type="text"
            id="CardNumber"

          />
          }
        </Grid>

        <Grid item xs={12} className={"addcreditcard-width100p"}>

          {!props.isLoading && <TextField
            variant="outlined"
            className={"addcreditcard-cvvcid"}
            placeholder="CVV/CID"
            fullWidth
            name="CvvCid"
            value={creditCardData.CvvCid}
            onChange={onChange}
            type="text"
            id="CvvCid"

          />
          }

        </Grid>

        <Grid item xs={12} sm={4} className={"addcreditcard-width100p"}>

          {!props.isLoading && <InputLabel className={"addcreditcard-payment-labels"}>
            EXPIRATION
          </InputLabel>
          }

          {!props.isLoading && <Select
            id="ExpiryMonth-select-outlined"
            className={"addcreditcard-select-placeholder"}
            fullWidth
            variant="outlined"
            name="ExpiryMonth"
            displayEmpty
            value={creditCardData.ExpiryMonth}
            onChange={onChange}

          >
            <MenuItem value="" disabled >
              MM
            </MenuItem>
            {expMonth.map((e, keyIndex) => {
              return (
                <MenuItem
                  key={keyIndex}
                  value={e}
                >
                  {e}
                </MenuItem>
              );
            })}
          </Select>
          }
        </Grid>
        <Grid item xs={12} sm={4} className={"addcreditcard-width100p"}>
          <InputLabel className={"addcreditcard-payment-labels-disbl"}>
            EXPIRATION
          </InputLabel>
          {!props.isLoading && <Select
            id="ExpiryYear-select-outlined"
            fullWidth
            className={"addcreditcard-select-placeholder"}
            variant="outlined"
            name="ExpiryYear"
            displayEmpty
            value={creditCardData.ExpiryYear}
            onChange={onChange}
          >
            <MenuItem value="" disabled >
              YYYY
            </MenuItem>
            {expYear.map((e, keyIndex) => {
              return (
                <MenuItem
                  key={keyIndex}
                  value={e}
                >
                  {e}
                </MenuItem>
              );
            })}
          </Select>
          }
        </Grid>
      </Grid>
    </div>
  );
}