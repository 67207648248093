import React from 'react';
export default function usePersistedState(key, defaultValue='{}') { 
  const [state, setState] = React.useState(() => {
    const persistedState = localStorage.getItem(key);
    return persistedState && persistedState!=="" && persistedState!=="undefined" && isJsonString(persistedState) ? JSON.parse(persistedState) : defaultValue;
  });
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);
  return [state, setState];
}

function isJsonString(str) {
  try {
      JSON.parse(str);
    console.log(JSON.parse(str))
  } catch (e) {
    console.log(e)
      return false;
  }
  return true;
}