import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Add, Close } from '@mui/icons-material';
import moment from "moment";
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  Container, CssBaseline,
  Grid, Typography, TextField, Fab,
  Button, Dialog, DialogActions, DialogContent, DialogContentText,
  IconButton
} from "@mui/material";
import MuiDialogTitle from '@mui/material/DialogTitle';
import { areas } from '../../common/constants/areas';
import Moment from 'react-moment';
import { API } from "aws-amplify";
import { Spinner } from '../../common/components/spinner';
import usePersistedState from '../../usePersistedState';
import { useHistory, useLocation } from "react-router-dom";
import { Skeleton } from '@mui/material';
import { AddTenant } from "./AddTenant";
import { LoaderCLickButton, LoaderDeleteButton } from "../LoaderButton";
// import { openSnackbar } from '../Notifier';
import { useSnackbar } from 'notistack'; 
import EditUser from "./editUser";
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
//** Data Grid Import Part **/
import {
  LicenseInfo,
  DataGridPro,
  GridOverlay,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport
} from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LockIcon from '@mui/icons-material/Lock';
import SearchIcon from '@mui/icons-material/Search';

LicenseInfo.setLicenseKey(
  '79e390e23cc1709a04137710c7c5cdc1T1JERVI6Mjg0NDQsRVhQSVJZPTE2NjEwMDE3MTQwMDAsS0VZVkVSU0lPTj0x',
);

function CustomToolbar(props) {
  return (
    <GridToolbarContainer>
      <Grid container
        spacing={3}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item minWidth="320px" >
          {!props.loading && <TextField
            variant="outlined"
            fullWidth
            display="flex"
            label="Search by email or name.."
            onBlur={props.onBlur}
            onKeyDown={props.onKeyDown}
            onChange={props.onChange}
            value={props.value}
            InputProps={{ 
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: props.value ? 'visible' : 'hidden' }}
                  onClick={props.onBlur}
                >
                  <SearchIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />}
          {props.loading && <Skeleton variant="rectangular" height={52} />}
        </Grid>
        <Grid item >
          {props.loading && <div style={{ display: "flex" }}>
            <Skeleton variant="rectangular" width={30} height={32} className={"skelton-margin-right-5"} />
            <Skeleton variant="rectangular" width={100} height={32} />
          </div>}
          {!props.loading && <GridToolbarFilterButton />}
        </Grid>
        <Grid item>
          {props.loading && <div style={{ display: 'flex' }}>
            <Skeleton variant='rectangular' width={30} height={32} className={'skelton-margin-right-5'} />
            <Skeleton variant='rectangular' width={100} height={32} />
          </div>}
          {!props.loading && <GridToolbarExport
            csvOptions={{
              fileName: props.customFileName,
              delimiter: ',',
              utf8WithBom: true,
            }}
          />}
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

const MAX_ROW_LENGTH = 1500;
//** Data Grid Import Part **/
// const MapData= (process.env.REACT_APP_STAGE==="dev")? awsdev:( process.env.REACT_APP_STAGE==="dev2")? awsdev2 :window['runConfig'];

const PREFIX = 'user';

const classes = {
  root: `${PREFIX}-root`,
  textPrimary: `${PREFIX}-textPrimary`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.text.secondary,
  },

  [`& .${classes.textPrimary}`]: {
    color: theme.palette.text.primary,
  }
}));
const DialogBox = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));



const DialogTitle = (props => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography sx={{ m: 0, p: 2, pl: 3, pr: 3 }}  {...other}>
      <Typography variant="h3" component="h3" sx={{ lineHeight: 1 }}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: "8px",
            top: "8px",
            color: "text.secondary",
          }}
          onClick={onClose}
          size="large">
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const MapData = (process.env.REACT_APP_STAGE === "dev") ? awsdev : (process.env.REACT_APP_STAGE === "dev2") ? awsdev2 : window['runConfig']
//** Data Grid Action related Code */
function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <Root style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </Root>
    </GridOverlay>
  );
}
function RowMenuCell({ api, id, row, userSession, selectedProperty, gridRefresh, ...rest }) {
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [userFormOpen,setUserFormOpen]= useState(false);
  const handleClose = (e) => {
    if (e === "delete") {
      setDeleteOpen(false);
    }
    if (e === "resetPassword") {
      setResetPasswordOpen(false);
    }
  };
  async function disableUser() {
    console.log("DISABLE USER ");
    setIsLoading(true);
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: { ...row, userData: userSession },
      queryStringParameters: {
        module: "user",
        op: "disableUser"
      }
    };
    console.log("PROPERTY OBJECT ", myInit);
    API.post(apiName, path, myInit).then(response => {
      console.log("UPDATE USER", response);
      setDeleteOpen(false);
      setIsLoading(false);
      setTimeout(() => {
        gridRefresh();
      }, 2000);
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      enqueueSnackbar(err.message, { variant: 'error' });
    })
  }
  async function resetPassword() {
    setIsLoading(true);
    let apiName = 'rentapi';
    let path = '/modules';
    let myInit = {
      headers: { 'Content-Type': 'application/json' },
      body: { ...row, userData: userSession, DomainName: MapData.aws_app_domain_prefix, firstName: row.FirstName, lastName: row.LastName },
      queryStringParameters: {
        module: 'user',
        op: 'resetPassword'
      }
    };
    await API.post(apiName, path, myInit).then(response => {
      setResetPasswordOpen(false);
      enqueueSnackbar(response.message, { variant: 'success' });
      setIsLoading(false);
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
    });

  }

  const handleGridAction = (e, rowData) => {
    if (e === 'delete') {
      setDeleteOpen(true);
    }
    if (e === 'reset') {
      setResetPasswordOpen(true);
    }
    if (e === 'view') {
      history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant/view`, props: { rowData: { ...row } } });
    }
  };
  return (
    <div className={classes.root}>
      {userFormOpen && <EditUser type={"tenant"} userSession={userSession} isEditing={true} user= {row} fromGrid={true} userFormOpen={userFormOpen} setUserFormOpen={setUserFormOpen} />}
      <DialogBox open={deleteOpen} onClose={e => handleClose("delete")} fullWidth={true}
        maxWidth={"sm"} aria-labelledby="form-dialog-title">
        <DialogTitle id="delete-dialog-title" onClose={e => handleClose("delete")}>
          Remove User From Tenant
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`${row.Email} will no longer be able to access this account.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction='row'
            spacing={2}
            alignItems='flex-start'
          >
            <Grid item>
              <LoaderCLickButton
                onClick={e => disableUser()}
                isLoading={isLoading}
              >
                REMOVE
              </LoaderCLickButton>
            </Grid>
            <Grid item>
              <LoaderDeleteButton
                isLoading={isLoading}
                size='large'
                onClick={() => { handleClose('delete'); }}
              >
                CANCEL
              </LoaderDeleteButton>
            </Grid>
          </Grid>
          <div style={{ flex: '1 0 0' }} />
        </DialogActions>
      </DialogBox>
      <DialogBox open={resetPasswordOpen} onClose={e => handleClose('resetPassword')} fullWidth
        aria-labelledby='form-dialog-title'>
        <DialogTitle id='delete-dialog-title' onClose={e => handleClose('resetPassword')}>
          Reset password
        </DialogTitle>
        <DialogContent>
          <DialogContentText color='textPrimary'>
            {row.Email} will have to change their password the next time they sign in or link a new device.
            <br /><br />
            They will also receive email in their inbox requesting to change the login. To proceed, please hit reset below.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction='row'
            spacing={2}
            alignItems='flex-start'
          >
            <Grid item>
              <LoaderCLickButton
                onClick={e => resetPassword()}
                isLoading={isLoading}
              >
                RESET PASSWORD
              </LoaderCLickButton>
            </Grid>
            <Grid item>
              <LoaderDeleteButton
                isLoading={isLoading}
                size='large'
                onClick={() => { handleClose('resetPassword'); }}
              >
                CANCEL
              </LoaderDeleteButton>
            </Grid>
          </Grid>
          <div style={{ flex: '1 0 0' }} />
        </DialogActions>
      </DialogBox>
      <Tooltip title="Edit">
        <IconButton
          color="inherit"
          className={classes.textPrimary}
          size="small"
          disabled={row.UserStatus === 'Deleted'}
          aria-label="edit"
          onClick={(event) => {
            event.stopPropagation();
            setUserFormOpen(true);
            //history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant/edit`, props: { isEditing: true, rowEditData: row, fromGrid: true } });
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          color="inherit"
          className={classes.textPrimary}
          size="small"
          disabled={row.UserStatus === 'Deleted'}
          aria-label="delete"
          onClick={(event) => {
            event.stopPropagation();
            handleGridAction('delete');
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Reset">
        <IconButton
          color="inherit"
          className={classes.textPrimary}
          size="small"
          aria-label="lock"
          disabled={row.UserStatus === 'Deleted'}
          onClick={(event) => {
            event.stopPropagation();
            handleGridAction('reset');
          }}
        >
          <LockIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="View">
        <IconButton
          color="inherit"
          className={classes.textPrimary}
          size="small"
          aria-label="Show"
          disabled={row.UserStatus === 'Deleted'}
          onClick={(event) => {
            event.stopPropagation();
            handleGridAction('view');
          }}
        >
          <VisibilityIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
}

//** Data Grid Action related Code */
export function User(props) {
  let history = useHistory();
  const location = useLocation();
  const isRefresh = React.useRef(true);
  const [selectedProperty] = usePersistedState('selectedProperty', {});
  const [addUserOpen, setAddUserOpen] = useState(false);
  //** Data Grid Related Code */
  const apiRef = useGridApiRef();
  const [filterValue, setFilterValue] = React.useState({}); 
  const [searchText, setSearchText] = React.useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tabActive, setTabActive] = useState(0)
  var tabIndex = 0;
  const [queryOptions, setQueryOptions] = React.useState({});

  const loadDataGridValues = async () => {
    setIsLoading(true);
    updateData("loading", true);
    const newData = await GetInfinityTable(data.pageSize, 0);
    if (newData) updateData("rows", newData);
    updateData("loading", false);
    setIsLoading(false);
  };
  const handleChange = (event, newValue) => {
    setTabActive(newValue);
    tabIndex = newValue;
    //loadDataGridValues();
  };
  // const handleChangeIndex = (index) => {
    // setValue(index);
  // };

  const filterData = (data) => {
    const filteredData = [];
    console.log(tabActive);
    data.map(function (item) {
      if (tabActive === 0 && item.CognitoUserStatus ==="CONFIRMED") {
        filteredData.push(item);
      } else if (tabActive === 1 && (item.CognitoUserStatus === "UNCONFIRMED" || item.CognitoUserStatus === "FORCE_CHANGE_PASSWORD")) {
        filteredData.push(item);
      } else if (tabActive === 2 && item.CognitoUserStatus === "DISABLED") {
        filteredData.push(item);
      } 
    });
    console.log("Filtered Data");
    console.log(filteredData);
    return filteredData;
  }
  const [data, setData] = useState({
    loading: true,
    rows: [],
    pageSize: 25,
    columns: [
      { field: "MasterID", hide: true, filterable: false },
      { headerName: "TENANT", field: "Name", flex: 1, minWidth: 100,
      renderCell: (params) => {
        return (<Link style={{ "cursor": "pointer"}}>{params.value} </Link>)
      }
       },
      { headerName: "EMAIL", field: "Email", flex: 1, minWidth: 100,
      renderCell: (params) => {
        return (<Link style={{ "cursor": "pointer"}}>{params.value} </Link>)
      },
    },
      { headerName: "PROPERTY", field: "Property", flex: 1, hide: true, filterable: false },
      {
        headerName: "LAST ACTIVITY", field: "CreatedDate", flex: 1, minWidth: 100, filterable: false,
        renderCell: (params) => {
          const rowData = params.api.getRow(params.id);
          return (<Moment format="YYYY-MM-DD">{rowData.LastLogin ? rowData.LastLogin : rowData.CreatedDate}</Moment>)
        }
      },
      // {
        // headerName: "STATUS", field: "CognitoUserStatus", flex: 1, minWidth: 100,
        // type: 'singleSelect',
        // renderCell: (params) => {
          // return (            
          // RenderUserStatus(params.value) === 'Inactive' ? 
          // <TextField variant='outlined' inputProps={{ min: 0, style: { textAlign: 'center',WebkitTextFillColor: RenderUserStatus(params.value) == 'Inactive' ? '#cbcbcb' : '#6100ED' } }} disabled={true} className={'tenant-grid-tf-dsbl' } value={RenderUserStatus(params.value)} />:
          // <NoBorderTextField variant='outlined' inputProps={{ min: 0, style: { textAlign: 'center', WebkitTextFillColor: RenderUserStatus(params.value) == 'Inactive' ? '#cbcbcb' : '#6100ED' } }} disabled={true} className={'tenant-grid-tf'} value={RenderUserStatus(params.value)}/>           
          // )
        // },
        // valueOptions: ['All', 'Active', 'Invited', 'Password Set Required', 'Inactive']
      // },
      {
        headerName: 'Actions',
        field: 'actions',
        sortable: false,
        width: 150,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        renderCell: params => { return RowMenuCell({ ...params, ...props, selectedProperty: selectedProperty, gridRefresh: gridRefresh }) }
      }
    ]
  });
  const handleSortModelChange = React.useCallback((sortModel) => {
    console.log('sort model change');
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);
  var cusFileName = MapData.aws_app_domain_prefix + "-" + selectedProperty?.PropertyName?.replace(/ /g, "_") + "-" + moment().format('MMMM-DD-YYYY');
  const onFilterChanges = React.useCallback((filterModel) => {
    console.log('filterModel', filterModel)
    setFilterValue(filterModel);
    mounted.current = true;
  }, []);
  const requestSearch = async (searchValue) => {
    setSearchText(searchValue);
  };
  const handleKeyDown = async (event) => {
    console.log('event', event);
    const ENTER_KEY = 13;
    if (event.keyCode === ENTER_KEY) { 
      setIsLoading(true);
      updateData("loading", true);
      const newData = await GetInfinityTable(data.pageSize, 0);
      if (newData)
          updateData("rows", newData);
      updateData("loading", false);
      setIsLoading(false);
    }
  };
  const handleBlur = async (event) => {
    if (1==1) {
      setIsLoading(true);
      updateData("loading", true);
      const newData = await GetInfinityTable(data.pageSize, 0);
      if (newData)
        updateData("rows", newData);
      updateData("loading", false);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    console.log('queryOptions $$$$', queryOptions);
    let active = true;
    (async () => {
      updateData('loading', true);
      const newData = await GetInfinityTable(data.pageSize, 0);
      if (mounted.current) {
        if (newData)
          updateData('rows', newData);
      }
      if (!active) {
        return;
      }
      updateData('loading', false);
    })();
    return () => {
      active = false;
    };
  }, [queryOptions]);
  useEffect(() => {
    let active = true;
    console.log("filterVal and tabactive",filterValue,tabActive);
    (async () => {
      updateData("loading", true);
      const newData = await GetInfinityTable(data.pageSize, 0);
      if (mounted.current) {
        if (newData)
          updateData("rows", newData);
      }
      if (!active) {
        return;
      }
      updateData("loading", false);
    })();
    return () => {  
      active = false;
    };
  }, [filterValue, tabActive]); 
  const mounted = React.useRef(true);

  const updateData = (k, v) => setData((prev) => ({ ...prev, [k]: v }));
  const loadServerRows = async (newRowLength) => {
    updateData("loading", true);
    const newData = await GetInfinityTable(data.pageSize, newRowLength);
    if (mounted.current) {
      if (newData)
        updateData("rows", data.rows.concat(newData));
      updateData("loading", false);
    }
  };
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  const handleOnRowsScrollEnd = (params) => {
    if (data.rows.length <= MAX_ROW_LENGTH && data.rows.length !== 0) {
      loadServerRows(data.rows.length);
    }
  };
  async function GetInfinityTable(pageSize, fromNo) {
    updateData("loading", true);
    updateData("pageSize", pageSize);
    var body = {
      Privilege: "RENTERS",
      masterType: "User",
      PropertyID: selectedProperty.PropertyID,
      UserID: props.userSession.UserName,
      pageSize: pageSize,
      fromNo: fromNo,
      userData: props.userSession,
      tabName: tabActive===2?"Inactive":tabActive===1?"Invited":"Active",
      filterValue: filterValue,
      searchValue: searchText,
      sortModel: queryOptions.sortModel,
      tabActive: tabActive
    };
    body.userData = props.userSession;
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: 'user',
          op: 'loadUserGrid'
        }
      };
      API.post(apiName, path, myInit)
        .then(async response => {
          console.log("---- Res Data ----"); 
          console.log(response.data);
          var data =response.data; //filterData(response.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject([]);
        });
    });
  }
  const gridRefresh = async () => {
    mounted.current = true;
    updateData("loading", true);
    const newData = await GetInfinityTable(data.pageSize, 0); 
    if (newData && newData.data)
      updateData("rows", newData.data);
    updateData("loading", false);
  }
  //** Data Grid Related Code END */

  useEffect(() => {
    let isSubscribed = true;
    const userFetch = async () => {
      if (!location.props && isRefresh.current && selectedProperty.hasOwnProperty("PropertyName")) {
        isRefresh.current = false;
      }
      return () => isSubscribed = false
    }
    userFetch();
  }, [location.props, selectedProperty]);
 
  const onChildClick = async (e) => {
    if (e === 'closeUser') {
      setAddUserOpen(false);
      setTimeout(async () => {
        await gridRefresh();
      }, 2000);
    }
  }
  return (
    <div className="home-root">
      <AddTenant
        fromLease={false}
        userOpen={addUserOpen}
        userSession={props.userSession}
        onChildClick={onChildClick}
      />
      <Grid container spacing={0} direction="column">
        <Grid item>
          <Container component="main" className="home-main">
            <CssBaseline />
            <Spinner className="common-spinner" area={areas.admin} />
            <div className="home-paper">
              <div style={{ padding: "20px 0px 40px 0px" }}>
                <Grid container spacing={1} className={"add-padding-p8p"}>
                  <Grid item>
                    <Button
                      onClick={(e) => {
                        history.push(
                          `/${selectedProperty?.PropertyName.replace(
                            /\s+/g,
                            "-"
                          )}`
                        );
                      }}
                    >
                      <Typography
                        component="h6"
                        sx={{ fontWeight: 600 }}
                        color="text.primary"
                        variant="h6"
                      >
                        &#8249; {" RETURN"}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item xs={12} sm={10} md={10} lg={10}>
                    {!data.loading && (
                      <Typography
                        component="h1"
                        className="home-headerOne"
                        variant="h1"
                      >
                        Tenant Management
                      </Typography>
                    )}
                    {data.loading && (
                      <Skeleton variant="rectangular" width={545} height={69} />
                    )}
                  </Grid>
                  {/* <Grid item xs={12} sm={2} md={2} lg={2}>
                    {data.loading && <div className={"skelton-user-page-flex-compo-grid"}>
                      <Skeleton variant="rectangular" width={30} height={32} className={"skelton-margin-right-5"} />
                      <Skeleton variant="rectangular" width={120} height={32} />
                    </div>}
                    {!data.loading && <CsvExport  {...props} module="user" masterType="User"></CsvExport>}
                  </Grid> */}
                </Grid>
              </div>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      {!data.loading && (
                        <Fab
                          size="small"
                          onClick={() => {
                            /*   history.push({pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant/add`, props:{ isEditing: false,rowEditData:{ "PropertyID": [], "PassWord": "" },fromGrid:false }});    */
                            setAddUserOpen(true);
                          }}
                          color="secondary"
                          aria-label="add"
                          sx={{
                            "&.MuiFab-root": {
                              backgroundColor: "secondary.main",
                              borderRadius: "50%",
                            },
                          }}
                        >
                          <Add />
                        </Fab>
                      )}
                      {data.loading && (
                        <Skeleton variant="circular" width={40} height={40} />
                      )}
                    </Grid>
                    <Grid item>
                      {!data.loading && (
                        <Typography
                          component="h5"
                          variant="h5"
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setAddUserOpen(true);
                          }}
                        >
                          ADD TENANT
                        </Typography>
                      )}
                      {data.loading && (
                        <Skeleton
                          variant="rectangular"
                          width={218}
                          height={32}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {!data.loading && (
                    <Typography className="home-headerfour" variant="subtitle1">
                      Manage all your tenant details here. You can add new
                      tenants, edit the personal information, reset the login
                      and review the transaction history.
                    </Typography>
                  )}
                  {data.loading && (
                    <Skeleton variant="rectangular" width={364} height={32} />
                  )}
                </Grid>
              </Grid>
              <Tabs
                value={tabActive}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
                style={{ padding: "20px 0px 00px 0px" }}>
                <Tab label="Active"/>
                <Tab label="Invited"  />
                <Tab label="Inactive" />
              </Tabs>
              <div style={{ height: 1024, width: "100%" }}>
                <div style={{ display: "flex", height: "100%" }}>
                  <div style={{ flexGrow: 1 }}>
                    <DataGridPro
                      apiRef={apiRef}
                      density="compact"
                      rowHeight={81}
                      hideFooter
                      hideFooterPagination
                      loading={data.loading}
                      onRowsScrollEnd={handleOnRowsScrollEnd}
                      rows={data.rows}
                      getRowId={(r) => r.MasterID}
                      columns={data.columns}
                      filterMode="server"
                      onFilterModelChange={onFilterChanges}
                      onSortModelChange={handleSortModelChange}
                      onRowClick={(row) => {
                        history.push({
                          pathname: `/${selectedProperty?.PropertyName.replace(
                            /\s+/g,
                            "-"
                          )}/tenant/view`,
                          props: { rowData: { ...row.row } },
                        });
                      }}
                      componentsProps={{
                        toolbar: {
                          value: searchText,
                          onChange: (event) =>
                            requestSearch(event.target.value),
                          onBlur: (event) => handleBlur(event),
                          onKeyDown: (event) => handleKeyDown(event),
                          loading: data.loading,
                          customFileName: cusFileName,
                        },
                      }}
                      components={{
                        LoadingOverlay: CustomLoadingOverlay,
                        Toolbar: CustomToolbar,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}