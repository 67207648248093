import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import Container from "@mui/material/Container";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import LoaderButton from "../LoaderButton";
import DataFetcher from '../../lib/Fetcher';

import Policy from "../../lib/Policy";
import PoweredBy from '../../lib/PoweredBy';
import { AmplifyS3Image  } from '@aws-amplify/ui-react';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
import { Skeleton } from '@mui/material';
const PREFIX = 'DeclineInvitation';

const classes = {
  "@global": `${PREFIX}-undefined`,
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  header: `${PREFIX}-header`,
  myImage: `${PREFIX}-myImage`,
  margin: `${PREFIX}-margin`,
  withoutLabel: `${PREFIX}-withoutLabel`,
  textField: `${PREFIX}-textField`,
  welcomeText: `${PREFIX}-welcomeText`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.undefined}`]: {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },

  [`& .${classes.root}`]: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  },

  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(8),
    display: "flex",
    justify: "center",
    flexDirection: "column",
    alignItems: "flex-start"
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },

  [`& .${classes.form}`]: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2)
  },

  [`& .${classes.header}`]: {
    fontSize: 20,
    fontStyle: "bold",
    fontFamily: "Arial-BoldMT, Arial"
  },

  [`& .${classes.myImage}`]: {
    width: "100%"
  },

  [`& .${classes.margin}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.withoutLabel}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.textField}`]: {
    width: 200,
  },

  [`& .${classes.welcomeText}`]: {
    fontSize: "20px",
  }
}));

const MapData= (process.env.REACT_APP_STAGE==="dev")? awsdev:( process.env.REACT_APP_STAGE==="dev2")? awsdev2 :window['runConfig'];
export function Decline(props) {    
  const queryParams = new URLSearchParams(props.location.search);
    var vrData = {}
    queryParams.forEach(function (value, key) { 
      vrData[key] = value;
    });
  const [verifyData,setVerifyData] = useState(vrData);

  
 function SkeltonLoader() {
   return (
     <Root width="100%">
       <Grid
         container
         spacing={0}
         direction="column"
         alignItems="center"
         justifyContent="center"
         style={{ minHeight: '85vh' }}
       >
         <Grid item xs={12} sm={12} md={5} lg={5}>
           <Container component="main" >
             <CssBaseline />
             <div className={classes.paper}>
               <Grid container justifyContent="flex-start" direction="column" alignItems="flex-start" spacing={2}>
               <Grid item>
                          {MapData.management_logo && MapData.management_logo != '' ? <AmplifyS3Image  level="public" imgKey={MapData.management_logo} /> :
                            <Typography className='managment-header' component="h2" variant="h2">
                              {MapData.management_name}
                            </Typography>}
                        </Grid> 
                 <Grid item xs={12} sm={12} md={12} lg={12}>
                   <Typography className="verify-user-header" gutterBottom={true}>
                     <Skeleton variant="rectangular" width={385} height={30} />
                   </Typography>
                 </Grid>
                 <Grid item xs={12} sm={12} md={12} lg={12}>
                   <Typography component="h1" variant="h5">
                     <Skeleton variant="rectangular" width={385} height={30} />
                     <Skeleton variant="rectangular" width={385} height={30} />
                     <Skeleton variant="rectangular" width={385} height={30} />
                   </Typography>
                 </Grid>
                 <Grid item xs={12} sm={12} md={12} lg={12}>
                   <Typography component="h1" variant="h5">
                     <Skeleton variant="rectangular" width={385} height={30} />
                   </Typography>
                 </Grid>
                 <Grid item xs={12} sm={12} md={12} lg={12}>
                   <Skeleton variant="rectangular" width={112} height={45} />
                 </Grid>
               </Grid>

             </div>
           </Container>
         </Grid>
       </Grid>
       <PoweredBy />
       <Policy class={"policy-cls"} />
     </Root>
   ); 
 }
  return (
    <div>
      <DataFetcher type="decline" userData={verifyData}>
        {({ data, IsLoading, error }) => { 
          if (!data) {
            return ( 
              <SkeltonLoader/>
            );
          }
          if (error) {
            console.log("Verify error", error);
            return <p>error.message</p>;
          }
          if (IsLoading) {
            return ( 
              <SkeltonLoader/>
            );
          }
          return data &&
            <div width="100%">
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '85vh' }}
              >
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <Container component="main" >
                    <CssBaseline />
                    <div className={classes.paper}>
                    <Grid container justifyContent="flex-start" direction="column" alignItems="flex-start" spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {MapData.management_logo && MapData.management_logo != '' ? <AmplifyS3Image  level="public" imgKey={MapData.management_logo} /> :
                          <Typography className='managment-header' component="h2" variant="h2">
                            {MapData.management_name}
                          </Typography>}
                      </Grid>                      
                      <Grid style={{paddingTop:'64px'}} item xs={12} sm={12} md={12} lg={12}>
                        <Typography style={{marginLeft:'64px'}}component="h5" variant="h5">
                          {data.message}
                        </Typography>
                      </Grid>                       
                    </Grid>
                    </div>
                  </Container>
                </Grid>
              </Grid>
              <PoweredBy />
              <Policy class={"policy-cls"} />
            </div>;
        }}
      </DataFetcher>
    </div>
  );

}