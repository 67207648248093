import React, { useState, useEffect, useRef } from 'react'; 
import { styled, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, } from '@mui/material';
import { API } from 'aws-amplify';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MuiDialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { openSnackbar } from '../Notifier';
import { useSnackbar } from 'notistack';
import FormControl from '@mui/material/FormControl';
import LoaderButton from '../../components/LoaderButton';

const DialogBox = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));

const DialogTitle = (props => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography sx={{ m: 0, p: 2, pl: 3, pr: 3 }}  {...other}>
      <Typography variant="h3" component="h3" sx={{ lineHeight: 1 }}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: "8px",
            top: "8px",
            color: "text.secondary",
          }}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const MapData = window['countryConfig'];

export default function AddNewProperty(props) {
  const [isStateSelect, setIsStateSelect] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [comboDisable, setComboDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [addNewProperty, setAddNewProperty] = useState(false);
  const [addNewPropertyOne, setAddNewPropertyOne] = useState(false);
  const [property, setProperty] = useState({
    PropertyName: ''
    , BusinessAddress: ''
    , City: ''
    , State: ''
    , Country: ''
    , PostalCode: ''
    , CurrencyName: ''
    , CurrencyCode: ''
    , Email: ''
    , Phone: ''
    , Website: ''
  });
  useEffect(() => {
    setAddNewProperty(props.addNewProperty);
  }, [props])
  useEffect(function effectFunction() {
    if (!isStateSelect && property.Country !== '') {
      const result = MapData.filter((country) => country.name === property.Country);
      if (result.length > 0) {
        setStateData(result[0].states);
        setComboDisable(false);
        setIsStateSelect(true);
      }
    }
  }, []);
  
  function onChange(e, inputProps) {
    if (e.target.name === 'Country') {
      var selected = inputProps.props.alldata;
      setComboDisable(false);
      setStateData(selected.states)
      setIsStateSelect(true);
      setProperty(prvProperty => { return { ...prvProperty, 'Country': e.target.value, 'CurrencyName': selected.currency.name + ' (' + selected.currency.code + ')', 'CurrencyCode': selected.currency.code } });
    }
    else {
      setProperty({ ...property, [e.target.name]: e.target.value });
    }
  }
  function handleClearForm() {
    if (document.getElementById('proeprty-form'))
      document.getElementById('proeprty-form').reset();
    if (document.getElementById('proeprty-formOne'))
      document.getElementById('proeprty-formOne').reset();
  }
  function NextFormClick(e) {
    console.log('NextFormClick')
    e.preventDefault();
    setAddNewProperty(false);
    setAddNewPropertyOne(true);
  }
  async function saveProperty(e) {
    e.preventDefault();
    setIsLoading(true);
    let apiName = 'rentapi';
    let path = '/modules';
    let myInit = {
      headers: { 'Content-Type': 'application/json' },
      body: { ...property, type: 'Create', userData: props.userSession, MasterType: 'Property', UserName: props.userSession.UserName },
      queryStringParameters: {
        module: 'property',
        op: 'saveGeneral'
      }
    };
    API.post(apiName, path, myInit)
      .then(async response => {
        if (response && response.success) {
          openSnackbar({
            message:
              response.message,
            variant: 'success'
          });
          setTimeout(async () => {
            setIsLoading(false);
            props.onChildClick('success');
            setAddNewPropertyOne(false);
            await handleClearForm();
          }, 500);
        }
        else {
          openSnackbar({ message: response.message, variant: 'error' });
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        openSnackbar({ message: err.message, variant: 'error' });
        setIsLoading(false);
      });
  }
  function validateForm() {
    return (
      property.PropertyName && property.PropertyName.length > 0 &&
      property.BusinessAddress && property.BusinessAddress.length > 0
    );
  }
  function validateFormOne(params) {
    return (property.Phone && property.Phone.length > 0 &&
      property.Email && property.Email.length > 0 &&
      property.WebSite && property.WebSite.length > 0 &&
      property.PropertyName && property.PropertyName.length > 0 &&
      property.BusinessAddress && property.BusinessAddress.length > 0)
  }
  return (
    <>
      <DialogBox
        maxWidth={'md'}
        open={addNewPropertyOne}
        onClose={e => {
          props.onChildClick('close')
          setAddNewPropertyOne(false);
        }} aria-labelledby='addnewOne-user-dialog-title'>
        <DialogTitle id='addnewOne-user-dialog-title' onClose={e => { props.onChildClick('close', {}); setAddNewPropertyOne(false) }}>
          <div className={'skelton-flex-parent fontweight-500'}>
            <div>Add A New Property</div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'span'}>
            <div className={'skelton-flex-parent'}>
              <div>You can also edit this in your Property Settings later.</div>
            </div>
          </DialogContentText>
          <Grid container
            className='mainpropertylist-Modal-grid'
            spacing={3}
            direction='row'
            justify='flex-start'
            alignItems='flex-start'>
            <Grid item xs={12}>
              <Grid container direction='column' spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    autoComplete="p-Phone"
                    name="Phone"
                    variant="outlined"
                    required
                    fullWidth
                    id="Phone"
                    value={property.Phone}
                    label="PHONE #"
                    onChange={onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="Email"
                    value={property.Email}
                    label="EMAIL"
                    onChange={onChange}
                    name="Email"
                    autoComplete="property-Email"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    id="WebSite"
                    value={property.WebSite}
                    label="WEBSITE"
                    onChange={onChange}
                    name="WebSite"
                    autoComplete="Website"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <form
            style={{ width: "100%"}} 
            id='proeprty-form' 
            noValidate
            onSubmit={saveProperty}
          >
            <Grid container
              spacing={2}
              direction='row'
              justify='flex-start'
              alignItems='flex-start'>
              <Grid item xs={12}>              
                  <LoaderButton
                    block='true'
                    type='submit'
                    size='large'
                    isLoading={isLoading}
                    disabled={!validateFormOne()}
                  >
                    + ADD
                  </LoaderButton> 
              </Grid>
            </Grid>
          </form>
        </DialogActions>
      </DialogBox>
      <DialogBox
        maxWidth={'md'}
        open={addNewProperty}
        onClose={e => {
          props.onChildClick('close')
          setAddNewProperty(false);
        }} aria-labelledby='addnew-user-dialog-title'>
        <DialogTitle id='addnew-user-dialog-title' onClose={e => { props.onChildClick('close', {}); setAddNewProperty(false) }}>
          <div className={'skelton-flex-parent fontweight-500'}>
            <div>Add A New Property</div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'span'}>
            <div className={'skelton-flex-parent'}>
              <div>You can also edit this in your Property Settings later.</div>
            </div>
          </DialogContentText>
          <Grid container
            className='mainpropertylist-Modal-grid'
            spacing={3}
            direction='row'
            justify='flex-start'
            alignItems='flex-start'>
            <Grid item xs={12}>
              <Grid container direction='column' spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    variant='outlined'
                    required
                    fullWidth
                    id='PropertyName'
                    onChange={onChange}
                    value={property.PropertyName}
                    name='PropertyName'
                    autoComplete='name'
                    autoFocus
                    placeholder='Property Name'
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel  id='select-state-label'>
                      COUNTRY/REGION
                    </InputLabel>
                    <Select
                      labelid='select-Country-label'
                      id='Country-select-outlined'
                      fullWidth 
                      label={"COUNTRY/REGION"}
                      variant='outlined'
                      name='Country'
                      value={property.Country}
                      onChange={(e, alldata) => { onChange(e, alldata); }} 
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      {MapData.map((e, keyIndex) => {
                        return (
                          <MenuItem key={keyIndex} alldata={e} value={e.name}>
                            {e.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    variant='outlined'
                    required
                    fullWidth
                    name='BusinessAddress'
                    value={property.BusinessAddress}
                    placeholder='Property Address'
                    onChange={onChange}
                    id='BusinessAddress'
                    autoComplete='current-address'
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    variant='outlined'
                    required
                    fullWidth
                    name='City'
                    value={property.City}
                    onChange={onChange}
                    id='City'
                    autoComplete='current-City'
                    placeholder='City'
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel id='select-state-label'>
                      STATE/PROVINCE
                    </InputLabel>
                    <Select
                      labelid='select-state-label'
                      id='state-select-outlined'
                      fullWidth
                      variant='outlined'
                      name='State'
                      label="STATE/PROVINCE"
                      value={property.State}
                      disabled={comboDisable}
                      onChange={onChange}>
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      {stateData.map((e, keyIndex) => {
                        return (
                          <MenuItem key={keyIndex} value={e}>
                            {e}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    variant='outlined'
                    required
                    fullWidth
                    name='PostalCode'
                    value={property.PostalCode}
                    onChange={onChange}
                    id='PostalCode'
                    autoComplete='current-PostalCode'
                    placeholder='Zip/Postal Code'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <form
            id='proeprty-formOne' 
            style={{ width: "100%"}}
            noValidate
            onSubmit={NextFormClick}
          >
            <Grid container
              spacing={2}
              direction='row'
              justify='flex-start'
              alignItems='flex-start'>
              <Grid item xs={12}> 
                  <LoaderButton
                    block='true'
                    type='submit'
                    size='large'
                    isLoading={isLoading}
                    disabled={!validateForm()}
                  >
                    Next
                  </LoaderButton> 
              </Grid>
            </Grid>
          </form>
        </DialogActions>
      </DialogBox>
    </>
  );

}