import React from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { amber, green } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import WarningIcon from "@mui/icons-material/Warning";
const PREFIX = 'Notifier';

const classes = {
  margin: `${PREFIX}-margin`,
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
  info: `${PREFIX}-info`,
  warning: `${PREFIX}-warning`,
  icon: `${PREFIX}-icon`,
  iconVariant: `${PREFIX}-iconVariant`,
  message: `${PREFIX}-message`
};

const StyledSnackbar = styled(Snackbar)((
  {
    theme
  }
) => ({
  [`& .${classes.success}`]: {
    backgroundColor: green[600]
  },

  [`& .${classes.error}`]: {
    backgroundColor: theme.palette.error.dark
  },

  [`& .${classes.info}`]: {
    backgroundColor: theme.palette.primary.main
  },

  [`& .${classes.warning}`]: {
    backgroundColor: amber[700]
  },

  [`& .${classes.icon}`]: {
    fontSize: 20
  },

  [`& .${classes.iconVariant}`]: {
    opacity: 0.9,
    marginRight: 1
  },

  [`& .${classes.message}`]: {
    display: "flex",
    alignItems: "center"
  }
}));

let openSnackbarFn;
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

 
function MySnackbarContentWrapper(props) {

  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
          size="large">
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["error", "info", "success", "warning"]).isRequired
};

class Notifier extends React.Component {
  state = {
    open: false,
    message: "",
    variant:""
  };
  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }
  openSnackbar = ({ message,variant }) => {
    this.setState({
      open: true,
      message,
      variant
    });
  };
  handleSnackbarClose = () => {
    this.setState({
      open: false,
      message: "",
      variant:""
    });
  };

  render() {
    const { } = this.props;
    const message = (
      <span
        id="snackbar-message-id"
        dangerouslySetInnerHTML={{ __html: this.state.message }}
      />
    );
    console.log("this.state",this.state);
    return (
      <StyledSnackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={3000}
        message={message}
        onClose={this.handleSnackbarClose}
        open={this.state.open}
        snackbarcontentprops={{
          "aria-describedby": "snackbar-message-id"
        }}
      >
        {this.state.open?  <MySnackbarContentWrapper
          variant={this.state.variant}
          message={this.state.message }
          className={classes.margin}       
        />:null}
      
      </StyledSnackbar>
    );
  }
}

export function openSnackbar({ message,variant }) {
  openSnackbarFn({ message,variant });
}
 

export default (Notifier);
