import React, {  useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AmplifyS3Image  } from '@aws-amplify/ui-react';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2'; 
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import usePersistedState from '../../usePersistedState';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import CarRectImg from "../../static/images/Image_A11_Rectangle_9_pattern.png";
import MLink from "@mui/material/Link";
import { API } from "aws-amplify";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import Fab from "@mui/material/Fab"; 
import AddNewProperty from './addnewpropertydialog';
const MapData = (process.env.REACT_APP_STAGE === "dev") ? awsdev : (process.env.REACT_APP_STAGE === "dev2") ? awsdev2 : window['runConfig'];

export function ListMyProperty(props) { 
    let history = useHistory();
    const location = useLocation();  
    const [isLoading, setIsLoading] = useState(false);    
    const loggedInUserName = props.userSession && props.userSession.FirstName ? props.userSession.FirstName.toUpperCase() : "";
    const [selectedProperty, setSelectedProperty] = usePersistedState('selectedProperty', {});
    const [addNewProperty, setAddNewProperty] = React.useState(false); 
    const [userProperties,setUserProperties] = usePersistedState('userProperties', []);
    useEffect(()=>{
      (async () => { 
          if (props.userSession && props.userSession.UserName) { 
              let myProperties=await loadMyProperties(); 
              setUserProperties(myProperties?myProperties:[]); //myProperties && myProperties.length>0? myProperties[0]:
              setSelectedProperty({}); 
              setIsLoading(false); 
          }
      })();
    },[props.userSession]);
    /* useEffect(()=>{
        (async () => { 
            if (props.userSession && props.userSession.UserName) { 
                let myProperties=await loadMyProperties(); 
                setUserProperties(myProperties?myProperties:[]);
                setSelectedProperty(myProperties && myProperties.length>0? myProperties[0]:{}); 
                setIsLoading(false); 
            }
        })();
      },[props.userSession]);

      useEffect(() => {
        let isSubscribed = true; 
        const propertyFetch = async () => {
          if (location.props && isSubscribed) { 
            if(location.props.currentProperty)
             {
               await setSelectedProperty(location.props.currentProperty);  
             } 
           }
          return () => isSubscribed = false
        }
        propertyFetch();
      }, [location]); */

      
    /**
     * this function will load properties for the user
     */
    function loadMyProperties () { 
        setIsLoading(true);
        var masterType = "User";
        var apiModule = "property";
        var op = "listMyProperty";
        let body = { userData: props.userSession, masterType: masterType, MasterID: props.userSession.UserName, for: "CardDisplay" };
        
        return new Promise((resolve, reject) => {
            let apiName = "rentapi";
            let path = "/modules";
            let myInit = {
              headers: { "Content-Type": "application/json" },
              body: body,
              queryStringParameters: {
                module: apiModule,
                op: op
              }
            };
            API.post(apiName, path, myInit)
              .then(response => {
                if (response && response.length > 0) { 
                  resolve(response);
                }
                else {
                  resolve([]);
                } 
              })
              .catch(err => {
                console.log(err);
                reject(err);
              });
          });
    }

    /**
     *this function will add new property
     */
/*     function addNewProperty () {

    } */

    /**
     * This will select property as default
     * @param {*} property 
     */
    function selectThisProperty(property) { 
        localStorage.selectedProperty = JSON.stringify(property);
        /* setSelectedProperty(prv=> {return { ...property}; });                    */
        history.push({ pathname: `/${property.PropertyName.replace(/\s+/g, '-')}`,props:{ currentProperty:property, userProperties:userProperties}});    
      /*   updateMenuAppBar(userProperties, property);   */      
    } 
      async function handleChildClick(type,value) {   
        if(type==='close'){ 
          setAddNewProperty(false);
        }
        if(type==='success')
        {
          setAddNewProperty(false);
          let myProperties = await loadMyProperties(props.userSession.UserName);
          if (myProperties && myProperties.length == 1) {
           setSelectedProperty(myProperties[0]);       
          }
          else
          {
            setUserProperties(myProperties && myProperties.length > 1?myProperties:[]);
            setSelectedProperty({});

          }
          setIsLoading(false);
        } 
      } 


    return (
        <div width="100%">
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '94vh' }}
            >

                {props.userSession.Privilege === "ADMIN" && 
                    <AddNewProperty {...props} addNewProperty={addNewProperty} onChildClick={handleChildClick} />
                }
                <Grid item xs={12} sm={12} md={12} lg={12} className="mainpropertylist-width100p mainpropertylist-paddingtop7p">
                    <Container component="main" className="mainpropertylist-width100p mainpropertylist-maxwidth100p">
                        <div className="mainpropertylist-paper">
                                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                <Grid item  >
                                    {MapData.management_logo && MapData.management_logo != '' ? <AmplifyS3Image  level="public" imgKey={MapData.management_logo} /> :
                                        <Typography className='mainpropertylist-managment-header'  component="h2" variant="h2" >
                                            {MapData.management_name}
                                        </Typography>}
                                        
                                </Grid>
                                <Grid item xs={12} container justifyContent="center">
                                <Divider variant="middle" className="mainpropertylist-short-divider mainpropertylist-paddingbottom1p" />
                                </Grid>
                                
                                <Grid item xs={12} >
                                    <Typography className='mainpropertylist-header'  component="h5" variant="h5" >
                                        WELCOME {loggedInUserName}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </Grid>


                <Grid item xs={12} container spacing={2}>
                    {!isLoading && userProperties.length>0 && userProperties.map((e, keyIndex) => {
                        return (
                            <Grid key={keyIndex} item xs={12} sm={12} md={6} lg={6}>
                              
                                <Card  className={"mainpropertylist-card"}>
                                    <CardActionArea style={{ display: "flex", padding: "16px" }} onClick={() => {
                                        selectThisProperty(e);
                                    }} >
                                        {e.PropertyLogo && e.PropertyLogo.hasOwnProperty("FileKey") ?
                                            <CardMedia
                                            className={"home-cover"}
                                            >
                                            <AmplifyS3Image  style={{"--height": "100%","--width": "100%"}} imgProps={{display: "block",  width:100, height:100 }} level="public" imgKey={e.PropertyLogo.FileKey} />
                                            </CardMedia>
                                            :
                                            <CardMedia 
                                            title="Make A Payment" > <img className={"home-cover"} src={CarRectImg} /> </CardMedia>
                                        }
                                        
                                        <div className={"mainpropertylist-details"}>

                                            <CardContent className={"mainpropertylist-content"}>
                                            <Typography component="h5" variant="h5">
                                                {e.PropertyName}
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                color="text.secondary"
                                            >
                                                {e.BusinessAddress}
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                color="text.secondary"
                                            >
                                                Phone # {' '} {e.Phone}
                                            </Typography>
                                            </CardContent>

                                            <div className={"home-controls"}>
                                            <MLink variant="body2" underline="hover">
                                                VIEW
                                            </MLink>
                                            </div>

                                        </div>
                                        
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        );
                    })}

                    
                </Grid>



                {isLoading ?  <Grid item xs={12}  container justifyContent="center" className="mainpropertylist-width100p">
                <CircularProgress size={50} />
                </Grid> : null}
                {isLoading ?  <Grid item xs={12}  container justifyContent="center" className="mainpropertylist-width100p mainpropertylist-paddingtop3p">
                <div>Loading {selectedProperty && selectedProperty.PropertyName ? selectedProperty.PropertyName : "Property"}</div>
                </Grid> : null}

                {!isLoading && props.userSession.Privilege === "ADMIN" ?  <Grid item xs={12}  container justifyContent="center" className="mainpropertylist-width100p mainpropertylist-paddingtop3d5p">
                    <Grid item xs={12}>
                    <Grid item container justifyContent="center">
                      <Fab size="small" color="secondary" aria-label="edit" className="mainpropertylist-add-fab" onClick={() => {  
                        setAddNewProperty(true);
                        }}>
                        <AddIcon style={{ fontSize: 18 }} />
                        
                      </Fab>
                      <Typography className='mainpropertylist-add-text'
                        component="h5"
                        variant="h5"

                        onClick={() => {  
                            setAddNewProperty(true);
                            }}
                      >
                        ADD A NEW PROPERTY
                      </Typography>
                      </Grid>

                      <Grid item xs={12} container justifyContent="center">
                            <Divider variant="middle" className="mainpropertylist-short-divider" />
                      </Grid>

                      <Grid item xs={12} container justifyContent="center" className="mainpropertylist-paddingtop1d5p">
                      <Link style={{ textDecoration: 'none' }} to={'/settings'}>
                      <span
                            variant="outlined"
                            className="mainpropertylist-settings-text"
                        >
                            GLOBAL SETTINGS
                        </span>
                      </Link>
                      
                    </Grid>
                    </Grid>
                </Grid> : null}

                {isLoading ?  <Grid item xs={12} container justifyContent="center">
                            <Divider variant="middle" className="mainpropertylist-short-divider" />
                </Grid> : null}
                
                </Grid></div>
    );
}
