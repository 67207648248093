import React, { useState } from "react"; 
import { Amplify, Auth, API } from 'aws-amplify';
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import MLink from "@mui/material/Link";
import Grid from "@mui/material/Grid"; 
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container"; 
import { Link } from "react-router-dom";
import LoaderButton from "./LoaderButton"; 
import { openSnackbar } from '../components/Notifier'; 
import { useSnackbar } from 'notistack';
import md5 from 'crypto-js';
import Policy from "../lib/Policy";
import PoweredBy from '../lib/PoweredBy';
import { AmplifyS3Image  } from '@aws-amplify/ui-react';
import awsdev from '../aws-dev';
import awsdev2 from '../aws-dev2';
import { RegistrationConfirmation } from "./auth";
const MapData= (process.env.REACT_APP_STAGE==="dev")? awsdev:( process.env.REACT_APP_STAGE==="dev2")? awsdev2 :window['runConfig'];
export default function SignUp() { 
  const { enqueueSnackbar } = useSnackbar();
  const [firstname, setfirstname] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [username, setusername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [property, setproperty] = useState({}); 
  const [unit, setunit] = useState("0");
  const [initialized, setInitialized] = useState(false);
  const [confirmationView,setConfirmationView]= useState(false);
  const [proeprtyData, setProeprtyData] = useState([]); 
  React.useEffect(() => {   
    if (!initialized) {
      getProperty();
    }
  }, []);
  async function getProperty() {
    setInitialized(true);
    let apiName = 'rentapi';
    let path = '/store/Property';
    let myInit = { headers: { 'Content-Type': 'application/json' } }
    try {
      var response = await API.get(apiName, path, myInit)
      setProeprtyData(response);
    } catch (error) {
      console.log("fetch error", error); 
    }
  }
  function onChange(e) {
    
    if (e.target.name == "firstname") setfirstname(e.target.value);
    if (e.target.name == "lastName") setlastName(e.target.value);
    if (e.target.name == "password") setpassword(e.target.value != "" ? md5.MD5(e.target.value).toString() : e.target.value);
    if (e.target.name == "property") setproperty(e.target.value); 
    if (e.target.name == "unit") setunit(e.target.value);
    if (e.target.name == "email") {  
      setemail(e.target.value.toLowerCase());
      setusername(e.target.value.toLowerCase());
    };
  };
  function validateForm() {
    return username.length > 0 && firstname.length > 0 && lastName.length > 0 && email.length > 0 && password.length > 0;
  };
  function handleClearForm() {
    document.getElementById("signup-form").reset();
  }
  async function updatepropertyDB(res) {
    let apiName = "rentapi";
    let path = '/store/updateUserProperty';
    var body = {};
    body.MasterID = res.userSub;
    body.email = email;
    body.name = firstname + ' ' + lastName;
    body["custom:firstname"] = firstname;
    body["custom:lastname"] = lastName;
    body["custom:privilege"] = "USER";
    body["custom:unit"] = unit;
    body["custom:domain"] = MapData.aws_app_domain_prefix;
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: body
    };
    API.post(apiName, path, myInit).then(response => {
      setIsLoading(false);
      if (response && response.success) {
        /*  openSnackbar({ message: `Your email, ${email} has been successfully registered. 
    Please confirm your email address by clicking the link in the confirmation we have sent you` , variant: "success" }); */
        setConfirmationView(true);
       // handleClearForm();
      }
      else
      { 
        // openSnackbar({ message:response.message /* 'Failed to register the email '+email */, variant: "error" });
        enqueueSnackbar(response.message, {variant: 'error'});
      }
    }).catch(err => {
      console.log(err);
      setIsLoading(false);
      // openSnackbar({ message: err, variant: "error" });
      enqueueSnackbar(err, {variant: 'error'});
    });
  }

  async function signUp(e) {    
    e.preventDefault();
    setIsLoading(true);
    var attrVal = {
      email: email,
      name: firstname + ' ' + lastName,
      "custom:firstname": firstname,
      "custom:lastname": lastName,
      "custom:privilege": "USER",
      "custom:domain": MapData.aws_app_domain_prefix
    }; 
    attrVal["custom:unit"] = unit; 
    Auth.signUp({
      username,
      password,
      attributes: attrVal
    })
      .then(response => {
        //setIsLoading(false); 
        if (response.UserSub != "") { 
          updatepropertyDB(response);
        }
      })
      .catch(err => {
        console.log(err);
        // openSnackbar({ message: err.message, variant: "error" });
        enqueueSnackbar(err.message, {variant: 'error'});
        setIsLoading(false);
      });
  };
  return confirmationView?
 <RegistrationConfirmation email={email}/>:
  <div width="100%">
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '85vh' }}
  > 
    <Grid item xs={12} sm={12} md={5} lg={5} >
      <Container component="main" >
        <CssBaseline />
        <div className="signup-paper">
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              {MapData.management_logo && MapData.management_logo != '' ? <AmplifyS3Image  level="public" imgKey={MapData.management_logo} className="main-logo" /> :
                <Typography component="h2" variant="h2" >
                  {MapData.management_name}
                </Typography>}
            </Grid>
            <Grid item xs={12}>
              <Typography className="verify-user-header" component="h5" variant="h5">
                  REGISTER
              </Typography>
            </Grid>
          </Grid> 
          <form
            id="signup-form"
            className="signup-form "
            noValidate
            onSubmit={signUp}
          >
            <Grid container spacing={3}>               
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  inputProps={{ style: { textTransform: "lowercase" } }}
                  onChange={onChange}
                  name="email"
                  autoComplete="emails"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  onChange={onChange}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="fname"
                  name="firstname"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstname"
                  label="First Name"
                  onChange={onChange}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  onChange={onChange}
                  name="lastName"
                  autoComplete="lname"
                />
              </Grid>             
            </Grid>
            <div style={{ padding: "20px 0px 40px 0px" }}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <LoaderButton
                    block="true"
                    type="submit"
                    size="large"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                  >
                    SUBMIT
                </LoaderButton>
                </Grid>
              </Grid>
            </div>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <MLink
                  color="text.secondary"
                  underline={"always"}
                  component={Link}
                  to="/"
                >
                  <Typography
                    color="text.secondary"
                    gutterBottom={true}
                    variant="body2"
                  >
                    &#8249; {"BACK"}
                  </Typography>
                </MLink>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </Grid></Grid>  <PoweredBy/><Policy class={"policy-cls-rg"} /> 
  </div>;
}