import React, { useState, useCallback, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Skeleton from '@mui/material/Skeleton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Check from '@mui/icons-material/Check';
import Fab from "@mui/material/Fab";
import { API } from "aws-amplify";
import MuiDialogTitle from '@mui/material/DialogTitle';
import usePersistedState from '../../usePersistedState';
//** Data Grid Import Part **/
import { LicenseInfo, DataGridPro, GridOverlay, useGridApiRef, GridToolbarContainer } from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';

const PREFIX = 'addlease';
const classes = {
    arrow: `${PREFIX}-arrow`,
    tooltip: `${PREFIX}-tooltip`,
};
const DialogBox = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    },
}));

const DialogTitle = (props => {
    const { children, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography sx={{ m: 0, p: 2, pl: 3, pr: 3 }}  {...other}>
            <Typography variant="h3" component="h3" sx={{ lineHeight: 1 }}>{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    sx={{
                        position: "absolute",
                        right: "8px",
                        top: "8px",
                        color: "text.secondary",
                    }}
                    onClick={onClose}
                    size="large">
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

//** Data Grid Action related Code */
LicenseInfo.setLicenseKey(
    '79e390e23cc1709a04137710c7c5cdc1T1JERVI6Mjg0NDQsRVhQSVJZPTE2NjEwMDE3MTQwMDAsS0VZVkVSU0lPTj0x',
);
function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    );
}
function RowMenuCell({ api, id, row, userSession, selectedProperty, gridRefresh, ...rest }) {
    return (
        <div className={classes.root}>
            {row.HasAdded !== "Yes" ?
                <div className={classes.wrapper}>
                    <Fab
                        size='small' sx={{
                            '&.MuiFab-root': {
                                backgroundColor: "secondary.main",
                                borderRadius: '50%',
                            },
                        }}
                        aria-label="add"
                        onClick={(event) => {
                            gridRefresh({ RenterMailID: row.Email, RenterName: row.Name, RenterID: row.MasterID, RenterPhoneNumber: row.PhoneNumber });
                        }}>
                        <AddIcon />
                    </Fab>
                </div> :
                <div className={classes.wrapper}>
                    <Fab
                        size='small'
                        sx={{
                            '&.MuiFab-root': {
                                backgroundColor: "secondary.main",
                                borderRadius: '50%',
                            },
                        }}
                        aria-label="check"
                        onClick={((event) => {

                        })}>
                        <Check />
                    </Fab>
                </div>
            }
        </div>
    );
}

//** Data Grid Action related Code */

export default function BrowseTenant(props) {
    const [tenantData, setTenantData] = usePersistedState('tenantData', []);
    const [selectedProperty] = usePersistedState('selectedProperty', {});
    //** Data Grid Related Code */
    const apiRef = useGridApiRef();
    const [filterValue, setFilterValue] = useState({});
    const [filterText, setFilterText] = useState('');
    const [queryOptions, setQueryOptions] = useState({});
    const [data, setData] = useState({
        loading: true,
        rows: [],
        pageSize: 1000,
        columns: [
            { field: 'MasterID', hide: true, filterable: false },
            { headerName: 'Resident', field: 'Name', flex: 1, filterable: false },
            { headerName: 'Email', field: 'Email', flex: 1, filterable: false },
            {
                headerName: 'ACTIONS',
                field: 'actions',
                sortable: false,
                width: 150,
                headerAlign: 'center',
                filterable: false,
                align: 'center',
                disableColumnMenu: true,
                disableReorder: true,
                renderCell: params => { return RowMenuCell({ ...params, ...props, selectedProperty: selectedProperty, gridRefresh: gridRefresh }) }
            }
        ]
    });
    const handleSortModelChange = useCallback((sortModel) => {
        console.log('sort model change');
        setQueryOptions({ sortModel: [...sortModel] });
    }, []);
    const onFilterChanges = useCallback((filterModel) => {
        setFilterValue(filterModel);
        mounted.current = true;
    }, []);
    const gridInitialLoad = (async (active) => {
        updateData('loading', true);
        const newData = await GetInfinityTable(data.pageSize, 0);
        if (mounted.current) {
            if (newData)
                updateData('rows', newData);
        }
        if (!active) {
            return;
        }
        updateData('loading', false);
    });
    useEffect(() => {
        console.log('queryOptions $$$$', queryOptions);
        let active = true;
        (async () => {
            updateData('loading', true);
            const newData = await GetInfinityTable(data.pageSize, 0);
            if (mounted.current) {
                if (newData)
                    updateData('rows', newData);
            }
            if (!active) {
                return;
            }
            updateData('loading', false);
        })();
        return () => {
            active = false;
        };
    }, [queryOptions]);
    useEffect(() => {
        console.log('no corenters')
        setTenantData(props.coRenters);
    }, [props.coRenters]);
    useEffect(() => {
        let active = true;
        gridInitialLoad(active);
        return () => {
            active = false;
        };
    }, [tenantData, filterValue, filterText]);
    const handleKeyDown = async (event) => {
        console.log('event', event);
        const ENTER_KEY = 13;
        if (event.keyCode === ENTER_KEY) {
            setFilterText(event.target.value);
        }
    };
    const mounted = React.useRef(true);
    function CustomToolbar(props) {
        const [search, setSearch] = React.useState(props.value);
        return (
            <GridToolbarContainer>
                <Grid container
                    spacing={3}
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                >
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        {!data.loading && <TextField
                            variant='outlined'
                            fullWidth
                            display='flex'
                            type='search'
                            label='Search by email or name..'
                            onChange={(e) => { setSearch(e.target.value); }}
                            onBlur={(e) => { setFilterText(e.target.value); }}
                            onKeyDown={(e) => { handleKeyDown(e); }}
                            value={search}
                        />}
                        {data.loading && <Skeleton variant='rectangular' height={52} />}
                    </Grid>
                </Grid>
            </GridToolbarContainer>
        );
    }
    const updateData = (k, v) => setData((prev) => ({ ...prev, [k]: v }));
    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    async function GetInfinityTable(pageSize, fromNo) {
        updateData('loading', true);
        updateData('pageSize', pageSize);
        let body = {
            masterType: 'User',
            PropertyData: selectedProperty,
            UserID: props.userSession.UserName,
            searchValue: filterText,
            pageSize: pageSize,
            fromNo: fromNo,
            sortModel: queryOptions.sortModel,
            userData: props.userSession
        };
        return new Promise((resolve, reject) => {
            let apiName = 'rentapi';
            let path = '/modules';
            let myInit = {
                headers: { 'Content-Type': 'application/json' },
                body: body,
                queryStringParameters: {
                    module: 'user',
                    op: 'getResidents'
                }
            };
            API.post(apiName, path, myInit).then(response => {
                response.data.forEach(function (entry) {
                    entry.HasAdded = "No";
                    tenantData && tenantData.forEach(function (childrenEntry) {
                        if (entry.MasterID === childrenEntry.RenterID &&
                            entry.PropertyID &&
                            entry.PropertyID.some((s) => s.PropertyID == selectedProperty.PropertyID)) entry.HasAdded = "Yes";
                    });
                });
                resolve(response.data);
            }).catch(err => {
                console.log(err); reject(err);
            });
        });
    }
    const gridRefresh = async (newTenant) => {
        setTenantData((prev) => ([...prev, newTenant]));
        props.setSelected(newTenant);
    }
    //** Data Grid Related Code END */  

    return (

        <DialogBox
            scroll="body"
            maxWidth={"md"}
            open={props.browseTenantOpen}
            onClose={e => props.setBrowseTenantOpen(false)}
            /*  scroll={addTenantScroll} */
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="customized-dialog-title" onClose={e => { props.setBrowseTenantOpen(false) }}>
                Browse Tenant
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add the existing tenant to this property that is already in your portfolio by searching the resident name below.To add co-tenants, select
                    multiple resident's names
                    from the dropdown. Only residents are shown here.
                </DialogContentText>
                <Grid item xs={12}>
                    <div className={classes.root}>
                        <Grid container
                            direction="row"
                            spacing={2}
                            minwidth={"md"}
                            justifyContent="flex-start"
                            alignItems="flex-start">
                            <Grid item xs={12}>
                                {/* DataGridPro Start */}
                                <div style={{ width: '100%', height: 760 }}>
                                    <DataGridPro
                                        apiRef={apiRef}
                                        density='compact'
                                        autoHeight={false}
                                        rowHeight={80}
                                        headerHeight={100}
                                        disableColumnMenu={true}
                                        hideFooter
                                        hideFooterPagination
                                        loading={data.loading}
                                        rows={data.rows}
                                        getRowId={(r) => r.MasterID}
                                        columns={data.columns}
                                        filterMode='server'
                                        onFilterModelChange={onFilterChanges}
                                        onSortModelChange={handleSortModelChange}
                                        componentsProps={{
                                            toolbar: {
                                                value: filterText,
                                            },
                                        }}
                                        components={{
                                            LoadingOverlay: CustomLoadingOverlay,
                                            Toolbar: CustomToolbar
                                        }}
                                    />
                                </div>
                                <div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </DialogContent>
        </DialogBox>
    );
}
