import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography"; 
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline"; 
import { AmplifyS3Image  } from '@aws-amplify/ui-react';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
import Avatar from '@mui/material/Avatar'; 
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
const MapData = (process.env.REACT_APP_STAGE === "dev") ? awsdev : (process.env.REACT_APP_STAGE === "dev2") ? awsdev2 : window['runConfig']; 
export function LoginOverview(props) { 
    return (
        <div width="100%">
            <>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '85vh' }}
                >
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                        <Container component="main" maxWidth="md">
                            <CssBaseline />
                            <div className="forgot-password-paper">
                                <Grid container justifyContent="center" alignItems="center" direction="column" spacing={1}>
                                    <Grid item>
                                        {MapData.management_logo && MapData.management_logo != '' ? <AmplifyS3Image  level="public" imgKey={MapData.management_logo} /> :
                                            <Typography component="h2" variant="h2" >
                                                {MapData.management_name}
                                            </Typography>}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <div className='page-content-underline' style={{maxWidth:'54px'}}></div>
                                    <Typography className="verify-user-header" component="h5" variant="h5">
                                         {`Welcome ${props.loginName}`}
                                    </Typography>
                                        </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div /* className={'forgot-user-icon'} */>
                                            <Avatar style={{ backgroundColor: '#6200EE',color:'#fff', fontSize: '54px' }} >
                                                <PauseCircleOutlineIcon />
                                            </Avatar> 
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography className="page-content-header" component="h5" variant="h5">
                                        Hang tight, our team has not setup your permission
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography className="page-content-body"  gutterBottom={true} variant="h6">
                                        We have received a notification to activate your account. 
                                        <br/>
                                        <br/>
                                        If you have issues please contact them directly
                                        </Typography>
                                    </Grid>  
                                </Grid>
                            </div>
                        </Container>
                    </Grid>
                </Grid> 
            </>
        </div>
    );
}