
import React, {useState, forwardRef, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import { ReactComponent as TableAccountSvg } from '../../static/images/table-account.svg';
import { SvgIcon } from '@mui/material';
import MenuItem from "@mui/material/MenuItem";
import { LoaderCLickButton } from "../LoaderButton";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { AddTenant } from "../user/AddTenant";
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker' 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloseIcon from '@mui/icons-material/Close'; 
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import Check from '@mui/icons-material/Check';
import Fab from "@mui/material/Fab";
import {API } from "aws-amplify";
import CardActions from "@mui/material/CardActions";
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import { useHistory,useLocation } from "react-router-dom";
import usePersistedState from '../../usePersistedState'; 
import DeleteIcon from '@mui/icons-material/Delete'; 
import Box from '@mui/material/Box';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import BrowseTenant from "./browseTenant";
import AddMiscFee from "./addMiscFee";
import { useSnackbar } from 'notistack';
import { isEmpty, isDate  } from "../../lib/util";

const PREFIX = 'addlease';

const classes = {
  arrow: `${PREFIX}-arrow`,
  tooltip: `${PREFIX}-tooltip`,
};
 
function BootstrapTooltip(props) {
  return <Tooltip arrow classes={classes} {...props} />;
}
const tableIcons = {
  Add: forwardRef((props, ref) => <AddCircleRoundedIcon {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <RemoveCircleRoundedIcon {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <CancelRoundedIcon {...props} ref={ref} />)
};
let miscInitialValue={
  "appfeeName": "",
  "appFeeValue": 0,
  "appFeeType":"percentage",
  "activeDatePlan":"oneTimePlan",
  "validFrom":"",
  "validTo": "",
  "added": false
}
let leaseInitialValue = {
  "unit": "",
  "monthlyRent": "",
  "securityDeposit": "",
  "leaseDueOn": "",
  "leaseStart": new Date().toISOString(),
  "leaseEnd": new Date().toISOString(),
  "afterLeaseEnd": "",
  "tenantSearch": "",
  "status": ""
}
export  function AddLease(props) {
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory(); 
  const location = useLocation();
  const [dateValue, setDateValue] = React.useState([null, null]); 
  const [labelWidth] = React.useState(0); 
  const [editLease, setEditLease] = usePersistedState('editLease',false);
  const [leaseData, setLeaseData] = usePersistedState('leaseData',leaseInitialValue); 
  const [miscData, setMiscData] = usePersistedState('miscData',miscInitialValue); 
  const [tenantData, setTenantData] =usePersistedState('tenantData',[]);
  const [propertyData, setProeprtyData] = usePersistedState('propertyData',{});
  const [isPastLeaseStart, setIsPastLeaseStart] = React.useState(false);
  const [manualTenantAdd, setManualTenantAdd] = React.useState(false);
  const [addTenant, setAddTenant] = React.useState(false); 
  const [residentTableState, setResidentTableState] = React.useState({}); 
  const [rentTableData,setRentTableData] = useState([]);
  const [rentVacancy, setRentVacancy] = React.useState(""); 
  const [addMiscFeeOpen, setAddMiscFeeOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
const [addUserOpen, setAddUserOpen] = useState(false);
const [browseTenantOpen, setBrowseTenantOpen] = useState(false);
  const [manualTenant,setManualTenant] = useState({
    "firstName": "",
    "lastName": "",
    "mobNumber":"",
    "email":""
  });
  const [activateValidationBorder, setActivateValidationBorder] = React.useState(false);
  const [selectedProperty] = usePersistedState('selectedProperty', {}); 
  const isRefresh = React.useRef(true);
  useEffect(() => {
    let isSubscribed = true;  
    const userFetch = async () => {
      if (selectedProperty.hasOwnProperty("PropertyID") && isSubscribed) { 
        await setProeprtyData(selectedProperty);
      }
      if (!location.props && isRefresh.current && selectedProperty.hasOwnProperty("PropertyName")) {
        isRefresh.current = false; 
      }
      return () => isSubscribed = false
    }
    userFetch();
  }, [location.props,selectedProperty]); 
  useEffect(() => {
    let isSubscribed = true;
    const userFetch=async ()=> {
       if(location.props && isSubscribed)
       {   
        let misc = []; 
        const currentLease= location.props.currentLease;
        if (currentLease.Miscs && currentLease.Miscs.length>0) {
            misc = currentLease.Miscs[0];
            misc.added = true;
          } 
          await setTenantData(currentLease.CoRenters && currentLease.CoRenters.length ? currentLease.CoRenters : [])
          await setEditLease(location.props.editLease ? true : false); 
          await setDateValue([isDate(currentLease.RentStartDate)?currentLease.RentStartDate:null,isDate(currentLease.RentEndDate)?currentLease.RentEndDate:null])
          await setLeaseData(currentLease? {
            "masterID": currentLease.MasterID,
            "unit": currentLease.RentUnit,
            "monthlyRent": currentLease.RentEachMonth,
            "securityDeposit": currentLease.RentSecurityDeposit,
            "leaseDueOn": currentLease.RentLeaseDueOn,
            "leaseStart": currentLease.RentStartDate,
            "leaseEnd": currentLease.RentEndDate,
            "afterLeaseEnd": currentLease.RentAfterLeaseEnd,
            "tenantSearch": "",
            "status": currentLease.RentStatus
          } :leaseInitialValue);
        await setMiscData(!isEmpty(misc)? misc : miscInitialValue);
       }  
      return () => isSubscribed = false;
    }
    userFetch();
  }, [location.props]);

  useEffect(() => {
    console.log("useEffect is called : EVERY TIME on tenantData" , tenantData); 
  }, [tenantData]);

  useEffect(() => {
    
    if (!manualTenantAdd) {
      setManualTenant({
        "firstName": "",
        "lastName": "",
        "mobNumber":"",
        "email":""
      });
    }

  }, [manualTenantAdd]);
  useEffect(() => {
    let isSubscribed = true; 
    console.log('location.props',location.props);
    const userFetch = async () => { 
      if (location.props && location.props.tenant && isSubscribed)
      {
        let tenant=location.props.tenant;
        console.log('tenant',tenant)
        let ob = {};
        ob.Property = selectedProperty.PropertyName;
        ob.PropertyID = selectedProperty.PropertyID;
        if(Array.isArray(tenant))
        {
          for (var i = 0; i < tenant.length; i++) {
            await addTenantToLease(ob, tenant[i].MasterID, { RenterMailID: tenant[i].Email, RenterName: tenant[i].Name, RenterID: tenant[i].MasterID, RenterPhoneNumber: tenant[i].PhoneNumber });
          }
        }
        else{
          await addTenantToLease(ob, tenant.MasterID, { RenterMailID: tenant.Email, RenterName: tenant.Name, RenterID: tenant.MasterID, RenterPhoneNumber: tenant.PhoneNumber });
        }  
      }
      return () => isSubscribed = false;
    }
    userFetch();
  }, [location.props]);
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (addTenant) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    if (manualTenantAdd) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [addTenant, manualTenantAdd]);
const dueOn = ["1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th",
    "11th", "12th", "13th", "14th", "15th", "16th", "17th", "18th", "19th", "20th",
    "21st", "22nd", "23rd", "24th", "25th", "26th", "27th", "28th", "29th", "30th", "31st"]; 
  const setSelectedMisc = async (selected) => {
    setMiscData(selected);
  }
  const setSelected= async (selected)=>{
    console.log('selected',selected);
    let ob = {};
    ob.Property = selectedProperty.PropertyName;
    ob.PropertyID = selectedProperty.PropertyID;
    await addTenantToLease(ob, selected.RenterID, { RenterMailID: selected.RenterMailID, RenterName: selected.RenterName, RenterID: selected.RenterID, RenterPhoneNumber: selected.RenterPhoneNumber });
  }
  const onChildClick= async (e,tenant)=>{
    if(e==='closeUser')
    {
      setAddUserOpen(false);
    }
    if(e==='add tenant')
    {
      if (tenant)
      { 
        console.log('add tenant 313',tenant)
        let ob = {};
        ob.Property = selectedProperty.PropertyName;
        ob.PropertyID = selectedProperty.PropertyID;
        if(Array.isArray(tenant))
        {
          for (var i = 0; i < tenant.length; i++) { 
            await addTenantToLease(ob, tenant[i].MasterID, { RenterMailID: tenant[i].Email, RenterName: tenant[i].Name, RenterID: tenant[i].MasterID, RenterPhoneNumber: tenant[i].PhoneNumber });
          }
        }
        else{
          await addTenantToLease(ob, tenant.MasterID, { RenterMailID: tenant.Email, RenterName: tenant.Name, RenterID: tenant.MasterID, RenterPhoneNumber: tenant.PhoneNumber });
        }  
        setAddUserOpen(false);
      }
    }
  }

  let deleteCurrentMisc = async () => {
    if (!isEmpty(miscData)) {       
      setMiscData(miscInitialValue);
    } 
    enqueueSnackbar("Misc fee removed successfully",{variant: 'success'}) 
  }

  return (
    <div className="addlease-root">  
      {addUserOpen && <AddTenant fromLease={true} userOpen={addUserOpen} userSession={props.userSession} onChildClick={onChildClick}/>}
      {browseTenantOpen && <BrowseTenant setSelected={setSelected} coRenters={tenantData} setBrowseTenantOpen={setBrowseTenantOpen} browseTenantOpen={browseTenantOpen} userSession={props.userSession} onChildClick={onChildClick}/>}        
      {addMiscFeeOpen && <AddMiscFee setSelectedMisc={setSelectedMisc} miscData={miscData} setAddMiscFeeOpen={setAddMiscFeeOpen} addMiscFeeOpen={addMiscFeeOpen} userSession={props.userSession} onChildClick={onChildClick}/>}        
      <Container component="main" className="addlease-main">
        <CssBaseline />
        <div className="addlease-paper">
       {/*  {isLoading ? <div className="common-spinner">
        <CircularProgress className={classes.spinner} size={50} /></div> : null} */}      
        <Grid container spacing={1} className={"add-padding-p8p"}  >
          <Grid item>
            <Button onClick={e =>{ 
         history.push({pathname: `/${propertyData.PropertyName.replace(/\s+/g, '-')}/lease` ,props:{PropertyName:propertyData.PropertyName, propertyData:propertyData}});                      
            }}>
                <Typography
                  component="h6"
                  sx={{ fontWeight: 600 }}
                  color="text.primary"
                  variant="h6"
                >
                  &#8249; {" RETURN"}
                </Typography>  
            </Button> 
          </Grid> 
        </Grid>
        <Grid container spacing={1} className={"add-padding-p8p"} >
          <Grid item>
            <Typography
              component="h1"
              className="addlease-headerone"
              variant="h1"
            >
              Add Lease
                </Typography>
          </Grid> 
        </Grid>
        <Grid container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start">
          <Grid item xs={12}>
            <Typography className="addlease-headerfour"
              variant="subtitle1"
            >
             Add new tenant(s) to this property and the lease terms in the same unit. Once submitted, the tenant(s) will be invited to the resident portal to activate
             their account and the lease terms will be active. If you want to create seperate lease terms, for individual recipient, please add new tenants individually.
                </Typography>
          </Grid>
        </Grid>
        <Grid container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start">
           <Grid item minWidth={'197px'} xs={props.whichDevice!=='Desktop'?5:4} sm={props.whichDevice!=='Desktop'?4:3} md={props.whichDevice!=='Desktop'?4:2} lg={props.whichDevice!=='Desktop'?4:2}>
          <TextField
            className={`addlease-unit-field ${activateValidationBorder && !leaseData.unit ? "common-mandatory-red": ""}`}
            id="input-with-icon-textfield"
            variant="outlined"
            name="unit"
            value={leaseData.unit || ''}
            onChange={onleaseElementsChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  UNIT #
                </InputAdornment>
              ),
            }}
          />
          </Grid>
        </Grid>
        <Grid container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="addlease-addtenant-inner"> 
          <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={1} className="add-lease-addtenant-card-inner-grid" >
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Button
                variant="outlined"
                className="addlease-activity-bt"
                startIcon={<GroupAddIcon />}
                onClick={(e) => { setAddUserOpen(true);  /* setManualTenantAdd(true) */ }}
              >
                ADD TENANT
            </Button>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Button
                variant="outlined"
                className="addlease-activity-bt"
                startIcon={<SupervisorAccountIcon />}
                onClick={(e) => { LoadResidentCombo();/*  setIsAddOrBrowse("Browse Tenant"); */ }}
              >
                BROWSE TENANT
              </Button>
            </Grid> 
            </Grid>
            <Grid className="add-lease-addtenant-table-inner-grid" item xs={12} sm={12} md={12} lg={12}>
            {
                    tenantData && tenantData.length > 0 && 
                    <Grid container spacing={1}  >
                      {
                        tenantData.map((e, keyIndex) => {
                          
                          return (
                            <Grid key={keyIndex} item xs={12}>
                              <Card className="addLease-tenantlist-card">    
                                <CardActions className="addLease-list-tenant-card-action">
                                  <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                  >
                                    <Grid item xs={5} className="addLease-list-tenant-each-lb">
                                      {e.RenterName}
                                    </Grid>
                                    <Grid item xs={6} className="addLease-list-tenant-each-lb">
                                      {e.RenterMailID}
                                    </Grid> 
                                    <Grid item xs={1}>
                                      <BootstrapTooltip title="Delete">
                                        <Fab
                                          id="outlined-basic"
                                          value={props.value}
                                          size="small"
                                          color="secondary"
                                          onClick={de => {removeTenantToLease(e.RenterID)}}>
                                          <CloseIcon />
                                        </Fab>

                                      </BootstrapTooltip>
                                    </Grid>
                                  </Grid>
                                </CardActions>
                              </Card>
                              <Divider />
                            </Grid>
                          );
                        })}
                        
                    </Grid>
                  }
                  
                  <Grid item xs={12}>
                        {
                          !tenantData || !tenantData.length &&                     
                              <SvgIcon
                              viewBox="0 0 240 240"
                              className={"addlease-cardsvg"}
                              >
                              <TableAccountSvg 
                                width='240'
                                height='240'                        
                              />
                              </SvgIcon>                      
                        }
                        </Grid>
          </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container spacing={1} className="add-lease-addtenant-card-inner-grid" >     
          <Grid  item xs={12} sm={12} md={12} lg={12}>
            <Card className="home-card addlease-rightcard"> 
            <CardContent>
            <Grid container spacing={4} className="add-lease-addtenant-rightcard-inner-grid" >
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                      <div>
                        <Typography variant="subtitle1" color="textPrimary"> MONTHLY RENT
                      </Typography>
                      </div>
                      <div className="addlease-right-fields-p">
                        <TextField
                          className={`${activateValidationBorder && !leaseData.monthlyRent ? " common-mandatory-red": ""}`}
                          id="input-with-icon-rent"
                          name="monthlyRent"
                          variant="outlined"
                          fullWidth
                          value={leaseData.monthlyRent || ""}
                          onChange={onleaseElementsChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4} lg={4}>
                      <div>
                        <Typography variant="subtitle1" color="textPrimary"> SECURITY DEPOSIT
                    </Typography>
                      </div>
                      <div className="addlease-right-fields-p">
                        <TextField 
                          id="input-with-icon-security"
                          name="securityDeposit"
                          variant="outlined"
                          fullWidth
                          onChange={onleaseElementsChange}
                          value={leaseData.securityDeposit || ""}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4} lg={4}>
                      <div>
                        <Typography variant="subtitle1" color="textPrimary"> DUE ON
                    </Typography>
                      </div>
                      <div className="addlease-right-fields-p">
                        <Select
                          className={`${activateValidationBorder && !leaseData.leaseDueOn ? " common-mandatory-red": ""}`}
                          labelid="select-outlined-label"
                          id="due-select-outlined"
                          name="leaseDueOn"
                          fullWidth
                          variant="outlined"
                          value={leaseData.leaseDueOn || ''}
                          labelWidth={labelWidth}
                        onChange={onleaseElementsChange}
                      >
                        {dueOn.map((day,index) => {
                          return(
                            <MenuItem key={index} value={day}>
                            {day}
                            </MenuItem>
                          )
                        })}
                        </Select>
                      </div>
                    </Grid> 
                    <Grid item xs={12} sm={12} md={8} lg={8}> 
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DateRangePicker
                                startText="Add date"
                                endText="Add date"
                                value={dateValue}
                                onChange={date => {
                                  onleaseElementsChange(date, 'leaseDate')
                                }}
                                renderInput={(startProps, endProps) => ( 
                                    <Grid container  direction="row" justifyContent="flex-start"  alignItems="center" spacing={4} >
                                      <Grid item xs={6} sm={6} md={6} lg={6}>
                                        <div>
                                          <Typography variant="subtitle1" color="textPrimary">LEASE START
                                          </Typography>
                                        </div>
                                        <div className="addlease-right-fields-p">
                                          <TextField 
                                           fullWidth
                                           InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <EventOutlinedIcon />
                                              </InputAdornment>
                                            ),
                                          }}
                                          className={`${activateValidationBorder && !leaseData.leaseStart ? " common-mandatory-red": ""}`}
                                          {...startProps} />
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={6} lg={6}>
                                        <div>
                                          <Typography variant="subtitle1" color="textPrimary">LEASE END
                                          </Typography>
                                        </div>
                                        <div className="addlease-right-fields-p">
                                          <TextField
                                          fullWidth
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <EventOutlinedIcon />
                                              </InputAdornment>
                                            ),
                                          }}
                                          className={`${activateValidationBorder && !leaseData.leaseEnd ? " common-mandatory-red": ""}`}
                                          {...endProps} />
                                        </div>
                                      </Grid>
                                    </Grid> 
                                )}
                              />
                            </LocalizationProvider> 
                    </Grid>                
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                      <div>
                        <Typography variant="subtitle1" color="textPrimary">AFTER LEASE END
                      </Typography>
                      </div>
                      <div className="addlease-right-fields-p">
                        <Select
                          className={`${activateValidationBorder && !leaseData.afterLeaseEnd ? " common-mandatory-red": ""}`}
                          labelid="select-outlined-label"
                          id="due-select-outlined"
                          fullWidth
                          variant="outlined"
                          name="afterLeaseEnd" 
                          value={leaseData.afterLeaseEnd ? leaseData.afterLeaseEnd : ""}
                          labelWidth={labelWidth}
                          onChange={onleaseElementsChange}
                        >
                          <MenuItem key="1" value="MONTH-TO-MONTH">
                            MONTH-TO-MONTH
                          </MenuItem>
                          <MenuItem key="2" value="TERMINATE">
                            TERMINATE
                          </MenuItem>
                        </Select>
                      </div>
                    </Grid> 
                  
                  </Grid>

            </CardContent>
            </Card>
          </Grid>
          </Grid>
          </Grid>          
          <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>          
            {miscData.added ?
              <Card className="home-card"> 
                  <CardMedia
                    className="addlease-bottomcard-inner-card "
                    image={""} 
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={3}>
                        {miscData.appfeeName}
                      </Grid>
                      <Grid item xs={2}>
                        {miscData.appFeeType == 'percentage' ? `${miscData.appFeeValue} %` : `$ ${miscData.appFeeValue}`}
                      </Grid>
                      <Grid item xs={3}>
                        {miscData.activeDatePlan == 'oneTimePlan' ? 'Expires-One-Time' : 'Reccuring'}
                      </Grid>
                      <Grid item xs={3}>
                        <Box width={"100%"} sx={{ textAlign: "end" }} flexGrow={1}>
                          <Button variant="outlined" className="addLease-misc-edit-icon" onClick={miscEdit => setAddMiscFeeOpen(true)}> <EditIcon /> </Button>
                          <Button variant="outlined" className="addLease-misc-edit-icon" onClick={miscEdit => deleteCurrentMisc()}> <DeleteIcon /> </Button>
                        </Box>
                      </Grid>
                    </Grid>                
                  </CardMedia>

              </Card>
              :  
                <Button
                  variant="outlined"
                  className="addlease-add-misc-bt"
                  startIcon={<AddIcon />}
                  onClick={e => setAddMiscFeeOpen(true)}
                >
                  ADD FEE
                            </Button>
       
            }
          </Grid>        
        </Grid> 
        <Grid container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="addlease-addtenant-inner">
        <Grid item xs={12} sm={12} md={6} lg={6}>
            <div style={{ padding: "20px 0px 40px 0px" }}>
              <LoaderCLickButton
                isLoading={false}
                size="large"  
                disabled={false}
                onClick={() => { activateLease() }}
              >
                {editLease? "UPDATE":"ACTIVATE"}
              </LoaderCLickButton>
            </div>
          </Grid>
          </Grid> 
        </div> 
      </Container>
    </div>
  );
  function onleaseElementsChange(e, dateType) {
    let ob = {};
    if (e.target && e.target.name) {
      
      ob[e.target.name] =  e.target.value
    }
    else {
      if(dateType === 'leaseDate')
      {
        setDateValue(e);
        if(e.length>0 && e[0] && isDate(e[0])) ob['leaseStart'] = e[0].toISOString();
        if(e.length>1 && e[1] && isDate(e[1])) ob['leaseEnd'] = e[1].toISOString();        
      } 
    } 
    setLeaseData({...leaseData, ...ob});
  }

  function activateLease(e) {
    setIsLoading(true);
    if (leaseData.monthlyRent &&
      leaseData.leaseDueOn &&
      leaseData.leaseStart &&
      leaseData.afterLeaseEnd &&
      leaseData.leaseEnd &&
      tenantData.length) {
      if (!leaseData.securityDeposit) leaseData.securityDeposit = 0;
      let leaseRequest = {
        PropertyID: propertyData.PropertyID,
        PropertyName: propertyData.PropertyName,
        tenants: tenantData,
        edit: editLease
      }
      if (!isEmpty(miscData) && miscData.added) leaseRequest.miscs = [miscData];
      leaseRequest = { ...leaseRequest, ...leaseData, userData: props.userSession };
      setLeaseData({ ...leaseData, ...{ status: "Activated", miscs: [miscData], tenants: tenantData } });
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: leaseRequest,
        queryStringParameters: {
          module: "biller",
          op: "saveRent"
        }
      };
      API.post(apiName, path, myInit).then(response => {
        if (response.success) {
          enqueueSnackbar(
            response.message,
            {
              variant: "success"
            });
          setMiscData(miscInitialValue)
          setDateValue([null, null])
          setLeaseData(leaseInitialValue);
          setTenantData([]);
          setActivateValidationBorder(false);
          setIsLoading(false);
          history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/lease`, props: { PropertyName: selectedProperty.PropertyName, selectedProperty: selectedProperty } });
        }
        else {
          enqueueSnackbar(response.message, { variant: "error" });
          setIsLoading(false);
        }
      }).catch(err => {
        console.log(err);
        setIsLoading(false);
        enqueueSnackbar(err.message, { variant: "error" });
      });
    }
    else {
      setActivateValidationBorder(true);
      let validationMsg = "Please enter all required fields";

      if (!leaseData.monthlyRent) {
        validationMsg = "Monthly rent is required to activate a lease";
      }
      if (!leaseData.leaseDueOn) {
        validationMsg = "Due on is required to activate a lease";
      }
      if (!leaseData.leaseStart) {
        validationMsg = "Lease start is required to activate a lease";
      }
      if (!leaseData.afterLeaseEnd) {
        validationMsg = "After lease end is required to activate a lease";
      }
      if (!leaseData.leaseEnd) {
        validationMsg = "Lease end end is required to activate a lease";
      }
      if (!tenantData.length) {
        validationMsg = "Minimum one tenant is required to activate a lease";
      }
      if (!leaseData.unit) {
        validationMsg = "Unit is required to activate a lease";
      }

      if (!leaseData.unit &&
        !leaseData.monthlyRent &&
        !leaseData.leaseDueOn &&
        !leaseData.leaseStart &&
        !leaseData.afterLeaseEnd &&
        !leaseData.leaseEnd &&
        !tenantData.length
      ) {
        validationMsg = "Please enter all required fields";
      }
      setIsLoading(false);
      enqueueSnackbar("Please enter all required fields", { variant: "error" });
    }
  } 
  function onResidentTextSearch(e) {
    
    let ob = {};
    if (e.target && e.target.name) {
      ob[e.target.name] =  e.target.value;
      
    }
    setLeaseData({...leaseData, ...ob});

    if (e.target.value.length > 3) {
      
      setLeaseData({tenantSearch: e.target.value});
      
      LoadResidentCombo(e.target.value);
    }

    if (e.target.value.length == 0) {
      setLeaseData({tenantSearch: ""});
      LoadResidentCombo();
    }

  }
  async function LoadResidentCombo(filterString) {    
    setBrowseTenantOpen(true)
  }

  async function addTenantToLease(updateData, residentID, newTenantData, cb) {    
    setTenantData(prvValue=>[...prvValue,newTenantData]);     
    if (residentTableState.data) {      
      residentTableState.data.forEach((each, index) => {
        console.log("CH : " , residentID,  each.MasterID)
        if (residentID == each.MasterID) each.HasAdded = "Yes";
      })        
      setResidentTableState(residentTableState);
    } 
  }  
  function removeTenantToLease(residentID) { 
    setTenantData(tenantData.filter(f => f.RenterID != residentID));
  } 
}


