import React, { useState } from "react";
import {
  TextField,
  styled,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton
} from "@mui/material";
import CustomizedTooltips from "../../lib/tooltIp";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import LoaderButton, { LoaderDeleteButton } from "../LoaderButton";


const DialogBox = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));

const DialogTitle = (props => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle sx={{ m: 0, p: 2, pl: 3, pr: 3 }}  {...other}>
      <Typography variant="h3" component="h3" sx={{ lineHeight: 1 }}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: "8px",
            top: "8px",
            color: "text.secondary",
          }}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export function StipeKeyManage(props) {
  console.log("ADD NEW PROPERTY PROPS ", props);
  const [isEditing, setIsEditing] = useState(false);
  const [stripeProperty, setStripeProperty] = useState({
    MasterID: "",
    StripePublicKey: "",
    StripeSecretKey: "",
    IncludeStripeFee: false
  });
  const [stripeOpen, setStripeOpen] = useState(false);
  function eventChange(type) {
    console.log("event change, type", type);
    if (type === "stripe add") {
      props.eventChange(type, stripeProperty);
      setStripeOpen(false);
    } else props.eventChange(type);
  }
  React.useEffect(() => {
      console.log('stripeProperty ***',stripeProperty);
  },[stripeProperty])

  React.useEffect(() => {
    setStripeOpen(props.stripeOpen);
    setIsEditing(props.isEditing);
    setStripeProperty((prvproperty) => {
      return {
        ...prvproperty,
        MasterID: props.MasterID,
        StripePublicKey: props.StripePublicKey,
        StripeSecretKey: props.StripeSecretKey,
        IncludeStripeFee: props.IncludeStripeFee?props.IncludeStripeFee:false
      };
    });
  }, [props]);

  function validateForm() {
    return (
      (!isEditing ||
        (stripeProperty.StripePublicKey &&
          stripeProperty.StripePublicKey.length > 0)) &&
      (!isEditing ||
        (stripeProperty.StripeSecretKey &&
          stripeProperty.StripeSecretKey.length > 0))
    );
  }
  const handleClose = (e) => {
    setStripeOpen(false);
    eventChange("stripe close");
  };
  function onChange(e, inputProps) {    
    let { name, value, checked } = e.target
    console.log("stripeProperty onChange", name, value, checked);
    if (name === 'IncludeStripeFee') {
      console.log('include stripe checkbox',checked);
      setStripeProperty({ ...stripeProperty, [name]: checked });
    }
    else {
      setStripeProperty({ ...stripeProperty, [name]: value });
    }
  }

  return (
    <>
      <DialogBox
        open={stripeOpen}
        onClose={(e) => handleClose()}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="delete-dialog-title" onClose={(e) => handleClose()}>
          {!isEditing ? "Add Stripe Keys" : "Edit Stripe Key"}
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="StripePublicKey"
                value={stripeProperty.StripePublicKey}
                label="Publishable key"
                onChange={onChange}
                name="StripePublicKey"
                autoComplete="StripePublicKey"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="StripeSecretKey"
                value={stripeProperty.StripeSecretKey}
                label={!isEditing ? "Secret key" : "Secret key"}
                onChange={onChange}
                name="StripeSecretKey"
                autoComplete="StripeSecretKey"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <FormControlLabel
                    label='Yes, pass payment processing fee on to the tenants'
                    control={
                      <Checkbox
                        color="default"
                        checked={stripeProperty.IncludeStripeFee}
                        onChange={onChange}
                        name='IncludeStripeFee'
                        style={{ color: '#374761' }}
                      />
                    }
                  />
                </Grid>
                <Grid item>
                  <CustomizedTooltips
                    content={
                      "Stripe fee (2.9% + 0.30 cents per transaction )"
                    }
                  />
                </Grid>
              </Grid>          
            </Grid>
          </Grid> 
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction='row'
            spacing={2}
            alignItems='flex-start'
          >
            <Grid item>
                <LoaderButton
                  block="true"
                  type="submit"
                  size="large"
                  disabled={!validateForm()}
                  onClick={() => eventChange("stripe add")}
                >
                  {isEditing ? "EDIT" : "+ ADD"}
                </LoaderButton>
            </Grid>
            <Grid item>
              <LoaderDeleteButton  
                size='large'
                onClick={(e) => handleClose()}
              >
                CANCEL
              </LoaderDeleteButton>
            </Grid>
          </Grid> 
          </DialogActions>
      </DialogBox>
    </>
  );
}
