import React from "react";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';
/* import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles'; */
import { styled } from '@mui/material/styles';
/* const BootstrapButton = withStyles({
    root: {
      boxShadow: "none",
      textTransform: "none",
      fontSize: 17,
      padding: "10px 30px 10px 30px",
      borderRadius:"0px",
      lineHeight: 1.5,
      backgroundColor: "#ffe100",
      color: "black",       
      fontFamily: ['"Roboto", "Helvetica", "Arial", "sans-serif"'].join(","),
      "&:hover": {
        backgroundColor: "#ffe1007a",        
        boxShadow: "none"
      },
      "&:active": {
        boxShadow: "none",   
        borderColor: "#005cbf"
      },
      "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)"
      }
    }
  })(Button); */

const BootstrapButton = styled(Button)(({ theme }) => ({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 17,
  /* padding: "10px 30px 10px 30px", */
  borderRadius: "0px",
  lineHeight: 1.5,
  color: "#000000",
  backgroundColor: "#ffe100",
  color: "black",
  fontFamily: ['"Roboto", "Helvetica", "Arial", "sans-serif"'].join(","),
  "& .MuiButton-contained, & .MuiButton-containedPrimary":{
    color: "#000000",
    backgroundColor:" #ffe100"
  },
  "&:hover": {
    backgroundColor: "#ffe1007a",
    boxShadow: "none"
  },
  "&:active": {
    boxShadow: "none",
    borderColor: "#005cbf"
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)"
  }
})); 
/* const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },   
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  button: {
    margin: theme.spacing(1),      
  },

  input: {
    display: 'none',
  },  
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
  },
})); */

export default function LoaderButton({
  isLoading,  
  disabled = false,
  ...props
}) { 
  return (
    <BootstrapButton
      type="submit"
      variant="contained"
      color="primary"
      disableRipple
      size="large"      
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading &&  <CircularProgress className="loader-circualar" />}
      {props.children}
    </BootstrapButton>
  );
}

export function LoaderCLickButton({
  isLoading,  
  disabled = false,
  ...props
}) { 
  function handleClick(e) {
    props.onClick(e);
  }
  return (
    <BootstrapButton
      onClick={handleClick}
      variant="contained"
      color="primary"
      disableRipple
      size="large"
      /* className={classes.margin}   */  
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading &&  <CircularProgress  className="loader-circualar"  />}
      {props.children}
    </BootstrapButton>
  );
}

export function LoaderDeleteButton({
  isLoading,  
  disabled = false,
  ...props
}) { 
  function handleClick(e) {
    props.onClick(e);
  }
  return (
    <BootstrapButton
      onClick={handleClick}
      variant="contained"
      disableRipple
      size="large"
      className={"loader-delete-bt offline-bt-padding-15p"}    
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading &&  <CircularProgress  className="loader-circualar"  />}
      {props.children}
    </BootstrapButton>
  );
}