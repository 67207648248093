import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import Container from "@mui/material/Container";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import MLink from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import LoaderButton from "../LoaderButton";
import { Link } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Policy from "../../lib/Policy";
import PoweredBy from '../../lib/PoweredBy';
import { AmplifyS3Image  } from '@aws-amplify/ui-react';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
import Avatar from '@mui/material/Avatar';
import CheckIcon from '@mui/icons-material/Check';
const PREFIX = 'RegistrationConfirmation';

const classes = {
    "@global": `${PREFIX}-undefined`,
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    avatar: `${PREFIX}-avatar`,
    form: `${PREFIX}-form`,
    submit: `${PREFIX}-submit`,
    header: `${PREFIX}-header`,
    myImage: `${PREFIX}-myImage`,
    margin: `${PREFIX}-margin`,
    withoutLabel: `${PREFIX}-withoutLabel`,
    textField: `${PREFIX}-textField`,
    welcomeText: `${PREFIX}-welcomeText`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.undefined}`]: {
        body: {
            backgroundColor: theme.palette.common.white
        }
    },

    [`& .${classes.root}`]: {
        display: "flex",
        "& > * + *": {
            marginLeft: theme.spacing(2)
        }
    },

    [`& .${classes.paper}`]: {
        marginTop: theme.spacing(8),
        display: "flex",
        justify: "center",
        flexDirection: "column",
        alignItems: "flex-start"
    },

    [`& .${classes.avatar}`]: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },

    [`& .${classes.form}`]: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3)
    },

    [`& .${classes.submit}`]: {
        margin: theme.spacing(3, 0, 2)
    },

    [`& .${classes.header}`]: {
        fontSize: 20,
        fontStyle: "bold",
        fontFamily: "Arial-BoldMT, Arial"
    },

    [`& .${classes.myImage}`]: {
        width: "100%"
    },

    [`& .${classes.margin}`]: {
        marginTop: theme.spacing(2),
    },

    [`& .${classes.withoutLabel}`]: {
        marginTop: theme.spacing(3),
    },

    [`& .${classes.textField}`]: {
        width: 200,
    },

    [`& .${classes.welcomeText}`]: {
        fontSize: "20px",
    }
}));

const MapData = (process.env.REACT_APP_STAGE === "dev") ? awsdev : (process.env.REACT_APP_STAGE === "dev2") ? awsdev2 : window['runConfig'];
export function RegistrationConfirmation(props) { 
    return (
        <Root width="100%">
            <>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '85vh' }}
                >
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                        <Container component="main" maxWidth="md">
                            <CssBaseline />
                            <div className="forgot-password-paper">
                                <Grid container justifyContent="center" alignItems="center" direction="column" spacing={1}>
                                    <Grid item>
                                        {MapData.management_logo && MapData.management_logo != '' ? <AmplifyS3Image  level="public" imgKey={MapData.management_logo} /> :
                                            <Typography className='managment-header' component="h2" variant="h2" >
                                                {MapData.management_name}
                                            </Typography>}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div className={'page-content-underline'}>
                                        <Avatar style={{ backgroundColor: '#0AE20A', color: '#fff', fontSize: '54px' }} >
                                            <CheckIcon />
                                        </Avatar>
                                        </div> 
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography className="page-content-header" component="h5" variant="h5">
                                        Registration is almost complete
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography className="page-content-body" gutterBottom={true} variant="h6">
                                        {`Your email, ${props.email} has been successfully registered. `}
                                        <br/>
                                        <br/>
                                        Please confirm your email address by clicking the link in the confirmation email we have sent to your inbox.
                                        <br/>
                                        <br/>
                                        Once confirmed, one of our team member will provide you access.
                                        </Typography>
                                    </Grid> 
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div style={{ padding: "20px 0px 40px 0px" }}>
                                            <MLink
                                                color="text.secondary"
                                                underline={"always"}
                                                component={Link}
                                                to="/"
                                            >
                                                <Typography
                                                    color="text.secondary"
                                                    gutterBottom={true}
                                                    variant="body2" 
                                                >
                                                    &#8249; {"BACK"}
                                                </Typography>
                                            </MLink> 
                                        </div>

                                    </Grid>
                                </Grid>
                            </div>
                        </Container>
                    </Grid>
                </Grid>
                <PoweredBy />
                <Policy class={"policy-cls"} />
            </>
        </Root>
    );
}