import React, { Component } from 'react';
import { API } from "aws-amplify";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ShareIcon from '@mui/icons-material/Share';
import CircularProgress from "@mui/material/CircularProgress";
import MLink from "@mui/material/Link";

class CsvExport extends Component {    
  constructor(props) {
    super(props); 
    this.state = {
      loading: false
    }
  }
  
     exportToCSV() {
      this.setState({ loading: true });
      console.log("sdsdsd", this.state.loading)
         let body = {};
         body.masterType = this.props.masterType;


         console.log("******************(****)*******a  *" , this.columnsOfTable);

         if (this.columnsOfTable) body.columnsOfTable = this.columnsOfTable;

         if(this.props.masterType === "Rent" || this.props.masterType === "ViewTransaction"){
            body.UserID = this.props.UserID;
            body.PropertyID = this.props.PropertyID;
         }

        let apiName = "rentapi";
        let path = "/modules";
        let myInit = {
          headers: { "Content-Type": "application/json" },
          body:body,
          queryStringParameters: {
            module:this.props.module,
            op: "export"
          }
        };
        API.post(apiName, path, myInit).then(response => {
          this.setState({ loading: false });
          if(response.success){
          const link = document.createElement('a');
          link.href = response.link;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          }
          
        }).catch(err => {
          console.log(err);
        });
    
      }
    render() { 

      if (this.props.tableReference && 
        this.props.tableReference.current && 
        this.props.tableReference.current.props && 
        this.props.tableReference.current.props.columns) {
 
           this.columnsOfTable = this.props.tableReference.current.props.columns.map((m) => {
            let ob = {
              title: m.title,
              field: m.field
            }
            if (m.field == 'ConvenienceFee') {
              ob.title = "FEE (TENANT)";
            }
            else if (m.field == 'StripeCharges') {
              ob.title = "FEE (PROPERTY)";
            }
            else {
              ob.title = m.title;
            }
            return ob;
          }); 
          //body.tableColumns = this.props.tableReference;
        }
      
        return !this.props.isMobile?
          (<div>
              <Grid container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center" 
                  disabled = {true}
                  className={this.state.loading ? "csv-export-text-disabled" : "csv-export-text-enabled"}
                  onClick={() => { 
                      if (!this.state.loading) this.exportToCSV()
                  }}>
                    
                  {this.state.loading && <CircularProgress  size={20} className={"csv-export-spinner"}/> }
                  <div className={this.state.loading ? "csv-export-disabled" : "csv-export csv"}>
                  <ShareIcon color="inherit" className={"csvexport-icon"}/>
                  
                      
                      <MLink
                        variant="body2"
                        className= {this.state.loading ? "csv-export-link-disabled csv-export-text-pad-top-1p csvexport-fnt" : "csv-export-link csv-export-text-pad-top-1p csvexport-fnt"}
                        underline="hover">
                        EXPORT CSV
                      </MLink>
                 
                 </div>
              </Grid>
          </div>):null;
    }
}

export default CsvExport;
