import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import MLink from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import viewbillinghistorysvg from "../static/images/viewbillinghistory.svg";
import makepaymentsvg from "../static/images/makepayment.svg";
import managecreditcardssvg from "../static/images/managecreditcards.svg";
import { useHistory, useLocation } from "react-router-dom";
import { ListPropertyCards } from '../common/components/placeholder';
import usePersistedState from "../usePersistedState";
import { Skeleton } from '@mui/material';
export default function Renter(props) {
  let history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProperty, setSelectedProperty] = usePersistedState('selectedProperty', {});
  const [userProperties] = usePersistedState('userProperties', {});

  useEffect(() => {
    let isSubscribed = true;
    const propertyFetch = async () => {
      if (location.props && isSubscribed) {
        if (location.props.currentProperty) {
          await setSelectedProperty(location.props.currentProperty);
        }
      }
      return () => isSubscribed = false
    }
    propertyFetch();
  }, [location]);
  useEffect(() => {
    if (localStorage.paymentPath && localStorage.paymentPath !== "{}" && userProperties && userProperties.length > 0) {
      setIsLoading(true);
      var retrievedObject = JSON.parse(localStorage.getItem('paymentPath'));
      userProperties.map((e, keyIndex) => {
        if (retrievedObject.prCode === e.PropertyID) {
          localStorage.removeItem('paymentPath');
          history.push({ pathname: `/${e.PropertyName}/payment`, props: { fromRenter: true, property: e, plaidLink: e.LinkToken, PlaidLinked: e.PlaidLinked } });
        }
      });
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
      if(!selectedProperty) setIsLoading(true);
      else setIsLoading(false);
  },[selectedProperty]);
  return (
    <div className={"home-root"}>
      <Grid container spacing={0} direction="column">
        <Grid item>
          <Container component="main" className="home-main">
            <CssBaseline />
            <div className="home-paper">
              <Grid container spacing={1} className="rent-header-pad" >
                <Grid item>
                  {!isLoading && <Typography
                    component="h1"
                    color={"textPrimary"}
                    variant="h1"
                  >
                    Welcome
                  </Typography>}
                  {isLoading && <Skeleton variant="rectangular" width={247} height={69} />}
                </Grid>
                <Grid item>
                  {!isLoading && <Typography
                    component="h1"
                    color={"textSecondary"}
                    variant="h1"
                  >
                    {props.userSession.FirstName}
                  </Typography>}
                  {isLoading && <Skeleton variant="rectangular" width={200} height={69} />}
                </Grid>
              </Grid>
              {!isLoading &&
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Card className={"home-card"}>
                      <CardActionArea style={{ display: "flex" }} onClick={() => {
                        history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/payment`, props: { fromRenter: true, property: selectedProperty } });
                      }}>
                        <CardMedia
                          className={"home-cover"}
                          style={{ width: '186px', height: "186px", display: "flex" }}
                          image={makepaymentsvg}
                          title="Manage users here. Add, remove, update"
                        />
                        <div className={"home-details"}>
                          <CardContent className={"home-content"}>
                          <Typography component="h5" variant="h5">
                              MAKE PAYMENT
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary">
                              Pay the rent balance easily. Or make a one-time payment.
                            </Typography>
                          </CardContent>
                          <div className={"home-controls card-listing-f1"}>
                            <MLink variant="body2" underline="hover">
                              VIEW
                            </MLink>
                          </div>
                        </div>
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Card className={"home-card"}>
                      <CardActionArea style={{ display: "flex" }} onClick={() => {
                        history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/manage`, props: { fromRenter: true } });
                      }
                      }>
                        <CardMedia
                          className={"home-cover"}
                          style={{ width: '186px', height: "186px", display: "flex" }}
                          image={managecreditcardssvg}
                          title="Manage credit cards"
                        />
                        <div className={"home-details"}>
                          <CardContent className={"home-content"}>
                            <Typography component="h5" variant="h5">
                              MANAGE PAYMENT METHOD
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary">
                              Add or remove credit card details from your account.<br />
                              You can choose the primary credit card for the transaction.
                            </Typography>
                          </CardContent>
                          <div className={"home-controls card-listing-f1"}>
                            <MLink variant="body2" underline="hover">
                              VIEW
                            </MLink>
                          </div>
                        </div>
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Card className={"home-card"}>
                      <CardActionArea style={{ display: "flex" }} onClick={() => {
                        history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/history`, props: { fromRenter: true } });
                      }}  >
                        <CardMedia
                          className={"home-cover"}
                          style={{ width: '186px', height: "186px", display: "flex" }}
                          image={viewbillinghistorysvg}
                          title="View Billing History"
                        />
                        <div className={"home-details"}>
                          <CardContent className={"home-content"}>
                            <Typography component="h5" variant="h5">
                              VIEW BILLING HISTORY
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary">
                              You can view a complete billing history of your account.
                            </Typography>
                          </CardContent>
                          <div className={"home-controls card-listing-f1"}>
                            <MLink variant="body2" underline="hover">
                              VIEW
                            </MLink>
                          </div>
                        </div>
                      </CardActionArea>
                    </Card>
                  </Grid>
                </Grid>}
              {isLoading && <Grid container spacing={2}>
                {[1, 2, 3, 4].map(() => {
                  return <ListPropertyCards />
                })}
              </Grid>}
            </div>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}
