import React from "react";
import "./placeholder.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
//import MaterialTable from "material-table";
import { Skeleton } from '@mui/material';
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/CardMedia"; 
import CardActions from "@mui/material/CardActions";
 


const styles = {
  card: {
    maxWidth: 345
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: "cover"
  },
  actionArea: {
    "&:hover $focusHighlight": {
      opacity: 0
    }
  },
  focusHighlight: {}
};
export const HomeCards = (props) => { 
  return <> <Card className="home-card"> 
              <CardActionArea style={{ display: "flex" }}  >
                <div style={{ width: '170px', height: "186px", padding:"16px" }}>
                <Skeleton variant="rectangular" width={'156px'}  height={"156px"}  /></div>
                <div className="home-details">
                  <CardContent className="home-content">
                    <Typography component="h5" variant="h5">
                    <Skeleton variant={'text'}  height={"50px"} />
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                    <Skeleton variant={'text'}  height={"47px"} />
                  </Typography>
                  </CardContent>
                  <div className="home-controls">
                    <MLink variant="body2" underline="hover">
                    <Skeleton variant={'text'} width={'33px'} height={"33px"}  />
                  </MLink>
                  </div>
                </div>
              </CardActionArea>
            </Card>
  </>;
};

export const GridLoader = (props) => { 
    return (
          <>  
         {/*  <MaterialTable
                                        title=""
                                        columns={[ { title: "PROPERTY NAME", field: "Value", render: rowData =>{ return <Skeleton/>} },
                                        { title: "EMAIL", field: "Email", render: rowData =>{ return <Skeleton/>} },
                                        { title: "PHONE #", field: "Phone", render: rowData =>{ return <Skeleton/>} },
                                        { title: "ADDRESS", field: "BusinessAddress", render: rowData =>{ return <Skeleton/>} },
                                        { title: "TOTAL UNIT #", field: "PropertyUnit", render: rowData =>{ return <Skeleton/>} },
                                        { title: "Action", field: "Actions", render: rowData =>{ return <Skeleton/>} }]}
                                        data={[{},{},{},{},{},{},{},{},{},{}]}
                                        options={{
                                            searchFieldStyle: {
                                                display: 'none'
                                            },
                                            actionsColumnIndex: -1,
                                            search: false,
                                            showTitle: false,
                                            toolbar: false,
                                            pageSize: 10,
                                            pageSizeOptions: [10]
                                        }} 
                                    /> */}
          </>
      );
  };

export const PropertyGridLoader = (props) => { 
  return (
        <>  
        <div style={{ padding: "20px 0px 40px 0px" }}>
                              <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                  {/* <MaterialTable
                                      title=""
                                      columns={
                                        [ { title: "PROPERTY NAME", field: "Value", render: rowData =>{ return <Skeleton className={"skelton-grid-element"}/>} },
                                        { title: "EMAIL", field: "Email", render: rowData =>{ return <Skeleton className={"skelton-grid-element"}/>} },
                                        { title: "PHONE #", field: "Phone", render: rowData =>{ return <Skeleton className={"skelton-grid-element"}/>} },
                                        { title: "ADDRESS", field: "BusinessAddress", render: rowData =>{ return <Skeleton className={"skelton-grid-element"}/>} },
                                        { title: "TOTAL UNIT #", field: "PropertyUnit", render: rowData =>{ return <Skeleton className={"skelton-grid-element"}/>} },
                                        { title: "ACTION", field: "Action", render: rowData =>{ return <div className={"skelton-action-parent-div"}><Skeleton className={"skelton-action"}/> <Skeleton className={"skelton-action"}/> </div>} }]  
                                      }
                                      data={[{},{},{},{},{},{},{},{},{},{}]}
                                      options={{
                                          searchFieldStyle: {
                                              display: 'none'
                                          },
                                          actionsColumnIndex: -1,
                                          search: false,
                                          showTitle: false,
                                          toolbar: false,
                                          pageSize: 10,
                                          pageSizeOptions: [10]
                                      }} 
                                  /> */}
                                  </Grid>
                              </Grid>
                          </div>
        </>
    );
};


 

export const UserCreditCards = (props) => {
     
return (
  <Grid  item xs={12}>
                            <Card className={"skelton-manage-list-card skelton-credit-card-list"}>
                              <CardContent style={{ width: "100%" }}>
                              <Skeleton  variant="rectangular" height={20}/>
                              </CardContent>
                              <CardActions>
                                <Grid
                                  container
                                  direction="row">
                                  <Grid item xs={6}>
                                  <Skeleton variant="circular" width={40} height={40}/>
                                  </Grid>
                                  <Grid item xs={6}>
                                  <Skeleton variant="circular" width={40} height={40}/>
                                  </Grid>
                                </Grid>
                              </CardActions>
                            </Card>
                          </Grid>
);
}

export const ListPropertyCards = (props) => {
     
return (
  <Grid  item xs={12} sm={12} md={6} lg={6} xl={6}>

    <Card className="home-card">
      <CardActionArea
     /*   classes={{
        root: classes.actionArea,
        focusHighlight: classes.focusHighlight
      }}  */
      style={{ display: "flex", padding: "16px" }}>
        
          
      <div style={{ width: '170px', height: "186px", padding:"16px" }}>
                    <Skeleton variant="rectangular" width={'156px'}  height={"156px"}  /></div>
                  
        
                    <div className={"home-details"}>
                            <CardContent className={"home-content"}>
                            <Skeleton  className={"skelton-margin-bottom-4"} variant="rectangular" height={30}/>
                            <Skeleton  className={"skelton-margin-bottom-4"} variant="rectangular" height={30}/>
                            <Skeleton  className={"skelton-margin-bottom-4"} variant="rectangular" width={269} height={30}/>
                            </CardContent>
                            <div className={"home-controls"}>
                            <Skeleton  variant="rectangular" width = {33} height={20}/> 
                            </div>
                          </div>
                          
      </CardActionArea>
    </Card>
  </Grid>
);
}


export const MakePaymentRentCards = (props) => {
 
  return (
    <Grid  item xs={4}>
      <Card className={"skelton-make-payment-rent-cards"}>
        <CardActionArea>
          <CardContent
            className={"skelton-pad-8"}
          >
            <div
              style={{
                float: "left",
                padding: "6px"
              }}
            >
                <Skeleton variant="rectangular" width={61} height={61}/>
            </div>
            <div
            style={{
                paddingTop: "8px",
                paddingRight: "8px",
                float: "left"
              }}>
                <div
              style={{
                paddingRight: "8px",
              }}
              >
                <Skeleton variant="rectangular" width={100} height={20}
                  style={{
                    marginBottom: "4px",
                  }}
                
                />
              </div>

              <div>
              <Skeleton variant="rectangular" width={100} height={32}/>
            </div>
            </div>


          </CardContent>
          <div
            style={{
              position: "absolute",
              top: "22px",
              right: "10px"
            }}
          ></div>
        </CardActionArea>
      </Card>

    </Grid>
  );
}
