export default {
    aws_project_region: "us-east-2",
    aws_cognito_identity_pool_id:
      "us-east-2:34bb5a2a-7bdc-4865-8874-78db7bdd2b79",
    aws_cognito_region: "us-east-2",
    aws_user_pools_id: "us-east-2_tOoWW2YHp",
    aws_user_pools_web_client_id: "29du0drjoper8onf7vskn1n1ra",
    oauth: {},
    aws_dynamodb_all_tables_region: "us-east-2",
    aws_dynamodb_table_schemas: [
      {
        tableName: "LeaseMaster-devtwo",
        region: "us-east-2",
      },
    ],
    aws_user_files_s3_bucket: "resourcesleaseleads234103-devtwo",
    aws_user_files_s3_bucket_region: "us-east-2",
    management_name: "Development Two",
    management_logo: "stmsax8x9pr2bqv6qjt1wv.png",
    aws_cloud_logic_custom: [
      {
        name: "rentapi",
        endpoint:
          "https://s35fihdsvh.execute-api.us-east-2.amazonaws.com/devtwo",
        region: "us-east-2",
        custom_header: function () {
          return {
            "x-amz-pool": "us-east-2_tOoWW2YHp",
          };
        },
      },
    ],
    aws_content_delivery_bucket: "leaseleads-app-devtwo",
    aws_content_delivery_bucket_region: "us-east-2",
    aws_content_delivery_url: "dev2.leaseleads.app",
    aws_app_domain_prefix: "dev2",
    aws_user_pools_web_client_name: "dev2_app_clientWeb",
    aws_cognito_identity_pool_name: "dev2identity_pool",
  }