import React, {useState } from "react";
import { styled } from '@mui/material/styles';
import { Storage, API } from 'aws-amplify';
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import MLink from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import Typography from "@mui/material/Typography";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";
import FormControl from '@mui/material/FormControl';
import LoaderButton from ".././LoaderButton";
import portaLogo from "../../static/images/leaseleads_logo_Rectangle_3.png";
// import { openSnackbar } from '../../components/Notifier';
import { useSnackbar } from 'notistack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import md5 from 'crypto-js';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from "@mui/icons-material/Add";
import { AmplifyS3Image  } from '@aws-amplify/ui-react';
import Fab from "@mui/material/Fab";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import Card from "@mui/material/Card"
import CardMedia from "@mui/material/CardMedia"
import CardActions from "@mui/material/CardActions"
const PREFIX = 'adminRegistration';

const classes = {
  card1: `${PREFIX}-card1`,
  card2: `${PREFIX}-card2`,
  details: `${PREFIX}-details`,
  input: `${PREFIX}-input`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.card1}`]: {
    display: "flex",   
    marginRight: "auto",
    marginBottom: "30px",
    maxHeight:"215px",
    maxWidth:"215px"
  },

  [`& .${classes.card2}`]: {
    display: "flex",   
    marginRight: "auto",
    marginBottom: "30px",
    maxHeight:"315px",
    maxWidth:"315px"
  },

  [`& .${classes.details}`]: {
    display: "flex"   
  },
  [`& .${classes.input}`]: {
    display: 'none',
  },
}));

const MapData =window['countryConfig'];


const ImpStyles = makeStyles((
  {
    theme
  }
) => ({
  [`& .${classes.card1}`]: {
    display: "flex",   
    marginRight: "auto",
    marginBottom: "30px",
    maxHeight:"215px",
    maxWidth:"215px"
  },

  [`& .${classes.card2}`]: {
    display: "flex",   
    marginRight: "auto",
    marginBottom: "30px",
    maxHeight:"315px",
    maxWidth:"315px"
  },

  [`& .${classes.details}`]: {
    display: "flex"   
  }
}));

export default function AdminRegistration(props) {

  const { enqueueSnackbar } = useSnackbar();
  const [firstname, setfirstname] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [username, setusername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [domain, setdomain] = useState("");  
  const [managementName, setManagementName] = useState("");  
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [zip, setzip] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [website, setwebsite] = useState("");
  const [initialized, setInitialized] = useState(false);  
  const inputLabel = React.useRef(null);
 const [labelWidth, setLabelWidth] = useState(0);
 const [currentView, setCurrentView] = useState("Page1"); 
 const [availableDomains,setAvailableDomains]=useState([]);
 const [isAvailable, setIsAvailable] = useState(false); 
 const [comboDisable, setcomboDisable] = useState(true);
 const [country, setCountry] = useState("");
 const [state, setStateName] = useState(""); 
 const [ManagementLogo, setManagmentLogo] = useState({}); 
 const [deltedKey]=useState([]);
 const [stateData,setStateData]=useState([]);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
    if (!initialized) {
      getAvailableDomain();
    }
  }, [initialized]);
  function imageDelete(e) {
    
    let items = deltedKey;
    if (items.length > 0) {
      for (let i = 0; i < items; i++) {
        if (items[i].FileKey !== e.FileKey) {
          items.push(e.FileKey);
        }
      }
    }
    else {
      items.push(e.FileKey);
    }
    Object.assign(deltedKey, items);
    setManagmentLogo({})
    

  }
  const ImageComponent = props => {
    const classes = ImpStyles();
    return (<div>
      <Card className={classes.card1}>
        <div className={classes.details}>
          <CardActions>
            <Fab onClick={() => imageDelete(props)} style={{ color: "#000000", background: "none", boxShadow: "none", }} size="small" aria-label="delete picture" component="span">
              <CloseSharpIcon />
            </Fab>
          </CardActions>
        </div>
        <CardMedia
          className={classes.cover}>
          <AmplifyS3Image  style={{"--height": "100%","--width": "100%"}} imgProps={{display: "block",  width:100, height:100 }} level="public" imgKey={props.FileKey} />
        </CardMedia>
      </Card>
    </div>);
  };
  
  function getFileExtension(filename) {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }
  async function getAvailableDomain() {
    setInitialized(true);
    let apiName = 'rentapi';
    let path = '/store/availableDomain';
    let myInit = { headers: { 'Content-Type': 'application/json' } }
    try {
      var response = await API.get(apiName, path, myInit);
      setAvailableDomains(response);
    } catch (error) {
      console.log("fetch error", error); 
    }
  }
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

   function onChange (e,inputProps) {
     
    
    if(e.target.name==="firstname") setfirstname( e.target.value);
    if(e.target.name==="lastName") setlastName( e.target.value);
    if(e.target.name==="country") {
      setCountry( e.target.value);
      var selected=inputProps.props.alldata;   
      
      
      setcomboDisable(false);   
      setStateData(selected.states)      
    }
    if(e.target.name==="state") {
      setStateName( e.target.value);
    }
    if(e.target.name==="password") setpassword( e.target.value !== ""? md5.MD5(e.target.value).toString() :e.target.value); 
    if(e.target.name==="domain") {
      if(!availableDomains.includes(e.target.value) && e.target.value.length>=3)
      {
        console.log("Doman available")
        setIsAvailable(true);
      }
      else
      {
        setIsAvailable(false);
      }
      setdomain( e.target.value);
     
    }
    if(e.target.name==="managementName") {
      setManagementName( e.target.value);
      var dName=e.target.value.replace(/\s+/g, '').toLowerCase();
      
      if(!availableDomains.includes(dName))
      {
        setdomain(dName);
      }
      else
      {
        var randNo=getRandomInt(101,999).toString();
        
        setdomain(dName+randNo);
      }  
      setIsAvailable(true);  
    }
    if(e.target.name==="email") {
      setemail(e.target.value );
      setusername(e.target.value ); 
    };  
    if(e.target.name ==="ManagementLogo")
    {      
      const target=e.target;
            
      const file = target.files[0];     
      let targetName=target.name;
      var img = document.createElement("img");
      img.onload = function () {         
        
        if(targetName === "ManagementLogo" && this.width<=500 && this.height<=500)
        {
         
          let fileName=file.name;
          let fileExt=getFileExtension(fileName);
          let s3Key=(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15))+'.'+fileExt;
          const fileData={FileKey:s3Key,FileName:fileName};
          Storage.put(s3Key, file, {
              contentType:file.type 
          })
          .then (result =>{ 
            
            setManagmentLogo(fileData);
          })
          .catch(err => console.log(err));
        }
        else
        {
          // openSnackbar({ message: "Image size should be lesser than 500X500", variant: "error" }); 
          enqueueSnackbar("Image size should be lesser than 500X500", {variant: 'error'});                  
        }
      };
      var reader = new FileReader();
      reader.onloadend = function(ended) {
        img.src = ended.target.result;
      };
      reader.readAsDataURL( target.files[0]);
      
    }
    if(e.target.name==="address") setaddress( e.target.value); 
    if(e.target.name==="city") setcity( e.target.value); 
    if(e.target.name==="zip") setzip( e.target.value); 
    if(e.target.name==="phonenumber") setphonenumber( e.target.value); 
    if(e.target.name==="website") setwebsite( e.target.value); 
  };    
  function validateForm()  {    
    return username.length > 0 && firstname.length > 0 && lastName.length > 0 && email.length > 0 && password.length > 0;
  };
  function handleClearForm(){
    document.getElementById("admin-signup-form1").reset();
    //document.getElementById("admin-signup-form").reset();
  } 
  async function signUp(e) {
    e.preventDefault();
    setIsLoading(true);     
    var attrVal = {
       email: email,
       password:password,
       name: firstname + ' ' + lastName,
      "custom:firstname" : firstname,
      "custom:lastname" : lastName,
      "custom:privilege" : "ADMIN",
      "custom:domain":domain,
      "Country":country,
      "State":state,
      "PostalCode":zip,
      "City":city,
      "PhoneNumber":phonenumber,
      "BusinessAddress":address,
      "ManagementName":managementName
    };
    if(website && website.length>0) attrVal.Website=website;
    if(ManagementLogo.hasOwnProperty("FileKey") && ManagementLogo.FileKey.length>0) attrVal.ManagementLogo=ManagementLogo;
    //attrCogVal["custom:domain"] = domain;
    
    let apiName = "rentapi";
    let path = '/verify/adminRegistration';
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: attrVal     
    };
    

   API.post(apiName, path, myInit).then(response => { 
        
      if(response.success)
      {
        setIsLoading(false);
        // openSnackbar({ message: `Your email, ${email} has been successfully registered. 
        // Please confirm your email address by clicking the link in the confirmation we have sent you` , variant: "success" });    
        enqueueSnackbar(`Your email, ${email} has been successfully registered.
        Please confirm your email address by clicking the link in the confirmation we have sent you`, {variant: 'success'});
        handleClearForm();
        setTimeout(() => {
          props.history.push("/");
        }, 1000);
      }  
      else
      {
        setIsLoading(false);
        setCurrentView("Page1")
        // openSnackbar({ message:response.message, variant: "error" }); 
        enqueueSnackbar(response.message, {variant: 'error'});
      }
    }).catch(err=>{
      console.log(err);
      setIsLoading(false);
      setCurrentView("Page1")
      // openSnackbar({ message:err.message, variant: "error" }); 
      enqueueSnackbar(err.message, {variant: 'error'});
    });    
  };  
    function confirmRegistration() {
      return (
        <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <Grid container justifyContent="center" alignItems="center" spacing={1}>
            <Grid item>
              <img src={portaLogo} alt="" className={classes.myImage} />
            </Grid>
            <Grid item>
              <Typography className={classes.admRegHeader} component="h6">
              PROPERTY MANAGEMENT REGISTRATION
              </Typography>
            </Grid>
            <Grid item>
              <Typography component="h5">
               Before we begin, we created a dedicated url to access your account
              </Typography>
            </Grid>
          </Grid>
          <Root
            id="admin-signup-form1"
            className={classes.form}
            noValidate
            onSubmit={signUp}
          >           
            <Grid container alignItems="center" spacing={1}>     
            <Grid item>
               <TextField
                    variant="outlined"                 
                    fullWidth
                    required
                    defaultValue={domain}
                    name="domain"                     
                    onChange={onChange}
                    type="text"
                    id="domain"
                  /> 
              </Grid> 
              <Grid item>    
              <Typography component="h5">
               .leaseleads.com
              </Typography>
              </Grid>  
              <Grid item >  
              {isAvailable? <Button
                    variant="outlined"                   
                    className="successIcon"
                    style={{color:"green"}}
                    startIcon={<DoneIcon />}
                  >
                   Available                    
               </Button>: <Button
                    variant="outlined"                   
                    className="errorIcon"
                    style={{color:"red"}}
                    startIcon={<CloseIcon   />}
                  >
                    Not Available                    
               </Button>}  
                 
              </Grid>    
            </Grid>
            <div style={{ padding: "20px 0px 40px 0px" }}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <LoaderButton
                    block="true"
                    type="submit"
                    size="large"
                    isLoading={isLoading}
                    disabled={!isAvailable}
                  >
                   GO LIVE
                  </LoaderButton>
                </Grid>
              </Grid>
            </div>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <MLink
                  color="text.secondary"
                  underline={"always"}                   
                   onClick={e=>{setCurrentView("Page1")}}
                >
                  <Typography
                    color="text.secondary"
                    gutterBottom={true}
                    variant="body2"
                  >
                   BACK
                  </Typography>
                </MLink>
              </Grid>
            </Grid>
          </Root>
        </div>        
      </Container>
      );
    }
    return (currentView!=="Page1")?confirmRegistration():
    <Root>
      <form
      id="admin-signup-form"
      className={classes.form}
    >
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <Grid container justifyContent="center" alignItems="center" direction="column" spacing={2} style={{marginBottom:'30px'}}>
            <Grid item>
              <img src={portaLogo} alt="" className={classes.myImage} />
            </Grid>
            <Grid item>
              <Typography className={classes.admRegHeader} component="h6">
              PROPERTY MANAGEMENT REGISTRATION
              </Typography>
            </Grid>
          </Grid>                   
            <Grid container spacing={2}>     
            <Grid item xs={12}>
                <TextField
                  variant="outlined"                 
                  fullWidth
                  required
                  name="managementName"
                  label="MANAGEMENT NAME"
                  onChange={onChange}
                  type="text"
                  id="managementName"
                />
              </Grid> 
              
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="EMAIL"
                  onChange={onChange}
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="PASSWORD"
                  onChange={onChange}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoComplete="fname"
                  name="firstname"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstname"
                  label="FIRST NAME"
                  onChange={onChange}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="LAST NAME"
                  onChange={onChange}
                  name="lastName"
                  autoComplete="lname"
                />
              </Grid> 
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="address"
                  label="BUSINESS ADDRESS"
                  onChange={onChange}
                  name="address"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="city"
                  label="CITY"
                  onChange={onChange}
                  name="city"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="zip"
                  label="ZIP/POSTAL CODE"
                  onChange={onChange}
                  name="zip"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel ref={inputLabel} id="select-state-label">
                    COUNTRY/REGION
                  </InputLabel>
                  <Select
                          labelid="select-Country-label"
                          id="Country-select-outlined"
                          fullWidth
                          variant="outlined"
                          name="country"
                          label="COUNTRY/REGION"
                          value={country}
                          onChange={(e,alldata)=>{onChange(e,alldata);}}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {MapData.map((e, keyIndex) => {
                            return (
                              <MenuItem key={keyIndex} alldata={e} value={e.name}>
                                {e.name}
                              </MenuItem>
                            );
                          })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel ref={inputLabel} id="select-state-label">
                    STATE/PROVINCE
                  </InputLabel>
                  <Select
              labelid="select-state-label"
              id="state-select-outlined"
              fullWidth
              variant="outlined"
              name="state"
              label=" STATE/PROVINCE"
              value={state}
              disabled={comboDisable}
              onChange={onChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {stateData.map((e, keyIndex) => {
                return (
                <MenuItem key={keyIndex} value={e}>
                  {e}
                </MenuItem>
                );
              })}
            </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="phonenumber"
                  label="PHONE NUMBER"
                  onChange={onChange}
                  name="phonenumber"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="website"
                  label="WEBSITE (optional)"
                  onChange={onChange}
                  name="website"
                />
              </Grid>
              <Grid item xs={12} sm={12} >
          {ManagementLogo && ManagementLogo.hasOwnProperty("FileName")?
          <ImageComponent Type={"ManagementLogo"} FileKey={ManagementLogo.FileKey} FileName={ManagementLogo.FileName} /> 
          :
            <Grid container direction="row" spacing={3}>
              <Grid item xs={1}>                
                <input name='ManagementLogo'  onChange={(e) => onChange(e)} accept="image/*" className={classes.input} id="icon-button-file1" type="file" />
                <label htmlFor="icon-button-file2">
                  <Fab size="small" color="secondary" aria-label="upload picture" component="span">
                    <AddIcon />
                  </Fab>
                </label>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="h6">UPLOAD MANAGEMENT LOGO (if applicable)</Typography>
                <Typography variant="caption">500x500 - png, jpeg, gif</Typography>
              </Grid>
            </Grid>}
          </Grid>
            </Grid>
            <div style={{ padding: "20px 0px 40px 0px" }}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <LoaderButton
                    block="true"                   
                    size="large"
                    isLoading={isLoading}
                    disabled={!validateForm()} 
                  onClick={e => {
                    setCurrentView("Page2");
                  }}  
                  >
                    SETUP ACCOUNT
                  </LoaderButton>
                </Grid>
              </Grid>
            </div>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <MLink
                  color="text.secondary"
                  underline={"always"}
                  onClick={e => {
                    props.history.push("/");
                  }}                   
                >
                  <Typography
                    color="text.secondary"
                    gutterBottom={true}
                    variant="body2"
                  >
                    BACK
                  </Typography>
                </MLink>
              </Grid>
            </Grid>
        
        </div>        
      </Container>
      </form>
    </Root>
  }
