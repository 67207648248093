import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import "./spinner.css";
import CircularProgress from "@mui/material/CircularProgress";
export const Spinner = (props) => {
  const { promiseInProgress } = usePromiseTracker({area: props.area, delay: 0});

  return (
    (props.isLoading ||  promiseInProgress) && (
      <div className="common-spinner">
        <CircularProgress  size={50} />
      </div>
    )
  );
};

