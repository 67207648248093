import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import { API } from 'aws-amplify';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Moment from 'react-moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { LoaderCLickButton } from '../LoaderButton';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CustomizedTooltips from '../../lib/tooltIp';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router-dom';
import usePersistedState from '../../usePersistedState';
import { Skeleton, ListItem, List, ListItemText } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
import moment from 'moment';
//** Data Grid Import Part **/
import {
  LicenseInfo,
  DataGridPro,
  GridOverlay,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  getGridSingleSelectOperators
} from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

export const CustomTextField = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
const PREFIX = 'managerents';
const MapData = (process.env.REACT_APP_STAGE === "dev") ? awsdev : (process.env.REACT_APP_STAGE === "dev2") ? awsdev2 : window['runConfig'];

const classes = {
  tabindicator: `${PREFIX}-tabindicator`
};

const DialogBox = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));

const DialogTitle = (props => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography sx={{ m: 0, p: 2, pl: 3, pr: 3 }}  {...other}>
      <Typography variant="h3" component="h3" sx={{ lineHeight: 1 }}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: "8px",
            top: "8px",
            color: "text.secondary",
          }}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.tabindicator}`]: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#F8F8F8'
    },
  }
}));
const CustomDataGridPro = styled(DataGridPro)(({ theme }) => ({
  minHeight: '132px',
  '& .MuiDataGrid-dataContainer, & .MuiDataGrid-viewport': {
    minWidth: 'auto!important'
  },

  '& .MuiDataGrid-viewport': {
    width: 'fit-content',
    maxWidth: 'none!important',
    minWidth: '100%!important'
  },

  '& .MuiDataGrid-viewport, & .MuiDataGrid-renderingZone, & .MuiDataGrid-row': {
    maxHeight: 'fit-content!important',
  },

  '& .MuiDataGrid-renderingZone': {
    transform: 'none!important',
    marginRight: '-16px'
  },
  "& .MuiDataGrid-columnHeaderTitle, & .MuiDataGrid-cell": {
    textOverflow: 'unset',
    whiteSpace: 'normal',
    lineHeight: '1.2!important',
    maxHeight: 'fit-content!important',
    minHeight: 'auto!important',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',

    '& > div': {
      maxHeight: 'inherit',
      width: '100%',
      whiteSpace: 'initial',
      lineHeight: '1'
    }
  },
  '& .MuiDataGrid-columnHeader > div': {
    height: '100%'
  },

  '& .MuiDataGrid-columnHeaderWrapper': {
    maxHeight: 'none!important',
    flex: '1 0 auto',
  },

  '& .MuiDataGrid-row .MuiDataGrid-columnsContainer': {
    maxHeight: 'none!important'
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    height: '100% !important',
    /* overflow: 'scroll' */
  },
  '& .MuiDataGrid-cell': {
    overflowWrap: 'anywhere',
    padding: '0 10px',

    '&--textRight div': {
      textAlign: 'right',
      justifyContent: 'flex-end'
    },

    '&:last-of-type > div': {
      paddingRight: theme.spacing(3)
    },

    '& > div': {
      padding: '0.75em',
      display: 'flex',
      alignSelf: 'stretch',
      alignItems: 'center'
    }
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

//** Data Grid Action related Code */
LicenseInfo.setLicenseKey(
  '79e390e23cc1709a04137710c7c5cdc1T1JERVI6Mjg0NDQsRVhQSVJZPTE2NjEwMDE3MTQwMDAsS0VZVkVSU0lPTj0x',
);

const MAX_ROW_LENGTH = 1500;

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <Root style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </Root>
    </GridOverlay>
  );
}
function CustomToolbar(props) {
  return (
    <GridToolbarContainer>
      <Grid container
        spacing={3}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item minWidth="320px" >
          {!props.loading && <TextField
            variant="outlined"
            fullWidth
            display="flex"
            label="Search email, name or unit #.."
            onBlur={props.onBlur}
            onKeyDown={props.onKeyDown}
            onChange={props.onChange}
            value={props.value}
            InputProps={{ 
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: props.value ? 'visible' : 'hidden' }}
                  onClick={props.onBlur}
                >
                  <SearchIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />}
          {props.loading && <Skeleton variant="rectangular" height={52} />}
        </Grid>
        <Grid item >
          {props.loading && <div style={{ display: "flex" }}>
            <Skeleton variant="rectangular" width={30} height={32} className={"skelton-margin-right-5"} />
            <Skeleton variant="rectangular" width={100} height={32} />
          </div>}
          {!props.loading && <GridToolbarFilterButton ref={props.setFilterButtonEl}/>}
        </Grid>
        <Grid item>
          {props.loading && <div style={{ display: 'flex' }}>
            <Skeleton variant='rectangular' width={30} height={32} className={'skelton-margin-right-5'} />
            <Skeleton variant='rectangular' width={100} height={32} />
          </div>}
          {!props.loading && <GridToolbarExport
            csvOptions={{
              fileName: props.customFileName,
              delimiter: ',',
              utf8WithBom: true,
            }}
          />}
        </Grid>
        <Grid item>
            
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

//** Data Grid Action related Code */

export function ManageRents(props) {
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory(); 
  const mounted = React.useRef(true);
  const tableRef = React.createRef(); 
  const [isLoading, setIsLoading] = useState(false);
  const [currentLease, setCurrentLease] = React.useState({});
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [selectedProperty] = usePersistedState('selectedProperty', {});

  //** Data Grid Related Code */
  const apiRef = useGridApiRef();
  const [filterButtonEl, setFilterButtonEl] = React.useState(null);
  const [filterValue, setFilterValue] = React.useState({}); 
  const [searchText, setSearchText] = React.useState('');
  const [queryOptions, setQueryOptions] = React.useState({});
  const [data, setData] = useState({
    loading: true,
    rows: [],
    pageSize: 25,
    columns: [
      {
        headerName: 'UNIT #', field: 'RentUnit', filterable: false, sortable: false, flex: 0.5, minWidth: 70, maxWidth: 70
      },
      { field: 'MasterID', hide: true, filterable: false },
      {
        headerName: 'LEASE HOLDER(S)', field: 'CoRentersName', flex: 1, minWidth: 250, renderCell: (params) => {
          return listCoRenters(params)
        }
      },
      {
        headerName: 'LEASE START', field: 'RentStartDate', flex: 0.65, minWidth: 100, sortable: false, filterable: false,
        renderCell: (params) => {
          return (<Moment format='YYYY-MM-DD'>{params.value}</Moment>)
        }
      }, {
        headerName: 'LEASE END', field: 'RentEndDate', flex: 0.5, minWidth: 100, sortable: false, filterable: false,
        renderCell: (params) => {
          return (<Moment format='YYYY-MM-DD'>{params.value}</Moment>)
        }
      },
      {
        headerName: 'SECURITY', field: 'RentSecurityDeposit', flex: 0.5, minWidth: 120, filterable: false,
        renderCell: (params) => {
          return params.value === 0 ? '-' : <div> {`$${params.value}`} </div>
        }
      }, {
        headerName: 'MONTHLY RENT', field: 'RentEachMonth', flex: 0.65, minWidth: 100, filterable: false,
        renderCell: (params) => {
          return params.value === 0 ? '-' : <div> {`$${params.value}`} </div>
        }
      }, {
        headerName: 'FEE', field: 'RentMiscFee', flex: 0.75, minWidth: 120, filterable: false,
        renderCell: (params) => {
          return infoButtonMisc(params)
        },
      },
      {
        headerName: "TOTAL RENT OWING", field: "RentOwing", filterable: false,
        renderCell: params => { return !params.value || params.value === 0 ? "-" : "$" + params.value }
      },
      {
        headerName: "OUTSTANDING BALANCE", field: "RentOutStanding", filterable: false,
        renderCell: params => { return !params.value || params.value === 0 ? "-" : "$" + params.value }
      },
      {
        headerName: 'LEASE ACTIVITY',sortable: false, field: 'RentStatus', flex: 1, minWidth: 200,
        type: 'singleSelect',
        filterOperators: getGridSingleSelectOperators().filter(
          (operator) => operator.value === 'is',
        ),
        renderCell: (params) => {
          return (<CustomTextField variant='outlined' inputProps={{ min: 0, style: { textAlign: 'center', WebkitTextFillColor: getRentStatus(params) == 'Expired' ? '#cbcbcb' : '#6100ED' } }} disabled={true} className={getRentStatus(params) == 'Expired' ? 'biller-lease-activity-tf-dsbl' : 'biller-lease-activity-tf'} value={getRentStatus(params)} > </CustomTextField>)
        },
        valueOptions: ['All','Active', 'Expired', 'Month-To-Month']
      },
      {
        headerName: 'ACTIONS',
        field: 'actions',
        sortable: false,
        width: 150,
        headerAlign: 'center',
        filterable: false,
        align: 'center',
        disableColumnMenu: true,
        disableExport: true,
        disableReorder: true,
        renderCell: params => { return RowMenuCell({ ...params, ...props, selectedProperty: selectedProperty, gridRefresh: gridRefresh }) }
      }
    ]
  }); 
  const handleSortModelChange = React.useCallback((sortModel) => { 
    console.log('sort model change');
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);
  var cusFileName = MapData.aws_app_domain_prefix + "-" + selectedProperty.PropertyName?.replace(/ /g, "_") + "-" + moment().format('MMMM-DD-YYYY');
  const onFilterChanges = React.useCallback((filterModel) => { 
    setFilterValue(filterModel);
    mounted.current = true;
  }, []);
  const requestSearch = async (searchValue) => {
    setSearchText(searchValue);
  };
  const handleKeyDown = async (event) => { 
    const ENTER_KEY = 13;
    if (event.keyCode === ENTER_KEY /* && searchText && searchText!=='' */) { 
      setIsLoading(true);
      updateData("loading", true);
      const newData = await GetInfinityTable(data.pageSize, 0);
      if (newData?.data)
        updateData("rows",newData.data);
      updateData("loading", false);
      setIsLoading(false);
    }
  };
  const handleBlur = async (event) => {
    if (searchText /* && searchText !== '' */) {
      setIsLoading(true);
      updateData("loading", true);
      const newData = await GetInfinityTable(data.pageSize, 0);
      if (newData?.data)
        updateData("rows", newData.data);
      updateData("loading", false);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    let active = true;
    (async () => { 
      updateData('loading', true);
      const newData = await GetInfinityTable(data.pageSize, 0); 
      if (mounted.current) {
        if (newData?.data)
          updateData('rows', newData.data);
      }
      if (!active) {
        return;
      }
      updateData('loading', false);
    })();
    return () => {
      active = false;
    };
  }, [filterValue]);
  useEffect(() => {
    console.log('queryOptions $$$$',queryOptions);
    let active = true;
    (async () => { 
      updateData('loading', true);
      const newData = await GetInfinityTable(data.pageSize, 0); 
      if (mounted.current) {
        if (newData?.data)
          updateData('rows', newData.data);
      }
      if (!active) {
        return;
      }
      updateData('loading', false);
    })();
    return () => {
      active = false;
    };
  }, [queryOptions]);
  const updateData = (k, v) => setData((prev) => ({ ...prev, [k]: v }));
  const loadServerRows = async (newRowLength) => { 
    updateData("loading", true);
    const newData = await GetInfinityTable(data.pageSize, newRowLength);
    if (mounted.current) {
      if (newData && newData.data)
        updateData("rows", data.rows.concat(newData.data));
    }
    updateData("loading", false);
  };
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  const handleOnRowsScrollEnd = (params) => {
    console.log("Event on scrolle params");
    console.log(params);
    if (data.rows.length <= MAX_ROW_LENGTH && data.rows.length !== 0) {
      loadServerRows(data.rows.length);
    }
  };

  async function GetInfinityTable(pageSize, fromNo) {
    updateData('loading', true);
    updateData('pageSize', pageSize);
    setIsLoading(true);
    let body = {
      masterType: 'Rent',
      property: selectedProperty,
      UserID: props.userSession.UserName,
      Status: 'All',
      filterValue: filterValue,
      searchValue: searchText,
      pageSize: pageSize,
      fromNo: fromNo,
      userData: props.userSession,
      sortModel: queryOptions.sortModel
    };
    return new Promise((resolve, reject) => {
      let apiName = 'rentapi';
      let path = '/modules';
      let myInit = {
        headers: { 'Content-Type': 'application/json' },
        body: body,
        queryStringParameters: {
          module: 'biller',
          op: 'listRents'
        }
      };
      API.post(apiName, path, myInit).then(response => {
        setIsLoading(false); 
        resolve(response.data);
      }).catch(err => {
        setIsLoading(true);
        resolve([])
        console.log(err); reject(err);
      });
    });
  }
  const gridRefresh = async () => {
    mounted.current = true;
    updateData('loading', true);
    const newData = await GetInfinityTable(data.pageSize, 0);
    if (newData?.data)
      updateData('rows', newData.data);
    updateData('loading', false);
  }
  //** Data Grid Related Code END */
  
  /**
 *  Function will handle dialog close event
 * @param {*} e -dialog type 
 */
  const handleClose = (e) => {
    if (e === 'delete') {
      setDeleteOpen(false);
      setCurrentLease({});
    }
  };
 
  async function deleteRent() {
    setIsLoading(true);
    let apiName = 'rentapi';
    let path = '/modules';
    let myInit = {
      headers: { 'Content-Type': 'application/json' },
      body: { 'MasterID': currentLease.MasterID, 'userData': props.userSession },
      queryStringParameters: {
        module: 'biller',
        op: 'deleteRent'
      }
    };
    await API.post(apiName, path, myInit).then(response => {
      if (response.success) {
        setDeleteOpen(false);
        setCurrentLease({});
        setIsLoading(false); 
        enqueueSnackbar(response.message, { variant: "success" });
        if (tableRef.current)
          tableRef.current.onQueryChange();
        setIsLoading(false);
      }
      else {
        setIsLoading(false); 
        enqueueSnackbar(response.message, { variant: "error" });
      }

    }).catch(err => {
      console.log(err);
      setIsLoading(false); 
      enqueueSnackbar(err.message, { variant: "error" });
    });
  } 
  var infoButtonMisc = (params) => {
    const rowData = params.api.getRow(params.id);
    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
      > <Grid item xs={5}>
          <Typography className='biller-wrapper'> {rowData.Miscs && rowData.Miscs.length ? (rowData.Miscs[0].appFeeType == 'fixedAmount' ? '$' : ' ') + rowData.Miscs[0].appFeeValue + ' ' + (rowData.Miscs[0].appFeeType == 'percentage' ? ' %' : '') : '-'}</Typography>
        </Grid>
        <Grid item xs={7}>
          {rowData.Miscs && rowData.Miscs.length ?
            <CustomizedTooltips style={{ float: 'left' }} content={rowData.Miscs && rowData.Miscs.length ? rowData.Miscs[0].activeDatePlan == 'oneTimePlan' ? 'Expires next payment' : 'Expires ' + (new Date(rowData.Miscs[0].validTo)).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : ''} />
            : null}
        </Grid>
      </Grid>
    );
  }
  var listCoRenters = (params) => {
    const rowData = params.api.getRow(params.id);
    return (
      <List dense={false}>
        {rowData.CoRenters.map((element,index) => {
          return (
            <ListItem key={index} sx={{ py: 0, pt: 0, pb: 0 }}>
              <Tooltip title={element.RenterMailID}>
                <ListItemText sx={{ width: '100%', py: 0, mt: 0, mb: 0, pr: 0.5 }}
                  primary={element.RenterName}
                />
              </Tooltip>
            </ListItem>
          )
        })
        }
      </List>
    );
  } 
  function getRentStatus(params) {
    const rowData = params.api.getRow(params.id);
    let currentTimeStamp = new Date().setHours(23, 59, 59, 0);
    let incomingTimeStamp = new Date(rowData.RentEndDate).getTime();
    let currentStatus = '' 
    if (currentTimeStamp > incomingTimeStamp) {
      if (rowData.RentAfterLeaseEnd == 'MONTH-TO-MONTH') currentStatus = 'Month-To-Month';
      if (rowData.RentAfterLeaseEnd == 'TERMINATE') currentStatus = 'Expired';
    }
    else {     
      currentStatus = rowData.RentStatus // rowData.RentStatus
    } 
    return currentStatus;
  } 

  return (
    <Root className='home-root'>
      <Grid container
        spacing={0}
        direction='column'
      >
        <Grid item>
          <Container component='main' sx={{ paddingBottom: 6 }} className='home-main'>
            <CssBaseline /> 
            <div className='home-paper'>
              <DialogBox open={deleteOpen} onClose={e => handleClose('delete')} fullWidth={true}
                maxWidth={'sm'} aria-labelledby='form-dialog-title'>
                <DialogTitle id='delete-dialog-title' onClose={e => handleClose('delete')}>
                  Deletion Confirmation
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    We just want to make sure this is the correct decision. This is irreversible action and the rent data will be removed from our system immediately
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <LoaderCLickButton
                    //size='large'
                    onClick={async e => await deleteRent()}
                    isLoading={isLoading}
                  >
                    YES
                  </LoaderCLickButton>

                </DialogActions>
              </DialogBox>
              <Grid container spacing={1} className={'add-padding-p8p'} >
                <Grid item>
                  <Button onClick={e => {
                    history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}`);
                  }}>
                    <Typography
                      component="h6"
                      sx={{ fontWeight: 600 }}
                      color="text.primary"
                      variant="h6"
                    >
                      &#8249; {" RETURN"}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid container
                spacing={2}
                direction='row'
                justifyContent='space-between'
                alignItems='center'>
                <Grid item xs={12}>
                  {!isLoading &&
                    <Typography
                      component='h1'
                      className='biller-headerOne biller-fontweight'
                      variant='h1'
                    >
                      Manage Lease {/* { props.userSession.Privilege === 'ADMIN' && selectedProperty? '- ' + selectedProperty.PropertyName : '' } QA: v2 : line number 52*/}
                    </Typography>
                  }
                  {isLoading &&
                    <div className={'skelton-flex-parent'}>
                      <Skeleton variant='rectangular' width={588} height={69} className={'skelton-margin-right-5'} />
                      <Skeleton variant='rectangular' width={588} height={69} />
                    </div>
                  }
                </Grid>
              </Grid>
              {(props.userSession.Privilege === "MANAGER" || props.userSession.Privilege === 'ADMIN') && <Grid container
                spacing={2}
                maxwidth='50%'
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
                className={'add-margintop-p8p'}
              >
                <Grid item>
                  {!isLoading &&
                    <Fab size='small' sx={{
                      '&.MuiFab-root': {
                        backgroundColor: "secondary.main",
                        borderRadius: '50%',
                      },
                    }} aria-label='edit' onClick={() => {

                      history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/lease/add`, props: { editLease: false, property: selectedProperty, currentLease: {} } });
                    }}>
                      <PersonOutlineIcon />

                    </Fab>
                  }
                  {isLoading && <Skeleton variant='circular' width={45} height={45} />}
                </Grid>
                <Grid item>
                  {!isLoading &&
                    <Typography
                      component='h5'
                      style={{ cursor: "pointer"}}
                      variant='h5'
                      onClick={() => {   history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/lease/add`, props: { editLease: false, property: selectedProperty, currentLease: {} } });
                      }}
                    >
                      ADD LEASE

                    </Typography>
                  }
                  {isLoading && <Skeleton variant='rectangular' width={94} height={32} />}
                </Grid>
              </Grid>}
              <Grid container
                spacing={2}
                direction='row'
                justifyContent='flex-start'
                alignItems='center'>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {!isLoading &&
                    <Typography className='biller-headerfour add-margintop-p8p add-padding-p8p'
                      variant='subtitle1'
                    >
                      You can add, delete and modify rent details here
                    </Typography>
                  }
                  {isLoading && <Skeleton className={'add-margintop-p8p add-padding-p8p'} variant='rectangular' width={262} height={19} />}
                </Grid>
              </Grid>
              {/* DataGridPro Start */}
              <div style={{ height: 1024, width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                  <div style={{ flexGrow: 1 }}>
                    <CustomDataGridPro
                      apiRef={apiRef}
                      autoHeight={false}
                      headerHeight={50}
                      scrollbarSize={25}
                      disableExtendRowFullWidth={false} 
                      disableColumnResize={true}
                      disableColumnReorder={true}
                      hideFooter={true}
                      hideFooterPagination={true}
                      loading={data.loading}
                      onRowsScrollEnd={handleOnRowsScrollEnd}
                      onSortModelChange={handleSortModelChange}
                      rows={data.rows}
                      getRowId={(r) => r.MasterID}
                      columns={data.columns}
                      columnBuffer={data.columns.length || 5}
                      filterMode='server'
                      onFilterModelChange={onFilterChanges}
                      componentsProps={{
                        panel: {
                          anchorEl: filterButtonEl,
                        },
                        toolbar: {
                          setFilterButtonEl:  setFilterButtonEl,
                          filterPanel: { linkOperators: ['and'] },
                          value: searchText,
                          onChange: (event) => requestSearch(event.target.value),
                          onBlur: (event) => handleBlur(event),
                          onKeyDown: (event) => handleKeyDown(event),
                          loading: data.loading,
                          customFileName: cusFileName
                        },
                      }}
                      components={{
                        LoadingOverlay: CustomLoadingOverlay,
                        Toolbar: CustomToolbar
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Grid>
      </Grid>
    </Root>
  );
}
function RowMenuCell({ api, id, row, userSession, selectedProperty, gridRefresh, ...rest }) {
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false); 
  const [deleteOpen, setDeleteOpen] = useState(false); 
  async function deleteRent() {
    setIsLoading(true);
    let apiName = 'rentapi';
    let path = '/modules';
    let myInit = {
      headers: { 'Content-Type': 'application/json' },
      body: { 'MasterID': row.MasterID, 'userData': userSession },
      queryStringParameters: {
        module: 'biller',
        op: 'deleteRent'
      }
    };
    await API.post(apiName, path, myInit).then(response => {
      if (response.success) {
        setDeleteOpen(false);
        setIsLoading(false); 
        enqueueSnackbar(response.message, { variant: "success" });
        setTimeout(() => {
          gridRefresh();
        }, 500);
        setIsLoading(false);
      }
      else {
        setIsLoading(false); 
        enqueueSnackbar(response.message, { variant: "error" });
      }

    }).catch(err => {
      console.log(err);
      setIsLoading(false); 
      enqueueSnackbar(err.message, { variant: "error" });
    });
  }
  const handleClose = (e) => {
    if (e === 'delete') {
      setDeleteOpen(false);
    }
  };

  return (
    <div className={classes.root}>
      <DialogBox open={deleteOpen} onClose={e => handleClose('delete')} fullWidth={true}
        maxWidth={'sm'} aria-labelledby='form-dialog-title'>
        <DialogTitle id='delete-dialog-title' onClose={e => handleClose('delete')}>
          Deletion Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            We just want to make sure this is the correct decision. This is irreversible action and the rent data will be removed from our system immediately
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container
            spacing={2}
            direction='row'
            justify='flex-start'
            alignItems='flex-start'>
            <Grid item xs={12}>
              <LoaderCLickButton
                //size='large'
                onClick={async e => await deleteRent()}
                isLoading={isLoading}
              >
                YES
              </LoaderCLickButton>
            </Grid>
          </Grid>

        </DialogActions>
      </DialogBox>
      <Tooltip title="Edit">
      <IconButton
        color='inherit'
        className={classes.textPrimary}
        size='small'
        disabled={row.UserStatus === 'Deleted'}
        aria-label='edit'
        onClick={(event) => {
          event.stopPropagation();
          history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/lease/edit`, props: { editLease: true, currentLease: row } });
        }}
      >
        <EditIcon fontSize='small' />
      </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
      <IconButton
        color="inherit"
        className={classes.textPrimary}
        size='small'
        disabled={row.UserStatus === 'Deleted'}
        aria-label='delete'
        onClick={(event) => {
          event.stopPropagation();
          setDeleteOpen(true);
        }}
      >
        <DeleteIcon fontSize='small' />
      </IconButton>
      </Tooltip>
    </div>
  );
}