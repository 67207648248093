import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import InputBase from '@mui/material/InputBase';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";  
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import InputLabel from "@mui/material/InputLabel";
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select'; 
import MenuItem from '@mui/material/MenuItem'; 
import { Skeleton } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import usePersistedState from '../../usePersistedState';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import MuiDialogTitle from '@mui/material/DialogTitle'; 
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'; 
import { useSnackbar } from 'notistack';
import { API } from "aws-amplify";
import Divider from '@mui/material/Divider';
import { LoaderCLickButton } from "../LoaderButton";
import { LoaderDeleteButton } from "../LoaderButton"; 
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'; 
import { number } from "yup";

const months = {
  "January": 0,
  "February": 1,
  "March": 2,
  "April": 3,
  "May": 4,
  "June": 5,
  "July": 6,
  "August": 7,
  "September": 8,
  "October": 9,
  "November": 10,
  "December": 11
}

const DialogBox = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));

const DialogTitle = (props => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle sx={{ m: 0, p: 2, pl: 3, pr: 3 }}  {...other}>
      <Typography variant="h3" component="h3" sx={{ lineHeight: 1 }}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: "8px",
            top: "8px",
            color: "text.secondary",
          }}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function AddPaymentOffline(props) { 
  const { enqueueSnackbar } = useSnackbar(); 
  let history = useHistory();
  const location = useLocation();
  const [offlinePayment, setOfflinePayment] = useState({});
  const [paidByList, setPaidByList] = React.useState(props.rowData && props.rowData.paidByList?props.rowData.paidByList:[]);
  const [labelWidth, setLabelWidth] = useState(0);
  const [isSecurityDepositExist, setIsSecurityDepositExist] = useState(true);
  const inputLabel = React.useRef(null);
  const [outstandingMonth, setOutstandingMonth] = useState(props.rowData && props.rowData.outstanding ? props.rowData.outstanding
    : props.rowData && props.rowData.rentMonth ? [props.rowData.rentMonth] : []); 
 
  const [addOfflinePayment, setAddOfflinePayment] = React.useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = usePersistedState('selectedProperty', {});
  const [propertyData, setPropertyData] = usePersistedState('propertyData', {});
  const [leaseHolderList, setLeaseHolderList] = React.useState([]);
  const [tenantList, setTenantList] = React.useState([]);
  useEffect(() => {
    const propertyData = localStorage.getItem("selectedProperty");
    setPropertyData(propertyData ? JSON.parse(propertyData) : {});
  }, []);
  useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, [inputLabel.current]);
  useEffect(() => { 
    setIsLoadingModal(props.isLoading);
  }, [props.isLoading]);
  useEffect(() => { 
    if(props.addOfflinePayment)
      console.log('props.addOfflinePayment',props.addOfflinePayment);
        setAddOfflinePayment(props.addOfflinePayment); 
  }, [props.addOfflinePayment]);
  useEffect(() => { 
    if(!addOfflinePayment)
        props.setAddOfflinePayment(addOfflinePayment); 
  }, [addOfflinePayment]);
  
  useEffect(() => { 
    setOfflinePayment(props.rowData);
    setOutstandingMonth(props.rowData && props.rowData.outstanding ? props.rowData.outstanding : [])
    setPaidByList(props.rowData && props.rowData.paidByList ? props.rowData.paidByList : []);
  },[props.rowData])

  useEffect(() => { 
  },[offlinePayment]);

  function onChange(e, child) { 
    let ob = {}; 
    if (e.target && e.target.name) { 
      if (e.target.name === 'proceedPayMiscFee' || e.target.name === 'proceedSecurityDeposit') {
        ob[e.target.name] = e.target.checked;
      }
      else ob[e.target.name] = e.target.value

      setOfflinePayment({ ...offlinePayment, ...ob })
      if (child && child.props && child.props.id && e.target.name !== 'rentMonth') setIsSecurityDepositExist(child.props.id.isSecuriteDepositExist);       
      handleChildClick(e.target.name, e.target.value, child && child.props && child.props.id ? child.props.id : null, e.target.checked);
    }
  }
  function validateForm() {
    console.log("-------Executing Validation--------");
    console.log(offlinePayment);
    return (
      offlinePayment && 
      offlinePayment.rentAmount &&
      offlinePayment.rentMonth > -1 &&
      offlinePayment.tenant &&
      offlinePayment.transactionDate &&
      offlinePayment.transactionType &&
      offlinePayment.leaseHolder
    );
  }
  function saveOfflinePayment() { 
    if (offlinePayment && offlinePayment.rentAmount &&
      offlinePayment.rentMonth > -1 &&
      offlinePayment.tenant &&
      offlinePayment.transactionDate &&
      offlinePayment.transactionType &&
      offlinePayment.leaseHolder
    ) { 
      let ob = {};
      ob.rentAmount = offlinePayment.rentAmount;
      ob.rentMonth = offlinePayment.currentPayedRentMonth.Month;
      ob.transactionDate = offlinePayment.transactionDate;
      ob.miscFee = offlinePayment.proceedPayMiscFee ? offlinePayment.miscFee : 0;
      // ob.transactionDate = offlinePayment.transactionDate;
      ob.transactionType = offlinePayment.transactionType;
      ob.CurrentUserID = offlinePayment.tenant;
      ob.PropertyID = propertyData.PropertyID;
      ob.PropertyName = propertyData.PropertyName;
      ob.Tax = propertyData.Tax;
      ob.unit = offlinePayment.unit;
      ob.securityDeposit = offlinePayment.proceedSecurityDeposit ? offlinePayment.securityDeposit : 0;
      ob.transactionId = offlinePayment.transactionId;
      ob.leaseId = offlinePayment.leaseHolder;
      ob.currentPayedRentMonth = offlinePayment.currentPayedRentMonth;

      let fullYear = new Date(ob.transactionDate).getFullYear();
      let month = Object.keys(months).find(key => months[key] === Number(ob.rentMonth));
      ob.rentMonthString = `${month} ${fullYear}`;

      let currentHolder = props.leaseHolderList.find(x => x.leaseMasterId == offlinePayment.leaseHolder);
      ob.leaseHolder = currentHolder.leaseHolders; 
      return new Promise((resolve, reject) => {
        let apiName = "rentapi";
        let path = "/modules";
        let op = offlinePayment?.isEditing ? "updateOfflinePayment" : "saveOfflinePayment";
        let apiModule = "renter";
        let myInit = {
          headers: { "Content-Type": "application/json" },
          body: ob,
          queryStringParameters: {
            module: apiModule,
            op: op
          }
        };
        API.post(apiName, path, myInit)
          .then(async response => { 
            enqueueSnackbar(offlinePayment?.isEditing ? "Payment updated successfully" : "Payment added successfully", {variant: "success" });
            setAddOfflinePayment(false)
            setIsLoadingModal(false);
            setOfflinePayment({});
            resolve();
          })
          .catch(err => {
            console.log(err);
            enqueueSnackbar("Payment adding failed", {variant: "error"});  
            reject([]);
          });
      });
    }
    else { 
      setIsLoadingModal(false);
    }
  }
  async function handleChildClick(fieldName, value, child, checkBoxValue) { 
    console.log("++++++++++++ Field change is working ++++++++++++++");
    console.log(offlinePayment);
    /* if (offlinePayment) {
      offlinePayment.totalAmountTobePaid = 0;
    } */
    let ob = {};
    ob[fieldName] = value;
    ob.totalAmountTobePaid = offlinePayment?.totalAmountTobePaid ? offlinePayment.totalAmountTobePaid : 0;
    if (fieldName === 'leaseHolder') {
      ob.totalAmountTobePaid = 0;
      let currentHolder = props.leaseHolderList.find(x => x.leaseMasterId == value); 
      ob.outstanding = currentHolder.outstanding ? currentHolder.outstanding : [];
      ob.currentLeaseId = currentHolder.leaseMasterId;
      ob.currentRentAmount = 0;
      ob.rentAmount = 0;
      let nextOutstanding = null;
      if (ob.outstanding.length) {
        nextOutstanding = ob.outstanding.filter(f => f.IsPaid == false); 
        if (nextOutstanding.length) nextOutstanding = nextOutstanding[0];
        ob.rentAmount = nextOutstanding.Rent;
        ob.nextOutstanding = nextOutstanding;
        ob.rentMonth = nextOutstanding.ID;
        ob.currentPayedRentMonth = nextOutstanding; 
      }
      if (ob.rentAmount) ob.totalAmountTobePaid += (ob.rentAmount); 
      if (child && (child.currentMiscAmount)) ob.miscFee = child.currentMiscAmount;
      if (child && child.currentMiscName) ob.currentMiscName = child.currentMiscName;
      if (child && child.currentSecurityAmount) ob.securityDeposit = child.currentSecurityAmount; 
      let tenantsIn = props.tenantList.filter(f => f.leaseMasterId == value); 
      ob.paidByList = tenantsIn;
      if (ob.paidByList && ob.paidByList.length == 1) ob.tenant = ob.paidByList[0].RenterID; 
    } 
    if ((fieldName === 'proceedPayMiscFee' || fieldName === 'proceedSecurityDeposit')) {
      ob[fieldName] = checkBoxValue;
      console.log('checkBoxValue ',checkBoxValue,ob.totalAmountTobePaid);
      if (checkBoxValue) {
        if (fieldName === 'proceedPayMiscFee' && offlinePayment.miscFee && Number(offlinePayment.miscFee) > 0) {
          ob.totalAmountTobePaid += Number(offlinePayment.miscFee);
          ob.miscFeeCheck=true;
        }
        if (fieldName === 'proceedSecurityDeposit' && offlinePayment.securityDeposit) {
          ob.totalAmountTobePaid += offlinePayment.securityDeposit;
          ob.securityDepositCheck=true;
        }
      } 
      if(!checkBoxValue) {
        if (fieldName === 'proceedPayMiscFee' && offlinePayment.miscFee && Number(offlinePayment.miscFee) > 0) {
          ob.totalAmountTobePaid -= Number(offlinePayment.miscFee);
          ob.miscFeeCheck=false;
        }
        if (fieldName === 'proceedSecurityDeposit' && offlinePayment.securityDeposit) {
          ob.totalAmountTobePaid -= offlinePayment.securityDeposit
          ob.securityDepositCheck=false;
        };
      }
    }
    console.log('rentMonth child',child);
    if (fieldName == 'rentMonth' && child) { 
      ob.currentPayedRentMonth = child;
      ob.rentAmount = child.Rent;
      ob.miscFee =offlinePayment.miscFee && Number(offlinePayment.miscFee>0) && offlinePayment.miscFeeCheck?Number(offlinePayment.miscFee):0
      ob.securityDeposit =offlinePayment.securityDeposit && Number(offlinePayment.securityDeposit>0) && offlinePayment.securityDepositCheck?Number(offlinePayment.securityDeposit):0;
      
      if (ob.rentAmount) ob.totalAmountTobePaid = (ob.rentAmount + ob.miscFee + ob.securityDeposit);
    }
    if (child && child.currentUnit) ob.unit = child.currentUnit;
      setOfflinePayment({ ...offlinePayment, ...ob })
  }
  function deleteOffLinePayment() { 
    return new Promise((resolve, reject) => {
      setIsLoadingModal(true);

      let apiName = "rentapi";
      let path = "/modules";
      let op = "deleteOfflinePayment";
      let apiModule = "renter";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: {
          transactionId: offlinePayment.transactionId
        },
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit)
        .then(async response => { 
          enqueueSnackbar("Payment deleted successfully", {variant:"success"});
          setIsLoadingModal(false);
          setAddOfflinePayment(false)
          setOfflinePayment({});
          resolve();
        })
        .catch(err => {
          console.log(err); 
          enqueueSnackbar("Payment deleting failed", {variant:"error"});
          setIsLoadingModal(false);

          reject([]);
        });
    });
  }
   
  return (
    <div>
      <DialogBox
        fullWidth
        maxWidth={'lg'}
        open={addOfflinePayment}
        onClose={e => { setOfflinePayment({}); setAddOfflinePayment(false); }}
        aria-labelledby="addnew-user-dialog-title">
        <DialogTitle  id="addnew-user-dialog-title" onClose={e => { setAddOfflinePayment(false); setOfflinePayment({}); }}>
          <div className={"skelton-flex-parent"}>
            {
              isLoadingModal &&
              <Skeleton variant="rectangular" width={263} height={29} className={"skelton-margin-right-5 skelton-margintop-2p"} />
            }
            {!isLoadingModal && <div>Add Payment</div>}
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className={"skelton-flex-parent"}>
              {!isLoadingModal && <div>Manually include payments made offline, ie e-transfer, checks or cash to active leases only.</div>}
              {
                isLoadingModal &&
                <Skeleton variant="rectangular" width={262} height={19} />
              }
            </div>
          </DialogContentText>        
          <Grid
            container
            className="addtenant-grid"
            spacing={6}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {!isLoadingModal && (
              <Grid item xs={12} sm={4}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className={"offline-payment-labels"}
                  color="primary"
                  sx={{pb:3,fontWeight:'500'}}
                >
                  LEASE HOLDER(S)
                </InputLabel>

                <Select
                  fullWidth
                  labelid="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined" 
                  name="leaseHolder"
                  value={
                    offlinePayment && offlinePayment.leaseHolder
                      ? offlinePayment.leaseHolder
                      : ""
                  }
                  variant="outlined"
                  onChange={(e, child) => {
                    onChange(e, child);
                  }} 
                >
                  {props.leaseHolderList.map((e, keyIndex) => {
                    return (
                      <MenuItem
                        key={keyIndex}
                        value={e.leaseMasterId}
                        id={{
                          isSecuriteDepositExist: e.isSecuriteDepositExist,
                          currentUnit: e.unit,
                          currentMiscAmount: e.miscAmount,
                          currentMiscName: e.miscName,
                          currentSecurityAmount: e.securityDepositAmount
                        }}
                      >
                        <ListItemText primary={e.leaseHolders} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            )}
            {isLoadingModal && (
              <Grid item xs={12} sm={4}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className={"offline-payment-labels"}
                  color="primary"
                  sx={{pb:3,fontWeight:'500'}}
                >
                  <Skeleton variant="text" className={"skelton-width-50p"} />
                </InputLabel>
                <Skeleton
                  className={"skelton-width-p100 skelton-minwidth-212"}
                  variant="rectangular"
                  height={55}
                />{" "}
              </Grid>
            )}

            {!isLoadingModal && (
              <Grid item xs={12} sm={4}>
                 
                <InputLabel
                  disabled={!(offlinePayment && offlinePayment.leaseHolder)}
                  id="demo-simple-select-outlined-label" 
                  className={"offline-payment-labels"}
                  color="primary"
                  sx={{pb:3,fontWeight:'500'}}
                >
                  PAID BY
                </InputLabel>

                <Select
                  fullWidth
                  labelid="demo-simple-select-outlined-label"
                  id="tenant-simple-select-outlined" 
                  label="PAID BY"
                  name="tenant"
                  value={offlinePayment?.tenant? offlinePayment.tenant: ""}
                  variant="outlined"
                  onChange={onChange}
                  /* labelWidth={labelWidth} */
                  disabled={!(offlinePayment && offlinePayment.leaseHolder)}
                >
                  {offlinePayment && offlinePayment.paidByList && offlinePayment.paidByList.map((e, keyIndex) => {
                    return (
                      <MenuItem key={keyIndex} value={e.value}>
                        <ListItemText primary={e.name} />
                      </MenuItem>
                    );
                  })}
                </Select>
               
              </Grid>
            )}
            {isLoadingModal && (
              <Grid item xs={12} sm={4}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className={"offline-payment-labels"}
                  color="primary"
                  sx={{pb:3,fontWeight:'500'}}
                >
                  <Skeleton variant="text" className={"skelton-width-50p"} />
                </InputLabel>
                <Skeleton
                  className={"skelton-width-p100 skelton-minwidth-212"}
                  variant="rectangular"
                  height={55}
                />{" "}
              </Grid>
            )}

            {!isLoadingModal && (
              <Grid item xs={12} sm={4}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className={"offline-payment-labels"}
                  color="primary"
                  sx={{pb:3,fontWeight:'500'}}
                >
                  UNIT #
                </InputLabel>

                <InputBase
                  placeholder="-"
                  inputProps={{ "aria-label": "naked" }} 
                  name="unit"
                  onChange={onChange}
                  value={offlinePayment && offlinePayment.unit?offlinePayment.unit:''}
                  id="unit"
                  fullWidth
                />
              </Grid>
            )}
            {isLoadingModal && (
              <Grid item xs={12} sm={4}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className={"offline-payment-labels"}
                  color="primary"
                  sx={{pb:3,fontWeight:'500'}}
                >
                  <Skeleton variant="text" className={"skelton-width-50p"} />
                </InputLabel>
                <Skeleton
                  variant="rectangular"
                  className={"skelton-width-p100 skelton-minwidth-fld"}
                  height={55}
                />{" "}
              </Grid>
            )}
          </Grid>

          <Grid
            container
            className="addtenant-grid"
            spacing={6}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {!isLoadingModal && (
              <Grid item xs={12} sm={4}>
                <InputLabel
                  id="demo-simple-select-outlined-label" 
                  className={"offline-payment-labels"}
                  color="primary"
                  sx={{pb:3,fontWeight:'500'}}
                >
                  RENT MONTH
                </InputLabel>

                <Select
                  fullWidth
                  labelid="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"                 
                  name="rentMonth"
                  value={
                    offlinePayment && offlinePayment.rentMonth > -1
                      ? offlinePayment.rentMonth
                      : ""
                  }
                  variant="outlined"
                  //onChange={onChange}
                  onChange={(e, child) => {
                    onChange(e, child);
                  }} 
                  disabled={!(offlinePayment && offlinePayment.leaseHolder)}
                >
                  {offlinePayment && offlinePayment.outstanding &&
                   Array.isArray(offlinePayment.outstanding) &&
                   offlinePayment.outstanding.map((e, keyIndex) => {
                      return (
                        <MenuItem key={keyIndex} value={Number(e.ID)} id={e}>
                          {e.RentMonth}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
            )}
            {isLoadingModal && (
              <Grid item xs={12} sm={4}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className={"offline-payment-labels"}
                  color="primary"
                  sx={{pb:3,fontWeight:'500'}}
                >
                  <Skeleton variant="text" className={"skelton-width-50p"} />
                </InputLabel>
                <Skeleton
                  variant="rectangular"
                  className={"skelton-width-p100 skelton-minwidth-fld"}
                  height={55}
                />{" "}
              </Grid>
            )}

            {!isLoadingModal && (
              <Grid item xs={12} sm={4}>
                <InputLabel
                  id="demo-simple-select-outlined-label" 
                  className={"offline-payment-labels"}
                  color="primary"
                  sx={{pb:3,fontWeight:'500'}}
                >
                  TRANSACTION DATE
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker 
                    fullWidth
                    label="Select Date"
                    // value={offlinePayment && offlinePayment.transactionDate || ""}
                    value={offlinePayment && offlinePayment.transactionDate?offlinePayment.transactionDate:null}
                    format="MMMM dd, yyyy"
                    onChange={(date) => {
                      if (date instanceof Date && isFinite(date)) { 
                        let ob = {
                          name: "transactionDate",
                          value: date.toISOString(),
                        };
                        let e = {
                          target: ob,
                        };
                        onChange(e);
                      }
                    }}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </LocalizationProvider>
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={"offline-width-100p"}
                    autoOk
                    emptyLabel="Select Date"
                    invalidDateMessage=""
                    //minDate={leaseData.leaseStart ? new Date(leaseData.leaseStart).toISOString() : new Date().toISOString()}
                    variant="inline"
                    inputVariant="outlined"
                    format="MMMM dd, yyyy"
                    value={offlinePayment && offlinePayment.transactionDate || ""}
                    name="transactionDate"
                    InputAdornmentProps={{ position: "start" }}
                    //readOnly={isPastLeaseStart}
                    onOpen={(e) => { }}
                    onChange={(date) => {
                      if (date instanceof Date && isFinite(date)) {
                        console.log("DATE CLICKED: : ", date);
                        let ob = {
                          name: "transactionDate",
                          value: date.toISOString(),
                        };
                        let e = {
                          target: ob,
                        };
                        onChange(e);
                      }
                    }}
                  />
                </MuiPickersUtilsProvider> */}
              </Grid>
            )}
            {isLoadingModal && (
              <Grid item xs={12} sm={4}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className={"offline-payment-labels"}
                  color="primary"
                  sx={{pb:3,fontWeight:'500'}}
                >
                  <Skeleton variant="text" className={"skelton-width-50p"} />
                </InputLabel>
                <Skeleton
                  variant="rectangular"
                  className={"skelton-width-p100 skelton-minwidth-fld"}
                  height={55}
                />{" "}
              </Grid>
            )}

            {!isLoadingModal && (
              <Grid item xs={12} sm={4}>
                <InputLabel
                  id="demo-simple-select-outlined-label" 
                  className={"offline-payment-labels"}
                  color="primary"
                  sx={{pb:3,fontWeight:'500'}}
                >
                  TRANSACTION TYPE
                </InputLabel>

                <Select
                  labelid="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined" 
                  name="transactionType"
                  value={
                    offlinePayment && offlinePayment.transactionType
                      ? offlinePayment.transactionType
                      : ""
                  }
                  variant="outlined"
                  onChange={onChange}
                  fullWidth
                >
                  {/* <MenuItem value="">
                        <em>SELECT TYPE</em>
                      </MenuItem> */}
                  <MenuItem value="cash">Cash</MenuItem>
                  <MenuItem value="etransfer">E-Transfer</MenuItem>
                  <MenuItem value="checkdeposit">Check</MenuItem>
                </Select>
              </Grid>
            )}
            {isLoadingModal && (
              <Grid item xs={12} sm={4}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className={"offline-payment-labels"}
                  color="primary"
                  sx={{pb:3,fontWeight:'500'}}
                >
                  <Skeleton variant="text" className={"skelton-width-50p"} />
                </InputLabel>
                <Skeleton
                  variant="rectangular"
                  className={"skelton-width-p100 skelton-minwidth-fld"}
                  height={55}
                />{" "}
              </Grid>
            )}
          </Grid> 
          <Grid
            container 
            spacing={6}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {!isLoadingModal && (
              <Grid item xs={12} sm={4}>
                <InputLabel
                  id="demo-simple-select-outlined-label" 
                  className={"offline-payment-labels"}
                  color="primary"
                  sx={{pb:3,fontWeight:'500'}}
                >
                  RENT AMOUNT
                </InputLabel>
                {offlinePayment && offlinePayment.leaseHolder ? (
                  <InputBase
                    fullWidth
                    defaultValue="-"
                    inputProps={{ "aria-label": "naked" }} 
                    name="rentAmount"
                    value={"$" + offlinePayment.rentAmount}
                    id="rentAmount"
                    readOnly={true} 

                  />
                ) : (
                  ""
                )}

                {/*                 <TextField
                  variant="outlined" 
                  //required = {!isEditing}
                  fullWidth
                  name="rentAmount"
                  //disabled={validatePasswordEmailForm()}
                  value = {offlinePayment.rentAmount}
                  onChange={onChange}
                  type="text"
                  id="rentAmount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                  
                /> */}
              </Grid>
            )}

            {isLoadingModal && (
              <Grid item xs={12} sm={4}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className={"offline-payment-labels"}
                  color="primary"
                  sx={{pb:3,fontWeight:'500'}}
                >
                  <Skeleton variant="text" className={"skelton-width-50p"} />
                </InputLabel>
                <Skeleton
                  variant="rectangular"
                  className={"skelton-width-p100 skelton-minwidth-fld"}
                  height={55}
                />{" "}
              </Grid>
            )}

            {!isLoadingModal && (
              <Grid item xs={12} sm={4}>
                <div className={"offline-bt-flex"}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    className={"offline-payment-labels"}
                    color="primary"
                    sx={{pb:3,fontWeight:'500'}}
                    disabled={!isSecurityDepositExist}                   
                  >
                    SECURITY DEPOSIT
                  </InputLabel>
                  {/*                   { !isSecurityDepositExist && <BootstrapTooltip title="Security deposit is already paid">
                    <ErrorIcon className={"addpayment-deposit-tooltip"}/> 
                  </BootstrapTooltip>
                  } */}
                </div>

                {!isSecurityDepositExist ? (
                  <InputBase
                    inputProps={{ "aria-label": "naked" }} 
                    name="securityDeposit"
                    value={"Already Paid"}
                    id="rentAmount"
                    disabled={true}
                    fullWidth
                  />
                ) : offlinePayment && offlinePayment.securityDeposit > 0 ? (
                  <FormControlLabel
                    fullWidth 
                    control={<Checkbox checked={offlinePayment.proceedSecurityDeposit} onChange={onChange} name="proceedSecurityDeposit" />}
                    label={" $" + offlinePayment.securityDeposit}
                  />
                ) : (
                  ""
                )}

                {/*                 <TextField
                  variant="outlined" 
                  //required = {!isEditing}
                  fullWidth
                  name="securityDeposit"
                  //disabled={validatePasswordEmailForm()}
                  value = {offlinePayment.securityDeposit}
                  onChange={onChange}
                  type="text"
                  id="rentAmount"
                  disabled={!isSecurityDepositExist}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                  
                /> */}
              </Grid>
            )}
            {isLoadingModal && (
              <Grid item xs={12} sm={4}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className={"offline-payment-labels"}
                  color="primary"
                  sx={{pb:3,fontWeight:'500'}}
                >
                  <Skeleton variant="text" className={"skelton-width-50p"} />
                </InputLabel>
                <Skeleton
                  variant="rectangular"
                  className={"skelton-width-p100 skelton-minwidth-fld"}
                  height={55}
                />{" "}
              </Grid>
            )}

            {!isLoadingModal && (
              <Grid item xs={12} sm={4}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className={"offline-payment-labels"}
                  color="primary"
                  sx={{pb:3,fontWeight:'500'}}
                  disabled={offlinePayment && !(offlinePayment.miscFee > 0)}
                >
                  MISC FEE
                </InputLabel>

                {offlinePayment && offlinePayment.miscFee && Number(offlinePayment.miscFee) > 0 ? (
                  <div>

                    <FormControlLabel 
                      fullWidth
                      control={<Checkbox checked={offlinePayment.proceedPayMiscFee} onChange={onChange} name="proceedPayMiscFee" />}
                      label={" " + offlinePayment.currentMiscName + ": $" + offlinePayment.miscFee}
                    />
                    <InputBase
                      inputProps={{ "aria-label": "naked" }} 
                      style={{display:'none'}}
                      name="miscFee"
                      value={offlinePayment.miscFee}
                      id="miscFee"
                      fullWidth
                    />
                  </div>
                ) : offlinePayment && offlinePayment.leaseHolder ? (
                  <InputBase
                    inputProps={{ "aria-label": "naked" }} 
                    name="miscFee"
                    value="None"
                    id="miscFee"
                    disabled={true}
                    fullWidth
                  />
                ) : (
                  ""
                )}

                {/*                 <TextField
                  variant="outlined" 
                  //required = {!isEditing}
                  fullWidth
                  name="miscFee"
                  //disabled={validatePasswordEmailForm()}
                  value = {offlinePayment.miscFee}
                  onChange={onChange}
                  type="text"
                  id="rentAmount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                  
                /> */}
              </Grid>
            )}
            {isLoadingModal && (
              <Grid item xs={12} sm={4}>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className={"offline-payment-labels"}
                  color="primary"
                  sx={{pb:3,fontWeight:'500'}}
                >
                  <Skeleton variant="text" className={"skelton-width-50p"} />
                </InputLabel>
                <Skeleton
                  variant="rectangular"
                  className={"skelton-width-p100 skelton-minwidth-fld"}
                  height={55}
                />{" "}
              </Grid>
            )}
          </Grid> 
          <Grid
            container
            className="addtenant-grid"
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {!isLoadingModal &&
              <Grid item>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  sx={{ fontSize:30, fontWeight: '500', }}
                  color="textSecondary" 
                >
                  Total Payment
                </InputLabel>
              </Grid>

            }
            {!isLoadingModal && offlinePayment &&  offlinePayment.totalAmountTobePaid ?
              <Grid item>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className={"addpayment-special-labels-2"}
                  color="textPrimary"
                >
                  {" $" + offlinePayment.totalAmountTobePaid}
                </InputLabel>

              </Grid>
              :
              ""
            }
          </Grid>
        </DialogContent>
        <Divider variant="middle" />
        <DialogActions>
          <Grid container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start">
            <Grid item xs={12}>
              {!isLoadingModal &&
                <div className={"offline-bt-flex"}>
                  <LoaderCLickButton
                    isLoading={false}
                    size="large"
                    disabled={!validateForm()}
                    onClick={async () => {
                      setIsLoadingModal(true);
                      await saveOfflinePayment();
                    }}
                  >
                    {offlinePayment?.isEditing ? "UPDATE" : "+ ADD"}
                  </LoaderCLickButton>
                  {offlinePayment?.isEditing &&
                    <LoaderDeleteButton
                      isLoading={false}
                      size="large"
                      disabled={false}
                      onClick={() => { deleteOffLinePayment() }}
                    >
                      DELETE
                    </LoaderDeleteButton>
                  }
                </div>
              }
              {isLoadingModal &&
                <div className={"offline-bt-flex"}>
                  <Skeleton variant="rectangular" className={"skelton-minwidth-212 skelton-width-p25 skelton-margin-r-25"} height={45} />
                  {offlinePayment?.isEditing &&
                    <Skeleton variant="rectangular" className={"skelton-minwidth-212 skelton-width-p25"} height={45} />}
                </div>}
            </Grid>

          </Grid> 
        </DialogActions>
      </DialogBox>  
    </div>
  );
}