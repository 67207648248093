import React, { useState, useEffect,useCallback } from "react";
import { styled } from '@mui/material/styles';
import { useHistory, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Box,
  Card,
  Divider,
  IconButton,
  CardActionArea,
  Container,
  Typography,
  Grid,
  DialogContentText,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CardContent from "@mui/material/CardContent";
import { API } from "aws-amplify";
import CardActions from "@mui/material/CardActions";
import { LoaderCLickButton } from "../LoaderButton";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CloseIcon from "@mui/icons-material/Close";
// import { openSnackbar } from "../Notifier";
import { useSnackbar } from 'notistack';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Tooltip from "@mui/material/Tooltip";
import { trackPromise } from "react-promise-tracker";
import LoaderButton, {LoaderDeleteButton} from "../LoaderButton";
import { areas } from "../../common/constants/areas";
import usePersistedState from "../../usePersistedState";
import usePersistedStateString from "../../usePersistedStateString";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MuiDialogTitle from "@mui/material/DialogTitle";
import { Skeleton } from '@mui/material';
import { UserCreditCards } from "../../common/components/placeholder";
import { usePlaidLink} from 'react-plaid-link';
import AddCreditCard from './addCreditCard';
import Button from '@mui/material/Button';
import { AmericanExpress,BitCoin,Cirrus,DinersClub,Discover,Jcb,Maestro,MasterCard,Paypal,Visa,ACH } from '../../static/cardlogo/cards';

const PREFIX = 'managepayment';

const classes = {
  "@global": `${PREFIX}-undefined`,
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  submit: `${PREFIX}-submit`,
  card: `${PREFIX}-card`,
  details: `${PREFIX}-details`,
  content: `${PREFIX}-content`,
  cover: `${PREFIX}-cover`,
  controls: `${PREFIX}-controls`,
  headerOne: `${PREFIX}-headerOne`,
  headerTwo: `${PREFIX}-headerTwo`,
  headerThree: `${PREFIX}-headerThree`,
  headerfour: `${PREFIX}-headerfour`,
  headerFive: `${PREFIX}-headerFive`,
  headerSix: `${PREFIX}-headerSix`,
  headerSeven: `${PREFIX}-headerSeven`,
  thankYouHTwo: `${PREFIX}-thankYouHTwo`,
  thankYouHThree: `${PREFIX}-thankYouHThree`,
  thankYouHFour: `${PREFIX}-thankYouHFour`,
  filterProperty: `${PREFIX}-filterProperty`,
  filterUType: `${PREFIX}-filterUType`,
  filterLabel: `${PREFIX}-filterLabel`,
  selectEmpty: `${PREFIX}-selectEmpty`,
  papers: `${PREFIX}-papers`,
  cards: `${PREFIX}-cards`,
  rentCardClick: `${PREFIX}-rentCardClick`,
  rentCard: `${PREFIX}-rentCard`,
  f: `${PREFIX}-f`,
  rentCardSelect: `${PREFIX}-rentCardSelect`,
  form: `${PREFIX}-form`,
  expand: `${PREFIX}-expand`,
  expandOpen: `${PREFIX}-expandOpen`,
  payImg: `${PREFIX}-payImg`,
  payCardCont: `${PREFIX}-payCardCont`,
  main: `${PREFIX}-main`,
  image: `${PREFIX}-image`,
  iconRoot: `${PREFIX}-iconRoot`
};

const StyledBox = styled(Box)((
  {
    theme
  }
) => ({
  [`& .${classes.undefined}`]: {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },

  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(8),
    flexDirection: "column",
    alignItems: "center",
    /*   minHeight: "600px", */
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },

  [`& .${classes.card}`]: {
    display: "flex",
    /* padding: theme.spacing(2, 2,3, 2)  */
  },

  [`& .${classes.details}`]: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    /*  width:"-moz-available" */
  },

  [`& .${classes.content}`]: {
    flex: "1 0 auto",
  },

  [`& .${classes.cover}`]: {
    width: "170px",
    height: "170px",
  },

  [`& .${classes.controls}`]: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.headerOne}`]: {
    color: "rgba(0,0,0,1)",
    fontSize: "60px",
  },

  [`& .${classes.headerTwo}`]: {
    color: "rgba(123,123,123,1)",
    fontSize: "60px",
  },

  [`& .${classes.headerThree}`]: {
    color: "rgba(0,0,0,1)",
    fontSize: "22px",
  },

  [`& .${classes.headerfour}`]: {
    color: "rgba(123,123,123,1)",
    fontSize: "20px",
  },

  [`& .${classes.headerFive}`]: {
    color: "#7c7c7c",
    fontSize: "40px",
  },

  [`& .${classes.headerSix}`]: {
    color: "rgba(0,0,0,1)",
    fontSize: "22px",
    fontWeight: "bold",
  },

  [`& .${classes.headerSeven}`]: {
    color: "#7c7c7c",
    fontSize: "14px",
  },

  [`& .${classes.thankYouHTwo}`]: {
    color: "rgba(123,123,123,1)",
  },

  [`& .${classes.thankYouHThree}`]: {
    color: "rgba(123,123,123,1)",
    fontSize: "16px",
  },

  [`& .${classes.thankYouHFour}`]: {
    color: "rgba(0,0,0,1)",
    borderLeft: "6px solid #ffe100",
    paddingLeft: "7px",
  },

  [`& .${classes.filterProperty}`]: {
    margin: theme.spacing(1),
    minWidth: 220,
    width: 564,
  },

  [`& .${classes.filterUType}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
    float: "left",
  },

  [`& .${classes.filterLabel}`]: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(4),
    width: "56px",
    color: "rgba(123,123,123,1)",
    fontSize: "17px",
    float: "left",
  },

  [`& .${classes.selectEmpty}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.papers}`]: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  [`& .${classes.cards}`]: {
    display: "flex",
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
  },

  [`& .${classes.rentCardClick}`]: {
    display: "flex",
    width: "100%",
    background: "#3f51b52e",
    border: "1px solid #3f51b5",
    borderRadius: "5px",
    /*  opacity:"0.8", */
    boxShadow: "none",
  },

  [`& .${classes.rentCard}`]: {
    display: "flex",
    width: "100%",
    border: "1px solid #DADADA",
    borderRadius: "5px",
  },

  [`& .${classes.f}`]: {
    display: "flex",
    width: "100%",
    border: "1px solid #DADADA",
    borderRadius: "5px",
    boxShadow: "none",
  },

  [`& .${classes.rentCardSelect}`]: {
    display: "flex",
    width: "100%",
    border: "1px solid #6200EE",
    borderRadius: "5px",
  },

  [`& .${classes.form}`]: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },

  [`& .${classes.expand}`]: {
    transform: "rotate(0deg)",
    marginLeft: "36%",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },

  [`& .${classes.expandOpen}`]: {
    transform: "rotate(180deg)",
  },

  [`& .${classes.payImg}`]: {
    width: "100%",
    height: "100%",
  },

  [`& .${classes.payCardCont}`]: {
    padding: "8px",
  },

  [`& .${classes.main}`]: {
    maxWidth: "100% !important",
  },

  [`& .${classes.image}`]: {
    height: 35,
    width: 35,
    display: "flex",
  },

  [`& .${classes.iconRoot}`]: {
    textAlign: "center",
  }
}));

const DialogBox = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));
  
const DialogTitle = (props => {
  const { children,  onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography  sx={{m:0, p:2, pl:3, pr:3}}  {...other}>
      <Typography variant="h3" component="h3" sx={{ lineHeight:1}}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: "8px",
            top: "8px",
            color: "text.secondary",
          }} 
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export function ManagePaymentMethod(props) {
  const { enqueueSnackbar } = useSnackbar(); 
  const location = useLocation();
  let history = useHistory(); 
  const primaryCard = React.useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [render, setRender] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [cardForDelete, setCardForDelete] = useState({});
  const [userCards, setUserCards] = usePersistedState("userCards", []);
  const [linkToken,setLinkToken]=usePersistedStateString("linkToken",""); 
  const [plaidToken,setPlaidToken]=useState({"token":"","accountId":""});
  const [plaidOpen,setPlaidOpen]=useState(false);     
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const year = (new Date()).getFullYear();
  const [expYear] = useState(Array.from(new Array(20),(val, index) => (index + year).toString())); 
  const [expMonth] = useState(["1","2","3","4","5","6","7","8","9","10","11","12"]);
  const [addCreditCard, setAddCreditCardOpen] = useState(false); 
  const [creditCardData, setCreditCardData] = useState({
    MasterID:"",
    NameOnCard: "",
    BillingAddress: "",
    Country:"",
    CountryCode:"",
    PostalCode: "",
    CardNumber: "",
    CvvCid: "",
    ExpiryMonth:"",
    ExpiryYear:"",
    UserName:props.userSession.UserName,
    RenterName:props.userSession.FirstName+' '+props.userSession.FirstName
  }); 
  const [selectedProperty,setSelectedProperty] = usePersistedState('selectedProperty', {});
  const onSuccess = useCallback(async (token, metadata) => {
     await setIsLoading(true);
    // send token to server
    console.log('onSuccess', token, metadata)
    console.log('Customer-selected account ID: ' + metadata.account_id);
    await setPlaidToken({token:token,accountId:metadata.account_id}); 
    await setPlaidOpen(false);
    await savePlaidData(token,metadata.account_id); 
  }, []);   
  const onEvent = useCallback(
    (eventName, metadata) => console.log('onEvent', eventName, metadata),
    []
  ); 
  const onExit = useCallback(
    async (err, metadata) => {
     console.log('onExit', err, metadata);
     await setPlaidOpen(false); 
     await setPlaidToken({token:"",accountId:""}); 
    },
    []
  ); 

  useEffect(() => { 
        if(selectedProperty.PlaidLinked && selectedProperty.PlaidLinked!=="Yes" && plaidOpen )
            open();
  },[plaidOpen]);
  useEffect(() => {
    let isSubscribed = true; 
    const userFetch = async () => {
      if (selectedProperty.hasOwnProperty("PropertyID") && isSubscribed) { 
        setIsLoading(true);
        if (isSubscribed) {
        if (selectedProperty.LinkToken && selectedProperty.LinkToken !== "")
             await setLinkToken(selectedProperty.LinkToken); 
        await getCardsData(props.userSession, selectedProperty.PropertyID, "getCards")
        .then(async (tablData) => {
          await setUserCards(tablData);
          await setIsLoading(false);
        });
        }
        return () => (isSubscribed = false);
      }
    };
    userFetch();
  }, [location]);
  const CardFetching = async (op) => {
    setIsLoading(true);
    if(selectedProperty.hasOwnProperty("PropertyID"))
    {
      trackPromise(
      getCardsData(props.userSession,selectedProperty.PropertyID, op)
      .then(async (tablData) => {
        await setUserCards(tablData); 
        await setIsLoading(false);
      }) 
        .catch((er) => {
          throw er;
        }),
      areas.renter
    );
  }
  };
  const getCardsData = async (userSession,propertyId, op) => {
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: {
          masterType: ['Bank Account',"User Card"],
          CurrentUserID: userSession.UserName,
          PropertyId:propertyId,
          userData: userSession,
        },
        queryStringParameters: {
          module: "renter",
          op: op ? op : "getCardData",
        },
      };
      API.post(apiName, path, myInit)
        .then((response) => {
          console.log("### getcard data response",response);
          if(response)
            resolve(response);
          else 
          resolve(response);
            resolve([]);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };
  const openPlaidLink= async ()=>{
      console.log("link clicked");
      await setPlaidOpen(true); 
      console.log("plaid open",plaidOpen);
  }

  async function handleChildClickCreditCard(fieldName, value, child) {
    console.log("FieldName : value : child : ", fieldName , value, child)
    let ob = {};
    ob[fieldName] =  value;
    setCreditCardData({...creditCardData, ...ob})
    
  }

  function validateCreditCardForm() {
    console.log("Log validateCreditCardForm",creditCardData);
    
    return creditCardData.ExpiryMonth.length>0 && creditCardData.ExpiryYear.length>0 &&
    creditCardData.NameOnCard.length > 0 && creditCardData.BillingAddress.length > 0 &&
    creditCardData.Country.length > 0 && creditCardData.PostalCode.length > 0 && 
    creditCardData.CardNumber.length > 0 && creditCardData.CvvCid.length > 0;
}

async function saveCardData(e) {
  e.preventDefault();
  setIsLoadingModal(true);
  let apiName = "rentapi";
  let path = "/modules";
  let myInit = {
    headers: { "Content-Type": "application/json" },
    body: {...creditCardData,userData:props.userSession, MasterType: 'User Card', CurrentUserID: props.userSession.UserName, CurrentUserEmail:props.userSession.Email, CurrentUserName: props.userSession.FirstName + " " + props.userSession.LastName },
    queryStringParameters: {
      module: "renter",
      op: "saveCardData"
    }
  };
  API.post(apiName, path, myInit)
    .then(async response => {
      setIsLoadingModal(false);
      if(response && response.success)
      {
        console.log("response" + JSON.stringify(response));             
        // openSnackbar({
        //   message:
        //   response.message,
        //   variant: "success"
        // });
        enqueueSnackbar(response.message, {variant: 'success'});
        setCreditCardData({
          MasterID:"",
          NameOnCard: "",
          BillingAddress: "",
          Country:"",
          PostalCode: "",
          CardNumber: "",
          CvvCid: "",
          ExpiryMonth:"",
          ExpiryYear:"",
          UserName:props.userSession.UserName,
          RenterName:props.userSession.FirstName+' '+props.userSession.FirstName
        });
        setAddCreditCardOpen(false);
        setIsLoadingModal(false);
        await CardFetching("getCards");
      }
      else{          
        // openSnackbar({ message:response.message, variant: "error" });
        enqueueSnackbar(response.message, {variant: 'error'});
        setAddCreditCardOpen(false);
        setIsLoadingModal(false);
      }
    })
    .catch(err => {
      console.log(err);
      // openSnackbar({ message: err.message, variant: "error" });
      enqueueSnackbar(err.message, {variant: 'error'});
      setIsLoadingModal(false);
    });
} 
  async function changePrimaryCard(clickedCard,type) {
    console.log("CLICKED");
    let apiName = "rentapi";
    let path = "/modules";
    let  myInit = {
      headers: { "Content-Type": "application/json" },
      body:{},
      queryStringParameters: {
        module: "renter",
        op: "changePrimaryCard",
      }
    };
    if(type==='Bank Account')
    {
      myInit.body= {
        CurrentPrimaryCard: primaryCard.current,
        NewPrimaryCard: clickedCard,
        userData: props.userSession,
      };
    }
    else
    {
      myInit.body= {
        CurrentPrimaryCard: primaryCard.current,
        NewPrimaryCard: clickedCard,
        userData: props.userSession,
      };
    }
    

    API.post(apiName, path, myInit)
      .then(async (response) => {
        // openSnackbar({ message: response.message, variant: "success" });
        enqueueSnackbar(response.message, {variant: 'success'});
        await CardFetching("getCardData");
      })
      .catch((err) => {
        // openSnackbar({ message: err.message, variant: "error" });
        enqueueSnackbar(err.message, {variant: 'error'});
      });
  }
  function BootstrapTooltip(props) { 
    return  <Tooltip arrow classes={classes} {...props} />;
  } 
  async function deleteCard() {
    console.log("CLICKED ", cardForDelete);
    let apiName = "rentapi";
    let path = "/modules";
    let myInit = {
      headers: { "Content-Type": "application/json" },
      body: {
        CardData: cardForDelete,
        userData: props.userSession,
        PropertyID:selectedProperty.PropertyID
      },
      queryStringParameters: {
        module: "renter",
        op: "deleteCard",
      },
    };

    API.post(apiName, path, myInit)
      .then(async (response) => {
        setDeleteOpen(false);
        // openSnackbar({ message: response.message, variant: "success" }); 
        enqueueSnackbar(response.message, {variant: 'success'});
        let myProperties = await loadMyProperties(props.userSession.UserName);  
        let currentProperty=myProperties[myProperties.findIndex(x => x.PropertyID == selectedProperty.PropertyID)]      
        localStorage.userProperties =JSON.stringify(myProperties); 
        setSelectedProperty(prv=>{ return currentProperty? currentProperty:{}}); 
        await CardFetching("getCardData");
      })
      .catch((err) => {
        setDeleteOpen(false);
        // openSnackbar({ message: err.message, variant: "error" });
        enqueueSnackbar(err.message, {variant: 'error'});
      });
  }
 

  const DialogTitleCreditCard = (props) => {
    const { children, onClose } = props;
    return (
      <MuiDialogTitle disableTypography>
        <Typography variant="h2"component = "h2" className="addlease-addtenant-header">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={"addTenant-closebt"}
            size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }; 

  /**
     * this function will load properties for the user
     */
  function loadMyProperties() {
    setIsLoading(true);
    var masterType = "User";
    var apiModule = "property";
    var op = "listMyProperty";
    let body = { userData: props.userSession, masterType: masterType, MasterID: props.userSession.UserName, for: "CardDisplay" };
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit)
        .then(response => {
          if (response && response.length > 0) {
            resolve(response);
          }
          else {
            resolve([]);
          }
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
  const savePlaidData = async (token,accountId ) => { 
    console.log("selectedProperty #########",selectedProperty);
    var propertyId=selectedProperty.PropertyID;
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: {"MasterType":"Bank Account","PropertyID":propertyId,"CurrentUserID": props.userSession.UserName, "userData": props.userSession,"token":token,"accountId":accountId },
        queryStringParameters: {
          module: "renter",
          op: "savePlaidData"
        }
      }; 
      API.post(apiName, path, myInit).then(async response => {
        console.log("card response", response);
        await setIsLoading(false);
        // openSnackbar({ message: response.message, variant: "success" });
        enqueueSnackbar(response.message, {variant: 'success'});
        let myProperties = await loadMyProperties(props.userSession.UserName);  
        let currentProperty=myProperties[myProperties.findIndex(x => x.PropertyID == selectedProperty.PropertyID)]      
        localStorage.userProperties =JSON.stringify(myProperties); 
        setSelectedProperty(prv=>{ return currentProperty? currentProperty:{}}); 
        await CardFetching("getCardData"); 
        resolve();
      }).catch(err => {
        console.log(err);   
        // openSnackbar({ message: err.message, variant: "error" });
        enqueueSnackbar(err.message, {variant: 'error'});
        reject(err);
      });
    });
  }  
  const config = {  
    token: linkToken,
    onSuccess,
    onEvent,
    onExit,
  };
  const { open, ready, error } = usePlaidLink(config); 
  return (
    <StyledBox
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >      
      <Container component="main" className={classes.main}>
        <CssBaseline />
        <div className="home-paper">
          <DialogBox
            open={deleteOpen}
            onClose={(e) => setDeleteOpen(false)}
            fullWidth={true}
            maxWidth={"sm"}
            aria-labelledby="customized-dialog-title"
          >
            <DialogTitle 
              id="customized-dialog-title"
              onClose={(e) => {
                setDeleteOpen(false);
              }}
            >
              Are you sure to delete this card?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                It cannot be reversed.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                direction='row'
                spacing={2}
                alignItems='flex-start'
              >
                <Grid item>
                  <LoaderCLickButton
                    onClick={(e) => {
                      deleteCard();
                    }}
                    isLoading={isLoading}
                  >
                    DELETE
                  </LoaderCLickButton>
                </Grid>
                <Grid item>
                  <LoaderDeleteButton
                    isLoading={isLoading}
                    size='large'
                    onClick={() => { setDeleteOpen(false); }}
                  >
                    CANCEL
                  </LoaderDeleteButton>
                </Grid>
              </Grid>
              <div style={{ flex: '1 0 0' }} />
            </DialogActions>
          </DialogBox>
          <DialogBox
            open={addCreditCard}
            onClose={(e) => setAddCreditCardOpen(false)}
            fullWidth={true}
            maxWidth={"sm"}
            aria-labelledby="customized-dialog-title"
          >
            <DialogTitle 
              id="addnew-user-dialog-title"
              onClose={(e) => {
                setAddCreditCardOpen(false);
              }}
            >
              <div style={{ display: "flex"}}>
                {isLoadingModal && (
                  <Skeleton
                    variant="rectangular"
                    height={55}
                    sx={{ 
                      mr: 1,
                      mt:1,
                      minWidth:"290px"
                    }}
                  />
                )}
                {!isLoadingModal && <div>Add Credit Card</div>}
              </div>
            </DialogTitle> 
            <DialogContent>
              <AddCreditCard
                creditCardData={creditCardData}
                isLoading={isLoadingModal}
                onChildClick={(fieldName, value) =>
                  handleChildClickCreditCard(fieldName, value)
                }
              />
            </DialogContent> 

            <DialogActions>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  <LoaderButton
                    block="true"
                    type="submit"
                    size="large"
                    //isLoading={isLoading}
                    disabled={!validateCreditCardForm()}
                    onClick={saveCardData}
                  >
                    + SAVE CARD
                  </LoaderButton>
                </Grid>
              </Grid>
            </DialogActions>
          </DialogBox>
     {/*      <div style={{ padding: "20px 0px 20px 0px" }}></div> */}
          <Grid container spacing={1} className={"add-padding-p8p"} >
            <Grid item>
              <Button onClick={e => {
            history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}`);
          }}>
                <Typography
                  component="h6"
                  sx={{ fontWeight: 600 }}
                  color="text.primary"
                  variant="h6"
                >
                  &#8249; {" RETURN"}
                </Typography>
              </Button>
            </Grid>
          </Grid>

          <Box mb={6}>
            {!isLoading && (
              <Typography
                component="h1"
                color={"textPrimary"}
                variant="h1"
              >
                Manage Payment Method
              </Typography>
            )}
            {isLoading && <Skeleton variant="rectangular" width={553} height={69} />}
          </Box>
          <Box mb={7}>
            {!isLoading && (
              <Typography
                color={"textSecondary"}
                variant="subtitle1"
              >
                You can add new credit card or bank account to your account.
                Also, you can remove existing cards from the list given below.
              </Typography>
            )}
            {isLoading && <Skeleton variant="rectangular" width={931} height={28} />}
          </Box>
          <Box mb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box mb={3}>
                  {!isLoading && (
                    <Typography
                      component="h3"
                      color={"textSecondary"}
                      variant="h3"
                    >
                      Add New
                    </Typography>
                  )}
                  {isLoading && (
                    <Skeleton variant="rectangular" width={369} height={45} />
                  )}
                </Box>
                <Box mb={3}>
                  <Grid container spacing={4} justifyContent="space-between">
                    <Grid item lg={6} sm={6} xl={6} xs={12}>
                      {!isLoading && (
                        <Card className={classes.rentCard}>
                          <CardActionArea
                            style={{ display: "flex" }}
                            onClick={() => { 
                              setAddCreditCardOpen(true);
                            }}
                          >
                            <CardContent
                              style={{
                                width: "100%",
                                padding: "19px",
                                color: "white",
                                backgroundColor: "#2404D5",
                              }}
                            >
                              <Grid
                                container
                                alignItems="center"
                                direction="row"
                              >
                                <Grid item xs={4}>
                                  <img
                                    alt="CREDIT CARD"
                                    className={classes.image}
                                    src={"/img/credit_card-white-24px.svg"}
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography>{"CREDIT CARD"}</Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      )}
                      {isLoading && <Skeleton variant="rectangular" height={76} />}
                    </Grid>
                    {/** Uncomment for enabling PLAID feature */}
                    {/* comment for remove ach release*/} 
                     {/* <Grid item lg={6} sm={6} xl={6} xs={12}> 
                      {!isLoading && (
                        <Card className={classes.rentCard}>
                          <CardActionArea
                            style={{ display: "flex" }}
                            onClick={async () => {
                              if (
                                selectedProperty.PlaidLinked  &&
                                selectedProperty.PlaidLinked !== "Yes" && selectedProperty.LinkToken !==""
                              ) {
                                console.log('plaid config not ready');
                                await openPlaidLink();
                              }
                              else {
                                // openSnackbar({ message: 'Bank account payment is not enabled, Please try other payment method', variant: "error" });
                                enqueueSnackbar('Bank account payment is not enabled, Please try other payment method', {variant: 'error'});
                              }
                            }}
                          >
                            <BootstrapTooltip
                              title={
                                selectedProperty.PlaidLinked &&
                                selectedProperty.PlaidLinked !== "Yes"
                                  ? ""
                                  : "Apologies, you can only link one account at time. Please remove account on file to proceed."
                              }
                            >
                              <CardContent
                                style={{
                                  width: "100%",
                                  padding: "19px",
                                  color: "white",
                                  backgroundColor:
                                  selectedProperty.PlaidLinked &&
                                  selectedProperty.PlaidLinked !== "Yes"
                                      ? "#D50454"
                                      : "#6E6E6E",
                                }}
                              >
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <Grid item xs={4}>
                                    <img
                                      alt="BANK ACCOUNT"
                                      className={classes.image}
                                      src={
                                        "/img/account_balance-white-24px.svg"
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={8}>
                                    <Typography>{"BANK ACCOUNT"}</Typography>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </BootstrapTooltip>
                          </CardActionArea>
                        </Card>
                      )}
                      {isLoading && <Skeleton variant="rectangular" height={76} />}
                    </Grid> */}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box mb={3}>
                  {!isLoading && (
                    <Typography
                      component="h3"
                      color={"textSecondary"}
                      variant="h3"
                    >
                      Your payment method on file
                    </Typography>
                  )}
                  {isLoading && (
                    <Skeleton variant="rectangular" width={369} height={45} />
                  )}
                </Box>
                <Box mb={3}>
                  <Grid container spacing={4}>
                    {!isLoading &&
                      userCards.map((e, keyIndex) => {
                        if (e.Primary === "Yes") primaryCard.current = e; // setPrimaryAccount(e);
                        return e.MasterType === "User Card" ? (
                          <Grid key={keyIndex} item xs={10}>
                            <Card className={classes.rentCard}>
                              <CardContent style={{ width: "100%" }}>
                                <Box alignItems="center" display="flex">
                                  <Box mr={6}>
                                    {e.CardType && e.CardType == "visa" ? (
                                      <Visa />
                                    ) : e.CardType &&
                                      e.CardType == "mastercard" ? (
                                      <MasterCard />
                                    ) : e.CardType &&
                                      e.CardType == "american-express" ? (
                                      <AmericanExpress />
                                    ) : e.CardType &&
                                      e.CardType == "discover" ? (
                                      <Discover />
                                    ) : e.CardType &&
                                      e.CardType == "maestro" ? (
                                      <Maestro />
                                    ) :e.CardType &&
                                    e.CardType == "diners-club" ? (
                                    <DinersClub />
                                  ) :e.CardType &&
                                  e.CardType == "jcb" ? (
                                  <Jcb />
                                ) : (
                                     <CreditCardIcon fontSize="large"  />
                                    )}
                                  </Box>
                                  <Box>
                                    <Typography>
                                      {e.DisplayCardNo} exp:{e.ExpiryMonth}/
                                      {e.ExpiryYear}{" "}
                                      {e.Primary === "Yes" ? "(Primary)" : ""}
                                    </Typography>
                                  </Box>
                                </Box>
                              </CardContent>
                              <CardActions>
                                <Grid container direction="row">
                                  <Grid item xs={6}>
                                    <BootstrapTooltip
                                      title={
                                        e.Primary !== "Yes"
                                          ? "Make Primary"
                                          : "Remove Primary"
                                      }
                                    >
                                      <IconButton
                                        color={
                                          e.Primary !== "Yes"
                                            ? "primary"
                                            : "secondary"
                                        }
                                        onClick={() => {
                                          changePrimaryCard(e);
                                        }}
                                        className={classes.button}
                                        aria-label="Favourite"
                                        size="large">
                                        {e.Primary !== "Yes" ? (
                                          <FavoriteBorderOutlinedIcon />
                                        ) : (
                                          <FavoriteIcon />
                                        )}
                                      </IconButton>
                                    </BootstrapTooltip>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <BootstrapTooltip title="Delete">
                                      <IconButton
                                        onClick={() => {
                                          setCardForDelete(e);
                                          setDeleteOpen(true);
                                        }}
                                        className={classes.button}
                                        aria-label="Favourite"
                                        size="large">
                                        <CloseIcon />
                                      </IconButton>
                                    </BootstrapTooltip>
                                  </Grid>
                                </Grid>
                              </CardActions>
                            </Card>
                          </Grid>
                        ) :<Grid key={keyIndex} item xs={10}>
                        <Card className={classes.rentCard}>
                          <CardContent style={{ width: "100%" }}>
                            <Box alignItems="center" display="flex">
                              <Box mr={6}>
                                <ACH />
                              </Box>
                              <Box>
                                <Typography>{e.BankAccount}</Typography>
                              </Box>
                            </Box>
                          </CardContent>
                          <CardActions>
                            <Grid container direction="row">
                              <Grid item xs={6}>
                                <BootstrapTooltip
                                  title={
                                    e.Primary !== "Yes"
                                      ? "Make Primary"
                                      : "Remove Primary"
                                  }
                                >
                                  <IconButton
                                    color={
                                      e.Primary !== "Yes"
                                        ? "primary"
                                        : "secondary"
                                    }
                                    onClick={() => {
                                      changePrimaryCard(e, "Bank Account");
                                    }}
                                    className={classes.button}
                                    aria-label="Favourite"
                                    size="large">
                                    {e.Primary !== "Yes" ? (
                                      <FavoriteBorderOutlinedIcon />
                                    ) : (
                                      <FavoriteIcon />
                                    )}
                                  </IconButton>
                                </BootstrapTooltip>
                              </Grid>
                              <Grid item xs={6}>
                                <BootstrapTooltip title="Delete">
                                  <IconButton
                                    onClick={() => {
                                      setCardForDelete(e);
                                      setDeleteOpen(true);
                                    }}
                                    className={classes.button}
                                    aria-label="Favourite"
                                    size="large">
                                    <CloseIcon />
                                  </IconButton>
                                </BootstrapTooltip>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </Grid>;
                      })}
                    {isLoading &&
                      [1, 2, 3, 4].map((keyIndex) => {
                        return <UserCreditCards key={keyIndex} />;
                      })}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Container>
    </StyledBox>
  );
}
