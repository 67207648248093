import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useHistory,useLocation } from 'react-router-dom';
import { Box,MenuList,AppBar,Toolbar,Typography,IconButton,Popper,Paper,ClickAwayListener,Button,Grid} from '@mui/material';
import { alpha } from '@mui/material/styles'; 
import { API, Auth } from 'aws-amplify';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ListItemText from '@mui/material/ListItemText';
import usePersistedState from '../../../usePersistedState';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddNewProperty from '../../../components/property/addnewpropertydialog';
import Grow from '@mui/material/Grow';
import AddIcon from '@mui/icons-material/Add'; 
import { PortalCircleLogo } from '../../../static/svgfile';
import SettingsIcon from '@mui/icons-material/Settings';
import useMediaQuery from '@mui/material/useMediaQuery';
import EditUser from "../../../components/user/editUser";

const PREFIX = 'AppBar';

const classes = {
  root: `${PREFIX}-root`,
  menuButton: `${PREFIX}-menuButton`,
  moreVert: `${PREFIX}-moreVert`,
  selectBox: `${PREFIX}-selectBox`,
  propertyButton: `${PREFIX}-propertyButton`,
  title: `${PREFIX}-title`,
  selectList: `${PREFIX}-selectList`,
  breadCrumbRoot: `${PREFIX}-breadCrumbRoot`,
  dividerColor: `${PREFIX}-dividerColor`,
  rightMenuIcon: `${PREFIX}-rightMenuIcon`,
  leftMenuIcon: `${PREFIX}-leftMenuIcon`,
  accountRoot: `${PREFIX}-accountRoot`,
  accountedgeEnd: `${PREFIX}-accountedgeEnd`,
  accountedgeHome: `${PREFIX}-accountedgeHome`,
  deviderClass: `${PREFIX}-deviderClass`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: { 
    backgroundColor: '#cccccc',
    flexGrow: 1,
    color:'#FFF',
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2)
  },

  [`& .${classes.moreVert}`]: {
    color: '#fff'
  },

  [`& .${classes.selectBox}`]: {
    position: 'relative',
    marginRight: '15px', 
    width: '100%',
    [theme.breakpoints.up('sm')]: { 
      width: 'auto',
    },
  },

  [`& .${classes.propertyButton}`]: {
    padding: 12,
    '&:hover': {     
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
  },

  [`& .${classes.title}`]: {
    fontSize:'1rem',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },

  [`& .${classes.selectList}`]: {
      margin:'14px' 
  },

  [`& .${classes.breadCrumbRoot}`]: {
    marginLeft:'18px'
  },

  [`& .${classes.dividerColor}`]: {
    backgroundColor: '#707070',    
  },

  [`& .${classes.rightMenuIcon}`]: {
    justifyContent: 'flex-end',
    width: 14,
    height: 14,
  },

  [`& .${classes.leftMenuIcon}`]: {
   justifyContent: 'flex-start',
   width: 14,
   height: 14,
   minWidth: '27px'
 },

  [`& .${classes.accountRoot}`]: {
    width: 'fit-content',  
    '& svg': {
      margin: theme.spacing(1),
    } 
  },

  [`& .${classes.accountedgeEnd}`]: {
    marginRight: '0',
  },

  [`& .${classes.accountedgeHome}`]: {
    marginRight: '0',
    paddingRight:'0'
  },

  [`& .${classes.deviderClass}`]: {
    backgroundColor:'white',
    marginTop:'15px',
    marginBottom:'21px'
  }
}));

export function MenuAppBar(props) {   
  let history = useHistory();
  const location = useLocation();  

  console.log("----- props ------");
  console.log(props);

  const [anchorEl, setAnchorEl] = useState(null);
  const [breadAnchorEl, setbreadAnchorEl] = React.useState(null);
  const [anchorAccountMenu, setAchorAccountMenu] = React.useState(null);    
  const [isLoading, setIsLoading] = useState(false); 
  const open = Boolean(anchorEl);
  const breadMenuOpen = Boolean(breadAnchorEl);
  const listopen = Boolean(anchorAccountMenu);
  const [selectedProperty, setSelectedProperty] = usePersistedState('selectedProperty', props.selectedProperty? props.selectedProperty:{});  
  const [addNewProperty, setAddNewProperty] = React.useState(false); 
  const [transactionTypeCombo, setTransactionTypeCombo] = usePersistedState('transactionTypeCombo',[]); 
  const [userProperties,setUserProperties] = usePersistedState('userProperties', []); 
  const propertyName=selectedProperty?.PropertyName?selectedProperty.PropertyName.replace(/\s+/g, '-'):''
  const [userFormOpen,setUserFormOpen]= useState(false); 
 
  let item = {
    '/': [],
    '/verify':[],
    '/reset':[],
    '/decline':[], 
    '/:property/settings':[{ to: `/${propertyName}/settings`, key: 'setting property', label: 'Property Settings' }],
    '/:property/settings/general': [{ to: `/${propertyName}/settings`, key: 'setting property', label: 'Property Settings' }, { to: `/${propertyName}/settings/general`, key: 'setting general', label: 'General' }],
    '/:property/settings/payments':[{ to: `/${propertyName}/settings`, key: 'setting property', label: 'Property Settings' }, { to:`/${propertyName}/settings/payments`, key: 'setting payment', label: 'Payments' }],
    '/:property/settings/teams':[{ to: `/${propertyName}/settings`, key: 'setting property', label: 'Property Settings' }, { to: `/${propertyName}/settings/teams`, key: 'setting teams', label: 'Teams' }],        
    '/:property/settings/teams/edit':[{ to: `/${propertyName}/settings`, key: 'setting property', label: 'Property Settings' },
     { to: `/${propertyName}/settings/teams`, key: 'setting teams', label: 'Teams' },{ to: `/${propertyName}/settings/teams/edit`, key: 'edit team', label: 'Edit Team Member' }],            
    '/:property/lease':[{ to: `/${propertyName}/lease`, key: 'ManageRents', label: 'Manage Lease' }] ,
    '/:property/transactions': [{ to: `/${propertyName}/transactions`, key: 'ViewTransactions', label: 'View Transactions' }],
    '/:property/lease/add': [{ to: `/${propertyName}/lease`, key: 'ManageLease', label: 'Manage Lease' },
      { to: `/${propertyName}/lease/add`, key: 'addLease', label: 'Add Lease' }],
    '/:property/lease/edit': [{ to: `/${propertyName}/lease`, key: 'ManageLease', label: 'Manage Lease' },
      { to: `/${propertyName}/lease/edit`, key: 'editLease', label: 'Edit Lease' }],   
    '/:property/tenant': [{ to: `/${propertyName}/tenant`, key: 'user grid', label: 'Tenant Management' }],
    '/:property/tenant/edit': [{to: `/${propertyName}/tenant`, key: 'user grid', label: 'Tenant Management' }, { to: `/${propertyName}/tenant/edit`, key: 'edit tenant', label: 'Edit Tenant' }],
    '/:property/tenant/add': [{ to: `/${propertyName}/tenant`, key: 'user grid', label: 'Tenant Management' }, { to: `/${propertyName}/tenant/add`, key: 'add tenant', label: 'Add Tenant' }],
    '/:property/tenant/view':[{ to: `/${propertyName}/tenant`, key: 'user grid', label: 'Tenant Management' }, { to: `/${propertyName}/tenant/view`, key: 'view view tenant', label: 'View Tenant' }],
    '/profile': [{ to: '/profile', key: 'manage user', label: 'Profile Edit' }],    
    '/settings':[{ to: '/settings', key: 'Global Settings', label: 'Global Settings' }],

    '/:property/payment': [{ to: `/${propertyName}/payment`, key: 'manage payment', label: 'Make a payment' }],   
    '/:property/history': [{ to: `/${propertyName}/history`, key: 'view billing', label: 'Billing History' }],    
    '/:property/manage': [{ to: `/${propertyName}/manage`, key: 'manage payment method', label: 'Manage Payment Method' }],    
    '/:property':[{ to: `/${propertyName}`, key: 'dashboard', label: 'Dashboard' }],
  }
  const matches = useMediaQuery("(max-width:868px)");
  /**
   * this function will load properties for the user
   */
  function loadMyProperties() {
    setIsLoading(true);
    var masterType = 'User';
    var apiModule = 'property';
    var op = 'listMyProperty';
    let body = { userData: props.userSession, masterType: masterType, MasterID: props.userSession.UserName, for: 'CardDisplay' };
    return new Promise((resolve, reject) => {
      let apiName = 'rentapi';
      let path = '/modules';
      let myInit = {
        headers: { 'Content-Type': 'application/json' },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit)
        .then(response => {
          if (response && response.length > 0) {
            
            resolve(response);
          }
          else {
            resolve([]);
          }
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
  /**
   * This will auto-select if only one property in the list selectedProperty
   */  
   useEffect(() => { 
    setSelectedProperty(props.selectedProperty);    
  }, [props.selectedProperty]);
  /*  useEffect(() => {
     let isSubscribed = true; 
     const propertyFetch = async () => {
       if (location.props && isSubscribed) { 
         if(location.props.currentProperty)
          {
            await setSelectedProperty(location.props.currentProperty);  
          } 
          if(location.props.userProperties)
          {
            await setUserProperties(location.props.userProperties);
          }
        }
       return () => isSubscribed = false
     }
     propertyFetch();
   }, [location]); */
  useEffect(()=>{
    (async () => { 
      transactionTypeCombo && transactionTypeCombo.length===0 && (await getTransactionType());  
    })(); 
 },[])

  async function getTransactionType() {  
    let apiName = 'rentapi';
    let path = '/store/transactionType';
    let myInit = {
      headers: { 'Content-Type': 'application/json' }
    };
    API.post(apiName, path, myInit).then(response => {  
        setTransactionTypeCombo(response);
        return;
    }).catch(err=>{console.log(err); throw err;;
    });   
  }
  const handleListClick = event => { 
    setAchorAccountMenu(event.currentTarget);
  };
  const handleListClose =  event => {
    setAchorAccountMenu(null);
  };
  const selectProperty = selectedNew => {
    setSelectedProperty(selectedNew); 
    history.push({ pathname: `/${selectedNew.PropertyName.replace(/\s+/g, '-')}`,props:{ currentProperty:selectedNew}});  
  } 
  async function handleLogout() {     
  /*   shutdownIntercom(); */
    delete localStorage.email;
    // Reboot Intercom in anonymous visitor mode
   /*  bootIntercom() */
    await Auth.signOut();
    props.userHasAuthenticated(false);    
    window.localStorage.removeItem('selectedProperty');
    window.localStorage.removeItem('userProperties');    
    window.localStorage.removeItem('renterProperty');
    window.localStorage.removeItem('user');
    setSelectedProperty({});
    props.history.push('/');
  }
  const handleBreadMenu = event => {
    setbreadAnchorEl(event.currentTarget);
  };
  const handleBreadClose = () => {
    setbreadAnchorEl(null);
  };
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  }; 
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleChildClick(type,value) {   
    if(type==='close'){ 
      setAddNewProperty(false);
    }
    if(type==='success')
    {
      setAddNewProperty(false);
      let myProperties = await loadMyProperties(props.userSession.UserName);      
      if (myProperties && myProperties.length == 1) {
        setUserProperties(myProperties);     
      } 
    } 
  } 
  return (
    <Root className={classes.root}>
    {userFormOpen && <EditUser type={"profile"} userSession={props.userSession} isEditing={true} user= {{}} fromGrid={false} userFormOpen={userFormOpen} setUserFormOpen={setUserFormOpen} />}
    {props.userSession.Privilege === 'ADMIN' && <AddNewProperty {...props} addNewProperty={addNewProperty} onChildClick={handleChildClick} />}
    <AppBar style={{ backgroundColor: 'rgba(0,0,0,1)' }} position='static'>
        <Toolbar> 
        { (props.path !== '/profile' && props.path !== '/settings' && props.path!=='/' && userProperties && userProperties.length && props.userSession.Privilege !== 'RENTERS') || (props.path !== '/profile' && props.path !== '/settings' && props.path!=='/' && userProperties && userProperties.length>1 && props.userSession.Privilege === 'RENTERS' ) ?
        <> 
          <IconButton
            edge='end'       
            sx={{padding:1.5}}    
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={(e) => { history.push('/'); }}
            color='inherit'>
          <PortalCircleLogo  sx={{width:"48px",height:"32px"}} style={{verticalAlign:'middle'}} />
          </IconButton>
            {selectedProperty?.PropertyName && 
            <>
            <Button  className={classes.propertyButton} onClick={handleListClick}>
               {props.whichDevice!=='Mobile' && <Typography
                style={{ color: 'rgba(255,255,255,1)', textTransform:'capitalize' }}            
                noWrap
                className={`${matches ? "reponsive-hide-text" : ""}`}
              >
                {selectedProperty.PropertyName}
              </Typography> }             
              {listopen ? (
                <ExpandLess style={{ color: 'rgba(255,255,255,1)' }} />
              ) : (
                <ExpandMore style={{ color: 'rgba(255,255,255,1)' }} />
              )}
            </Button>           
            <Popper
              open={!!anchorAccountMenu}
              anchorEl={anchorAccountMenu}
              transition 
              className='appbar-popper'
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  id='menu-item-grow'
                  style={{ transformOrigin: 'center top' }}
                >
                  <Paper
                    style={{
                      backgroundColor: 'rgba(0,0,0,1)',
                      color: 'rgba(255,255,255,1)',
                    }}
                  >
                    <ClickAwayListener onClickAway={handleListClose}>
                      <MenuList className={classes.selectList}>
                        {
                        userProperties && userProperties.length && userProperties.map((property,index) => { 
                          return(
                            <MenuItem key={index} onClick={() => {                              
                              selectProperty(property);
                              handleListClose()
                              }}>
                            <ListItemIcon className={classes.leftMenuIcon}>
                              <ArrowForwardIosIcon
                                style={{ color: 'rgba(255,255,255,1)' , width: 14,height: 14,}}
                              />
                            </ListItemIcon>
                            <ListItemText
                              classes={{ primary: classes.title }}
                              primary={property.PropertyName}
                            />

                          </MenuItem>
                          )
                        })
                      } 
                       
                        {props.userSession.Privilege == 'ADMIN' && <div>
                        <Divider classes={{ root: classes.dividerColor }} />
                        <MenuItem onClick={() => { setAddNewProperty(true); handleListClose(); }}>
                          <ListItemIcon className={classes.leftMenuIcon}>
                            <AddIcon className='appbar-popper-plus-icon' style={{ color: 'rgba(255,255,255,1)' }} />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: classes.title }}
                            className='appbar-addnewproperty-padding-top-3p'
                            primary='Add A New Property'
                          />
                        </MenuItem></div>}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            </>
            }         
        </>
        :
        <>
          <IconButton
            edge='end'
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            style={{}}
            onClick={(e) => { history.push('/'); }}
            color='inherit' 
            size="large">
            <PortalCircleLogo sx={{width:"48px",height:"32px"}} />
          </IconButton>
          {props.whichDevice!=='Mobile' && props.path !== '/' && props.path !== '/profile' &&  props.path !== '/settings' && 
                <Typography
                  variant='h6'
                  className='menuappbar-title'
                  style={{ color: 'rgba(255,255,255,1)', textTransform: 'capitalize' }}
                  noWrap
                >
                  {selectedProperty.PropertyName}
                </Typography>  
          }
          </>
          } 
          {selectedProperty && selectedProperty.PropertyName &&
            <>
              {props.path !== '/profile' && props.path !== '/settings' && props.path !== '/' &&
                <Divider className={classes.deviderClass} orientation='vertical' flexItem />
              }
              {props.path !== '/profile' && props.path !== '/settings' && props.path !== '/' && props.isMobile && 
                    <Box
                    className='homeBox homeBox-alone'
                  >
                    <HomeOutlinedIcon className='homeIcon' />                    
                  </Box>
                }
             {!props.isMobile && <Breadcrumbs maxItems={2}
                classes={{ root: classes.breadCrumbRoot }}
                className={`${matches ? "reponsive-hide-text" : ""}`}
                separator={
                  <NavigateNextIcon
                    style={{ color: 'rgba(255,255,255,1)' }}
                    fontSize='small'
                  />
                }
                aria-label='breadcrumb'
              >
                {props.path !== '/' && props.path !== '/profile' &&  props.path !== '/settings' && props.path !== '/admin/register' ?
                  <Link style={{ textDecoration: 'none' }} to={`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}`}>
                    <Box
                      className='homeBox'
                    >
                      <HomeOutlinedIcon className='homeIcon' />
                     { props.whichDevice !== 'Mobile' && <Typography
                        style={{ color: 'rgba(255,255,255,1)' }}
                      >
                        {'Dashboard'}
                      </Typography>}
                    </Box>
                  </Link>
                  : null} 
                {props.path &&
                  item[props.path].map(({ to, label, key }, keyIndex) => {
                    while (keyIndex < 4 && key!=='dashboard') {
                      return (
                        <Link
                          key={keyIndex}
                          style={{ textDecoration: 'none' }}
                          to={to}
                        >
                          <Typography
                            style={{ color: 'rgba(255,255,255,1)' }}
                          >
                            {label}
                          </Typography>
                        </Link> 
                      );
                    }
                  })}
                {item[props.path] > 4 && (
                  <div className='menuappbar-moreVert'>
                    <IconButton
                      aria-label='menu'
                      aria-controls='menu-appbar'
                      aria-haspopup='true'
                      edge='start'
                      className='menuappbar-menuButton'
                      color='inherit'
                      onClick={(e) => handleBreadMenu(e)}
                      size="large">
                      <MoreVertIcon />
                    </IconButton>

                    <Menu
                      id='long-menu'
                      anchorEl={breadAnchorEl}
                      //keepMounted
                      open={breadMenuOpen}
                      onClose={(e) => handleBreadClose()}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      {props.appPath &&
                        item[props.path].map((m, keyIndex) => { 
                          if (keyIndex >= 4 && keyIndex < item[props.path].length) { 
                            return (
                              <MenuItem
                                key={m.key}
                                onClick={(e) => history.push(m.to)}
                              >
                                {m.label}
                              </MenuItem>
                            );
                          }
                        })}
                    </Menu>
                  </div>
                )}
              </Breadcrumbs>}
            </>
          }
          <Box flexGrow={1} />
          <div>
          <Grid container alignItems='center' className={classes.accountRoot}>
          {props.path !== '/profile' && props.path !== '/settings' && props.path!=='/' && props.userSession.Privilege === 'ADMIN' && selectedProperty.PropertyName && <IconButton
            edge='end'
            aria-label='admin settings'
            aria-haspopup='true'
            onClick={()=>{
              history.push({
                pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/settings`,
                props: {
                  fromAppbar: true,                   
                },
              });
            }}
            classes={{edgeEnd:classes.accountedgeEnd}}
            color='inherit'
            size="large">
              <SettingsIcon />
              {!props.isMobile && <Typography style={{ paddingLeft: '10px' }} className={`${matches ? "reponsive-hide-text" : ""}`}>
                {'Property Settings'} 
              </Typography>}
            </IconButton>
            }
            {props.path !== '/profile' && props.path !== '/settings' && props.path!=='/' && props.userSession.Privilege === 'ADMIN' && selectedProperty.PropertyName && 
             <Divider   className={classes.deviderClass} orientation='vertical' flexItem /> }
            <IconButton
              edge='end'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleMenu}
              color='inherit'
              classes={{edgeEnd:classes.accountedgeEnd}}
              size="large">
              <AccountCircle />

              {!props.isMobile && <Typography style={{paddingLeft: '10px'}} className={`${matches ? "reponsive-hide-text" : ""}`} >
                {' '}
                {props.userSession.FirstName}
              </Typography>}
            </IconButton>
            </Grid>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={async () => {
                  await handleClose();
                  setUserFormOpen(true);
                  /* history.push({
                    pathname: '/profile',
                    props: {
                      isEditing: true,
                      rowEditData: [],
                      fromGrid: false,
                    },
                  }); */
                }}
              >
                Profile
              </MenuItem>
              <MenuItem component={Link} to='/SignIn' onClick={handleLogout}>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Root>
  );
}