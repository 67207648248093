import React from "react";
import { Link } from "react-router-dom"; 
import MLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
export default function Policy(props) {
  return (
    <Box className={props.class}   >

    <Grid
    container
    spacing={1}
    direction="row"
    justifyContent="center"
    alignItems="center"
    >
    <Grid item >
      <MLink color="text.secondary" underline={"always"} component={Link} to="/terms-of-service">   
      <p>   TERMS OF SERVICES
          </p>            
      </MLink>
    </Grid>
    <Grid item>
    <p> | </p>     
    </Grid>
    <Grid item>
      <MLink color="text.secondary" underline={"always"} component={Link} to="/privacy-policy" variant="body2">
      <p> 
        PRIVACY POLICY
        </p>                  
      </MLink>
    </Grid>
    </Grid>
            
        </Box>
  );
}

