import React, { useState, forwardRef, useImperativeHandle } from "react";
import { styled } from '@mui/material/styles'; 
import {IconButton, DialogContentText, DialogContent, DialogActions, Dialog, Grid, Typography} from "@mui/material"; 
import MuiDialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close'; 
import {LoaderCLickButton} from "../../../components/LoaderButton";


const PREFIX = 'ModelWindow';

const classes = {
  root: `${PREFIX}-root`,
  closeButton: `${PREFIX}-closeButton`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    margin: 0,
    padding: 2,
  },

  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: 1,
    top: 1,
    color: theme.palette.grey[500],
  }
}));

const DialogBox = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));

const DialogTitle = (props => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography sx={{ m: 0, p: 2, pl: 3, pr: 3 }}  {...other}>
      <Typography variant="h3" component="h3" sx={{ lineHeight: 1 }}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: "8px",
            top: "8px",
            color: "text.secondary",
          }}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const ModelWindow =  forwardRef((props, ref) => {
  const [modelOpen, setModelOpen] = useState(false);
  const handleClose = () => {
      setModelOpen(false);   
    };
  const handleOpen = () => {  
    setModelOpen(true);
  };
  useImperativeHandle(ref, () => {
    return {
        handleOpen: handleOpen,
        handleClose: handleClose
    };
  });
  const onClickOne= (e)=>{
    props.onClickOne(e)
  }
  const onClickTwo= (e)=>{
    props.onClickTwo(e)
  } 

  return (
    <Root>
 <DialogBox open={modelOpen} onClose={e=>handleClose(e)} fullWidth={true}
     maxWidth={"sm"} aria-labelledby="form-dialog-title">        
       <DialogTitle  id="delete-dialog-title" onClose={e=>handleClose(e)}>
       {props.title}
       </DialogTitle>
       <DialogContent sx={{ color:'#000',}}>   
       <DialogContentText variant='body1' component='h3'>
           {props.content}
         </DialogContentText>
       </DialogContent>
        <DialogActions>
          <Grid container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start">
            <Grid item>
              {props.IsbuttonOne && <LoaderCLickButton
                //size="large"
                onClick={onClickOne}
                isLoading={false}
              >
                {props.buttonOneText}
              </LoaderCLickButton>}
            </Grid>
            <Grid item>
              {props.IsbuttonTwo && <LoaderCLickButton
                //size="large"
                onClick={onClickTwo}
                isLoading={false}
              >
                {props.buttonTwoText}
              </LoaderCLickButton>}
            </Grid>
          </Grid>
        </DialogActions>
     </DialogBox>
     </Root>
  );
});
  