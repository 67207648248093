import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom"; 
import Copyright from "../lib/Copyright";
import PaymentInfo from "../lib/securepayinfo";
import {MenuAppBar} from "../common/components/AppBar";  
import usePersistedState from '../usePersistedState';
export default function AppliedRoute({ component: C, appProps, ...rest }) {   
  const [currentProperty, setCurrentProperty] = useState({});  
     useEffect(() => {
    let slProperty= JSON.parse(localStorage.getItem('selectedProperty'));  
    console.log('AppliedRoute slProperty',slProperty); 
    setCurrentProperty(slProperty);
  },[appProps]);
  return { ...(appProps.isAuthenticated && (rest.path==="/:property/manage" || rest.path=== "/:property/payment") ? <Route {...rest} render={props => <><MenuAppBar
    {...appProps}
    selectedProperty={currentProperty}
    {...rest}  
  /><C {...props} {...appProps} /><PaymentInfo /></>} /> : appProps.isAuthenticated && rest.path!=='/verify'?
  <Route {...rest} render={props => <><MenuAppBar
    {...appProps}
    selectedProperty={currentProperty}
    {...rest}  
  /><C {...props} {...appProps} /><Copyright {...props} {...appProps}  class= {"footer-wtbg"} /></>} />:
  <Route {...rest} render={props => <><C {...props} {...appProps} /><Copyright {...props} {...appProps}   class= {"footer-wtbg"} /></>} />)
};
}

 