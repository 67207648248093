import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import Link from '@mui/material/Link';
import {TextField, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Container from "@mui/material/Container";
import Fab from '@mui/material/Fab';
import { API } from "aws-amplify";
import Moment from 'react-moment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import CustomizedTooltips from '../../lib/tooltIp';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { useHistory } from "react-router-dom";
import { Skeleton } from '@mui/material';
import AddPaymentOffline from './addpaymentdialog';
import usePersistedState from '../../usePersistedState';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
//** Data Grid Import Part **/
import {
    LicenseInfo,
    DataGridPro,
    GridOverlay,
    useGridApiRef,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarExport
} from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';

const PREFIX = 'viewtransactions';
const MapData= (process.env.REACT_APP_STAGE==="dev")? awsdev:( process.env.REACT_APP_STAGE==="dev2")? awsdev2 :window['runConfig'];

const classes = {
    root: `${PREFIX}-root`,
    textPrimary: `${PREFIX}-textPrimary`
};
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        display: 'inline-flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        color: theme.palette.text.secondary,
    },

    [`& .${classes.textPrimary}`]: {
        color: theme.palette.text.primary,
    }
}));
LicenseInfo.setLicenseKey(
    '79e390e23cc1709a04137710c7c5cdc1T1JERVI6Mjg0NDQsRVhQSVJZPTE2NjEwMDE3MTQwMDAsS0VZVkVSU0lPTj0x',
);
const MAX_ROW_LENGTH = 1500;
//** Data Grid Import Part **/
const min = new Date('2018-01-01').getFullYear();
const max = new Date().getFullYear();
const comboYear = [];
for (var i = min; i <= max; i++) {
    comboYear.push(i)
}
const months = {
    "January": 0,
    "February": 1,
    "March": 2,
    "April": 3,
    "May": 4,
    "June": 5,
    "July": 6,
    "August": 7,
    "September": 8,
    "October": 9,
    "November": 10,
    "December": 11
}
function getTenants(selectedProperty, userSession) {
    var body = {
        PropertyID: selectedProperty.PropertyID,
        PropertyData: selectedProperty,
        FilterData: ""
    }
    body.userData = userSession;
    return new Promise((resolve, reject) => {
        let apiName = "rentapi";
        let path = "/modules";
        let myInit = {
            headers: { "Content-Type": "application/json" },
            body: body,
            queryStringParameters: {
                module: 'user',
                op: 'getActiveTenant'
            }
        };
        API.post(apiName, path, myInit)
            .then(async response => {
                resolve({ paidBy: response.paidBy, leaseHolders: response.leaseHolders });
            })
            .catch(err => {
                console.log(err);
                reject();
            });
    });
}
//** Data Grid Action related Code */
function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <Root style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </Root>
        </GridOverlay>
    );
}

function RowMenuCell({ params,userSession, selectedProperty, ...rest }) {  
    const {api, id, row} = params;
    const [addOfflinePayment, setAddOfflinePayment] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [offlinePayment, setOfflinePayment] = useState({});
    const [tenantList, setTenantList] = useState([]);
    const [leaseHolderList, setLeaseHolderList] = useState([]);

    async function editOfflinePAyment(event) { 
        setAddOfflinePayment(true);
        setIsLoadingModal(true);
        const rowData = api.getRow(id);
        let ob = {};
        ob.rentAmount = rowData.TransactionRent;
        ob.tenant = rowData.TransactionRenterID;
        ob.transactionDate = rowData.TransactionOn;
        let month = rowData.TransactionRentMonth ? rowData.TransactionRentMonth.split(" ")[0] : -1;
        ob.rentMonth = months[month];
        ob.securityDeposit = rowData.TransactionDeposit;
        ob.miscFee = rowData.TransactionMisc && rowData.TransactionMisc.length && rowData.TransactionMisc[0].appFeeValue ? Number(rowData.TransactionMisc[0].appFeeValue) : 0;
        ob.unit = rowData.TransactionUnit;
        ob.transactionType = rowData.TransactionType;
        ob.isEditing = true;
        ob.transactionId = rowData.MasterID
        ob.leaseHolder = rowData.RentMasterID
        ob.currentPayedRentMonth = rowData.TransactionPaidMonth;
        ob.totalAmountTobePaid = 0;
        if (ob.rentAmount) ob.totalAmountTobePaid += ob.rentAmount;
        if (ob.securityDeposit) ob.totalAmountTobePaid += ob.securityDeposit;
        if (ob.miscFee) ob.totalAmountTobePaid += Number(ob.miscFee);
        ob.proceedPayMiscFee = false;
        ob.proceedSecurityDeposit = false;
        if (ob.miscFee && Number(ob.miscFee) > 0) ob.proceedPayMiscFee = true;
        if (ob.securityDeposit) ob.proceedSecurityDeposit = true;
        let tenantResult = await getTenants(selectedProperty, userSession);
        if (tenantResult.paidBy) setTenantList(tenantResult.paidBy);
        if (tenantResult.leaseHolders) setLeaseHolderList(tenantResult.leaseHolders);
        setIsLoadingModal(false)
        if (tenantResult.leaseHolders && tenantResult.leaseHolders.length) {
            let currentHolder = tenantResult.leaseHolders.find(x => x.leaseMasterId == rowData.RentMasterID);
            ob.outstanding = currentHolder && currentHolder.outstanding ? currentHolder.outstanding : [];
            if (rowData.TransactionPaidMonth) ob.outstanding.push(rowData.TransactionPaidMonth);
        }

        if (tenantResult.paidBy && tenantResult.paidBy.length) {
            let tenantsIn = tenantResult.paidBy.filter(f => f.leaseMasterId == rowData.RentMasterID);
            ob.paidByList = tenantsIn;
        }
        setOfflinePayment(ob); 
    }
    return (
        <div className={classes.root}>
           {<AddPaymentOffline isLoading={isLoadingModal} leaseHolderList={leaseHolderList} tenantList={tenantList} addOfflinePayment={addOfflinePayment} setAddOfflinePayment={setAddOfflinePayment} rowData={offlinePayment} />}
           <Tooltip title="Edit">
            <IconButton
                color="inherit"
                className={classes.textPrimary}
                size="small"
                aria-label="edit"
                onClick={async (e)=>{ await editOfflinePAyment(e)}}
                disabled={!((userSession.Privilege === "MANAGER" || 
                    userSession.Privilege === "BILLING" ||
                    userSession.Privilege === "ADMIN") &&
                    (row.TransactionType &&
                        (row.TransactionType === 'cash' ||
                            row.TransactionType === 'etransfer' ||
                            row.TransactionType === 'checkdeposit')))}
            >
                <EditIcon fontSize="small" />
            </IconButton>      
            </Tooltip>
        </div>
    );
}
function CustomToolbar(props) { 
    return (
        <GridToolbarContainer>
            <Grid container
                spacing={3}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                   <Grid item minWidth="320px" >
                    {!props.loading && <TextField
                        variant="outlined" 
                        fullWidth                            
                        display="flex"
                        label="Search email, name or unit #.."   
                        onBlur={props.onBlur}
                        onKeyDown={props.onKeyDown}                         
                        onChange={props.onChange}
                        value={props.value}
                        InputProps={{ 
                            endAdornment: (
                              <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                                onClick={props.onBlur}
                              >
                                <SearchIcon fontSize="small" />
                              </IconButton>
                            ),
                          }}
                    />}
                    {props.loading && <Skeleton variant="rectangular" height={52} />}
                </Grid>
                <Grid item >
                    {props.loading && <div style={{ display: "flex" }}>
                        <Skeleton variant="rectangular" width={30} height={32} className={"skelton-margin-right-5"} />
                        <Skeleton variant="rectangular" width={100} height={32} />
                    </div>}
                    {!props.loading && <GridToolbarFilterButton />}
                </Grid>
                <Grid item>
                    {props.loading && <div style={{ display: 'flex' }}>
                        <Skeleton variant='rectangular' width={30} height={32} className={'skelton-margin-right-5'} />
                        <Skeleton variant='rectangular' width={100} height={32} />
                    </div>}
                    {!props.loading && <GridToolbarExport
                        csvOptions={{
                            fileName: props.customFileName,
                            delimiter: ',',
                            utf8WithBom: true,
                        }}
                    />}
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}
//** Data Grid Action related Code */
export function ViewTransactions(props) {
    let history = useHistory(); 
    const [isLoading, setIsLoading] = useState(false);
    const [tenantList, setTenantList] = useState([]);
    const [leaseHolderList, setLeaseHolderList] = useState([]);
    const [addOfflinePayment, setAddOfflinePayment] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    // Transaction type will get when application loading, Deff in AppBar 
    const [transactionTypeCombo] = usePersistedState("transactionTypeCombo", []);
    const [selectedProperty] = usePersistedState('selectedProperty', {});
    //** Data Grid Related Code */
    const mounted = React.useRef(true);
    const apiRef = useGridApiRef();
    const [filterValue, setFilterValue] = React.useState({});
    const [searchText, setSearchText] = React.useState('');
    const [queryOptions, setQueryOptions] = React.useState({});  
    const handleOnCellClick = (params) => {
        if (params.field === "TransactionRenter") {
            console.log("On cell click event");
            console.log(params);
            const rowdata = {
                MasterID: params.row.TransactionRenterID
            };
            history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/tenant/view`, props: { rowData: { ...rowdata } } });
        } 
      };
    
    const [data, setData] = useState({
        loading: true,
        rows: [],
        pageSize: 25,
        columns: [
            { field: "MasterID", hide: true,sortable: false, filterable: false },
            {  minWidth: 100, flex: 1, headerName: "PAID BY", field: "TransactionRenter",
            renderCell: (params) => {
                return (<Link style={{ "cursor": "pointer"}}>{params.value} </Link>)
              },
        },
            {  minWidth: 100, flex: 1, headerName: "LEASE HOLDER(S)", field: "LeaseHolder" },
            {
                headerName: "TRANSACTION DATE",
                field: "TransactionOn",
                renderCell: (params) => <Moment format="MMM DD YYYY">{params.value}</Moment>,
                type: 'date'
            },
            {  minWidth: 100, flex: 1,headerName: "RENT MONTH", field: "TransactionRentMonth",type: 'date' },
            {
                minWidth: 100, flex: 1, headerName: "TRANSACTION TYPE", field: "TransactionType",
                renderCell: (params) => {
                    return params.value ? (
                        params.value == 'cash' ? "Cash"
                            : params.value == 'etransfer' ? "E-Transfer"
                                : params.value == 'checkdeposit' ? "Check"
                                    :params.value == 'STRIPE'? 'Credit Card'
                                        :params.value == 'PLAID'? 'ACH'                                    
                                            : params.value == '' ? "" : capitalizeFirstLetter(params.value.toLowerCase())
                    ) : ""
                },
                type: 'singleSelect',
                valueOptions: transactionTypeCombo.map((e) => { return e.Text })
            },
            {
                minWidth: 80, flex: 0.75,headerName: "UNIT", field: "TransactionUnit",
                renderCell: params => { return (!params.value || params.value === "") ? "-" : params.value }
            },
            {
                minWidth: 80, flex: 0.75,minWidth: 100, flex: 1,headerName: "DEPOSIT", field: "TransactionDeposit", filterable: false,
                renderCell: params => { return params.value === 0 ? "-" : "$" + params.value }
            },
            {
                minWidth: 80, flex: 0.75,headerName: "MISC", field: "TransactionMiscValue", filterable: false,
                renderCell: params => { return params.value === 0 ? "-" : "$" + params.value }
            },
            {
                minWidth: 100, flex: 1,headerName: "RENT", field: "TransactionRent", filterable: false,
                renderCell: params => { return params.value === 0 ? "-" : "$" + params.value }
            },
            {
                minWidth: 130, flex: 1,field: "ConvenienceFee", filterable: false,
                renderCell: params => { return params.value === 0 ? "-" : "$" + params.value },
                renderHeader: () => (
                    <> <strong>
                        {'FEE (TENANT) '}
                    </strong>
                        <CustomizedTooltips content={'Payment gateway processing fee paid by the tenant'} />
                    </>
                )
            },
            {
                minWidth: 100, flex: 1,headerName: "TOTAL", field: "TransactionTotal", filterable: false,
                renderCell: params => { return params.value === 0 ? "-" : "$" + params.value }
            },
            {
                minWidth: 150, flex: 1, field: "StripeCharges", filterable: false,
                renderCell: params => { return params.value === 0 ? "-" : "$" + params.value },
                renderHeader: () =>
                    <> <strong>
                        {'FEE (PROPERTY) '}
                    </strong>
                        <CustomizedTooltips content={'Payment gateway processing fee paid by the property'} />
                    </>
            },
            {
                minWidth: 100, flex: 1,headerName: "NET(" + selectedProperty.CurrencyCode + ")", field: "NetAmount",
                renderCell: params => { return params.value === 0 ? "-" : "$" + params.value },
                filterable: false
            },
            {
                minWidth: 100, flex: 1,headerName: 'Actions',
                field: 'actions',
                sortable: false,
                headerAlign: 'center',
                filterable: false,
                align: 'center',
                disableColumnMenu: true,
                disableExport: true,
                disableReorder: true,
                renderCell: params => { return <RowMenuCell params={params} userSession={props.userSession} selectedProperty={selectedProperty}/> } 
            }
        ]
    });
    const handleSortModelChange = React.useCallback((sortModel) => { 
        console.log('sort model change');
        setQueryOptions({ sortModel: [...sortModel] });
      }, []);
    var cusFileName = MapData.aws_app_domain_prefix + "-" + selectedProperty?.PropertyName?.replace(/ /g,"_") + "-" + moment().format('MMMM-DD-YYYY')    
    const updateData = (k, v) => setData((prev) => ({ ...prev, [k]: v }));
    const onFilterChanges = React.useCallback((filterModel) => { 
        setFilterValue(filterModel);
        mounted.current = true;
    }, []);
    useEffect(() => {
        console.log('queryOptions $$$$',queryOptions);
        let active = true;
        (async () => { 
          updateData('loading', true);
          const newData = await GetInfinityTable(data.pageSize, 0); 
          if (mounted.current) {
            if (newData?.data)
              updateData('rows', newData.data);
          }
          if (!active) {
            return;
          }
          updateData('loading', false);
          setIsLoading(false);
        })();
        return () => {
          active = false;
        };
      }, [queryOptions]);
    useEffect(() => {
        let active = true;
        (async () => {
            const newData = await GetInfinityTable(data.pageSize, 0);
            if (mounted.current) {
                if (newData && newData.data)
                    updateData("rows", newData.data);
            }
            if (!active) {
                return;
            }
            updateData("loading", false);
            setIsLoading(false);
        })();
        return () => {
            active = false;
        };
    }, [filterValue]);  
  
    const requestSearch = async (searchValue) => { 
        setSearchText(searchValue);  
      }; 
    const handleKeyDown = async (event) => { 
        const ENTER_KEY = 13;
        if (event.keyCode === ENTER_KEY && searchText && searchText!=='') { 
            setIsLoading(true);
            updateData("loading", true);
            const newData = await GetInfinityTable(data.pageSize, 0);
            if (newData && newData.data)
                updateData("rows",newData.data);
            updateData("loading", false);
            setIsLoading(false); 
        }
    };  
    const handleBlur = async (event) => {
        if (searchText && searchText !== '') {
            setIsLoading(true);
            updateData("loading", true);
            const newData = await GetInfinityTable(data.pageSize, 0);
            if (newData && newData.data)
                updateData("rows", newData.data);
            updateData("loading", false);
            setIsLoading(false);
        }
    };  
    const loadServerRows = async (newRowLength) => {
        updateData("loading", true);
        const newData = await GetInfinityTable(data.pageSize, newRowLength);
        if (mounted.current) {
            if (newData && newData.data)
                updateData("rows", data.rows.concat(newData.data));
        }
        updateData("loading", false);
        setIsLoading(false);
    };

    const handleOnRowsScrollEnd = (params) => {
        if (data.rows.length <= MAX_ROW_LENGTH && data.rows.length !== 0) {
            loadServerRows(data.rows.length);
        }
    };
    async function GetInfinityTable(pageSize, fromNo) {
        updateData("loading", true);
        updateData("pageSize", pageSize);
        setIsLoading(true);
        var body = {
            masterType: "Transaction",
            property: selectedProperty,
            UserID: props.userSession.UserName,
            pageSize: pageSize,
            fromNo: fromNo,
            userData: props.userSession,
            searchValue: searchText,
            filterValue: filterValue,
            sortModel: queryOptions.sortModel
        };
        body.userData = props.userSession;
        return new Promise((resolve, reject) => {
            let apiName = "rentapi";
            let path = "/modules";
            let myInit = {
                headers: { "Content-Type": "application/json" },
                body: body,
                queryStringParameters: {
                    module: 'biller',
                    op: 'viewTransactionsAdmin'
                }
            };
            API.post(apiName, path, myInit)
                .then(async response => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.log(err);
                    reject([]);
                });
        });
    }
    //** Data Grid Related Code END */

    let capitalizeFirstLetter = (word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }
    const addPayment = async () => {
        setIsLoadingModal(true);
        setAddOfflinePayment(true);
        let response = await getTenants(selectedProperty, props.userSession);
        if (response.paidBy) setTenantList(response.paidBy);
        if (response.leaseHolders) setLeaseHolderList(response.leaseHolders);
        setIsLoadingModal(false)
    }
    return (
        <div className="home-root">
            <AddPaymentOffline isLoading={isLoadingModal} leaseHolderList={leaseHolderList} tenantList={tenantList} addOfflinePayment={addOfflinePayment} setAddOfflinePayment={setAddOfflinePayment} />
            <Grid container
                spacing={0}
                direction="column"
            >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Container component="main" className="home-main">
                        <CssBaseline />
                        <div className="home-paper">
                            <Grid container spacing={1} sx={{ pb: 5 }} >
                                <Grid item>
                                    <Button onClick={e => {
                                        history.push(`/${selectedProperty.PropertyName.replace(/\s+/g, '-')}`);
                                    }}>
                                        <Typography
                                            component="h6"
                                            sx={{ fontWeight: 600 }}
                                            color="text.primary"
                                            variant="h6"
                                        >
                                            &#8249; {" RETURN"}
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container
                                spacing={2}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start">
                            </Grid>
                            <Grid container
                                spacing={4}
                                sx={{ pb: 5 }}
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start">
                                <Grid item xs={12} sm={10} md={10} lg={10} /* className={"skelton-property-page-flex-compo-grid"} */>
                                    {!isLoading &&
                                        <Typography
                                            component="h2"
                                            variant="h2"
                                        >
                                            View Transactions
                                        </Typography>
                                    }
                                    {isLoading && <div className={"skelton-flex-parent skelton-width-p100"}>
                                        <Skeleton variant="rectangular" height={69} className={"skelton-margin-right-19 skelton-width-p58"} />
                                        <Skeleton variant="rectangular" height={69} className={"skelton-width-p40"} />
                                    </div>}
                                </Grid>
                                <Grid item xs={12}>
                                    {props.userSession && (props.userSession.Privilege === "MANAGER" || props.userSession.Privilege === "BILLING" || props.userSession.Privilege === "ADMIN") &&
                                        <Grid item xs={12}>
                                            <Grid container direction="row" spacing={1} justifyContent="flex-start" alignItems="center" /* className={"skelton-property-page-flex-compo-grid"} */>
                                                <Grid item /* className={"skelton-property-page-flex-compo-grid"} */>
                                                    {!isLoading && <Fab variant="circular" size="small"
                                                        sx={{
                                                            '&.MuiFab-root': {
                                                                backgroundColor: "secondary.main",
                                                                borderRadius: '50%',
                                                            },
                                                        }} onClick={async () => {
                                                            await addPayment()
                                                        }} aria-label="add">
                                                        <AccountBalanceWalletOutlinedIcon className="home-linkIcon" />
                                                    </Fab>}
                                                    {isLoading && <Skeleton variant="circular" width={40} height={40} />}
                                                </Grid>
                                                <Grid item /* className={"skelton-property-page-flex-compo-grid"} */>
                                                    {!isLoading && <Typography /* className="home-headerThree add-payment-tag" */
                                                        component="h5"
                                                        variant="h5"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={async () => {
                                                            await addPayment()
                                                        }}
                                                    > ADD PAYMENT
                                                    </Typography>}

                                                    {isLoading && <Skeleton variant="rectangular" width={218} height={32} />}

                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {!isLoading &&
                                        <Typography /* className="biller-headerfour biller-paddingtop-1p5p" */
                                            variant="subtitle1"
                                        >
                                            View all successful transactions here. You can filter transaction data based on the date. And export it as a CSV.
                                        </Typography>
                                    }
                                    {isLoading && <Skeleton variant="text" height={32} />}
                                </Grid>
                            </Grid>
                            {/* DataGridPro Start */}
                            <div style={{ height: 1024, width: '100%' }}>
                                <div style={{ display: 'flex', height: '100%' }}>
                                    <div style={{ flexGrow: 1 }}>
                                        <DataGridPro
                                            apiRef={apiRef}
                                            density="compact"
                                            rowHeight={81}
                                            hideFooter
                                            hideFooterPagination
                                            loading={data.loading}
                                            onRowsScrollEnd={handleOnRowsScrollEnd}
                                            rows={data.rows}
                                            onCellClick= {handleOnCellClick}
                                            getRowId={(r) => r.MasterID}
                                            columns={data.columns}
                                            filterMode="server"
                                            onFilterModelChange={onFilterChanges}
                                            onSortModelChange={handleSortModelChange}
                                            componentsProps={{
                                                toolbar: {
                                                    value: searchText,
                                                    onChange: (event) => requestSearch(event.target.value),
                                                    onBlur:(event) =>handleBlur(event),
                                                    onKeyDown:(event) =>handleKeyDown(event),
                                                    loading: data.loading,
                                                    customFileName: cusFileName
                                                },
                                            }} 
                                            components={{
                                                LoadingOverlay: CustomLoadingOverlay,
                                                Toolbar: CustomToolbar
                                            }}
                                            
                                        />
                                    </div>
                                    {/* DataGridPro Start */}
                                </div>
                            </div>
                        </div>
                    </Container>
                </Grid>
            </Grid>
        </div>
    );
}