import React, { useEffect,useRef, useState } from "react";
import {
  TextField,
  styled,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import CustomizedTooltips from "../../lib/tooltIp";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import LoaderButton, { LoaderDeleteButton } from "../LoaderButton";

const DialogBox = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));

const DialogTitle = (props => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography sx={{ m: 0, p: 2, pl: 3, pr: 3 }}  {...other}>
      <Typography variant="h3" component="h3" sx={{ lineHeight: 1 }}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: "8px",
            top: "8px",
            color: "text.secondary",
          }}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export function PlaidKeyManage(props) {
  const [isEditing, setIsEditing] = useState(false);
  const [plaidProperty, setPlaidProperty] = useState({
    MasterID: ""
    , PlaidApiMode: ""
    , PlaidSecretKey: ""
    , PlaidClientKey: ""
    , IncludePlaidFee: false
  });
  const inputLabel = useRef(null);
  const [plaidOpen, setPlaidOpen] = useState(false);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setPlaidProperty(prvProperty => {
      return {
        ...prvProperty, 
        MasterID: props.PropertyID,
        PlaidClientKey: props.property.PlaidClientKey,
        PlaidClientKey: props.property.PlaidClientKey,
        PlaidApiMode: props.property.PlaidApiMode, 
        IncludePlaidFee: props.property.IncludePlaidFee?props.property.IncludePlaidFee:false
      }
    });
  }, [props.property]);
  useEffect(() => {
    if (inputLabel.current)
      setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  useEffect(() => {
    setPlaidOpen(props.plaidOpen);
    setIsEditing(props.isEditing);
  }, [props.plaidOpen]);

  function validateForm() {
    console.log("plaid property", plaidProperty);
    return (
      plaidProperty.PlaidClientKey && plaidProperty.PlaidClientKey.length > 0 &&
      plaidProperty.PlaidSecretKey && plaidProperty.PlaidSecretKey.length > 0
    );
  }

  const handleClose = (e) => {
    setPlaidOpen(false);
    eventChange("plaid close");
  };

  function onChange(e, inputProps) {
    let { name, value, checked } = e.target
    console.log("plaid property onChange", name, value, checked);
    if (name === 'IncludePlaidFee') { 
      setPlaidProperty({ ...plaidProperty, [name]: checked });
    }
    else {
      setPlaidProperty({ ...plaidProperty, [name]: value });
    }
  }
  function eventChange(type) {
    console.log("event change, type", type);
    if (type === "plaid add") {
      props.eventChange(type, plaidProperty);
      setPlaidOpen(false);
    }
    else
      props.eventChange(type);
  }
  return (
    <>
      <DialogBox
        open={plaidOpen}
        onClose={(e) => handleClose()}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="delete-dialog-title"
          onClose={(e) => handleClose()}
        >
          {!isEditing ? "Add Plaid Keys" : "Edit Plaid Key"}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="PlaidClientKey"
                value={plaidProperty.PlaidClientKey}
                label={
                  !isEditing
                    ? "Publishable key"
                    : "Publishable key"
                }
                onChange={onChange}
                name="PlaidClientKey"
                autoComplete="PlaidClientKey"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="PlaidSecretKey"
                value={plaidProperty.PlaidSecretKey}
                label={
                  !isEditing
                    ? "Secret key"
                    : "Secret key"
                }
                onChange={onChange}
                name="PlaidSecretKey"
                autoComplete="PlaidSecretKey"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel ref={inputLabel} id="select-plaid-label">
                  PLAID API MODE
                </InputLabel>
                <Select
                  labelid="select-plaid-label"
                  id="select-plaid-outlined"
                  fullWidth
                  variant="outlined"
                  name="PlaidApiMode"
                  label="PLAID API MODE"
                  value={plaidProperty.PlaidApiMode ? plaidProperty.PlaidApiMode : 'Sandbox'}
                  labelWidth={labelWidth}
                  onChange={(e) => { onChange(e); }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Sandbox"}>
                    {"Sandbox"}
                  </MenuItem>
                  <MenuItem value={"Development"}>
                    {"Development"}
                  </MenuItem>
                  <MenuItem value={"Production"}>
                    {"Production"}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}> 
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <FormControlLabel
                    label='Yes, pass payment processing fee on to the tenants'
                    control={
                      <Checkbox
                        color="default"
                        checked={plaidProperty.IncludePlaidFee}
                        onChange={onChange}
                        name='IncludePlaidFee'
                        style={{ color: '#374761' }}
                      />
                    }
                  />
                </Grid>
                <Grid item>
                  <CustomizedTooltips
                    content={
                      "Plaid fee (0.8% per transaction)"
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction='row'
            spacing={2}
            alignItems='flex-start'
          >
            <Grid item>
              <LoaderButton
                block="true"
                type="submit"
                size="large"
                disabled={!validateForm()}
                onClick={() => eventChange('plaid add')}
              >
                {isEditing ? "EDIT" : "+ ADD"}
              </LoaderButton>
            </Grid>
            <Grid item>
              <LoaderDeleteButton
                size='large'
                onClick={(e) => handleClose()}
              >
                CANCEL
              </LoaderDeleteButton>
            </Grid>
          </Grid>
        </DialogActions>
      </DialogBox>
    </>
  );
}
