import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import clsx from 'clsx';
import { API } from 'aws-amplify';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography"; 
import Box from '@mui/material/Box';
import { LoaderDeleteButton } from "../LoaderButton";
import LoaderButton, { LoaderCLickButton } from "../LoaderButton";
import { openSnackbar } from '../Notifier';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CropPortraitSharpIcon from '@mui/icons-material/CropPortraitSharp';
import usePersistedState from '../../usePersistedState';
import Avatar from '@mui/material/Avatar';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import StepConnector from '@mui/material/StepConnector';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
import { useSnackbar } from 'notistack';
const MapData = (process.env.REACT_APP_STAGE === 'dev') ? awsdev : (process.env.REACT_APP_STAGE === 'dev2') ? awsdev2 : window['runConfig'];

/* const MapData = window['getConfig']; */


const PREFIX = 'addTenant';

const classes = {
    button: `${PREFIX}-button`,
    actionsContainer: `${PREFIX}-actionsContainer`,
    actionsExtContainer: `${PREFIX}-actionsExtContainer`,
    resetContainer: `${PREFIX}-resetContainer`,
    disabled: `${PREFIX}-disabled`,
    activeLabel: `${PREFIX}-activeLabel`,
    activeSubLabel: `${PREFIX}-activeSubLabel`,
    disabledLabel: `${PREFIX}-disabledLabel`,
    completedLabel: `${PREFIX}-completedLabel` 
};

const Root = styled('div')((
  {
    theme
  }
) => ({ 
  width: '100%',
  [`& .${classes.button}`]: {
    marginTop: 8,
    marginRight: 8,
  },
  [`& .${classes.actionsContainer}`]: {
    marginBottom: 16,
  },
  [`& .${classes.actionsExtContainer}`]: {
    marginBottom: 16,
    marginTop: 16,
  },
  [`& .${classes.resetContainer}`]: {
    padding: 24,
    paddingTop:0
  },
  [`& .${classes.disabled}`]: {
    backgroundColor:'#707070'
  },
  [`& .${classes.activeLabel}`]: {
    color:'#000000',
    display: 'inline',
    fontWeight:'bold',
    fontSize:'1rem'
  },
  [`& .${classes.activeSubLabel}`]: {
    color:'#757575',
    display: 'inline', 
    fontSize:'1rem'
  },
  [`& .${classes.disabledLabel}`]: {
    color: '#757575'
  },
  [`& .${classes.completedLabel}`]: {
    color: '#000000'
  },
  
}));

  const ColorlibStepContent = styled(StepContent)(({ theme }) => ({
    [`&.${stepConnectorClasses.root}`]: {
        marginTop: '8px',
        borderLeft: '1px solid #707070',
        marginLeft: '22px',
        paddingLeft: '20px',
        paddingRight: '8px'
    }, 
  }));
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor:'#707070',
        borderColor:'#707070',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: '#707070',
        borderColor: '#707070',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
        width: 1,      
        border: 0,
        backgroundColor: '#707070',
        borderRadius: 1,
    },
    [`& .${stepConnectorClasses.vertical}`]: {
        marginLeft:'22px',
    },
  }));
 const ColorlibStepIconStyles = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',    
    justifyContent: 'center',
    alignItems: 'center',
    '& .inherit': {
        color: 'inherit',
        backgroundColor:  'inherit'
    },
    ...(ownerState.active1 && {
        backgroundColor: '#9354F3',
    }),
    ...(ownerState.active2 && {
        backgroundColor:'#FF0000'
    }),
    ...(ownerState.active3 && {
        backgroundColor:'#FF7600'
    }),
    ...(ownerState.completed1 && {
        backgroundColor:'#ccc',
    }),
    ...(ownerState.completed2 && {
        backgroundColor: '#0AE20A', 
    }),
    ...(ownerState.activeLabel && {
        color:'#000000',
        display: 'inline',
        fontWeight:'bold',
        fontSize:'1rem'
    }),
  }));

  const DialogBox = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(2),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  }));
    
  const DialogTitle = (props => {
    const { children,  onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography  sx={{m:0, p:2, pl:3, pr:3}}  {...other}>
        <Typography variant="h3" component="h3" sx={{ lineHeight:1}}>{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: "8px",
              top: "8px",
              color: "text.secondary",
            }} 
            onClick={onClose}
            size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  

function getSteps() {
    return ['Enter the email addresses','Enter the email addresses'];
  }
let defaultFields={ 'MasterID':'', 'FirstName': '-',
'LastName': '-',
'CognitoUserID':'',
'Name':'',
'PhoneNumber':'',
'Email':'',
'PropertyID': [], 
'Privilege':'RENTERS' };
export function AddTenant(props) {
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [labelWidth, setLabelWidth] = useState(0);   
    const [addUserOpen, setAddUserOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);    
    const [selectedProperty] = usePersistedState('selectedProperty', {});
    const [email, setEmail]= useState('');
    const [emailHelper, setEmailHelper]= useState('');
    const [isUserExist,setIsUserExist]=useState(false);
    const [tenantUser, setTenantUser] =usePersistedState('tenantUser',defaultFields);
    const [leaseUsers, setLeaseUsers] =usePersistedState('leaseUsers',[]); 
    useEffect(() => {
        if (props.userOpen) {
            setLeaseUsers([]);
            setTenantUser(defaultFields);
        }
        setAddUserOpen(props.userOpen);
    }, [props]);
      
    useEffect(() => {
       console.log('leaseUsers',leaseUsers);
     },[leaseUsers])
    //fromLease
    const steps = getSteps();
    const handleNext =async () => {
        //getUserByEmail]
        try {
            let userData= await getUserByEmail();
            console.log('userData',userData);
            if(userData.length>0)
            {
                if(userData[0].Privilege==='RENTERS' || userData[0].Privilege==='USER')
                { 
                    if(userData[0].hasOwnProperty('Property') && _isContains(userData[0].Property,selectedProperty.PropertyID))
                    {
                        setIsLoading(false); 
                            setEmailHelper(`The user already exists in the system as a tenant. Please try again with a different email`)                       
                        
                    }
                    else
                    {                       
                        setIsUserExist(true);
                        setTenantUser({...tenantUser, ...userData[0]});  
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);   
                        setIsLoading(false);
                    }                             
                }
                else{
                    setIsLoading(false); 
                    setEmailHelper(`The user already exists in the system as a team member. Please try again with a different email or remove the user as an administrator.`)
                }             
            }
            else
            {
                setIsLoading(false); 
                setIsUserExist(false);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
           
        } catch (er) {
            setIsLoading(false); 
            console.log('check tenantUser exist error');            
            enqueueSnackbar('Error happened, Please try after some time!', { variant: "error" });
        }
    };
    const _isContains = (json, value) => {
        let contains = false;
        Object.keys(json).some(key => {
            contains = typeof json[key] === 'object' ? _isContains(json[key], value) : json[key] === value;
            return contains;
        });
        return contains;
    }
    const handleFinish =async () => {
        setIsLoading(true);
        try {
            await addNewTenant();    
            //  setActiveStep((prevActiveStep) => prevActiveStep + 1);    //      
            setIsLoading(false); 
        } catch (err) {
            console.log(err);
            setIsLoading(false);  
        }      
    };
    const handleBack = () => {
        setIsUserExist(false);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
        setEmail('');
        setTenantUser(defaultFields);
    };
    const handleClose = (e) => {
        if (e === "addUser") {
            setAddUserOpen(false);
            setActiveStep(0);
            props.onChildClick('closeUser');
        }
    };
    function ColorlibStepIcon(props) {
       /*  const classes = useColorlibStepIconStyles(); */
        const { active, completed } = props; 
        const icons = {
            1:activeStep !== steps.length ?<Avatar className={'inherit'}>1</Avatar>:<CheckOutlinedIcon/>,
            2:activeStep !== steps.length ?<Avatar  className={'inherit'}>2</Avatar>:<CheckOutlinedIcon/>,
            3:activeStep !== steps.length ?<Avatar  className={'inherit'}>3</Avatar>:<CheckOutlinedIcon/>,
        }; 
        let active2 =active && Boolean(emailHelper)?true:false;
        let active3 = active && isUserExist? true:false;
        let active1 = active && (!active2 && !active3)? true:false;
        let completed1= completed && (activeStep !== steps.length)? true:false;
        let completed2 = completed && (!completed1)? true:false;
        console.log(completed1, active1, active2, active3, completed2)
        return (
            <ColorlibStepIconStyles
                ownerState={{ completed1, active1, active2, active3, completed2 }}               
            >
                {icons[String(props.icon)]}
            </ColorlibStepIconStyles>
        );
    };
   
    function onChange(e) {
        setEmail(e.target.value);
        setEmailHelper('');
        setTenantUser({...tenantUser,Email:e.target.value});
    };
    async function getUserByEmail(userSession) {
        setIsLoading(true);
        let body = { userData: userSession,PropertyID: selectedProperty.PropertyID, masterType: 'User', email: email };
        return new Promise((resolve, reject) => {
            let apiName = 'rentapi';
            let path = '/modules';
            let myInit = {
                headers: { 'Content-Type': 'application/json' },
                body: body,
                queryStringParameters: {
                    module: 'user',
                    op: 'getUserByEmail'
                }
            };
            API.post(apiName, path, myInit)
                .then(response => {
                    if (response.data && response.data.length > 0) {
                        resolve(response.data);
                    }
                    else {
                        resolve([]);
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }
    const validateForm =()=>{
        console.log('email && email.length>0',email, email.length);
        return Boolean(email) && !Boolean(emailHelper)
    }
    function addNewTenant() { 
        /* const MapData =window['getConfig']; */
        setIsLoading(true);
        if (tenantUser.Email) {            
            let apiName = 'rentapi';
            let path = '/modules';
            let userRequest = { 
              MasterID: tenantUser.MasterID,
              FirstName: tenantUser.FirstName,
              LastName: tenantUser.LastName,
              PropertyID: selectedProperty.PropertyID,
              PropertyName: selectedProperty.PropertyName, 
              Email: tenantUser.Email,
              domain:MapData.aws_app_domain_prefix,
              userData:props.userSession,
              Privilege:tenantUser.Privilege  
            } 
            let myInit = {
              headers: { 'Content-Type': 'application/json' },
              body: userRequest,
              queryStringParameters: {
                module: 'user',
                op: 'addTenantUser'
              }
            }; 
            API.post(apiName, path, myInit).then(response => { 
              setIsLoading(false);              
              if(response.success && response.data)
              { 
                setLeaseUsers(prvValue=>[...prvValue,response.data]);
                setTenantUser(response.data);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);    
                  let message =response.isUserExist?`${tenantUser.Email} already exist in the system. We have sent out an invitation`:`Invitation sent out ${tenantUser.Email}`;
                  //setEmailHelper(message);
                  enqueueSnackbar(message, { variant: 'success' });
              }
              else
              {      
                //setEmailHelper(response.message);      
                enqueueSnackbar(response.message, { variant: 'error' });
              }                  
            }).catch(err => {
              console.log(err);
              setIsLoading(false);
              enqueueSnackbar('Tenant adding failed', { variant: 'error' });
            });    
        }
        else {
          //PLEASE FILL ALL DATA
          setIsLoading(false);
          setEmailHelper('Please enter all required fields');
          enqueueSnackbar('Please enter all required fields', { variant: 'error' }); 
        }
      }
  /*   const classes = useStyles(); */
    return (
        <DialogBox open={addUserOpen} onClose={e => handleClose("addUser")} fullWidth={true}
            maxWidth={"sm"} aria-labelledby="form-dialog-title">
            <DialogTitle  id="email-dialog-title" onClose={e => handleClose("addUser")}>
                Add Tenant
            </DialogTitle>
            <DialogContent>
                <Root className={classes.root}>
                    <Stepper activeStep={activeStep} orientation="vertical" connector={<ColorlibConnector />}>                          
                         <Step>
                                <StepLabel 
                                  classes={{
                                    active: classes.activeLabel,
                                    disabled: classes.disabledLabel,
                                    completed:classes.completedLabel
                                  }} 
                                StepIconComponent={ColorlibStepIcon}>
                              {activeStep===0?<>
                                {'Enter the email addresses'}
                               <Typography  component="span"  className={classes.activeSubLabel}>{` of the user you would like to invite to the resident portal. The tenant will receive an email  invitation to input their personal information and setup a password.`}
                                </Typography> </>: "Enter the email addresses"}                                   
                                 </StepLabel>
                                <ColorlibStepContent>
                            <Grid container direction="column" spacing={2}>           
                                <Grid item xs={12} sm={12}> 
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email"                                             
                                            onChange={onChange}
                                            name="email"
                                            autoComplete="off"
                                            value={email} 
                                            inputProps={{ style: { textTransform: "lowercase" } }}
                                            error={Boolean(emailHelper)}
                                            helperText={emailHelper}
                                        /> 
                                </Grid> 
                                </Grid>           
                                <Grid
                                    container
                                    direction='row'
                                    spacing={2}
                                    alignItems='flex-start'
                                >
                                    <Grid item>
                                    <LoaderDeleteButton                                                    
                                                size="large"                                                    
                                                disabled
                                                onClick={handleBack} 
                                            >
                                               BACK
                                            </LoaderDeleteButton> 
                                    </Grid>
                                    <Grid item>
                                    <LoaderCLickButton                                                    
                                                size="large"                                                    
                                                onClick={handleNext}
                                                className={classes.button}
                                                isLoading={isLoading}
                                                disabled={!validateForm()}
                                            >
                                                NEXT
                                            </LoaderCLickButton>   
                                    </Grid>
                                </Grid>                            
                                 
                                </ColorlibStepContent>
                            </Step>
                            <Step>
                                <StepLabel    
                                 classes={{
                                    active: classes.activeLabel,
                                    disabled: classes.disabledLabel,
                                    completed:classes.completedLabel
                                  }} 
                                StepIconComponent={ColorlibStepIcon}>
                              {activeStep===1?<>
                                {'Confirm invitation'}
                               <Typography  component="span"  className={classes.activeSubLabel}>{isUserExist?` for ${email} into ${selectedProperty.PropertyName}’s resident portal. 
                               We noticed the tenant already exists in the platform. If you wish to invite the same user, please click finish and we will notify the user. `:
                              ` for ${email} into ${selectedProperty.PropertyName}'s resident portal.`}
                                </Typography> </>: "Confirm invitation"} 
                                  </StepLabel>
                                <ColorlibStepContent>   
                                <Grid
                                    container
                                    direction='row'
                                    spacing={2}
                                    alignItems='flex-start'
                                >
                                    <Grid item>
                                    <LoaderDeleteButton                                                    
                                                size="large"    
                                                onClick={handleBack} 
                                            >
                                               BACK
                                            </LoaderDeleteButton> 
                                    </Grid>
                                    <Grid item>
                                    <LoaderCLickButton                                                    
                                                size="large"                                                    
                                                onClick={handleFinish}
                                                className={classes.button}
                                                isLoading={isLoading}
                                            >
                                                FINISH
                                            </LoaderCLickButton>  
                                    </Grid>
                                </Grid>                    
                                    
                                </ColorlibStepContent>
                            </Step>
                    </Stepper>
                    {activeStep === steps.length && (
                        <Box className={classes.resetContainer}>
                            <Typography className={classes.activeSubLabel}>
                               { `Success, we have sent out an invitation to ${email}. Please inform the user to check the inbox for email confirmation shortly`}
                                <br/> <br/>
                                Now let’s activate the lease for the tenant.
                            </Typography>
                            <div className={classes.actionsExtContainer}>
                                         
                                            <LoaderDeleteButton                                                    
                                                size="large"     
                                                block="true"  
                                                onClick={handleReset} 
                                                
                                            >
                                               START OVER
                                            </LoaderDeleteButton> 
                                            <LoaderCLickButton                                                    
                                                size="large"                                                    
                                                onClick={()=>{
                                                    if (props.fromLease) {
                                                            props.onChildClick('add tenant',leaseUsers);
                                                    }
                                                    else {
                                                        history.push({ pathname: `/${selectedProperty.PropertyName.replace(/\s+/g, '-')}/lease/add`, props: { editLease: false, property: selectedProperty, tenant: leaseUsers/* tenantUser */, currentLease: {} } });
                                                    }
                                                }}
                                               
                                            >
                                                ACTIVATE LEASE
                                            </LoaderCLickButton>                                             
                                        
                                    </div>
                        </Box>
                    )}
                </Root>
            </DialogContent>
        </DialogBox>
    );
}