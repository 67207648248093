import React, { useState, useEffect, useRef, useCallback } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { areas } from '../../common/constants/areas';
import Moment from 'react-moment';
import { API } from "aws-amplify";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Spinner } from '../../common/components/spinner';
import { useLocation } from "react-router-dom";
import { AmplifyS3Image  } from '@aws-amplify/ui-react';
import profileDefault from "../../static/images/Avatar_A25_Ellipse_2_pattern.png";
import usePersistedState from '../../usePersistedState';
import CustomizedTooltips from '../../lib/tooltIp';
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Skeleton } from '@mui/material';
//** Data Grid Import Part **/
import { LicenseInfo, DataGridPro, GridOverlay, useGridApiRef, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport } from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import EditUser from "./editUser";
LicenseInfo.setLicenseKey(
  '79e390e23cc1709a04137710c7c5cdc1T1JERVI6Mjg0NDQsRVhQSVJZPTE2NjEwMDE3MTQwMDAsS0VZVkVSU0lPTj0x',
);
const MAX_ROW_LENGTH = 1500;
//** Data Grid Import Part **/

const min = new Date('2018-01-01').getFullYear();
const max = new Date().getFullYear();
const comboYear = [];
for (var i = min; i <= max; i++) {
  comboYear.push(i)
}

const PREFIX = 'manageuser';

const classes = { 
  card1: `${PREFIX}-card1`, 
  details: `${PREFIX}-details`
};

const Root = styled('div')((
  {
    theme
  }
) => ({  
  [`& .${classes.card1}`]: {
    display: "flex",
    marginRight: "auto", 
    maxHeight: "100px",
    maxWidth: "125px"
  }, 
  [`& .${classes.details}`]: {
    display: "flex"
  }
}));
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

 
//** Data Grid Action related Code */
function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}
//** Data Grid Action related Code */
export function ViewUser(props) { 
  const location = useLocation(); 
  const [isLoading, setIsLoading] = useState(false);
  const [rowEditData, setRowEditData] = usePersistedState("rowEditData", {});
  const [userViewFilter, setuserViewFilter] = useState({ "Month": [], "Year": [] });
  const inputLabel = useRef(null);
  const [selectedProperty] = usePersistedState('selectedProperty', {});
  const [transactionTypeCombo] = usePersistedState("transactionTypeCombo", []);
  const [userFormOpen, setUserFormOpen] = useState(false);
  //** Data Grid Related Code */
  const apiRef = useGridApiRef();
  const [filterValue, setFilterValue] = React.useState({});
  const [data, setData] = useState({
    loading: true,
    rows: [],
    pageSize: 25,
    columns: [{ field: "MasterID", hide: true, filterable: false },
    {
      headerName: "TRANSACTION DATE",
      field: "TransactionOn",
      renderCell: params => <Moment format="MMM DD YYYY">{params.value}</Moment>,
      flex: 1,
      minWidth: 150,
      type: 'date'
    },
    { headerName: "LEASE HOLDER(S)", field: "LeaseHolder", flex: 1, minWidth: 150 },

    { headerName: "RENT MONTH", field: "TransactionRentMonth", flex: 1, minWidth: 150 },
    {
      headerName: "TRANSACTION TYPE", field: "TransactionType", flex: 1, minWidth: 150,
      renderCell: (params) => {
        return params.value ? (
          params.value == 'cash' ? "Cash"
            : params.value == 'etransfer' ? "E-transfer"
              : params.value == 'checkdeposit' ? "Check"
                : params.value == 'STRIPE' ? 'Credit Card'
                  : params.value == 'PLAID' ? 'ACH'
                    : params.value == '' ? "" : capitalizeFirstLetter(params.value.toLowerCase())
        ) : ""
      },
      type: 'singleSelect',
      valueOptions: transactionTypeCombo.map((e) => { return e.Text })
    },
    {
      headerName: "DEPOSIT", field: "TransactionDeposit", flex: 1, minWidth: 150, filterable: false,
      renderCell: params => { return params.value === 0 ? "-" : "$" + params.value }
    },
    {
      headerName: "MISC", field: "TransactionMiscValue", flex: 1, minWidth: 150, filterable: false,
      renderCell: params => { return params.value === 0 ? "-" : "$" + params.value }
    },
    {
      headerName: "RENT", field: "TransactionRent", flex: 1, minWidth: 150, filterable: false,
      renderCell: params => { return params.value === 0 ? "-" : "$" + params.value }
    },
    {
      field: "ConvenienceFee", flex: 1, minWidth: 170, filterable: false,
      renderCell: params => { return params.value === 0 ? "-" : "$" + params.value },
      renderHeader: () => (
        <> <strong>
          {'FEE (TENANT) '}
        </strong>
          <CustomizedTooltips content={'Payment gateway processing fee paid by the tenant'} />
        </>
      )
    },
    {
      headerName: "TOTAL", field: "TransactionTotal", flex: 1, minWidth: 150, filterable: false,
      renderCell: params => { return params.value === 0 ? "-" : "$" + params.value }
    }
    ]
  });
  const onFilterChanges = useCallback((filterModel) => {
    console.log('filterModel', filterModel)
    setFilterValue(filterModel);
    mounted.current = true;
  }, []);
  useEffect(() => {
    let active = true;
    (async () => {
      const newData = await GetInfinityTable(data.pageSize, 0);
      if (mounted.current) {
        if (newData && newData)
          updateData("rows", newData);
      }
      if (!active) {
        return;
      }
      updateData("loading", false);
      setIsLoading(false);
    })();
    return () => {
      active = false;
    };
  }, [filterValue]);
  const mounted = React.useRef(true);
  function CustomToolbar(props) {
    const [search, setSearch] = React.useState(props.value);
    return (
      <GridToolbarContainer>
        <Grid container
          spacing={3}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            {data.loading && <div style={{ display: "flex" }}>
              <Skeleton variant="rectangular" width={30} height={32} className={"skelton-margin-right-5"} />
              <Skeleton variant="rectangular" width={100} height={32} />
            </div>}
            {!data.loading && <GridToolbarFilterButton />}
          </Grid>
          <Grid item>
            {data.loading && <div style={{ display: 'flex' }}>
              <Skeleton variant='rectangular' width={30} height={32} className={'skelton-margin-right-5'} />
              <Skeleton variant='rectangular' width={100} height={32} />
            </div>}
            {!data.loading && <GridToolbarExport />}
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }
  const updateData = (k, v) => setData((prev) => ({ ...prev, [k]: v }));
  const loadServerRows = async (newRowLength) => {
    updateData("loading", true);
    const newData = await GetInfinityTable(data.pageSize, newRowLength);
    console.log('newData 2', newData);
    if (mounted.current) {
      if (newData)
        updateData("rows", data.rows.concat(newData));
      updateData("loading", false);
    }
  };

  const handleOnRowsScrollEnd = (params) => {
    if (data.rows.length <= MAX_ROW_LENGTH && data.rows.length !== 0) {
      loadServerRows(data.rows.length);
    }
  };
  async function GetUserProfile(MasterId) {
    console.log("UserID ", MasterId);
    setIsLoading(true);
    var columns = [];
    var masterType = "User";
    var apiModule = "user";
    var op = "getUser";
    var userData = { "userData": props.userSession }
    let body = { masterType: masterType, MasterID: MasterId, for: "CardDisplay", ...userData };
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: apiModule,
          op: op
        }
      };
      API.post(apiName, path, myInit)
        .then(response => {
          setIsLoading(false);
          if (response && response.length > 0) {
            console.log("prop lateMinutes changed 1 : ", response[0]);
            setRowEditData(response[0]);
            console.log(rowEditData);
          }
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }
  async function GetInfinityTable(pageSize, fromNo) {
    updateData("loading", true);
    updateData("pageSize", pageSize);
    var rwData = location.props && location.props.rowData ? location.props.rowData : rowEditData;
    if (!location.props.rowData.hasOwnProperty('Email')) {
      console.log("Rendering from the Transaction Page");
      console.log(location.props.rowData);
      GetUserProfile(rwData.MasterID);
    }
    var body = {
      masterType: "Transaction",
      property: selectedProperty,
      UserID: rwData.MasterID,
      pageSize: pageSize,
      fromNo: fromNo,
      userData: props.userSession,
      filterValue: filterValue
    };
    return new Promise((resolve, reject) => {
      let apiName = "rentapi";
      let path = "/modules";
      let myInit = {
        headers: { "Content-Type": "application/json" },
        body: body,
        queryStringParameters: {
          module: 'renter',
          op: 'viewTransactions'
        }
      };
      API.post(apiName, path, myInit)
        .then(async response => {
          resolve(response.data);
        })
        .catch(err => {
          console.log(err);
          reject({ "data": [] });
        });
    });
  }
  //** Data Grid Related Code END */

  useEffect(() => {
    let isSubscribed = true;
    console.log(location.pathname); // result: '/secondpage'   
    console.log(location.props); // result: 'some_value' 
    const userFetch = async () => {
      if (location.props && isSubscribed) {
        setRowEditData(location.props.rowData);
      }
      return () => isSubscribed = false
    }
    userFetch();
  }, [userViewFilter]);

  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }

  let capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  return (
    <div className="home-root">
      {userFormOpen && <EditUser type={"tenant"} userSession={props.userSession} isEditing={true} user={rowEditData} fromGrid={true} userFormOpen={userFormOpen} setUserFormOpen={setUserFormOpen} />}
      <Grid
        container
        spacing={0}
        direction="column"
      >
        <Grid item>
          <Container component="main" className="home-main">
            <CssBaseline />
            <Spinner className="common-spinner"
              area={areas.admin}
            />
            <div className="home-paper">
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <Root className={classes.card1}>
                        <div className={classes.card1}>
                          {rowEditData.ProfileImage && rowEditData.ProfileImage.hasOwnProperty("FileKey") ?
                            <AmplifyS3Image style={{ "--height": "100%", "--width": "100%" }} imgProps={{ display: "block", width: 100, height: 100 }} level="public" imgKey={rowEditData.ProfileImage.FileKey} />
                            : <img src={profileDefault} alt="" />}
                        </div>
                      </Root>                      
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <Typography
                            component="h2"
                            variant="h2"
                          >
                            {rowEditData.Name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            component="h5"
                            variant="h5"
                          >
                            {rowEditData.Email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid style={{ alignSelf: 'baseline' }} item>
                      <BootstrapTooltip arrow title="Edit Profile" placement="right">
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            setUserFormOpen(true);
                          }}
                          aria-label="delete"
                          size="large">
                          <EditOutlinedIcon />
                        </IconButton>
                      </BootstrapTooltip>
                    </Grid>

                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                  >
                    This page will output a complete billing transaction history for
                    the account holder.
                  </Typography>
                </Grid>
              </Grid>
              {/* DataGridPro Start */}
              <div style={{ width: '100%', height: 560 }}>
                <DataGridPro
                  apiRef={apiRef}
                  density="compact"
                  rowHeight={81}
                  hideFooter
                  hideFooterPagination
                  loading={data.loading}
                  onRowsScrollEnd={handleOnRowsScrollEnd}
                  rows={data.rows}
                  getRowId={(r) => r.MasterID}
                  columns={data.columns}
                  filterMode="server"
                  onFilterModelChange={onFilterChanges}
                  components={{
                    LoadingOverlay: CustomLoadingOverlay,
                    Toolbar: CustomToolbar
                  }}
                />
              </div>
              {/* DataGridPro Start */}
            </div>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}
