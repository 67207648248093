import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import Container from "@mui/material/Container";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import MLink from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import LoaderButton from "../LoaderButton";
import clsx from 'clsx';
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { API } from 'aws-amplify';
import { openSnackbar } from '../Notifier';
import { useSnackbar } from 'notistack';
import Visibility from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Policy from "../../lib/Policy";
import PoweredBy from '../../lib/PoweredBy';
import { AmplifyS3Image  } from '@aws-amplify/ui-react';
import awsdev from '../../aws-dev';
import awsdev2 from '../../aws-dev2';
import { Skeleton } from '@mui/material';
const PREFIX = 'ResetPassword';

const classes = {
    "@global": `${PREFIX}-undefined`,
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    avatar: `${PREFIX}-avatar`,
    form: `${PREFIX}-form`,
    submit: `${PREFIX}-submit`,
    header: `${PREFIX}-header`,
    myImage: `${PREFIX}-myImage`,
    margin: `${PREFIX}-margin`,
    withoutLabel: `${PREFIX}-withoutLabel`,
    textField: `${PREFIX}-textField`,
    welcomeText: `${PREFIX}-welcomeText`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.undefined}`]: {
        body: {
            backgroundColor: theme.palette.common.white
        }
    },

    [`& .${classes.root}`]: {
        display: "flex",
        "& > * + *": {
            marginLeft: theme.spacing(2)
        }
    },

    [`& .${classes.paper}`]: {
        marginTop: theme.spacing(8),
        display: "flex",
        justify: "center",
        flexDirection: "column",
        alignItems: "flex-start"
    },

    [`& .${classes.avatar}`]: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },

    [`& .${classes.form}`]: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3)
    },

    [`& .${classes.submit}`]: {
        margin: theme.spacing(3, 0, 2)
    },

    [`& .${classes.header}`]: {
        fontSize: 20,
        fontStyle: "bold",
        fontFamily: "Arial-BoldMT, Arial"
    },

    [`& .${classes.myImage}`]: {
        width: "100%"
    },

    [`& .${classes.margin}`]: {
        marginTop: theme.spacing(2),
    },

    [`& .${classes.withoutLabel}`]: {
        marginTop: theme.spacing(3),
    },

    [`& .${classes.textField}`]: {
        width: 200,
    },

    [`& .${classes.welcomeText}`]: {
        fontSize: "20px",
    }
}));

const MapData = (process.env.REACT_APP_STAGE === "dev") ? awsdev : (process.env.REACT_APP_STAGE === "dev2") ? awsdev2 : window['runConfig'];
export function ResetPassword(props) {
    const { enqueueSnackbar } = useSnackbar(); 
    const queryParams = new URLSearchParams(props.location.search);
    var vrData = {}
    queryParams.forEach(function (value, key) {
        vrData[key] = value;
    });
    const [verifyData, setVerifyData] = useState(vrData);
    const [welcomeView, setWelcomeView] = useState(false);
    var [isVerifyLoading, setIsVerifyLoading] = useState(false);
    const [confirmPass, setConfirmPass] = useState({
        newPassword: "",
        email: "",
        password: "",
        codeSent: false,
        confirmed: false,
        confirmPassword: "",
        isConfirming: false,
        isSendingCode: false,
        isLoading: false,
        setIsLoading: (value) => {
            confirmPass.isLoading = value;
        },
        showPassword: false
    });

    const handleClickShowPassword = () => {
        setConfirmPass({ ...confirmPass, showPassword: !confirmPass.showPassword });
    };
    const handleMouseDownPassword = e => {
        e.preventDefault();
    };
    function validateResetForm() {
        return (
            confirmPass.newPassword.length > 0 &&
            confirmPass.password.length > 0 &&
            confirmPass.password === confirmPass.newPassword
        );
    }
    function SkeltonLoader() {
        return (
            <Root width="100%">
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '85vh' }}
                >
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                        <Container component="main" >
                            <CssBaseline />
                            <div className={classes.paper}>
                                <Grid container justifyContent="flex-start" direction="column" alignItems="flex-start" spacing={2}>
                                    <Grid item>
                                        {MapData.management_logo && MapData.management_logo != '' ? <AmplifyS3Image  level="public" imgKey={MapData.management_logo} /> :
                                            <Typography className='managment-header' component="h2" variant="h2">
                                                {MapData.management_name}
                                            </Typography>}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography className="verify-user-header" gutterBottom={true}>
                                            <Skeleton variant="rectangular" width={385} height={30} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography component="h1" variant="h5">
                                            <Skeleton variant="rectangular" width={385} height={30} />
                                            <Skeleton variant="rectangular" width={385} height={30} />
                                            <Skeleton variant="rectangular" width={385} height={30} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography component="h1" variant="h5">
                                            <Skeleton variant="rectangular" width={385} height={30} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Skeleton variant="rectangular" width={112} height={45} />
                                    </Grid>
                                </Grid>

                            </div>
                        </Container>
                    </Grid>
                </Grid>
                <PoweredBy />
                <Policy class={"policy-cls"} />
            </Root>
        );
    }
    const onChange = e => {        
        setConfirmPass({
            ...confirmPass,
            [e.target.id]: e.target.value
        });
    }
    const confirmPassword = async (e) => { 
        e.preventDefault();
        setIsVerifyLoading(true);
        setConfirmPass({ ...confirmPass, isConfirming: true });      
        let apiName = "rentapi";
        let path = "/verify/reset";
        let myInit = {
            headers: { "Content-Type": "application/json" },
            body: {
                email: verifyData.email,
                password: confirmPass.newPassword,
                code: verifyData.code,
                userId: verifyData.username,
                clientId: MapData.aws_user_pools_web_client_id
            },
            queryStringParameters: {
                module: "user",
                op: "forceResetPassword"
            }
        };
        API.post(apiName, path, myInit).then(response => {
            setIsVerifyLoading(false);
            if (response.success) {                
                setConfirmPass({ ...confirmPass, confirmed: true, password: "", confirmPassword: "", newPassword: "" });
                // openSnackbar({ message: response.message, variant: "success" });
                enqueueSnackbar(response.message, {variant: 'success'});
                setWelcomeView(true);
            }
            else {
                // openSnackbar({ message: response.message, variant: "error" });
                enqueueSnackbar(response.message, {variant: 'error'});
                setConfirmPass({ ...confirmPass, isConfirming: false });
                setWelcomeView(true);
            }
        }).catch(err => {
            console.log(err);
            setIsVerifyLoading(false);
            // openSnackbar({ message: err.message, variant: "error" }); 
            enqueueSnackbar(err.message, {variant: 'error'});         
            setConfirmPass({ ...confirmPass, isConfirming: false });
        });
    } 
    return (
        <div width="100%">
        {!isVerifyLoading && <>   <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '85vh' }}
            >
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Container component="main" maxWidth="sm">
                        <CssBaseline />
                        {welcomeView ?
                              <div className="forgot-password-paper">
                              <Grid container justifyContent="center" alignItems="center"   direction="column" spacing={1}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  {MapData.management_logo && MapData.management_logo != '' ? <AmplifyS3Image  level="public" imgKey={MapData.management_logo} /> :
                                    <Typography className='managment-header' component="h2" variant="h2" >
                                      {MapData.management_name}
                                    </Typography>}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div  className={'forgot-user-icon'}>
                                  <CheckCircleIcon style={{fill: '#0AE20A'  , fontSize:'54px'}} />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Typography className="forgot-user-header" component="h5" variant="h5">
                                    Password set successfully
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Typography className="forgot-user-body"  gutterBottom={true} variant="h5">
                                    To continue, please click the login button below
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <div style={{ padding: "20px 0px 40px 0px" }}>
                                    <LoaderButton
                                      block="true"
                                      type="submit"
                                      size="large"
                                      component={Link}
                                      to="/"
                                    >
                                      LOGIN
                                    </LoaderButton>
                                  </div>
                              
                                </Grid> 
                              </Grid> 
                            </div>          
                            :
                            <div className={classes.paper}>
                                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        {MapData.management_logo && MapData.management_logo != '' ? <AmplifyS3Image  level="public" imgKey={MapData.management_logo} /> :
                                            <Typography className='managment-header' component="h2" variant="h2">
                                                {MapData.management_name}
                                            </Typography>}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography className="verify-user-header" component="h5" variant="h5">
                                            Welcome {verifyData.name}!
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <form className={classes.form} noValidate onSubmit={confirmPassword}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                autoComplete="new password"
                                                name="newPassword"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                type="password"
                                                id="newPassword"
                                                label="New Password"
                                                value={confirmPass.newPassword}
                                                onChange={onChange}
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                        <FormControl fullWidth required className={clsx(classes.margin)} variant="outlined">
                                        <InputLabel htmlFor="password">Verify New Password</InputLabel>
                                        <OutlinedInput
                                            id="password"
                                            autoComplete="old password"
                                            type={confirmPass.showPassword ? 'text' : 'password'}
                                            value={confirmPass.password}
                                            onChange={onChange}
                                            autoFocus
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => handleClickShowPassword()}
                                                        onMouseDown={(e) => handleMouseDownPassword(e)}
                                                        size="large">
                                                        {confirmPass.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            } 
                                            label="Verify New Password"
                                        />
                                        </FormControl>
                                        </Grid>
                                    </Grid>
                                    
                                    <div style={{ padding: "20px 0px 40px 0px" }}>
                                        <Grid container direction="column" justifyContent="center" alignItems="center">
                                            <Grid item xs={12}>
                                                <LoaderButton
                                                    block="true"
                                                    type="submit"
                                                    size="large"
                                                    className={classes.submit}
                                                    isLoading={isVerifyLoading}
                                                    disabled={!validateResetForm()}
                                                >
                                                    SUBMIT
                                                </LoaderButton>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <Grid container direction="column" justifyContent="center" alignItems="center">
                                        <Grid item>
                                            <MLink color="text.secondary" underline={"always"} component={Link} to="/">
                                                <Typography color="text.secondary" gutterBottom={true} variant="body2">
                                                    BACK
                                                </Typography>
                                            </MLink>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        }
                    </Container>
                </Grid>
            </Grid>
            <PoweredBy />
            <Policy class={"policy-cls"} />
            </>}
            {isVerifyLoading && <SkeltonLoader/>  }  
        </div>
    );

}