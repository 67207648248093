import React, { useRef, useState, useEffect } from "react";
import './App.css';
import  { Amplify, API, Auth } from 'aws-amplify';
import { withRouter, useHistory } from "react-router-dom";
import Routes from "./Routes";
import Notifier from './components/Notifier';
import usePersistedState from './usePersistedState';  
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  responsiveFontSizes} from "@mui/material/styles"
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
/* import { load as loadIntercom, boot as bootIntercom, update as updateIntercom, shutdown as shutdownIntercom } from "./lib/intercom";
import { createBrowserHistory } from "history" */
import { ModelWindow } from "./common/components/modelwindow"
import { useIdleTimer } from 'react-idle-timer'
import awsdev from './aws-dev';
import awsdev2 from './aws-dev2';
import * as Sentry from '@sentry/browser';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { SnackbarProvider } from 'notistack'; 

const MapData = (process.env.REACT_APP_STAGE === "dev") ? awsdev : (process.env.REACT_APP_STAGE === "dev2") ? awsdev2 : window['runConfig'];


Amplify.configure(MapData);  /* Configure Amplify */ 
/* const bhistory = createBrowserHistory()
bhistory.listen((location) => {
  // Calls Intercom('update') on every page change
  updateIntercom();
}); */
 

function App(props) {
  const history = useHistory();
  const ref = useRef(null);
  const text = {
    primary: '#000000',
    secondary: '#757575',
    disabled: 'rgba(55, 65, 81, 0.48)',
    info: '#6100ED'
  };
  const divider = '#E6E8F0';
  /* create global mui theame*/
  let theme =   createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1000,
        lg: 1200,
        xl: 1920
      }
    },
     spacing: 8,
     typography: {
      button: {
        fontWeight: 600
      },    
      body1: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5
      },
      body2: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.57
      },
      subtitle1: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '1.25rem'
      },
      subtitle2: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.57
      },
      overline: {
        fontSize: '0.75rem',
        fontWeight: 600,
        letterSpacing: '0.5px',
        lineHeight: 2.5,
        textTransform: 'uppercase'
      },
      caption: {
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.66
      },
      h1: {
        fontWeight: 700,
        fontSize: '3.5rem',
        lineHeight: 1.375
      },
      h2: {
        fontWeight: 700,
        fontSize: '3rem',
        lineHeight: 1.375
      },
      h3: {
        fontWeight: 700,
        fontSize: '2.25rem',
        lineHeight: 1.375
      },
      h4: {
        fontWeight: 700,
        fontSize: '2rem',
        lineHeight: 1.375
      },
      h5: {
        fontWeight: 500,
        fontSize: '1.5rem',
        lineHeight: 1.375
      },
      h6: {
        fontWeight: 400,
        fontSize: '1.125rem',
        lineHeight: 1.375
      },

    },   
    palette: {
      textPrimary: { main: "#000000de" },
      textSecondary:{ main: "#707070" },
      text:text,
      background: {
        default: "#C7C7C7",
        paper: "#FFFFFF",
        card: "#F4F4F4"
        
      },
      primary: { main: "#3f51b5" },
      secondary: { main: "#ffe100" },
      error: { main: "#f44336" },
      info: {main: "#6200EE"},
      success: {main: "#4CAF50"}
    },
    overrides:{  
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 1,
              color: text.secondary
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: divider
          }
        }
      },
      MuiDataGrid:{
        root:{
          border:'none',
          '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: `1px solid #f0f0f0`,
          },
        },

      }, 
      MuiButton:{
        styleOverrides:{
          textPrimary:{
            color: text.primary
          }
        }
      }
    }
  });
  theme = responsiveFontSizes(theme);
  const [idleCounter, setIdleCounter] = useState(1);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userSession, setuserSession] = useState({});
  const [isForceLogout, setIsForceLogout] = useState(false);
  const [locationKeys, setLocationKeys] = useState([]) 
  const [whichDevice, setWhichDevice] = useState('Desktop');
  const [selectedProperty, setSelectedProperty] = usePersistedState('selectedProperty', {}); 
  const [userProperties,setUserProperties] = usePersistedState('userProperties', []); 
  const [isLoading, setIsLoading] = useState(false);   
  useEffect(() => {
    /** Handling Browser back button */
    return history.listen(location => {
      // Calls Intercom('update') on every page change 
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }
      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          // Handle forward event  
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          // Handle back event 
          //history.goBack();
        }
      }
    })
  }, [locationKeys,]);

  /** useIdleTimer SATRT
   * Module will check user is idle or not
   * Show user idle warning 
   * Handle force logout
  */
  useEffect(() => {
    if (isAuthenticated) {
      if (idleCounter === 5) {
        if (ref) ref.current.handleOpen();
      }
      else if (idleCounter === 6) {
        setIsForceLogout(true);
      }
    }
  }, [idleCounter]);
  useEffect(() => {
    if (isForceLogout && isAuthenticated)
      handleLogout();
  }, [isForceLogout]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search) // id=123
    let id = params.get('prCode') // 123  
    if(id && id!=='')
       localStorage.setItem('paymentPath', JSON.stringify({prCode:id})); 
  }, []);
  const handleOnIdle = event => {
    reset();
    setIdleCounter(idleCounter + 1);
  }
  const handleOnActive = event => {
    setIsForceLogout(false);
    setIdleCounter(1);
  }
  const handleOnAction = (e) => {
    setIsForceLogout(false);
    setIdleCounter(1);
  }
  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * 1,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onA23Wction: handleOnAction,
    debounce: 5000
  });
  const handleClose = () => {
    if (ref) ref.current.handleClose();
    setIsForceLogout(false);
    setIdleCounter(1);
  };
  /** useIdleTimer  END */ 

  useEffect(() => {
    onLoad();
  }, []);
  async function onLoad() {
    console.log('app js 1',props); 
    try { 
      isMobileOnly ? setWhichDevice('Mobile') : isTablet ? setWhichDevice('Tablet') : setWhichDevice('Desktop');
      const user = await Auth.currentSession(); 
      if (user) { 
        /** chack for user session present or not */ 
        Sentry.setUser({ email:  user.idToken.payload["email"] }); 
        setuserSession({
          FirstName: user.idToken.payload["custom:firstname"],
          LastName: user.idToken.payload["custom:lastname"],
          Email: user.idToken.payload["email"],
          UserName: user.idToken.payload["cognito:username"],
          Privilege: user.idToken.payload["custom:privilege"],
          PoolId: MapData.poolId
        });
      /*   loadIntercom() // Initialise intercom chat bot
        bootIntercom({
          email: localStorage.email
        })  */
        userHasAuthenticated(true);
      }
      else { 
        userHasAuthenticated(false); 
        console.log('on load props &&&&& ',props.location.pathname)   
        if (props.location.pathname !== "/") {
          localStorage.setItem('redirectUrl', props.location.pathname);
        }        
        //props.history.push("/");
      }
    }
    catch (e) {
      console.log("User Exception", e);      
      if ((e === 'No current user' || e.code === 'UserNotFoundException') && props.location.pathname !== "/verify") {
        console.log("UserNotFoundException", e);
        console.log('on load props &&&&& ',props.location.pathname)   
        if (props.location.pathname !== "/") {
          localStorage.setItem('redirectUrl', props.location.pathname);
        }
        userHasAuthenticated(false);
        //props.history.push("/");
      } 
    }
    setIsAuthenticating(false);
  }


  const handleLogout = async () => { 
    /* shutdownIntercom() */
    delete localStorage.email
    window.localStorage.removeItem('selectedProperty');
    window.localStorage.removeItem('renterProperty');
    window.localStorage.removeItem('user');
    /* bootIntercom(); */
    await Auth.signOut();
    userHasAuthenticated(false);
    handleClose();
    props.history.push("/");
  }
  return !isAuthenticating &&
    <div>
       <CacheProvider value={createCache({ key: 'css' })}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Notifier />
          <SnackbarProvider maxSnack={3}>
          <Routes appProps={{ whichDevice, isMobile, isAuthenticated, userHasAuthenticated, userSession, setuserSession, history }} />
          <ModelWindow /* Show user idle model window */
            onClickOne={handleLogout}
            onClickTwo={handleClose}
            buttonOneText={"Logout"}
            buttonTwoText={"Stay"}
            title={"You Have Been Idle!"}
            content={"You will get timed Out. You want to stay?"}
            IsbuttonOne={true}
            IsbuttonTwo={true}
            ref={ref} />
        </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
      </CacheProvider>
    </div>;
}
export default withRouter(App);
