import React from 'react';
import { Route, Switch } from 'react-router-dom';
import  SignIn  from './components/SignIn';
import Home from './components/home';
import Biller from './components/biller';
import Renter from './components/renter';
import Manager from './components/manager';
import SignUp from './components/SignUp';
import NotFound from './components/NotFound';
import AppliedRoute from './components/AppliedRoute';
import ForgotPassword from './components/ForgotPassword';
import Verify from './components/Verify';
import ChangeEmail from './components/ChangeEmail';
import AdminRegistration from './components/admin/adminRegistration';
import PrivacyPolicy from './static//pages/PrivacyPolicy'
import TermsOfService from './static/pages/TermsOfService'
import {User, ManageUser,ViewUser} from './components/user';
import GlobalSettings from './components/admin/globalSettings';
import { ResetPassword, Decline } from "./components/auth";
import { ListMyProperty,/* Property,ManageProperty, */ PropertySettings,SettingsGeneral,SettingsPayment,SettingsTeams } from './components/property';
import {/* ListProperty, */ MakePayment,ViewPaymentHistory,ManagePaymentMethod } from './components/renters';
import {/* ViewProperty, */ViewTransactions,ManageRents,AddLease} from './components/billers'
/** Handle application page routing 
 * THREE TYPE OF PRIVILEGES ARE THERE ADMIN, BILLING, RENTERS
 * Based on the user privilege page routing decision will perform
 */
export default function Routes({ appProps }) {    
  return (
    <Switch>
    <AppliedRoute  path='/' exact component={appProps.isAuthenticated?  ListMyProperty : SignIn} appProps={appProps} />
    <AppliedRoute  path='/change-email' exact component={appProps.isAuthenticated? ChangeEmail:SignIn} appProps={appProps} />    
    <AppliedRoute  path='/register' exact component={SignUp} appProps={appProps} />
    <AppliedRoute  path='/forget' exact component={ForgotPassword} appProps={appProps} />      
    <AppliedRoute  path='/verify' exact component={Verify} appProps={appProps} /> 
    <AppliedRoute  path='/reset' exact component={ResetPassword} appProps={appProps} />     
    <AppliedRoute  path='/decline' exact component={Decline} appProps={appProps} /> 
    <AppliedRoute  path='/admin/register' exact component={AdminRegistration} appProps={appProps} />   
    <AppliedRoute  path='/privacy-policy' exact component={PrivacyPolicy} appProps={appProps} />   
    <AppliedRoute  path='/terms-of-service' exact component={TermsOfService} appProps={appProps} /> 
    <AppliedRoute  path='/profile' exact component={appProps.isAuthenticated?ManageUser:SignIn} appProps={appProps} /> 
    <AppliedRoute  path='/settings' exact component={appProps.isAuthenticated && appProps.userSession.Privilege==='ADMIN'?GlobalSettings:SignIn} appProps={appProps} />   
    <Route path='/404' component={NotFound} />
    <AppliedRoute  path='/:property' exact component={appProps.isAuthenticated? (appProps.userSession.Privilege==='ADMIN'?Home: (appProps.userSession.Privilege==='BILLING'?Biller:(appProps.userSession.Privilege==='MANAGER'?Manager:Renter))):SignIn} appProps={appProps} /> 
    <AppliedRoute  path='/:property/settings' exact component={appProps.isAuthenticated && appProps.userSession.Privilege==='ADMIN'?PropertySettings:SignIn} appProps={appProps} />
    <AppliedRoute  path='/:property/settings/general' exact component={appProps.isAuthenticated? (appProps.userSession.Privilege==='ADMIN'?SettingsGeneral: (appProps.userSession.Privilege==='BILLING'?Biller:(appProps.userSession.Privilege==='MANAGER'?Manager:Renter))):SignIn} appProps={appProps} />  
    <AppliedRoute  path='/:property/settings/payments' exact component={appProps.isAuthenticated? (appProps.userSession.Privilege==='ADMIN'?SettingsPayment: (appProps.userSession.Privilege==='BILLING'?Biller:(appProps.userSession.Privilege==='MANAGER'?Manager:Renter))):SignIn} appProps={appProps} />  
    <AppliedRoute  path='/:property/settings/teams' exact component={appProps.isAuthenticated? (appProps.userSession.Privilege==='ADMIN'?SettingsTeams: (appProps.userSession.Privilege==='BILLING'?Biller:(appProps.userSession.Privilege==='MANAGER'?Manager:Renter))):SignIn} appProps={appProps} />      
    <AppliedRoute  path='/:property/settings/teams/edit' exact component={appProps.isAuthenticated? (appProps.userSession.Privilege==='ADMIN'?ManageUser: (appProps.userSession.Privilege==='BILLING'?Biller:(appProps.userSession.Privilege==='MANAGER'?Manager:Renter))):SignIn} appProps={appProps} /> 
    <AppliedRoute  path='/:property/transactions' exact component={appProps.isAuthenticated? (appProps.userSession.Privilege==='ADMIN'?ViewTransactions: (appProps.userSession.Privilege==='BILLING'?ViewTransactions:(appProps.userSession.Privilege==='MANAGER'?ViewTransactions:Renter))):SignIn} appProps={appProps} />
    <AppliedRoute  path='/:property/lease' exact component={appProps.isAuthenticated? (appProps.userSession.Privilege==='ADMIN'?ManageRents: (appProps.userSession.Privilege==='BILLING'?ManageRents:(appProps.userSession.Privilege==='MANAGER'?ManageRents:Renter))):SignIn} appProps={appProps} />   
    <AppliedRoute  path='/:property/lease/add' exact component={appProps.isAuthenticated? (appProps.userSession.Privilege==='ADMIN'?AddLease: (appProps.userSession.Privilege==='BILLING'?AddLease:(appProps.userSession.Privilege==='MANAGER'?AddLease:Renter))):SignIn} appProps={appProps} />      
    <AppliedRoute  path='/:property/lease/edit' exact component={appProps.isAuthenticated? (appProps.userSession.Privilege==='ADMIN'?AddLease: (appProps.userSession.Privilege==='BILLING'?AddLease:(appProps.userSession.Privilege==='MANAGER'?AddLease:Renter))):SignIn} appProps={appProps} />        
    <AppliedRoute  path='/:property/tenant' exact component={appProps.isAuthenticated? (appProps.userSession.Privilege==='ADMIN'?User: (appProps.userSession.Privilege==='BILLING'?Biller:(appProps.userSession.Privilege==='MANAGER'?User:Renter))):SignIn} appProps={appProps} />       
    <AppliedRoute  path='/:property/tenant/add' exact component={appProps.isAuthenticated? (appProps.userSession.Privilege==='ADMIN'?ManageUser: (appProps.userSession.Privilege==='BILLING'?Biller:(appProps.userSession.Privilege==='MANAGER'?ManageUser:Renter))):SignIn} appProps={appProps} />
    <AppliedRoute  path='/:property/tenant/edit' exact component={appProps.isAuthenticated? (appProps.userSession.Privilege==='ADMIN'?ManageUser: (appProps.userSession.Privilege==='BILLING'?Biller:(appProps.userSession.Privilege==='MANAGER'?ManageUser:Renter))):SignIn} appProps={appProps} /> 
    <AppliedRoute  path='/:property/tenant/view' exact component={appProps.isAuthenticated? (appProps.userSession.Privilege==='ADMIN'?ViewUser: (appProps.userSession.Privilege==='BILLING'?Biller:(appProps.userSession.Privilege==='MANAGER'?ViewUser:Renter))):SignIn} appProps={appProps} />    
    <AppliedRoute  path='/:property/payment' exact component={appProps.isAuthenticated && appProps.userSession.Privilege==='RENTERS'?MakePayment:SignIn} appProps={appProps} />
    <AppliedRoute  path='/:property/manage' exact component={appProps.isAuthenticated && appProps.userSession.Privilege==='RENTERS'? ManagePaymentMethod /* ManageCards */:SignIn} appProps={appProps} />        
    <AppliedRoute  path='/:property/history' exact component={appProps.isAuthenticated && appProps.userSession.Privilege==='RENTERS'?ViewPaymentHistory:SignIn} appProps={appProps} />         
    {/* <AppliedRoute  path='/properties/:property' exact component={appProps.isAuthenticated? (appProps.userSession.Privilege==='ADMIN'?ViewProperty: (appProps.userSession.Privilege==='BILLING'?ViewProperty:Renter)):SignIn} appProps={appProps} /> */}
    
     <Route component={NotFound} />
    </Switch>
  );
}
