import React from 'react';
import { styled } from '@mui/material/styles'; 
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

 

export default function CustomizedTooltips({
    content,
    iconClass,
    placement, 
  }) {
    
    return ( 
      <BootstrapTooltip
           title={content}  
           placement={placement?placement:'top'}
           arrow 
         >
          <ErrorIcon />               
         </BootstrapTooltip>
    );
  }